import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    usuario: {
        id: "",
        Prefijo: null,
        Nombre: "",
        ApellidoPaterno: "",
        ApellidoMaterno: "",
        NombreComercial: "",
        TelefonoOficina: "",
        Direccion: "",
        Celular: "",
        Cel: "",
        Email: "",
        Correo: "",
        Nacimiento: "",
        Localidad: null,
        Estado: null,
        Universidad: null,
        Sexo: null,
        TelefonoEmergencia: "",
        Tipo: null,
        Imagen_Url: "",
        Status: null,
        Paquete: "",
        IdPaquete: "",
        IdAlmacenamiento: null,
        PaqueteStatus: null,
        FechaPaquete: "",
        fecha: "",
        GoogleSynced: false,
    },
    profesiones: []
};

const setSyncGoogleState = (state, data) => {
    state.usuario.GoogleSynced = data;
}

export const userSlice = createSlice({
    name: "infoUser",
    initialState,
    reducers: {
        infoUser: (state, action) => {
            const {
                Usuario,
                Profesiones,
            } = action.payload;
            state.usuario = Usuario;
            state.usuario.GoogleSynced = Usuario.google_calendar_refresh_token? true: false;
            state.usuario.Contra = undefined;
            state.usuario.remember_token = undefined;
            state.usuario.api_token = undefined;
            state.usuario.google_calendar_token = undefined;
            state.usuario.google_calendar_refresh_token = undefined;
            state.profesiones = Profesiones;
        },
        userSyncGoogle: (state, action) => setSyncGoogleState(state, action.payload)
    }
})


export const { infoUser, userSyncGoogle } = userSlice.actions;

export default userSlice.reducer;