import React, { useRef, useState } from 'react';
import { Label, LeyendaError } from '../GeneralComponents/Formularios';
import "../../pages/styles/Registro.scss"
import { Input } from 'antd';
import "dayjs/locale/es";




const InputDate = ({ value, setStateValue, type, label, placeholder, id, textError, setStateError, valueError, disabled,
    readOnly, vacio, maxLength, className, style, styleDiv, extras, styleLabel, mayorEdad, setEdad, requiredICon }) => {

    const [MensajeError, setMensajeError] = useState(textError !== undefined ? textError[0] : "");

    const inputRef = useRef(null);

    function getEdad(dateString, inputValue) {
        if (inputValue.length === 10) {
            let hoy = new Date();
            let fechaArray = dateString.split("/");
            let fechaNacimiento = new Date(`${fechaArray[2]}/${fechaArray[1]}/${fechaArray[0]}`);

            let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
            let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
            if (
                diferenciaMeses < 0 ||
                (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
            ) {
                edad--
            }
            console.log("EDAD: " + edad);
            if (setEdad !== undefined) {
                setEdad(edad);
            }
            if (edad >= 18) {
                // setEdad(true);
                return true;
            } else {
                // setEdad(false);
                return false;
            }
        } else {
            if (setEdad !== undefined) {
                setEdad(0);
            }

        }


    }

    const handleChange = (e) => {
        const input = e.target.value.replace(/\D/g, ""); // Eliminar todo lo que no sea número
        let newValue = "";

        // Formatear la fecha automáticamente
        if (input.length <= 2) {
            newValue = input; // Solo DD
            if (input.length === 2) {
                newValue += "/"; // Agregar "/" después de DD
            }
        } else if (input.length <= 4) {
            newValue = `${input.slice(0, 2)}/${input.slice(2)}`; // DD/MM
            if (input.length === 4) {
                newValue += "/"; // Agregar "/" después de MM
            }
        } else {
            newValue = `${input.slice(0, 2)}/${input.slice(2, 4)}/${input.slice(4, 8)}`; // DD/MM/AAAA
        }

        setStateValue(newValue);
        if (newValue.length === 10) {
            setStateError("true");

        }

        let edad = getEdad(newValue, newValue);
        if (mayorEdad !== undefined) {
            if (edad) {
                setStateError("true");
            } else {
                setMensajeError(textError[1]);
                setStateError("false");
            }
        } else {

            if (setStateError !== undefined) {
                setStateError("true");
            }
        }

        // Mover el cursor a la posición correcta
        let cursorPos = newValue.length;
        if (input.length === 2) {
            cursorPos = 3; // Después de DD/
        } else if (input.length === 4) {
            cursorPos = 6; // Después de DD/MM/
        }

        // Asegurarse de que la posición del cursor no exceda la longitud del valor
        // if (cursorPos > newValue.length) {
        //     cursorPos = newValue.length;
        // }

        // Mover el cursor solo si el campo de texto está enfocado
        // if (inputRef.current) {
        //     inputRef.current.setSelectionRange(cursorPos, cursorPos);
        // }

        inputRef.current.setSelectionRange(cursorPos, cursorPos);


        // if (mayorEdad !== undefined) {
        //     if (edad) {
        //         setStateError("true");
        //     } else {
        //         setMensajeError(textError[1]);
        //         setStateError("false");
        //     }
        // } else {

        //     if (setStateError !== undefined) {
        //         setStateError("true");
        //     }
        // }
    };

    // Manejar la pérdida de enfoque del campo
    const handleBlur = (e) => {
        if (e.target.value === "") {
            setMensajeError(textError[0]);
            setStateError("false");
        } else if (e.target.value.length > 0 && e.target.value.length < 10) {
            setMensajeError(textError[1]);
            setStateError("false");
        }
    };

    return (
        <div style={styleDiv}>
            {(label !== undefined && !!label) && (<Label valido={valueError}>{vacio !== undefined ? (!vacio && requiredICon) && <b style={{ color: "#ff4d4f", fontSize: "14px", fontFamily: "SimSun,sans-serif" }}>*&nbsp;</b> : ""}{label}</Label>)}
            <Input
                placeholder={placeholder}
                value={!!value ? value : ""}
                style={style}
                status={valueError !== undefined && !!valueError ? valueError === 'true' ? "" : "error" : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
            />
            <LeyendaError valido={valueError}>{MensajeError}</LeyendaError>

        </div>
    );
}

export default InputDate;