import React, { useState } from "react";
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import "dayjs/locale/es";
import { SetFechaInicial, SetFechaFinal, SetCalendarView } from './../../redux/citas';

dayjs.extend(isBetween);
const localizer = dayjsLocalizer(dayjs);

export default function CalendarPage({ ClickCalendarCallback, ClickEventCallback, citas, ChangeRangeCallback, Icons}) {
    const [currentView, setCurrentView] = useState('month');
    const { fechaInicio, fechaFinal, calendarDate } = useSelector((state) => state.appointments);

    const dispatch = useDispatch();
    const { calendarView } = useSelector(state=>state.appointments)

    const components = {
        event: props => {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {Icons && Icons[props.event.status]}
                    <p style={{ marginLeft: 5, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: "90vw", fontSize:"12px" }}>{props.title}</p>
                </div>
            )
        }
    }

    const changeRangeHandler = (range) => {        
        const dayStart = dayjs(range.start);
        const dayEnd = dayjs(range.end);

        const dif = dayEnd.diff(dayStart);
        let calendarNewDate = dayStart.add((dif/2), 'millisecond');
        
        let rangeChage = false;

        if(dayStart < dayjs(fechaInicio)){
            dispatch(SetFechaInicial(dayStart.toISOString()));
            rangeChage = true;
        }
        if(dayEnd > dayjs(fechaFinal)){
            dispatch(SetFechaFinal(dayEnd.toISOString()));
            rangeChage = true;
        }

        if(rangeChage){
            ChangeRangeCallback();
        }
    }

    return (
        <div>
            <Calendar
                localizer={localizer}
                // date={calendarDate}
                events={citas}
                startAccessor="start"
                endAccessor="end"
                onSelectEvent={(e) => {
                    ClickEventCallback(e.id, e.idContacto, 1);
                    // historiaClinica(e.idContacto); //TODO que es? creo que no se usa
                }}
                style={{ height: "100vh", position: 'sticky' }}
                culture="es"
                messages={{
                    next: "siguiente",
                    previous: "anterior",
                    today: "Hoy",
                    month: "Mes",
                    week: "Semana",
                    day: "Día",
                    date: "Fecha",
                    time: "Hora",
                    event: "Evento",
                    noEventsInRange: "No hay citas en este rango de fechas"
                }}
                components={components}
                eventPropGetter={
                    (event, start, end, isSelected) => {
                        let newStyle = {
                            backgroundColor: 'rgba(0, 188, 212, .2)',
                            color: 'black',
                            borderRadius: "0px",
                            border: "none",
                            borderRadius: 7,
                        };

                        return {
                            className: "",
                            style: newStyle
                        };
                    }
                }
                selectable={true}
                onSelectSlot={(slotInfo) => {
                    let selectDate = new Date(slotInfo.slots[0])
                    const offset = selectDate.getTimezoneOffset()
                    selectDate = new Date(selectDate.getTime() - (offset * 60 * 1000))
                    let date = selectDate.toISOString().split('T')[0];
                    
                    if(ClickCalendarCallback){
                        ClickCalendarCallback(date);
                    }
                }}
                onRangeChange={(range)=>changeRangeHandler(range)}
                formats={{
                    monthHeaderFormat: (date, culture, localizer) => localizer.format(date, `MMMM  YYYY `, culture),
                }}
                onView={(view)=>dispatch(SetCalendarView(view))}
                view={calendarView}
            />
        </div>
    );

}
