import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "../comp-styles/addExpediente.scss"

import CheckGuardar from "../../Img/Iconos/checkmark_white.svg";
import Iconos from "../../componentes/IconosDeSitio";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import TextArea from "antd/es/input/TextArea";
import { Select } from "antd";
import InputAntd from "../../componentes/GeneralComponents/InputAntd"
import SelectAntd from "../../componentes/GeneralComponents/SelectAntd"
import TextAreaAntd from "../../componentes/GeneralComponents/TextAreaAntd"
import Accordionn from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import dayjs from 'dayjs';
import "dayjs/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import { Loader } from "../../componentes/GeneralComponents/Loader";

import { DatePicker, Row, Col, Button } from "antd";
import { LeyendaError, Label } from "../../componentes/GeneralComponents/Formularios";

import { addExpediente_request } from "../../services/expediente_service";
import { getEstado_request, getLocalidad_request } from "../../services/expediente_service";

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import NavbarNuevo from "../GeneralComponents/Navbar_Nuevo";
import { Content } from "antd/es/layout/layout";
import DatePickerAntd from "../GeneralComponents/DatePickerAntd";
import InputDate from "../GeneralComponents/InputDate";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function NuevoExpediente() {
    const location = useLocation();
    const navigate = useNavigate();

    const [DatosContacto, setDatosContacto] = useState({ id: location.state.id, nombre: location.state.nombre, imagen: location.state.imagen });

    const [loader, setLoader] = useState(false);

    const [Alergias, setAlergias] = useState("");
    const [Refiere, setRefiere] = useState("");
    const [ObservacionesPaciente, setObservacionesPaciente] = useState("");
    const [EstadoCivil, setEstadoCivil] = useState(null);
    const [Ocupacion, setOcupacion] = useState("");
    const [Sexo, setSexo] = useState(null);
    const [Nacimiento, setNacimiento] = useState("");
    const [Edad, setEdad] = useState(0);
    const [Nacionalidad, setNacionalidad] = useState("");
    const [Estado, setEstado] = useState(null);
    const [Localidad, setLocalidad] = useState(null);
    const [CodigoPostal, setCodigoPostal] = useState("");
    const [NombrePareja, setNombrePareja] = useState("");
    const [EdadPareja, setEdadPareja] = useState("");
    const [OcupacionPareja, setOcupacionPareja] = useState("");
    const [NotasPareja, setNotasPareja] = useState("");
    const [AHeredoFamiliares, setAHeredoFamiliares] = useState("");
    const [APersonalesPatologicos, setAPersonalesPatologicos] = useState("");
    const [APersonalesNoPatologicos, setAPersonalesNoPatologicos] = useState("");
    const [AAndroGineGeneral, setAAndroGineGeneral] = useState("");
    const [AAndroGine, setAAndroGine] = useState("");
    const [SignosSintomas, setSignosSintomas] = useState("");
    const [AparatoCardiovascular, setAparatoCardiovascular] = useState("");
    const [AparatoRespiratorio, setAparatoRespiratorio] = useState("");
    const [AparatoDigestivo, setAparatoDigestivo] = useState("");
    const [SistemaNefrourologico, setSistemaNefrourologico] = useState("");
    const [SistemaEndocrino, setSistemaEndocrino] = useState("");
    const [SistemaHematoproyetico, setSistemaHematoproyetico] = useState("");
    const [SistemaNervioso, setSistemaNervioso] = useState("");
    const [MusculoEsqueletico, setMusculoEsqueletico] = useState("");
    const [PielTegumentos, setPielTegumentos] = useState("");
    const [OrganosSentidos, setOrganosSentidos] = useState("");
    const [EsferaPsiquica, setEsferaPsiquica] = useState("");
    const [Genitales, setGenitales] = useState("");

    const [EstadosList, setEstadosList] = useState([]);
    const [LocalidadesList, setLocalidadesList] = useState([]);

    // MANEJA DE MENSAJES
    const [open, setOpen] = useState(false);
    const [typeMessage, setTypeMessage] = useState("");
    const [message, setMessage] = useState("");

    const [MensajeSexo, setMensajeSexo] = useState("true");

    async function addExpediente() {
        setLoader(true);
        if (!!Sexo) {
            // let data = new FormData();
            let data = {
                idUsuario: DatosContacto.id,
                antecedentesHereditarios: AHeredoFamiliares,
                antecedentesPatologicos: APersonalesPatologicos,
                antecedentesNoPatologicos: APersonalesNoPatologicos,
                sintomasGenerales: SignosSintomas,
                aparatoRespiratorio: AparatoRespiratorio,
                aparatoDigestivo: AparatoDigestivo,
                aparatoCardiovascular: AparatoCardiovascular,
                sistemaNefrourologico: SistemaNefrourologico,
                sistemaEndocrino: SistemaEndocrino,
                sistemaHematopoyetico: SistemaHematoproyetico,
                sistemaNervioso: SistemaNervioso,
                sistemaMusculo: MusculoEsqueletico,
                piel: PielTegumentos,
                organosSentidos: OrganosSentidos,
                esferaPsiquica: EsferaPsiquica,
                alergias: Alergias,
                observaciones: ObservacionesPaciente,
                refiere: Refiere,
                sexo: Sexo,
                nacimiento: Nacimiento,
                nacionalidad: Nacionalidad,
                estado: Estado,
                localidad: Localidad,
                cp: CodigoPostal,
            }

            let response = await addExpediente_request(data, DatosContacto.id);
            if (response.ok) {
                setOpen(true);
                setTypeMessage("success");
                setMessage("Expediente guardado");
                navigate("/app/ExpedientesNuevo");
            } else {
                setOpen(true);
                setTypeMessage("error");
                setMessage(response.mensaje);
            }
        } else {
            // cambiarNotasValido(false);
            setOpen(true);
            setTypeMessage("warning");
            setMessage("Faltan campos por completar");
            // if (Antecedente.valido === null) {
            //     cambiarAntecedente({ campo: "", valido: "false" });
            // }

            // if (Padecimiento.valido === null) {
            //     cambiarPadecimiento({ campo: "", valido: "false" });
            // }

            // if (Resultado.valido === null) {
            //     cambiarResultado({ campo: "", valido: "false" });
            // }

            // if (Diagnostico.valido === null) {
            //     cambiarDiagnostico({ campo: "", valido: "false" });
            // }

            // if (Terapeutica.valido === null) {
            //     cambiarTerapeutica({ campo: "", valido: "false" });
            // }

            // if (ExploFisica.valido === null) {
            //     cambiarExploFisica({ campo: "", valido: "false" });
            // }

            // if (Interrogatorio.valido === null) {
            //     cambiarInterrogatorio({ campo: "", valido: "false" });
            // }

            // if (document.getElementById("Tipo_Antecedente").value === "") {
            //     setComboTipoAntecedente("false");
            // }
        }
        setLoader(false);
        return;
    };

    async function getEstados() {
        let response = await getEstado_request();
        if (response.ok) {
            // console.log(response.data);
            let estados_temp = []
            response.data.forEach(estado => {
                estados_temp.push({ value: estado.id, label: estado.Estado });
            });
            setEstadosList(estados_temp);
        } else {
            setEstadosList([]);
        }
    }

    async function getLocalidades(estado) {
        let response = await getLocalidad_request(estado);
        if (response.ok) {
            // console.log(response.data);
            let localidades_temp = []
            response.data.forEach(estado => {
                localidades_temp.push({ value: estado, label: estado });
            });
            setLocalidadesList(localidades_temp);
        } else {
            setLocalidadesList([]);
        }
    }

    function filterOptions(input, option) {
        var chars = {
            "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
            "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
            "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
            "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
        }
        var expr = /[áàéèíìóòúùñ]/ig;
        return option?.label.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(input.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1
    }

    function filterSort(optionA, optionB) {
        var chars = {
            "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
            "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
            "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
            "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
        }
        var expr = /[áàéèíìóòúùñ]/ig;
        return (optionA?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase().localeCompare((optionB?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase())
    }

    function getEdad(dateString) {
        let hoy = new Date()
        let fechaNacimiento = new Date(dateString.replace(/-/g, "/"))
        let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
        let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
        if (
            diferenciaMeses < 0 ||
            (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
        ) {
            edad--
        }
        // console.log(edad);
        setEdad(edad);
        // return edad;
    }

    useEffect(() => {
        getEstados();
    }, []);

    return (
        <Stack>


            <NavbarNuevo
                mensajesError={{
                    setOpen: setOpen,
                    setTypeMessage: setTypeMessage,
                    setMessage: setMessage,
                }} />
            <Content
                className="site-layout"
                style={{
                    padding: '0 10px',
                    width: "100%",
                    overflow: 'initial',
                }}
            >
                <div className="Row">
                    {/* <Row> */}
                    {loader ? (<Loader />) : (
                        <>
                            <Content
                                className="site-layout"
                                style={{
                                    //   padding: '0 10px',
                                    width: "100%",
                                    overflow: 'initial',
                                }}
                            >

                                <div className="Historialflexible">
                                    <h5>Historial clínico</h5>
                                    <div className="BotonGuardarActive">
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                addExpediente();
                                            }}
                                            size="md"
                                            style={{
                                                background: "#148F9F",
                                                padding: ".5% 5%",
                                                margin: "0",
                                                border: "#148F9F",
                                                color: "white"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "6px",
                                                }}
                                            >
                                                <span>Guardar</span>
                                                <img
                                                    style={{ width: "12px", height: "12px" }}
                                                    src={CheckGuardar}
                                                    alt=""
                                                />
                                            </div>
                                        </Button>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginRight: "3%",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "1%",
                                            marginBottom: "1%",
                                            width: "95%",
                                        }}
                                    >
                                        <div>
                                            <img className="ImgHistorialPerf" src={!!DatosContacto.imagen ? `https://${DatosContacto.imagen}` : Iconos[0]} alt="" />

                                        </div>

                                        <div>
                                            <p style={{ fontWeight: "500", margin: "0" }}>
                                                {DatosContacto.nombre}
                                            </p>
                                            <div>
                                                <div>
                                                    <span style={{ fontWeight: "500", fontSize: "12px" }}>
                                                        Edad: {Edad === 0 ? "No especificada" : `${Edad} años`}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span style={{ fontWeight: "500", fontSize: "12px" }}>
                                                        ID YGIA: {DatosContacto.id}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <TextAreaAntd
                                        label="Alergias:"
                                        value={Alergias}
                                        placeholder="Alergias"
                                        style={{
                                            // margin: ".5% 0",
                                            marginBottom: ".5%",
                                            fontFamily: "sans-serif",
                                        }}
                                        columns="30"
                                        autoSize={true}
                                        setStateValue={setAlergias}

                                    />

                                    <TextAreaAntd
                                        label="Quien refiere:"
                                        value={Refiere}
                                        placeholder="Quien refiere"
                                        style={{
                                            // margin: ".5% 0",
                                            marginBottom: ".5%",
                                            fontFamily: "sans-serif",
                                        }}
                                        columns="30"
                                        autoSize={true}
                                        setStateValue={setRefiere}

                                    />

                                    <TextAreaAntd
                                        label="Observaciones sobre el paciente:"
                                        value={ObservacionesPaciente}
                                        placeholder="Observaciones sobre el paciente"
                                        style={{
                                            // margin: ".5% 0",
                                            marginBottom: ".5%",
                                            fontFamily: "sans-serif",
                                        }}
                                        columns="30"
                                        autoSize={true}
                                        setStateValue={setObservacionesPaciente}

                                    />

                                    <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                                        <SelectAntd
                                            label="Estado civil:"
                                            value={EstadoCivil}
                                            style={{ width: "100%" }}
                                            placeholder="Seleccione un estado civil"
                                            options={
                                                [
                                                    { value: "0", label: "Soltero/a" },
                                                    { value: "1", label: "Casado/a" },
                                                    { value: "2", label: "Viudo/a" },
                                                    { value: "3", label: "Divorciado/a" },
                                                    { value: "4", label: "Separado/a" },
                                                    { value: "5", label: "Concubinato" },
                                                ]
                                            }
                                            setStateValue={setEstadoCivil}
                                            styleDiv={{ width: "45%" }}
                                        // setStateError={setMensajeSexo}
                                        // valueError={MensajeSexo}
                                        // textError={"Debe seleccionar un estado civil"}
                                        />
                                        <InputAntd
                                            className="inputblue"
                                            value={Ocupacion}
                                            setStateValue={setOcupacion}
                                            label="Ocupación:"
                                            placeholder="Escribe una ocupación"
                                            // valueError={MensajeAddNombrecontacto}
                                            vacio={true}
                                            // disabled={false}
                                            // expresionRegular={expresiones.Letras}
                                            styleDiv={{ width: "45%" }}
                                        />
                                    </Row>

                                    <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                                        <SelectAntd
                                            label="Género:"
                                            requiredICon={true}
                                            value={Sexo}
                                            style={{ width: "100%" }}
                                            placeholder="Género"
                                            options={[{ value: "Femenino", label: "Femenino" }, { value: "Masculino", label: "Masculino" }]}
                                            setStateValue={setSexo}
                                            setStateError={setMensajeSexo}
                                            valueError={MensajeSexo}
                                            textError={["Debe seleccionar un género"]}
                                            styleDiv={{ width: "45%" }}
                                        />
                                        {/* <DatePickerAntd
                                            label="Fecha de nacimiento:"
                                            requiredICon={true}
                                            placeholder="DD/MM/AAAA"
                                            style={{ width: "100%", background: "white" }}
                                            value={!!Nacimiento ? dayjs(Nacimiento) : ""}
                                            setStateValue={setNacimiento}
                                            styleDiv={{ width: "45%" }}
                                            setEdad={setEdad}
                                        /> */}
                                        <InputDate
                                            label="Nacimiento:"
                                            requiredICon={true}
                                            placeholder="DD/MM/AAAA"
                                            style={{ width: "100%", background: "white" }}
                                            value={!!Nacimiento ? Nacimiento : ""}
                                            setStateValue={setNacimiento}
                                            styleDiv={{ width: "45%" }}
                                            setEdad={setEdad}
                                        // setStateError={setMensajeNacimiento}
                                        // valueError={MensajeNacimiento}
                                        // textError={["Debe elegir una fecha de nacimiento", "Fecha no válida"]}
                                        // vacio={false}
                                        />
                                    </Row>
                                    <InputAntd
                                        className="inputblue"
                                        value={Nacionalidad}
                                        setStateValue={setNacionalidad}
                                        label="Nacionalidad:"
                                        placeholder="Nacionalidad"
                                        vacio={true}
                                    />

                                    <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                                        <Col style={{ width: "45%" }}>
                                            <Label>Estado:</Label>
                                            <Select

                                                showSearch
                                                style={{
                                                    width: "100%",
                                                    margin: ".5% 0"
                                                }}
                                                placeholder="Estado"
                                                optionFilterProp="children"
                                                filterOption={filterOptions}
                                                filterSort={filterSort}
                                                value={Estado}
                                                onSelect={(e) => {
                                                    setEstado(e);
                                                    getLocalidades(e);
                                                }}
                                                options={EstadosList}
                                            />
                                        </Col>
                                        <Col style={{ width: "45%" }}>
                                            <Label>Localidad:</Label>
                                            <Select

                                                showSearch
                                                style={{
                                                    width: "100%",
                                                    margin: ".5% 0"
                                                }}
                                                placeholder="Localidad"
                                                optionFilterProp="children"
                                                filterOption={filterOptions}
                                                filterSort={filterSort}
                                                value={Localidad}
                                                onSelect={(e) => {
                                                    setLocalidad(e);
                                                }}
                                                options={LocalidadesList}
                                                disabled={LocalidadesList.length === 0 ? true : false}
                                            />
                                        </Col>
                                    </Row>
                                </div>

                            </Content>
                            <Content
                                className="site-layout"
                                style={{
                                    //   padding: '0 10px',
                                    width: "100%",
                                    overflow: 'initial',
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        alignItems: "center",
                                        marginRight: "3%",
                                    }}
                                >
                                    <div className="BotonGuardarActivation">
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                addExpediente();
                                            }}
                                            size="md"
                                            style={{
                                                background: "#148F9F",
                                                padding: ".5% 5%",
                                                margin: "0",
                                                border: "#148F9F",
                                                color: "white"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "6px",
                                                }}
                                            >
                                                <span>Guardar</span>
                                                <img
                                                    style={{ width: "12px", height: "12px" }}
                                                    src={CheckGuardar}
                                                    alt=""
                                                />
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                                {/* ### VISTA ANTECEDENTES EN HISTORIA CLINICA NUEVA ### */}
                                <div className="ScrollNotas" style={{ marginTop: "1%" }}>
                                    {/* DATOS DE PAREJA */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>
                                                Datos de pareja
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <InputAntd
                                                label="Nombre:"
                                                className="inputblue"
                                                value={NombrePareja}
                                                setStateValue={setNombrePareja}
                                                placeholder="Escriba un nombre completo"
                                                vacio={true}
                                                styleDiv={{ width: "100%" }}
                                            // maxLength={15}
                                            />
                                            <Row style={{ justifyContent: "space-between" }}>
                                                <InputAntd
                                                    className="inputblue"
                                                    value={OcupacionPareja}
                                                    setStateValue={setOcupacionPareja}
                                                    label="Ocupación:"
                                                    placeholder="Escribe una ocupación"
                                                    // valueError={MensajeAddNombrecontacto}
                                                    vacio={true}
                                                    // disabled={false}
                                                    // expresionRegular={expresiones.Letras}
                                                    styleDiv={{ width: "70%" }}
                                                />

                                                <InputAntd
                                                    label="Edad:"
                                                    className="inputblue"
                                                    value={EdadPareja}
                                                    setStateValue={setEdadPareja}
                                                    placeholder="Edad"
                                                    vacio={true}
                                                    styleDiv={{ width: "15%" }}
                                                    maxLength={2}
                                                />
                                            </Row>
                                            <TextAreaAntd
                                                label="Notas/Comentarios:"
                                                value={NotasPareja}
                                                placeholder="Escriba una nota o comentario"
                                                style={{
                                                    // margin: ".5% 0",
                                                    marginBottom: ".5%",
                                                    fontFamily: "sans-serif",
                                                }}
                                                columns="30"
                                                autoSize={true}
                                                setStateValue={setNotasPareja}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* ANTECEDENTES HEREDO-FAMILIARES */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography> Antecedentes heredo-familiares</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TextArea
                                                placeholder="Antecedentes heredo-familiares"
                                                style={{
                                                    margin: ".5% 0",
                                                    fontFamily: "sans-serif",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                onChange={(e) => {
                                                    setAHeredoFamiliares(e.target.value);
                                                }}
                                                value={AHeredoFamiliares}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* ANTECEDENTES PATOLOGICOS */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography>
                                                Antecedentes personales patológicos
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TextArea
                                                placeholder="Antecedentes personales patológicos"
                                                style={{
                                                    margin: ".5% 0",
                                                    fontFamily: "sans-serif",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                onChange={(e) => {
                                                    setAPersonalesPatologicos(e.target.value);
                                                }}
                                                value={APersonalesPatologicos}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* ANTECEDENTES NO PATOLOGICOS */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography>
                                                Antecedentes personales no patológicos
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TextArea
                                                placeholder="Antecedentes personales patológicos"
                                                style={{
                                                    margin: ".5% 0",
                                                    fontFamily: "sans-serif",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                onChange={(e) => {
                                                    setAPersonalesNoPatologicos(e.target.value);
                                                }}
                                                value={APersonalesNoPatologicos}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* ANTECEDENTES ANDROLOGICOS O GINECO-OBSTETRICOS */}
                                    {!!Sexo && (
                                        <Accordionn>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                            >
                                                <Typography>
                                                    {Sexo === "Masculino" ? "Antecedentes andrológicos" : "Antecedentes gineco-obstetricos"}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <label htmlFor="">Generales</label>
                                                <TextArea
                                                    placeholder="Inicio de vida sexual, método de planificación familiar, orientación sexual, si sostiene relaciones sexuales de alto riesgo, alteraciones de la libido, si ha padecido enfermedades de transmisión sexual."
                                                    style={{
                                                        margin: ".5% 0",
                                                        fontFamily: "sans-serif",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    onChange={(e) => {
                                                        setAAndroGineGeneral(e.target.value);
                                                    }}
                                                    value={AAndroGineGeneral}
                                                />
                                                <label htmlFor="">{Sexo === "Masculino" ? "Andrológicos específicos" : "Ginecoobstetricos"}</label>
                                                <TextArea
                                                    placeholder={Sexo === "Masculino" ? "Priapismo, alteraciones de la erección y/o eyaculación, secreción uretral, dolor testicular, alteraciones escrotales, enfermedades de transmisión sexual."
                                                        : "Menarca, Telarca, Pubarca, Gestas, Partos Cesáreas, Abortos, Dismenorreas, Ciclo menstrual."}
                                                    style={{
                                                        margin: ".5% 0",
                                                        fontFamily: "sans-serif",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    onChange={(e) => {
                                                        setAAndroGine(e.target.value);
                                                    }}
                                                    value={AAndroGine}
                                                />
                                            </AccordionDetails>
                                        </Accordionn>
                                    )
                                    }
                                    {/* INTERROGATORIO APARATOS Y SISTEMAS */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography>
                                                Interrogatorio por aparatos y sistemas
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <label htmlFor="">Signos y síntomas generales</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Fiebre, cefalea, astenia, adinamia y anorexia."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={SignosSintomas}
                                                onChange={(e) => {
                                                    setSignosSintomas(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Aparato cardiovascular</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Disnea, dolor precordial, palpitaciones, sincope, lipotimia, edema, cianosis, acúfenos, fosfenos. "
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={AparatoCardiovascular}
                                                onChange={(e) => {
                                                    setAparatoCardiovascular(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Aparato respiratorio</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Rinorrea, rinolalia, tos, expectoración, disnea, dolor torácico, epistaxis, disfonía, hemoptisis, vómica, sibilancias audibles a distancia. "
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={AparatoRespiratorio}
                                                onChange={(e) => {
                                                    setAparatoRespiratorio(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Aparato digestivo</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Trastornos de la deglución, de la digestión, de la defecación, nausea, vómito, dolor abdominal, diarrea, constipación, ictericia, rumiación, regurgitación, pirosis, aerofagia, eructos, meteorismos, distención abdominal, flatulencia, hematemesis, características de las heces fecales, diarrea, estreñimiento, acolia, hipocolia, melena, rectorragia, lientería, pujo, tenesmo y prurito anal."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={AparatoDigestivo}
                                                onChange={(e) => {
                                                    setAparatoDigestivo(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Sistema nefrourologico</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Dolor renoureteral, hematuria, piuria, coluría, oliguria, tenesmo, control de esfínteres, cólico renal, edema, trastornos en el volumen urinario, nictámero, urgencia, características del chorro urinario."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={SistemaNefrourologico}
                                                onChange={(e) => {
                                                    setSistemaNefrourologico(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Sistema endocrino y metabolismo</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Intolerancia al calor o al frio, pérdida o aumento de peso, alteraciones del color de la piel, vello corporal, distribución de la grasa corporal, astenia o adinamia, alteraciones del volumen urinario, amenorreas, ginecomastia, galactorrea, alteraciones de la libido, espasmos o calambres musculares."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={SistemaEndocrino}
                                                onChange={(e) => {
                                                    setSistemaEndocrino(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Sistema hematopoyético</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Palidez, rubicundez, adenomegalias, hemorragias, fiebre, fatigabilidad, equimosis, petequias y adenomegalias."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={SistemaHematoproyetico}
                                                onChange={(e) => {
                                                    setSistemaHematoproyetico(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Sistema nervioso</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Cefalalgia, pérdida de conocimiento, mareos vértigo y trastornos del equilibrio, movimientos anormales involuntarios, debilidad muscular, convulsiones, trastornos de la visión, dolor hormigueo y adormecimiento, trastornos del sueño."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={SistemaNervioso}
                                                onChange={(e) => {
                                                    setSistemaNervioso(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Sistema musculo esquelético</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Mialgias, dolor óseo, artralgias, alteraciones en la marcha, hipotonía, disminución del volumenmuscular, limitación de movimientos y deformidades."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={MusculoEsqueletico}
                                                onChange={(e) => {
                                                    setMusculoEsqueletico(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Piel y tegumentos</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Coloración, pigmentación, prurito, características del pelo, uñas, lesiones (primarias y secundarias), hiperhidrosis y xerodermia."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={PielTegumentos}
                                                onChange={(e) => {
                                                    setPielTegumentos(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Órganos de los sentidos</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Alteraciones de la visión, de la audición, del olfato, del gusto y del tacto (hipo, hiper o disfunción). Mareo y sensación de líquido en el oído."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={OrganosSentidos}
                                                onChange={(e) => {
                                                    setOrganosSentidos(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Esfera psíquica</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Tristeza, euforia, alteraciones del sueño, , terrores nocturnos, ideaciones, miedo exagerado a situaciones comunes, irritabilidad, apatía."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={EsferaPsiquica}
                                                onChange={(e) => {
                                                    setEsferaPsiquica(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Genitales</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Escriba..."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={Genitales}
                                                onChange={(e) => {
                                                    setGenitales(e.target.value);
                                                }}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                </div>
                            </Content>
                        </>
                    )}
                </div>
            </Content>

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
            >
                <Alert onClose={() => setOpen(false)} severity={typeMessage} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Stack >
    )
}