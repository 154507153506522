import React, { useEffect } from "react";
import { useState } from "react";
import { UseModal } from "../hooks/UseModal";

import "../pages/styles/Recetas.scss";

import Recetascard from "../componentes/Recetas-ordenes/RecetasCard";
import { Input, Row, QRCode, InputNumber, Select, Button, Layout, Empty, Modal, Checkbox } from "antd";
import Listarec from "../componentes/GeneralComponents/General-Json";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ContenedorBotonCentrado,
  Boton,
  LeyendaError,
} from "../componentes/GeneralComponents/Formularios2Grids";
import AddLista from "../componentes/GeneralComponents/ModListDeContactos";
import InputFile from "../componentes/GeneralComponents/agregarimagen";
import SelectMedicos from "../componentes/GeneralComponents/lista_medicos_reenviar";
import Autosuggest from "react-autosuggest";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";
import TextArea from "antd/es/input/TextArea";
import { Option } from "antd/es/mentions";
import Campos from "../componentes/GeneralComponents/campos";
import { Label } from "../componentes/GeneralComponents/Formularios";
import InputAntd from "../componentes/GeneralComponents/InputAntd";
import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";
import TextAreaAntd from "../componentes/GeneralComponents/TextAreaAntd";

import Modaldetail from "../componentes/GeneralComponents/ModalDetalle";
import ModalConfig from "../componentes/GeneralComponents/ModalDetalle";
import ModalAddReceta from "../componentes/GeneralComponents/ModalDetalle";
import ModalSelect from "../componentes/GeneralComponents/ModalDetalle";
import ModalFiltro from "../componentes/GeneralComponents/ModalFiltro";
import ModalAddContacto from "../componentes/GeneralComponents/ModalDetalle";

import { Loader } from "../componentes/GeneralComponents/Loader";
import LoaderModals from "../componentes/GeneralComponents/Loader_modals";

//Importacion de iconos
import Agregar from "../Img/Iconos/AgregarBlanco.svg";
import Configuracion from "../Img/Iconos/Configuracion.svg";
import Filtro from "../Img/Iconos/Filtro.svg";
import Foto from "../Img/Caras/Recurso25.png";
import Iconos from "../componentes/IconosDeSitio";
import Eliminar from "../Img/Iconos/eliminar.svg";
import { Cancel, FilePresent, RestaurantRounded, Search, Add } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { clean } from "semver";

// Servicios
import {
  getRecetas_request,
  getDetailRecetas_request,
  addReceta_request,
  getConfigRecetas_request,
  addConfigReceta_request,
  getRecetasFilter_request,
  sendRecetas_request,
} from "../services/recetas_services";
import { getContacts_request, addContact_request } from "../services/contactos_services";
import { getMedicamentos_request } from "../services/consultas_services";
import { getHistoriasClinicas_request } from "../services/expediente_service";
import { getCIIEDiagnosticos_request } from "../services/consultas_services";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { CircularProgress } from "@mui/material";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import ReactGA from "react-ga4";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const { Header, Content, Footer } = Layout;

function Recetas() {
const location = useLocation();
  const data = useSelector((state) => state.userInfo.profesiones);
  let CedulasMedico = [];
  data.forEach(cedula => {
    CedulasMedico.push({ label: `${cedula.Cedula} - ${cedula.Profesion}`, value: cedula.Cedula })
  });
  // ******** VARIABLES ********

  const style = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  const styleDetail = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  //Declaracion de los inputs
  const expresiones = {
    Numeros: /^\d{1,}$/, // numeros.
    Letras: /^[ñíóáéú a-zA-Z-ZÀ-ÿ\s]{1,}$/, // Letras y espacios, pueden llevar acentos.
    Correo: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i, //Letras y numeros sin espacios y caracteres especiales (-,_,.)
  };

  // ******** USE STATES ********

  const [toggleState, setToggleState] = useState(1);

  // Modales
  const [isOpenFiltro, OpenFiltro, CloseFiltro] = UseModal(false);
  const [isOpenAddRec, OpenAddRec, CloseAddRec] = UseModal(false);
  const [isOpenConfig, OpenConfig, CloseConfig] = UseModal(false);
  const [IsOpenAddContacto, OpenAddContacto, ClosedAddContacto] = UseModal(false);

  const [isOpenagregar, Openagregar, Closeagregar] = UseModal(false);

  // Modal del detalle
  const [isOpenDetallerec, OpenDetallerec, CloseDetallerec] = UseModal(false);

  const [EstatusModalEnviarReceta, setEstatusModalEnviarReceta] = useState(false);

  const [RecetaValido, setRecetaValido] = useState(false);
  const [ActModalReceta, setActModalReceta] = useState(0);

  //Estado del formulario
  const [Recetavalido, setRecetavalido] = useState();

  // Formulario add receta
  const [TipoReceta, setTipoReceta] = useState(3);
  const [Cedula, setCedula] = useState(CedulasMedico.length > 0 ? CedulasMedico[0].value : "");
  const [CostoReceta, setCostoReceta] = useState(0);
  const [Recomendaciones, setRecomendaciones] = useState("");
  const [Indicaciones, setIndicaciones] = useState("");

  const [NombreProducto, setNombreProducto] = useState("");
  const [CantidadProducto, setCantidadProducto] = useState("");
  const [DescripcionUso, setDescripcionUso] = useState("");

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [RecetasList, setRecetasList] = useState([]);
  const [RecetasListFilter, setRecetasListFilter] = useState([]);

  const [DatosContacto, setDatosContacto] = useState({ id: "", nombre: "" });
  const [ContactosList, setContactosList] = useState([]);
  const [ContactosListFilter, setContactosListFilter] = useState([]);

  const [MedicosList, setMedicosList] = useState([]);
  const [IdMedico, setIdMedico] = useState("");

  const [DetailRecetas, setDetailRecetas] = useState("");

  const [MedcSelection, setMedcSelection] = useState({});
  const [MedcSelectionDos, setMedcSelectionDos] = useState({});

  const [Medicamento, setMedicamento] = useState("");
  const [Cantidad, setCantidad] = useState(1);
  const [Dosis, setDosis] = useState("");
  const [ListaMedicamentos, setListaMedicamentos] = useState([]);

  const [Medicamentos, setMedicamentos] = useState([]);
  const [MedicamentosFilter, setMedicamentosFilter] = useState([]);

  // VARIABLES MODAL ADD CONTACTO
  const [ProfileImage, setProfileImage] = useState({ name: "", url: "", file: "" });
  const [AddNombrecontacto, setAddNombrecontacto] = useState("");
  const [AddApellidoPcontacto, setAddApellidoPcontacto] = useState("");
  const [AddApellidoMcontacto, setAddApellidoMcontacto] = useState("");
  const [AddCelularcontacto, setAddCelularcontacto] = useState("");
  const [AddCorreocontacto, setAddCorreocontacto] = useState("");
  const [AddNotaContacto, setAddNotaContacto] = useState("");

  const [loader, setLoader] = useState(false);
  const [loaderDetalle, setLoaderDetalle] = useState(false);
  const [loaderReceta, setLoaderReceta] = useState(false);

  const [CheckFirma, setCheckFirma] = useState(true);
  const [CheckEnviar, setCheckEnviar] = useState(true);
  const [CheckIndicaciones, setCheckIndicaciones] = useState(true);
  const [CheckDiagnostico, setCheckDiagnostico] = useState(true);

  // DIAGNOSTICOS
  const [loaderSearch, setLoaderSearch] = useState(false);
  const [PalabraClave, setPalabraClave] = useState("");
  const [ListaDiagnosticos, setListaDiagnosticos] = useState([]);
  const [DiagnosticosSeleccionados, setDiagnosticosSeleccionados] = useState([]);
  const [DiagnosticosSeleccionadosMostrados, setDiagnosticosSeleccionadosMostrados] = useState([]);
  const [OtrosDiagnosticos, setOtrosDiagnosticos] = useState("");

  // Mensajes form ADD RECETA
  const [MensajeNombreProducto, setMensajeNombreProducto] = useState("true");
  const [MensajeCantidadProducto, setMensajeCantidadProducto] =
    useState("true");
  const [MensajeDescripcionUso, setMensajeDescripcionUso] = useState("true");
  const [MensajeTipoReceta, setMensajeTipoReceta] = useState("true");
  const [MensajeCedula, setMensajeCedula] = useState("true");
  const [MensajeListaMedicamentos, setMensajeListaMedicamentos] =
    useState("true");
  const [MensajeMedicamento, setMensajeMedicamento] = useState("true");
  const [MensajeDosis, setMensajeDosis] = useState("true");

  // Estatus mensajes ADD CONTACTO
  const [MensajeAddNombrecontacto, setMensajeAddNombrecontacto] = useState("true");
  const [MensajeAddApellidoPcontacto, setMensajeAddApellidoPcontacto] = useState("true");
  const [MensajeAddApellidoMcontacto, setMensajeAddApellidoMcontacto] = useState("true");
  const [MensajeAddCelularcontacto, setMensajeAddCelularcontacto] = useState("true");
  const [MensajeAddCorreocontacto, setMensajeAddCorreocontacto] = useState("true");

  // Mensajes Filtro
  const [MensajeFechaInicio, setMensajeFechaInicio] = useState("true");
  const [MensajeFechaFin, setMensajeFechaFin] = useState("true");

  const [ReenviarActivate, setReenviarActivate] = useState(0);

  const [ConfigReceta, setConfigReceta] = useState("");

  // MESNAJE DIAGNOSTICO
  const [MensajeDiagnosticoCIIE, setMensajeDiagnosticoCIIE] = useState("true");
  const [MensajeOtrosDiagnosticos, setMensajeOtrosDiagnosticos] = useState("true");
  const [MensajePalabraClave, setMensajePalabraClave] = useState("true");

  // FILTRO
  const [FechaInicio, setFechaInicio] = useState("");
  const [FechaFin, setFechaFin] = useState("");
  const [EstadoFiltro, setEstadoFiltro] = useState("Todos");
  const [FiltroActive, setFiltroActive] = useState(false);

  // ******** FUNCIONES ********

  async function getRecetas() {
    setLoader(true);
    let response = await getRecetas_request();
    if (response.ok) {
      // console.log(response.data);
      setRecetasList(response.data);
      setRecetasListFilter(response.data);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function detailReceta(id) {
    // console.log(id);
    let response = await getDetailRecetas_request(id);
    if (response.ok) {
      console.log(response.data);
      setDetailRecetas(response.data);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
  }

  async function getContactos() {
    let response = await getContacts_request();
    if (response.ok) {
      // console.log(response.data);
      let array = [];
      let medicos = [];
      response.data.forEach((element) => {
        if (element.Type !== 1 && element.Type !== 4) {
          array.push({
            usuario: `${element.Nombre} ${element.ApellidoPaterno} ${element.ApellidoMaterno}`,
            imagen: `${!!element.Imagen_url ? element.Imagen_url : ""}`,
            id: `${element.IdContacto}`,
          });
        } else if (element.Type === 1) {
          medicos.push({
            usuario: `${element.Nombre} ${element.ApellidoPaterno} ${element.ApellidoMaterno}`,
            imagen: `${!!element.Imagen_url ? element.Imagen_url : ""}`,
            id: `${element.IdContacto}`,
          });
        }
      });
      setContactosList(array);
      setContactosListFilter(array);
      setMedicosList(medicos);
    } else {
    }
  }

  async function addReceta() {
    setLoaderDetalle(true);
    if (!!Cedula && ListaMedicamentos.length > 0) {
      let diagnosticos_temp = []
      if (DiagnosticosSeleccionadosMostrados.length > 0) {
        DiagnosticosSeleccionadosMostrados.forEach(element => {
          diagnosticos_temp.push(element.diagnostico);
        });
      }
      let data = new FormData();
      data.append("Costo", CostoReceta);
      data.append("Cedula", Cedula);
      data.append("IdContacto", DatosContacto.id);
      data.append("Tipo", "0");
      data.append("Recomendaciones", Recomendaciones);
      data.append("Indicaciones", Indicaciones);
      data.append("Diagnostico", diagnosticos_temp.toString());
      data.append("Firma", CheckFirma ? 0 : 1);
      data.append("Enviar", CheckEnviar ? 0 : 1);
      data.append("MostrarIndicaciones", CheckIndicaciones ? 1 : 0);
      data.append("MostrarDiagnostico", CheckDiagnostico ? 1 : 0);
      ListaMedicamentos.forEach((element, index) => {
        data.append(`Concepto[${index}][Descripcion]`, element.Descripcion);
        data.append(`Concepto[${index}][Concepto]`, element.Concepto);
        data.append(`Concepto[${index}][Cantidad]`, element.Cantidad);
      });
      let response = await addReceta_request(data);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("Receta creada");
        cleanInputsAdd();
        getRecetas();
        Closeagregar(true);
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!Cedula) {
        setMensajeCedula("false");
      }
      if (ListaMedicamentos.length === 0) {
        setMensajeListaMedicamentos("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }
    setLoaderDetalle(false);
    return;
  }

  async function getRecetasFilter(type) {
    setLoader(true);
    // console.log(!!FechaInicio);
    // console.log(!!FechaFin);
    if (type === 4) {
      if (!!!FechaInicio || !!!FechaFin) {
        if (!!!FechaInicio) {
          setMensajeFechaInicio("false");
        }
        if (!!!FechaFin) {
          setMensajeFechaFin("false");
        }
        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
      } else {
        let data = new FormData();
        data.append("Tipo", EstadoFiltro);
        data.append("FechaInicio", FechaInicio);
        data.append("FechaFin", FechaFin);

        // console.log(type);

        let response = await getRecetasFilter_request(type, data);
        if (response.ok) {
          // console.log(response.data);
          // return;
          setRecetasList(response.data);
          setRecetasListFilter(response.data);
          CloseFiltro(false);
          setFiltroActive(true);
        } else {
          setRecetasList([]);
          setRecetasListFilter([]);
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      }
    } else {
      if (MensajeFechaInicio === "false" || MensajeFechaFin === "false") {
        inputsFilter();
      }
      let data = new FormData();
      data.append("Tipo", EstadoFiltro);

      // console.log(type);

      let response = await getRecetasFilter_request(type, data);
      if (response.ok) {
        // console.log(response.data);
        // return;
        setRecetasList(response.data);
        setRecetasListFilter(response.data);
        CloseFiltro(false);
        setFiltroActive(true);
      } else {
        setRecetasList([]);
        setRecetasListFilter([]);
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    }
    setLoader(false);
    return;
  }

  async function getMedicamentos() {
    let response = await getMedicamentos_request();
    if (response.ok) {
      // console.log(response.data);
      let medi_temp = [];
      response.data.forEach(medi => {
        medi_temp.push({ Numero: medi.id, Opcion: `${medi.nombreActivo} - ${medi.id}` })
      });
      // console.log(medi_temp);
      setMedicamentos(medi_temp);
      setMedicamentosFilter(medi_temp);
    } else {

    }
  }

  async function getCIIEDiagnosticos() {
    // let response = await getCIIEDiagnosticos(text.target.value);
    setLoaderSearch(true);
    if (!!PalabraClave) {
      let response = await getCIIEDiagnosticos_request(PalabraClave);
      // console.log(response.data);
      // return;
      if (response.ok) {
        let list = [];
        // console.log(response.data);
        if (!!response.data[0]) {
          response.data.forEach((diagnostico, index) => {
            list.push({ key: index, value: diagnostico, label: diagnostico })
          });
          setListaDiagnosticos(list);
        } else {
          setListaDiagnosticos([]);
          setOpen(true);
          setTypeMessage("warning");
          setMessage("Sin resultados");
        }
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {

    }
    setLoaderSearch(false);
    return;
  }

  async function saveContact() {
    // console.log(AddNombrecontacto);
    // console.log(AddApellidoPcontacto);

    // return;
    setLoaderDetalle(true);
    let data = new FormData();
    if (!!AddNombrecontacto && !!AddApellidoPcontacto && MensajeAddNombrecontacto !== "false" &&
      MensajeAddApellidoPcontacto !== "false" && MensajeAddApellidoMcontacto !== "false" &&
      MensajeAddCelularcontacto !== "false" && MensajeAddCorreocontacto !== "false"
    ) {

      data.append("Nombre", AddNombrecontacto);
      data.append("ApellidoPaterno", AddApellidoPcontacto);
      data.append("ApellidoMaterno", AddApellidoMcontacto);
      data.append("Nota", AddNotaContacto);
      if (!!AddCelularcontacto) {
        data.append("Celular", AddCelularcontacto);
      }
      if (!!AddCorreocontacto) {
        data.append("Email", AddCorreocontacto);
      }

      data.append("Tipo", "Paciente");
      if (ProfileImage.file !== "") {
        data.append("Imagen", ProfileImage.file, ProfileImage.name);
      }
      let response = await addContact_request(data);
      if (response.ok) {
        // console.log(response.data);
        // return;
        getContactos();
        setOpen(true);
        setTypeMessage("success");
        setMessage("Contacto guardado");
        ClosedAddContacto(true);
        CloseAddRec(true);
        cleanInputsContacto();
        setDatosContacto({ id: response.data.IdContacto, nombre: `${response.data.Nombre} ${response.data.ApellidoPaterno} ${response.data.ApellidoMaterno}`, imagen: response.data.Imagen_url });

        Openagregar(true);
        // getContactos();
        // CloseModalcontacto1(false);
        // clean_inputsAdd();
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {

      if (!!!AddNombrecontacto) {
        setMensajeAddNombrecontacto("false");
      }

      if (!!!AddApellidoPcontacto) {
        setMensajeAddApellidoPcontacto("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");

    }
    // console.log("AQUUI");
    setLoaderDetalle(false);
    return;
  };

  async function enviarReceta(idReceta) {
    // console.log(idReceta);
    setLoaderReceta(true);
    let response = await sendRecetas_request(idReceta);
    if (response.ok) {
      setOpen(true);
      setTypeMessage("success");
      setMessage("Receta enviada");
      detailReceta(DetailRecetas.Receta.IdReceta);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoaderReceta(false);
  }

  function filterList(text) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    let filter = [];

    RecetasListFilter.forEach((receta) => {
      if (
        receta.Contacto.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1 ||
        receta.folio
          .replace(expr, function (e) {
            return chars[e];
          })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1
      ) {
        filter.push(receta);
      }
    });
    setRecetasList(filter);
  }

  function filterContacts(text) {
    // console.log(text.target.value);
    // console.log(solicitudesSearch);
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    let filter = [];

    ContactosListFilter.forEach((contacto) => {
      if (
        contacto.usuario
          .replace(expr, function (e) {
            return chars[e];
          })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1
      ) {
        filter.push(contacto);
      }
    });

    setContactosList(filter);
  }

  function cleanInputsAdd() {
    setCostoReceta(0);
    setRecomendaciones("");
    setNombreProducto("");
    setCantidadProducto("");
    setDescripcionUso("");
    setIndicaciones("");
    setListaMedicamentos([]);
    setDiagnosticosSeleccionadosMostrados([]);
  }

  function inputsFilter() {
    setMensajeFechaInicio("true");
    setMensajeFechaFin("true");
  }

  const onSuggestionsFetchRequested2 = ({ value }) => {
    // console.log("Filtro RECETA");
    setMedicamentos(FiltrarMedicamentos(value));
  };

  const FiltrarMedicamentos = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLenght = inputValue.Lenght;

    var filtrado = MedicamentosFilter.filter((medicamento) => {
      var textoCompleto = medicamento.Opcion;

      if (
        textoCompleto
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(inputValue)
      ) {
        return medicamento;
      }
    });
    return inputLenght === 0 ? [] : filtrado;
  };

  const onSuggestionsClearRequested2 = () => {
    setMedicamentos([]);
  };

  const getSuggestionValueDos = (suggestion) => {
    return `${suggestion.Opcion} `;
  };

  const renderSuggestionDos = (suggestion) => (

    <div className="sugerencia" onClick={() => { setMedcSelectionDos(suggestion); }}>
      {/* {// console.log(suggestion)} */}
      {`${suggestion.Opcion}`}
    </div>
  );

  const onChangeDos = (e, { newValue }) => {
    if (newValue.length > 0) {
      setMensajeMedicamento("true");
    }
    // console.log(e.target);
    // console.log(newValue);
    setMedicamento(newValue);
  };

  const inputPropsDos = {
    placeholder: "Medicamento",
    value: Medicamento,
    onChange: (e, newValue) => {
      onChangeDos(e, newValue);
    },
  };

  const eventEnterDos = (e) => {
    if (e.key == "Enter") {
      // console.log(e.target.value);
      var split = e.target.value.split("-");
      // console.log(split);
      var medicamento = {
        Opcion: split[0].trim(),
        // Numero: split[1].trim(),
      };
      // console.log(medicamento);
      setMedcSelection(medicamento);
    }
  };

  // RECETA-MEDICAMENTOS
  function addMedicamento() {
    // console.log(NombreProducto);
    // console.log(CantidadProducto);
    // console.log(DescripcionUso);

    if (!!Medicamento && !!Dosis && !!Cantidad) {
      // setOpen(true);
      // setTypeMessage("success");
      // setMessage("Agregado");
      // console.log(Medicamento);
      let arrayMedi = Medicamento.split("-");
      let medicamento_temp = arrayMedi[0];
      let id_temp = arrayMedi[1];
      // console.log({ medicamento_temp, id_temp });
      let array = ListaMedicamentos;
      if (array.length > 0) {
        array.push({
          index: ListaMedicamentos[ListaMedicamentos.length - 1].index + 1,
          Id: id_temp !== undefined ? id_temp : "",
          Concepto: medicamento_temp,
          Cantidad: Cantidad,
          Descripcion: Dosis
        });
      } else {
        array.push({
          index: 1,
          Id: id_temp !== undefined ? id_temp : "",
          Concepto: medicamento_temp,
          Cantidad: Cantidad,
          Descripcion: Dosis
        });
      }
      // console.log(array);

      setListaMedicamentos(array);

      setMedicamento("");
      setCantidad(1);
      setDosis("");
      // setMensajeListaMedicamentos("true");
    } else {
      if (!!!Medicamento) {
        setMensajeMedicamento("false");
      }
      if (!!!Dosis) {
        setMensajeDosis("false");
      }
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");

    }

    return;
  }

  function eliminarMedicamento(posicion) {
    // console.log(posicion);
    let array = ListaMedicamentos;

    let arrayDos = [];

    let arrayTres = [];

    arrayDos = array.filter((element) => { return element.index !== posicion; });
    // console.log(arrayDos);
    arrayDos.forEach((element, index) => {
      arrayTres.push({
        index: index + 1,
        Concepto: element.Concepto,
        Cantidad: element.Cantidad,
        Descripcion: element.Descripcion
      });
    });

    setListaMedicamentos(arrayTres);
    // console.log(arrayTres);
  }

  function cleanInputsContacto() {
    setAddNombrecontacto("");
    setAddApellidoPcontacto("");
    setAddApellidoMcontacto("");
    setAddCelularcontacto("");
    setAddCorreocontacto("");
    setMensajeAddNombrecontacto("true");
    setMensajeAddApellidoPcontacto("true");
    setMensajeAddApellidoMcontacto("true");
    setMensajeAddCelularcontacto("true");
    setMensajeAddCorreocontacto("true");
  }

  function addDiagnosticoCIIE() {
    if (DiagnosticosSeleccionados.length > 0) {
      DiagnosticosSeleccionados.forEach((diagnostico, index) => {
        let array = DiagnosticosSeleccionadosMostrados;
        if (array.length > 0) {
          // DiagnosticosSeleccionados.forEach(diagnostico => {
          array.push({
            index: DiagnosticosSeleccionadosMostrados[DiagnosticosSeleccionadosMostrados.length - 1].index + 1,
            diagnostico: diagnostico
          });
          // });
          setDiagnosticosSeleccionadosMostrados(array);

        } else {
          array.push({
            index: 1,
            diagnostico: diagnostico
          });

          setDiagnosticosSeleccionadosMostrados(array);
        }
      });

      // console.log(DiagnosticosSeleccionadosMostrados);

      setDiagnosticosSeleccionados([]);
    } else {
      if (DiagnosticosSeleccionados.length === 0) {
        setMensajeDiagnosticoCIIE("false");
      }
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");

    }

    return;
  }

  function addDiagnosticoOtro() {
    if (!!OtrosDiagnosticos) {
      let array = DiagnosticosSeleccionadosMostrados;
      if (array.length > 0) {
        array.push({
          index: DiagnosticosSeleccionadosMostrados[DiagnosticosSeleccionadosMostrados.length - 1].index + 1,
          diagnostico: OtrosDiagnosticos
        });
      } else {
        array.push({
          index: 1,
          diagnostico: OtrosDiagnosticos
        });
      }

      // console.log(array);

      setDiagnosticosSeleccionadosMostrados(array);
      setOtrosDiagnosticos("");
    } else {
      if (!!!OtrosDiagnosticos) {
        setMensajeOtrosDiagnosticos("false");
      }
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");

    }

    return;
  }

  function eliminarDiagnostico(posicion) {
    // console.log(posicion);
    let array = DiagnosticosSeleccionadosMostrados;

    let arrayDos = [];

    let arrayTres = [];

    arrayDos = array.filter((element) => { return element.index !== posicion; });
    // console.log(arrayDos);
    arrayDos.forEach((element, index) => {
      arrayTres.push({
        index: index + 1,
        diagnostico: element.diagnostico
      });
    });

    setDiagnosticosSeleccionadosMostrados(arrayTres);
    // console.log(arrayTres);
  }

  function filterOptions(input, option) {
    var chars = {
      "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
      "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
      "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
      "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
    }
    var expr = /[áàéèíìóòúùñ]/ig;
    return option?.label.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(input.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1
  }

  function filterSort(optionA, optionB) {
    var chars = {
      "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
      "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
      "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
      "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
    }
    var expr = /[áàéèíìóòúùñ]/ig;
    return (optionA?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase().localeCompare((optionB?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase())
  }

  // const [EstatusModalEnviarReceta, setEstatusModalEnviarReceta] = useState(false);
  // const [loaderReceta, setLoaderReceta] = useState(false);
  // MODAL ELIMINAR CITA
  const [modal, ModalEnviarReceta] = Modal.useModal();
  const confirm = () => {
    modal.confirm({
      title: `¿Desea enviar la receta al paciente ${DetailRecetas.Receta.Contacto}?`,
      okText: "Enviar",
      cancelText: "Cancelar",
      onOk: () => enviarReceta(DetailRecetas.Receta.IdReceta),
      onCancel: () => setEstatusModalEnviarReceta(false),
      open: EstatusModalEnviarReceta,
      icon: <ExclamationCircleOutlined />,
      // content: (
      //   <div>
      //     <p>Paciente: {DatosCita.nombre}</p>
      //     <p>Fecha: {DatosCita.fecha}</p>
      //     <p>Hora: {DatosCita.hora}</p>
      //     <p>
      //       Tipo:{" "}
      //       {DatosCita.tipo === 0
      //         ? "Urgencia"
      //         : DatosCita.tipo === 1
      //           ? "Primera vez"
      //           : "Seguimiento"}
      //     </p>
      //     <p>Estado: {DatosCita.estado}</p>
      //   </div>
      // ),
    });
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
    getRecetas();
    getContactos();
    getMedicamentos();
    return;
  }, []);

  return (
    <Stack>
      {/* <div className="contenedoprin"> */}
      <NavbarNuevo
        mensajesError={{
          setOpen: setOpen,
          setTypeMessage: setTypeMessage,
          setMessage: setMessage,
        }}
        modulo={"Recetas"}
        searchBar={
          <div className="Encabezadorec">
            <div className="espaciadorec">
              <div>
                <b>Recetas</b>
                <br />
              </div>
              <ButtonAntd
                tipo={1}
                style={{ width: "105px" }}
                onClick={OpenAddRec}
                children={
                  <Row style={{ gap: ".5rem", color: "white", justifyContent: "space-between", alignItems: "center" }}>
                    Nueva
                    <img style={{ width: "12px" }} src={Agregar} alt="" />
                  </Row>
                }
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "1%" }}>
              {/* <Input
                placeholder="Paciente o folio"
                type="text"
                className="inputblue"
                style={{ width: "45%" }}
                onChange={filterList}
              /> */}
              <Input
                prefix={<Search style={{ color: "#d7d7d7", width: "18px" }} />}
                style={{ width: "45%" }}
                placeholder="Paciente o folio"
                type="text"
                onChange={filterList}
              />
              <div style={{ display: "flex", width: "100%" }}>
                <img
                  onClick={() => {
                    OpenFiltro(true);
                    setMensajeFechaInicio("true");
                    setMensajeFechaFin("true");
                  }}
                  style={{ width: "30px", cursor: "pointer" }}
                  src={Filtro}
                  alt=""
                />
                {FiltroActive && (
                  <Button
                    style={{
                      background: "white",
                      color: "#148F9F",
                      border: "0",
                      height: "30px",
                      padding: "0 1%",
                      margin: ".8% 0",
                      // marginLeft: "3%",
                      marginRight: "0",
                    }}
                    onClick={() => {
                      setFiltroActive(false);
                      setEstadoFiltro("Todos");
                      setFechaInicio("");
                      setFechaFin("");
                      getRecetas();
                    }}
                  >
                    <Cancel sx={{ color: "#148f9f" }} />
                    Filtro
                  </Button>
                )}
              </div>
            </div>
          </div>
        } />

      <Content
        className="site-layout"
        style={{
          // marginTop: "100px",
          padding: '0 3%',
          // height: "calc(100vh - 52px)",
          width: "100%",
          overflow: 'initial',
        }}
      >
        {/* <div className="reccard"> */}
        {loader ? (
          <Loader />
        ) : (
          <div
            className={
              toggleState === 1 ? "contrec  active-contrec" : "contrec"
            }
            style={{ height: "70%" }}
          >
            <Recetascard
              Opendetailrec={OpenDetallerec}
              lista={RecetasList}
              detailReceta={detailReceta}
              setReenviarActivate={setReenviarActivate}
            />
            {RecetasList.length === 0 && (

              <Empty description={<span style={{ color: "black" }}>Sin recetas</span>} />
            )}
          </div>
        )}
      </Content>

      {/* </div> */}
      <ModalFiltro
        isOpen={isOpenFiltro}
        closeModal={CloseFiltro}
        titulos={["Ordena tus recetas", "Hoy", "Ayer", "Semana"]}
        functions={[getRecetas, getRecetasFilter]}
        statesVariables={{
          estadoFiltro: setEstadoFiltro,
          fechaInicio: setFechaInicio,
          mensajeFechaInicio: setMensajeFechaInicio,
          fechaFin: setFechaFin,
          mensajeFechaFin: setMensajeFechaFin,
        }}
        variables={{
          estadoFiltro: EstadoFiltro,
          fechaInicio: FechaInicio,
          mensajeFechaInicio: MensajeFechaInicio,
          fechaFin: FechaFin,
          mensajeFechaFin: MensajeFechaFin,
        }}
      />
      <Modaldetail
        styleDet={styleDetail}
        isOpen={isOpenDetallerec}
        closeModal={CloseDetallerec}
        optionClose={true}
        extras={[1, setReenviarActivate, setDetailRecetas]}
      >
        <div className="Topdetail">
          <>
            Informacion de{" "}
            {DetailRecetas !== ""
              ? !!DetailRecetas.Receta.TipoDocumento
                ? DetailRecetas.Receta.TipoDocumento
                : ""
              : ""}
          </>
          <CloseIcon style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={() => {
              CloseDetallerec(true);
              setDetailRecetas("");
              setReenviarActivate(0);
            }} />

        </div>

        <div className="detallesrec">
          {DetailRecetas === "" || loaderDetalle ? (
            <LoaderModals className={"detallesrec"} />
          ) : (
            <div>
              <header style={{ position: "sticky", top: "0", zIndex: "999" }}>
                <Row style={{ display: "flex", justifyContent: "space-between", padding: "1.5%" }} >
                  {DetailRecetas.Receta.Enviar === 0 ? (
                    <div></div>
                  ) : (
                    <>
                      {
                        loaderReceta ?
                          (<CircularProgress size={30} sx={{ color: "#148f9f" }} />) :
                          (<Button
                            className="GeneralBoton"
                            style={{ color: "white" }}
                            onClick={confirm}>
                            Enviar a paciente
                          </Button>)
                      }
                    </>

                  )}
                  <Button
                    className="GeneralBoton"
                    style={{ width: "79px", color: "white", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                    onClick={() => {
                      if (!!DetailRecetas) {
                        window.open(DetailRecetas.Receta.Url, "_blank");
                      }
                    }}
                  >
                    Ver<PictureAsPdfIcon sx={{ fontSize: "20px", cursor: "pointer" }} />
                  </Button>

                </Row>
              </header>
              <div action="" style={{ width: "100%", padding: "1%", textAlign: "center", wordBreak: "break-word" }}>
                <div className="contenedorimg">
                  <div>

                  </div>
                  <div>
                    <img
                      src={
                        !!DetailRecetas.Receta.Logo
                          ? "https://" + DetailRecetas.Receta.Logo
                          : Iconos[0]
                      }
                      alt=""
                    />
                  </div>
                  {/* <div>
                    <img
                      src={
                        !!DetailRecetas.Receta.Logo2
                          ? "https://" + DetailRecetas.Receta.Logo2
                          : Iconos[0]
                      }
                      alt=""
                    />
                  </div> */}
                </div>

                <div style={{ width: "100%", padding: "1%" }}>
                  <h3 style={{ color: "#3AABEA", marginBottom: "1.5%" }}>
                    {DetailRecetas.Receta.Medico}
                  </h3>
                  <p >{DetailRecetas.Receta.Profesion}
                  </p>
                  <p >
                    Cedula: {DetailRecetas.Receta.Cedula}
                  </p>
                  <p >
                    YGIA ID: {DetailRecetas.Receta.IdContacto}
                  </p>

                  <p >
                    {DetailRecetas.Receta.Subtitulo}

                  </p>

                </div>
                <hr />
                <div className="medlista">
                  <Row style={{ justifyContent: "center" }}>
                    <b>Medicamentos:</b>
                  </Row>
                  <br />
                  {DetailRecetas.Descripcion.map((medicamento, index) => (
                    <p style={{ marginLeft: "2%", marginRight: "2%", textAlign: "justify" }} key={index}>
                      {medicamento.Cantidad +
                        " " +
                        medicamento.Concepto +
                        ", " +
                        medicamento.Descripcion}
                    </p>
                  ))}
                  <br />
                  <Row style={{ justifyContent: "center" }}>
                    <b>Indicaciones:</b>
                  </Row>
                  <br />
                  <Row style={{ justifyContent: "center" }}>
                    <TextArea
                      autoSize
                      readOnly
                      bordered={false}
                      style={{
                        // marginLeft: "5%",
                        // marginRight: "5%",
                        width: "100%",
                        color: "black",
                        fontSize: "16px",
                        textAlign: "justify"
                      }}
                      value={DetailRecetas.Receta.Indicaciones}
                    // value={"No tomar alcohol\r\nTomar mucha agua\r\nTomar mucha agua"}
                    />
                  </Row>
                  {/* <textarea value={DetailRecetas.Receta.Recomendaciones} rows="" cols=""></textarea> */}
                  {/* <p style={{ marginLeft: "15%", marginRight: "15%" }}>{DetailRecetas.Receta.Recomendaciones}</p> */}
                  <br />
                </div>
                <br />
                <p><b>Paciente: </b>{DetailRecetas.Receta.Contacto}</p>
                <br />
                <hr />
                <br />
                <p style={{ paddingBottom: "1%" }}>
                  Receta: {DetailRecetas.Receta.Folio}
                </p>
                <p>Fecha: {DetailRecetas.Receta.Fecha}</p>
                <br />
                <hr />
                <br />
                <div style={{ padding: "0 7%" }}>
                  <p>{DetailRecetas.Receta.Anexo}</p>
                </div>
                <br />
                <hr />
                <br />
                <img />
                <p>Firma</p>
                {!!DetailRecetas.Receta.FirmaMedico ? (
                  <img style={{ width: "150px", height: "70px" }} className="FirmaImg"
                    src={"https://" + DetailRecetas.Receta.FirmaMedico}
                    alt=""
                  />
                ) : (
                  <p>Sin Firma</p>
                )}
                <br />
                <br />
                <p>QR Receta</p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <QRCode value={DetailRecetas.Receta.Url} size={100} />
                </div>
              </div>
              {/* <div style={{ marginLeft: "2%" }}>
                <p>&nbsp;Enviar a:</p>
                <h5 style={{ fontWeight: "normal", color: "grey" }}>
                  &nbsp;Nota: Seleccionar, solo si se desea compartir con otro
                  médico
                </h5>
                <br />
                <SelectMedicos
                  lista={MedicosList}
                  acciones={[1, setIdMedico]}
                />
                <br />
                <br />
              </div> */}
            </div>
          )}
        </div>
      </Modaldetail>

      <ModalSelect optionClose={true} styleDet={styleDetail} isOpen={isOpenAddRec} closeModal={CloseAddRec}>

        <div style={{ color: "#fff", background: "#148f9f", padding: "1% 2%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          Nueva receta u orden
          <CloseIcon onClick={CloseAddRec} style={{ cursor: "pointer", fontSize: "20px" }} />

        </div>
        <div className="ModalListCfiltro">
          <p style={{ paddingBottom: "1.5%" }}>Nombre:</p>
          <Input
            placeholder="Buscar..."
            type="search"
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={filterContacts}
          />
          <div
            className="contCardModal"
          // style={{ borderTop: "1px solid #d5d5d5" }}
          >
            <AddLista
              CloseModal={CloseAddRec}
              OpenModal={Openagregar}
              funcionExtra={[2, setDatosContacto]}
              Lista={ContactosList}
            />
          </div>
        </div>
      </ModalSelect>

      <ModalAddReceta
        styleDet={style}
        isOpen={isOpenagregar}
        closeModal={Closeagregar}
      >
        <div className="Topdetail" >
          Nueva receta
          <CloseIcon style={{ cursor: "pointer", fontSize: "20px" }} onClick={Closeagregar} />

        </div>
        {loaderDetalle ? (
          <LoaderModals className={"detallesrec"} />
        ) : (
          <div className="detallesrec" style={{ textAlign: "start", marginTop: "15px" }}>
            <>
              <header
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 999,
                  width: '100%',
                  background: "white",
                  padding: "0 10px",
                }}
              >

                <div style={{ display: "flex", justifyContent: "end", alignItems: "center", }}>
                  <Checkbox
                    defaultChecked={true}
                    checked={CheckEnviar}
                    onChange={(e) => {
                      // console.log(e.target.checked);
                      setCheckEnviar(e.target.checked)
                    }}
                  >Enviar a paciente</Checkbox>
                  <Checkbox
                    defaultChecked={true}
                    checked={CheckDiagnostico}
                    onChange={(e) => {
                      // console.log(e.target.checked);
                      setCheckDiagnostico(e.target.checked);
                    }}
                  >Diágnostico</Checkbox>
                  <Checkbox
                    defaultChecked={true}
                    checked={CheckIndicaciones}
                    onChange={(e) => {
                      // console.log(e.target.checked);
                      setCheckIndicaciones(e.target.checked);
                    }}
                  >Indicaciones</Checkbox>
                  <Checkbox
                    defaultChecked={true}
                    checked={CheckFirma}
                    onChange={(e) => {
                      // console.log(e.target.checked);
                      setCheckFirma(e.target.checked);
                    }}
                  >Firma</Checkbox>
                  <Button
                    style={{
                      display: "flex", justifyContent: "space-between", alignItems: "center",
                      width: "100px",
                      backgroundColor: "#148f9f",
                      color: "white",

                      gap: "1%"
                    }}
                    onClick={() => {
                      addReceta();
                    }}
                  >Guardar <CheckCircleIcon style={{ fontSize: "15px" }} /></Button>
                </div>

              </header>
              <Content
                className="site-layout"
                style={{
                  padding: '1% 10px',
                  // height: "calc(70vh - 52px)",
                  width: "100%",
                  overflow: 'initial',
                  // background: "#eaeef0"
                }}
              >
                <form action="" style={{ padding: "0" }}>
                  <InputAntd
                    label={"Nombre:"}
                    value={DatosContacto.nombre}
                    disabled={true}
                  />
                  <Row
                    style={{
                      marginBottom: "2%",
                      // justifyContent: "end",
                    }}
                  >

                    <Campos
                      label={"Costo:"}
                      styleDiv={{ width: "30%" }}
                    >
                      <InputNumber
                        // style={{ marginBottom: "2%", width:"40%" }}
                        style={{ width: "100%", height: "60%", display: "flex" }}
                        defaultValue={0}
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        onChange={(e) => setCostoReceta(e)}
                      />
                    </Campos>
                  </Row>

                  <Campos
                    valueError={MensajeCedula}
                    textError={"Debe seleccionar una cédula"}
                    label={"Cédula profesional"}
                  >
                    <Select
                      placeholder="Cédula profesional"
                      defaultValue={Cedula}
                      style={{
                        width: "100%",
                      }}
                      onSelect={(e) => {
                        setCedula(e);
                        setMensajeCedula("true");
                      }}
                      options={CedulasMedico}
                    />
                  </Campos>

                  {/* <div> */}
                  {/* <Label>Medicamento</Label>
                  <div style={{ width: "100%", position: "relative" }}>
                    <Autosuggest
                      suggestions={Medicamentos}
                      onSuggestionsFetchRequested={
                        onSuggestionsFetchRequested2
                      }
                      onSuggestionsClearRequested={
                        onSuggestionsClearRequested2
                      }
                      getSuggestionValue={getSuggestionValueDos}
                      renderSuggestion={renderSuggestionDos}
                      inputProps={inputPropsDos}
                      onSuggestionSelected={eventEnterDos}
                    />
                    <LeyendaError valido={MensajeMedicamento}>Debe escribir un medicamento</LeyendaError>
                  </div> */}

                  <Campos label="Medicamento">
                    <div style={{ width: "100%", position: "relative" }}>
                      <Autosuggest
                        suggestions={Medicamentos}
                        onSuggestionsFetchRequested={
                          onSuggestionsFetchRequested2
                        }
                        onSuggestionsClearRequested={
                          onSuggestionsClearRequested2
                        }
                        getSuggestionValue={getSuggestionValueDos}
                        renderSuggestion={renderSuggestionDos}
                        inputProps={inputPropsDos}
                        onSuggestionSelected={eventEnterDos}
                      />
                      <LeyendaError valido={MensajeMedicamento}>Debe escribir un medicamento</LeyendaError>
                    </div>
                  </Campos>

                  <InputAntd
                    value={Dosis}
                    placeholder={"Dósis"}
                    label={"Dósis"}
                    styleDiv={{ width: "100%" }}
                    valueError={MensajeDosis}
                    setStateError={setMensajeDosis}
                    vacio={false}
                    textError={["Debe escribir una dósis"]}
                    setStateValue={setDosis}
                  />

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      style={{
                        display: "flex", justifyContent: "space-between", alignItems: "center",
                        width: "100px",
                        backgroundColor: "#148f9f",
                        color: "white",
                        marginTop: "15px"
                      }}
                      onClick={() => {
                        if (!!Cedula) {
                          addMedicamento();
                        } else {
                          setOpen(true);
                          setTypeMessage("warning");
                          setMessage("Debe seleccionar una cédula");
                        }
                      }}
                    >Agregar <AddCircleIcon style={{ fontSize: "15px" }} /> </Button>

                  </div>

                  <hr style={{ margin: "1% 0", color: "#d7d7d7" }} />

                  <h3 style={{ marginBottom: "5%", textAlign: "center" }}>
                    Medicamentos
                  </h3>
                  <LeyendaError
                    valido={MensajeListaMedicamentos}
                    style={{
                      display:
                        MensajeListaMedicamentos === "false" ? "flex" : "none",
                    }}
                  >
                    Debe agregar al menos un medicamento
                  </LeyendaError>
                  <div>
                    {ListaMedicamentos.map((medicamento, index) => (

                      <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1%", borderBottom: "1px solid #d7d7d7" }}>
                        <span style={{ width: "80%" }}>{`${medicamento.Concepto} - ${medicamento.Descripcion}`}</span>
                        <img style={{ width: "20px" }} onClick={() => { eliminarMedicamento(medicamento.index) }} src={Eliminar} alt="" />
                      </div>

                    ))}
                    {ListaMedicamentos.length === 0 && (
                      <p style={{ marginBottom: "5%", textAlign: "center" }}>
                        Aún no se agregan medicamentos
                      </p>
                    )}
                  </div>

                  <TextAreaAntd
                    autoSize={true}
                    label={"Indicaciones"}
                    placeholder={"Escribe algunas indicaciones"}
                    value={Indicaciones}
                    setStateValue={setIndicaciones}
                    cols="30"
                  />

                  <Label valido={MensajeDiagnosticoCIIE}>Diagnósticos CIE</Label>
                  <div style={{ display: "flex", gap: "1", margin: "1% 0" }}>
                    <InputAntd
                      className="InputNormal"
                      type=""
                      placeholder="Palabra clave"
                      value={PalabraClave}
                      setStateValue={setPalabraClave}
                      vacio={true}
                      disabled={false}
                      style={{ width: "100%" }}
                      setStateError={setMensajePalabraClave}
                      textError={["Debe escribir una palabra clave", ""]}
                      valueError={MensajePalabraClave}
                    />
                    {loaderSearch ? (<CircularProgress size={35} sx={{ color: "#148f9f" }} />) : (
                      <Button
                        type="button"
                        style={{ margin: "0 1%", marginLeft: "1%", border: "0", background: "#148f9f", color: "white" }}
                        onClick={() => {
                          if (!!PalabraClave) {
                            getCIIEDiagnosticos();
                          } else {
                            setMensajePalabraClave("false");
                            setOpen(true);
                            setTypeMessage("warning");
                            setMessage("Debe escribir una palabra");
                          }
                        }}>Buscar</Button>
                    )}
                  </div>

                  <div>
                    {ListaDiagnosticos.length > 0 && (
                      <>
                        <div style={{ display: "flex", gap: "1rem" }}>
                          <Select
                            value={DiagnosticosSeleccionados}
                            mode="multiple"
                            placeholder="Seleciona tus diagnósticos"
                            style={{ width: "100%" }}
                            options={ListaDiagnosticos}
                            filterOption={filterOptions}
                            filterSort={filterSort}
                            onChange={(e) => {
                              // console.log(e);
                              setDiagnosticosSeleccionados(e);
                            }}
                          />
                          <Button
                            icon={<Add style={{ color: "white" }} />}
                            style={{ background: "#148f9f" }}
                            onClick={() => {
                              addDiagnosticoCIIE();
                            }}
                          />
                        </div>
                        <LeyendaError valido={MensajeDiagnosticoCIIE}>Debe seleccionar un diagnóstico</LeyendaError>
                      </>
                    )}
                    <Label valido={MensajeOtrosDiagnosticos}>Otros diagnósticos</Label>
                    <div style={{ display: "flex", gap: "1rem" }}>
                      <TextAreaAntd
                        value={OtrosDiagnosticos}
                        placeholder="Otros diagnósticos"
                        style={{
                          marginBottom: ".5%",
                          fontFamily: "sans-serif",
                        }}
                        columns="100"
                        autoSize={true}
                        setStateValue={setOtrosDiagnosticos}
                        setStateError={setMensajeOtrosDiagnosticos}
                        valueError={MensajeOtrosDiagnosticos}
                        textError={["Debe escribir un diagnósticos"]}
                        vacio={true}
                      />
                      <Button
                        icon={<Add style={{ color: "white" }} />}
                        style={{ background: "#148f9f" }}
                        onClick={() => {
                          addDiagnosticoOtro();
                        }}
                      />
                    </div>
                  </div>
                  <h3 style={{ marginBottom: "5%", textAlign: "center" }}>
                    Diagnósticos
                  </h3>
                  {DiagnosticosSeleccionadosMostrados.length > 0 ? (
                    <>
                      {DiagnosticosSeleccionadosMostrados.map((diagnostico, index) => (
                        // <div className="ItemCard">
                        //     <div className="CardNotasMed">
                        //         <span>{diagnostico.diagnostico}</span>
                        //         <img onClick={() => { eliminarDiagnostico(diagnostico.index) }} src={Eliminar} alt="" />
                        //     </div>
                        // </div>
                        <div className="ItemCard">
                          <Row style={{ justifyContent: "space-between" }}>
                            <span>{diagnostico.diagnostico}</span>
                            <img style={{ width: "14px", cursor: "pointer" }} onClick={() => { eliminarDiagnostico(diagnostico.index) }} src={Eliminar} alt="" />
                          </Row>
                        </div>

                      ))}
                    </>
                  ) : (
                    <p style={{ textAlign: "center" }}>Aún no se agregan diagnósticos</p>
                  )}
                </form>
              </Content>
            </>

          </div>
        )}
      </ModalAddReceta>

      <ModalAddContacto
        isOpen={IsOpenAddContacto}
        closeModal={ClosedAddContacto}
        optionClose={false}
      >
        <div
          style={{
            width: "100%",
            background: "#148f9f",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1% 2%",
          }}
        >
          <b>Nuevo contacto</b>
          <b
            style={{ cursor: "pointer" }}
            onClick={() => {
              ClosedAddContacto(true);
              OpenAddRec(true);
              cleanInputsContacto();
            }}
          >
            x
          </b>
        </div>
        <div className="scrolMotivoConsulta">
          {loaderDetalle ? (<LoaderModals />) : (
            <div style={{ padding: "2%", width: "100%" }}>
              <div style={{ textAlign: "center", marginBottom: "1%" }}>
                <label htmlFor="imgPerfilConsulta">
                  <img
                    style={{
                      width: " 45px",
                      height: "45px",
                      overflow: "hidden",
                      borderRadius: "5rem",
                      objectFit: "cover",
                      objectPosition: "center",
                      border: "2px solid #d7d7d7",
                    }}
                    src={!!ProfileImage.url ? ProfileImage.url : Iconos[0]}
                    alt=""
                  />
                  <input
                    id="imgPerfilConsulta"
                    style={{ display: "none" }}
                    type="file"
                    accept="/image/x-PNG,image/jpeg"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.type.substring(0, 5) === "image") {
                        setProfileImage({
                          name: e.target.value,
                          url: URL.createObjectURL(e.target.files[0]),
                          file: e.target.files[0],
                        });
                      }
                    }}
                  />
                </label>
              </div>
              <br />
              <InputAntd
                className="inputblue"
                value={AddNombrecontacto}
                setStateValue={setAddNombrecontacto}
                label="Nombre:"
                style={{ margin: "2% 0" }}
                placeholder="Nombre"
                setStateError={setMensajeAddNombrecontacto}
                textError={[
                  "Debe escribir un nombre",
                  "Solo se permiten letras",
                ]}
                type="text"
                valueError={MensajeAddNombrecontacto}
                vacio={false}
                disabled={false}
                expresionRegular={expresiones.Letras}
                maxLength={40}
              />

              <InputAntd
                className="inputblue"
                value={AddApellidoPcontacto}
                setStateValue={setAddApellidoPcontacto}
                label="Apellido Paterno:"
                style={{ margin: "2% 0" }}
                placeholder="Apellido Paterno:"
                setStateError={setMensajeAddApellidoPcontacto}
                textError={[
                  "Debe escribir un apellido paterno",
                  "Solo se permiten letras",
                ]}
                type="text"
                valueError={MensajeAddApellidoPcontacto}
                vacio={false}
                disabled={false}
                expresionRegular={expresiones.Letras}
                maxLength={25}
              />
              <InputAntd
                className="inputblue"
                value={AddApellidoMcontacto}
                setStateValue={setAddApellidoMcontacto}
                label="Apellido Materno:"
                style={{ margin: "2% 0" }}
                placeholder="Apellido Materno"
                setStateError={setMensajeAddApellidoMcontacto}
                textError={["", "Solo se permiten letras"]}
                type="text"
                valueError={MensajeAddApellidoMcontacto}
                vacio={true}
                disabled={false}
                expresionRegular={expresiones.Letras}
                maxLength={25}
              />
              <InputAntd
                className="inputblue"
                value={AddCelularcontacto}
                setStateValue={setAddCelularcontacto}
                label="Celular"
                id="celular"
                style={{ margin: "2% 0" }}
                placeholder="Celular:"
                setStateError={setMensajeAddCelularcontacto}
                textError={[
                  "",
                  "Celular solo puede contener numeros",
                  "Celular debe contener 10 dígitos",
                ]}
                type="text"
                valueError={MensajeAddCelularcontacto}
                vacio={true}
                disabled={false}
                expresionRegular={expresiones.Numeros}
                maxLength={10}
              />

              <InputAntd
                className="inputblue"
                value={AddCorreocontacto}
                setStateValue={setAddCorreocontacto}
                label="Correo"
                style={{ margin: "2% 0" }}
                placeholder="Correo:"
                setStateError={setMensajeAddCorreocontacto}
                textError={["", "Formato incorrecto"]}
                type="text"
                valueError={MensajeAddCorreocontacto}
                vacio={true}
                disabled={false}
                expresionRegular={expresiones.Correo}
              />

              <TextAreaAntd
                label="Comentarios o notas:"
                value={AddNotaContacto}
                placeholder="Escriba un comentario o nota (opcional)"
                style={{
                  marginBottom: ".5%",
                  fontFamily: "sans-serif",
                }}
                columns="30"
                autoSize={true}
                setStateValue={setAddNotaContacto}
              />
              <div>
                <Button
                  style={{
                    width: "100%",
                    background: "#148f9f",
                    borderColor: "#148f9f",
                    color: "white",
                  }}
                  size="sm"
                  onClick={(e) => {
                    saveContact();
                  }}
                >
                  Crear Paciente
                </Button>
              </div>
            </div>
          )}

        </div>
      </ModalAddContacto>

      {ModalEnviarReceta}

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
export default Recetas;
