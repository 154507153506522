import { useState } from 'react';
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from 'react-redux';

import LoaderCircle from '../../GeneralComponents/LoaderCircle';
import { getGoogleCalendarEvents, getGoogleTokens, getUserGoogleCalendars, saveGoogleCalendarData } from '../../../services/calendar_services';
import { userSyncGoogle } from '../../../redux/userSlice';
import { showSnackbar } from '../../../redux/snackbarSlice';

const Step = Object.freeze({
    Loading: 0,
    PickCalendar: 1,
    ErrorPick:2,
    Successpick:3
});

const GoogleCalendarSync = () => {
    const [loading, setIsLoading] = useState(false);
    const [stepCalendarSelect, setStepCalendarSelect] = useState(Step.Loading);
    const [loadingMessage, setLoadingMessage] = useState('Conectando a Google');
    const [acToken, setAcToken] = useState('el token va aqui!');
    const [refreshToken, setRefreshToken] = useState('');
    const [calendarios, setCalendarios] = useState([]);

    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo.usuario);

    const login = useGoogleLogin({
        onSuccess: codeResponse => handleGoogleLoginResponse(codeResponse),
        onError: ()=>setStepCalendarSelect(Step.ErrorPick),
        onNonOAuthError:()=>setIsLoading(false),
        flow: 'auth-code',
        scope: 'https://www.googleapis.com/auth/calendar'
    });

    const handleGoogleLoginResponse = async(codeResponse) => {
        const googleResponse = await getGoogleTokens(codeResponse.code);

        if(googleResponse.status ===200){
            const tks = googleResponse.data;
            setAcToken(tks.access_token);
            setRefreshToken(tks.refresh_token);
            getCalendars(tks.access_token);
        }
        else{
            setStepCalendarSelect(Step.ErrorPick);
        }
    }

    const getCalendars = async(token) => {
        const calendars = await getUserGoogleCalendars(token);

        if(calendars.success){
            setCalendarios(calendars.data);
            setStepCalendarSelect(Step.PickCalendar);
        }
        else{
            setStepCalendarSelect(Step.ErrorPick);
        }
    }

    const googleCalendarSyncHandler = () => {
        setIsLoading(true);
        login();
    }

    const retryGoogleLogin = () => {
        setLoadingMessage('Conectando a Google');
        setStepCalendarSelect(Step.Loading);
        login();
    }

    const saveSyncCalendarData = async(calendario) => {
        setLoadingMessage('Guardando seleccion, espera un momento');
        setStepCalendarSelect(Step.Loading);

        const saveCalendar = await saveGoogleCalendarData(calendario.id, acToken, refreshToken);
        
        if(saveCalendar){
            setLoadingMessage('Sincronizando.... espera un momento.');
            const calendarios = await getGoogleCalendarEvents(calendario.id, acToken);
            
            setIsLoading(false);
            dispatch(userSyncGoogle(true));
            dispatch(showSnackbar({
                show: true,
                text: 'Calendario sincronizado correctamente!.',
                tpe: 'success'
            }));
            
        }
        else{
            setStepCalendarSelect(Step.ErrorPick);
        }
    }

    const renderCalendarios = () => {
        let calendarObjs =[];

        calendarios.map(calendario=>{
            calendarObjs.push(
                <div style={{width:'80%', height:45, cursor:'pointer', borderBottom:'solid 1px #888888', marginBottom:5}} key={calendario.id}
                    onClick={()=>saveSyncCalendarData(calendario)}>
                    <p style={{fontStyle:'bold', fontSize:16}}>{calendario.summary}</p>
                    <p style={{fontSize:12}}>{calendario.description}</p>
                </div>
            );
        })

        return calendarObjs;
    }

    const exit = () =>{
        setAcToken('');
        setCalendarios([]);
        setLoadingMessage('Conectando a Google');
        setIsLoading(false);
    }

    const selectCalendarSteps = [
        <>
            <p style={{marginBottom:'25px', fontStyle:'bold', fontSize:'20px'}}>{loadingMessage}</p>
            <LoaderCircle />
        </>,
        <div style={{width:'90%', height:'90%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
            <p style={{fontSize: 18, fontStyle:'bold', height:30, }}>Selecciona un calendario:</p>
            <div style={{ marginTop:20, flexGrow:1, overflow:'hidden', overflowY:'auto',display:'flex',flexDirection:'column', alignItems:'center'}}>
                { renderCalendarios() }
            </div>
        </div>,
        <div style={{textAlign:'center', width:'90%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
            Vaya parece que algo ha salido mal, por favor reintenta de nuevo.
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', rowGap:10, alignItems:'center', width:'80%'}}>
                <button style={{width:'45%',border:0, height:30,marginTop:25, backgroundColor:'rgb(20, 143, 159)', color:'white', cursor:'pointer'}} onClick={()=>retryGoogleLogin()}>Reintentar</button>
                <button style={{width:'45%',border:0, height:30,marginTop:25, backgroundColor:'rgb(224, 21, 21)', color:'white', cursor:'pointer'}} onClick={()=>exit()}>Salir</button>
            </div>
        </div>
    ]

    return(
        <>
            <button onClick={()=>googleCalendarSyncHandler()} style={{backgroundColor:'rgba(0, 188, 212, .2)', marginTop:5, marginBottom:10, marginLeft:'auto', marginRight:'auto', userSelect:'none', width:'99%', height:35, display:'flex', flex:1, alignItems:'center', 
                justifyContent:'center', boxShadow:'0 4px 2px -2px #888888', cursor:'pointer', border:'solid 1px rgba(20, 143, 159, 0.4)', borderRadius:5, borderColor:'#148F9F'}}>
                <p>{userInfo.GoogleSynced?'Sincronizado con Google':'Sincroniza tu calendario de Google'}</p><img style={{height:'110%',}} src={require('../../../Img/google_calendar.webp')}/>
            </button>
            {   loading && 
                <div style={{position:'absolute', top:0, left:0, zIndex:1000, width:'100%', height:'100%', backgroundColor:'rgba(0, 0, 0, 0.8)', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                    <div style={{backgroundColor:'#fff', width:'60%', maxHeight:'80%', minHeight:'40%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', borderRadius:10}}>
                        { selectCalendarSteps[stepCalendarSelect] }
                    </div>
                </div>
            }
        </>
    )
}

export default GoogleCalendarSync;