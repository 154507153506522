
import React, { useState, useEffect } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';

// importar scss
import "./styles/ExpedientesNuevo.scss";

// Iconos
import AgregarBlanco from "../Img/Iconos/AgregarBlanco.svg";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";

//Libreria hormiga
import { Input, Empty, } from "antd";
import AddExpediente from "../componentes/Expedientes/ModalAddExpediente";
import ModalEx from "../componentes/GeneralComponents/ModalDetalle";
import { UseModal } from "../hooks/UseModal";
import { expedientes_partes_request, expedientes_request, getContactosSinExpedientes_request } from "../services/expediente_service";
import { Loader } from "../componentes/GeneralComponents/Loader";
import LoaderCircle from "../componentes/GeneralComponents/LoaderCircle.js";

import ExpedienteTablaCard from '../componentes/NuevoExpediente/ExpedienteTablaCard';
import { Content } from "antd/es/layout/layout";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";
import ExpedientesCard from "../componentes/NuevoExpediente/ExpedientesCard";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { Row, Skeleton, List, Divider } from "antd";
import { CircularProgress } from "@mui/material";
import useExpedientesData from "../hooks/Query/useExpedientesData";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// TODO refactor & clean
export default function Expedientes() {
  let data = JSON.parse(sessionStorage.getItem("infoUser"));
  const expedientesData = useExpedientesData();
  const location = useLocation();

  // Modales
  const [isOpenModalEx, openModalEx, CloseModalEx] = UseModal(false);
  const [isOpenModalEx2, openModalEx2, CloseModalEx2] = UseModal(false);

  const [expe, setExpe] = useState([]);
  const [ScreenDetail, setScreenDetail] = useState(false);
  const [detalleFicha, setDetalleFicha] = useState("");
  const [loader, setLoader] = useState(true);
  const [loaderDetalle, setLoaderDetalle] = useState(false);
  const [expedientes, setExpedientes] = useState([]);
  const [expedientesFilter, setExpedientesFilter] = useState([]);
  const [contactosSinExpedientes, setContactosSinExpedientes] = useState([]);
  const [contactosSinExpedientesFilter, setContactosSinExpedientesFilter] =
    useState([]);
  const [userImage, setUserImage] = useState("");
  const [idContacto, setIdContacto] = useState("");
  const [datos, setDatos] = useState({ id: "", nombre: "", imagen: "" });

  const [TotalPaginas, setTotalPaginas] = useState(0);
  const [paginaCargada, setpaginaCargada] = useState(0);

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [comboTipoAntecedente, setComboTipoAntecedente] = useState("true");

  // Tamaños de modal
  const styleDet = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };


  const get_contactosSinExpedientes = async () => {
    let response = await getContactosSinExpedientes_request();
    if (response["ok"]) {
      if (response.data.length !== 0) {
        let contactos = [];
        response.data.forEach(element => {
          if (element.tipo !== "Asistente") {
            contactos.push(element);
          }
        });
        setContactosSinExpedientes(contactos);
        setContactosSinExpedientesFilter(contactos);
      } else {
        setContactosSinExpedientes([]);
        setContactosSinExpedientesFilter([]);
      }
    } else {
      setContactosSinExpedientes([]);
    }
    return;
  };

  function filterListContacts(text) {
    let filter = [];
    contactosSinExpedientesFilter.forEach((contacto) => {
      if (
        contacto.nombre.toLowerCase().indexOf(text.target.value.toLowerCase()) >
        -1
      ) {
        console.log(contacto);

        filter.push(contacto);
      }
    });
    setContactosSinExpedientes(filter);
  }

  function filterList(text) {
    var chars = {
      "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
      "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
      "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
      "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
    }
    var expr = /[áàéèíìóòúùñ]/ig;
    let filter = [];
    expedientesFilter.forEach((expediente) => {
      if (
        expediente.Nombre.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(
          text.replace(expr, function (e) { return chars[e] }).toLowerCase()
        ) > -1
      ) {
        filter.push(expediente);
      }
    });

    setExpedientes(filter);
  }

  useEffect(() => {
    if (!expedientesData.isLoading) {
      if (!expedientesData.data?.success) {
        setOpen(true);
        setTypeMessage("error");
        setMessage(expedientesData.data.mensaje);
        setExpedientes([]);
        setExpedientesFilter([]);
        setLoader(false);
        return;
      }

      setExpedientes(expedientesData.data.expedientes.slice().sort((a, b) => a.Nombre.localeCompare(b.Nombre)));
      setExpedientesFilter(expedientesData.data.expedientes.slice().sort((a, b) => a.Nombre.localeCompare(b.Nombre)));
      setLoader(false);
    }
    else if (expedientesData.isRefetching) {
      setLoader(false);
    }
  }, [expedientesData.data]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
    get_contactosSinExpedientes();
  }, []);

  return (
    <>
      <Stack>
        <NavbarNuevo
          modulo={"Expedientes"}
          mensajesError={{
            setOpen: setOpen,
            setTypeMessage: setTypeMessage,
            setMessage: setMessage,
          }}
          searchBar={
            <div className="CebezeraExpedientes">
              <div className="Encabesado-Expedientes" style={{ alignItems: "center" }}>
                <div style={{ width: "50%", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <b>Expediente</b>
                  <div style={{ marginLeft: 15 }}>
                    {!expedientesData.isLoading && expedientesData.isFetching &&
                      <LoaderCircle Width={32} Height={32} />
                    }
                  </div>
                </div>
                {(
                  data.Tipo === 1 && (<ButtonAntd
                    tipo={1}
                    style={{ width: "100px" }}
                    onClick={() => {
                      openModalEx(true);
                    }}
                    children={
                      <Row style={{ width: "100%", gap: ".5rem", color: "white", justifyContent: "space-between", alignItems: "center" }}>
                        Nuevo
                        <img style={{ width: "12px" }} src={AgregarBlanco} alt="" />
                      </Row>
                    }
                  />)
                )}

              </div>
              <div style={{ margin: "1% 0", display: "flex", justifyContent: "space-between" }}>

                <Input
                  prefix={<SearchIcon style={{ color: "#d7d7d7", width: "18px" }} />}
                  style={{ width: "50%" }}
                  placeholder="Paciente"
                  type="text"
                  onChange={(text) => filterList(text.target.value)}
                />

                <div style={{ display: "flex" }}>
                  {/* <b>Página: {paginaCargada}/{TotalPaginas}</b>
                  {paginaCargada !== TotalPaginas && (<CircularProgress size={25} sx={{ color: "#148f9f" }} />)} */}
                </div>
              </div>
            </div>
          } />

        <Content
          className="site-layout"
          style={{
            padding: '0 3%',
            position: "relative",
            width: "100%",
            overflow: 'initial',
          }}
        >
          {loader ? (
            <Loader />
          ) : (
            <>
              <ExpedientesCard expedientes={expedientes} />
              {expedientes.length === 0 && (
                <Empty description={<span style={{ color: "black" }}>Sin expedientes</span>} />
              )}
            </>
          )}
        </Content>
        <ModalEx isOpen={isOpenModalEx} closeModal={CloseModalEx} styleDet={styleDet} optionClose={true}>
          <div className="ExpediTop">
            Nuevo expediente <CloseIcon style={{ cursor: "pointer", fontSize: 20 }} onClick={CloseModalEx} />
          </div>
          <div className="ExpediContAddNew">
            <p>Contacto</p>
            <Input
              placeholder="Buscar..."
              type="search"
              style={{ width: "100%", marginBottom: "10px" }}
              onChange={filterListContacts}
            />

            <div
              className="contCardModal"
              style={{ borderTop: "1px solid #d5d5d5" }}
            >
              {/* <AddLista lista={contactosSinExpedientes} openModal={openModalEx2} CloseModal={CloseModalEx} setId={setIdContacto}/> */}
              <AddExpediente
                contactos={contactosSinExpedientes}
                isOpen={openModalEx2}
                closeModal={CloseModalEx}
                setIdContacto={setIdContacto}
              />
            </div>
          </div>
        </ModalEx>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
        >
          <Alert
            onClose={() => setOpen(false)}
            severity={typeMessage}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}
