import axios from "axios";
import baseUrl, { googleClient, googleSecret, googleUri } from "./apiUrl";

export async function getUserGoogleCalendars(token){
    try {
        const response = await axios({
            url:'https://www.googleapis.com/calendar/v3/users/me/calendarList',
            method:'GET',
            headers:{ "Authorization": `Bearer ${token}`}
        })
        
        if(response && response.status === 200){
            return { success: true, data: response.data.items }
        }
        else{
            return { success: false, data: undefined }
        }
    }
    catch(e){
        console.log(e)
    }
}

export async function saveGoogleCalendarData(calendarId, googleToken, refreshToken){
    const data = {
        token: googleToken,
        calendarId: calendarId,
        refreshToken: refreshToken
    }
    console.log(data)
    const token = await localStorage.getItem("token");

    try {
        const response = await axios({
            url: baseUrl + `/SyncCalendar/token`,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            data: data
        });

        if(response && response.status === 200){
            return true;
        }
        else{
            return false;
        }
    }
    catch(e){
        console.log(e);
    }
}

export async function syncGoogleCalendar(){
    const token = await localStorage.getItem("token");

    try{
        const response = await axios({
            url: `${baseUrl}/SyncCalendar/sync`,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
        
        return response.status===200? true : false;
    }
    catch(e){
        console.log(e)
    }
}

export async function getGoogleCalendarEvents(calendarID, token){
    try{
        const response = await axios({
            url: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`,
            method: 'GET',
            headers:{ "Authorization": `Bearer ${token}`}
        })
        console.log(response)
    }
    catch(e){
        console.log(e);
    }
}

export async function getGoogleTokens(acCode) {
    const data ={
        code: acCode,
        client_id: googleClient,
        client_secret: googleSecret,
        grant_type: 'authorization_code',
        redirect_uri: googleUri,
    }

    try{
        const response = await axios({
            method: 'POST',
            url: 'https://oauth2.googleapis.com/token',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        });

        return response;
    }
    catch(e){
        console.log(e)
    }
}