import React, {useEffect, useState} from "react";
import ReactGA from "react-ga4";
import { Button, Layout, Calendar, Tabs, Row, TimePicker, Select } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/es";
import locale from "antd/es/calendar/locale/es_ES";
import { useSelector, useDispatch } from 'react-redux';

import LoaderModals from "../../GeneralComponents/Loader_modals";
import Campos from "../../GeneralComponents/campos";
import SelectAntd from "../../GeneralComponents/SelectAntd";
import TextAreaAntd from "../../GeneralComponents/TextAreaAntd";
import CitasAgendadasCard from "../CitasAgendadasCard";

import { ModalScreen, ShowModal, ModalBusy, HideModal } from "../../../redux/appSlice";
import { SelectContact } from '../../../redux/contactos';
import { SetReloadCitas, SelectCitaDate } from "../../../redux/citas";
import { showSnackbar } from "../../../redux/snackbarSlice";

import { getComboHorariosData } from "../../../utils/utils.js";

import { getHorarios_request, getCitasFilter_request, addCita_request } from "../../../services/citas_services";
import useCitasData from "../../../hooks/Query/useCitasData";
import { syncGoogleCalendar } from "../../../services/calendar_services.js";

const { Header, Content, Footer } = Layout;

const styleDet = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

const CitaTipos = [
    { value: "0", label: "Urgencia" },
    { value: "1", label: "Primera Vez" },
    { value: "2", label: "Seguimiento" },
    // { value: "3", label: "Procedimiento" },
]

const AddCitasContent = ({StartDate}) => {
    const [loaderDetalle, setLoaderDetalle] = useState(true);
    const [AddFecha, setAddFecha] = useState(StartDate);
    const [tipoCita, setTipoCita] = useState(null);
    
    const [HorarioInicio, setHorarioInicio] = useState("");
    const [HorarioFin, setHorarioFin] = useState("");
    const [horarios, setHorarios] = useState([]);
    const [AddHorarios, setAddHorarios] = useState([]);
    const [horarioCita, setHorarioCita] = useState(null);
    const [ComboHorarioCita, setComboHorarioCita] = useState('true');
    const [motivo, setMotivo] = useState("");
    const [comentario, setComentario] = useState("");
    const [ComboTipoCita, setComboTipoCita] = useState(true);
    const [MensajeContacto, setMensajeContacto] = useState("true");
    const [FechaHoraCita, setFechaHoraCita] = useState({ fecha: "", hora: "" });
    // const [listCitasOcupadas, setListCitasOcupadas] = useState([]);
    const [citasAgendadas, setCitasAgendadas] = useState([]);
    const [listHorariosDisponibles, setListHorariosDisponibles] = useState([]);
    const [TabsAddCita, setTabsAddCita] = useState("1");

    const dispatch = useDispatch();
    const contactos = useSelector((state) => state.contacts);
    const { selectedDay } = useSelector((state)=> state.appointments);

    const Citas = useCitasData();

    useEffect(() => {
        return () => {
          dispatch(SelectContact({}));
          setHorarios([]);
        };
      }, []);

      useEffect(()=>{
        const fetchDataCitas = async() =>{
            await getHorarios(selectedDay);
        };

        fetchDataCitas();
      },[selectedDay]);

      useEffect(()=>{
        if(contactos.selectedContact.id){
            setMensajeContacto("true");
        }
      },[contactos.selectedContact])

    async function getCitasDisponibles(date, hours) {
        let data = new FormData();
    
        data.append("FechaInicio", date);
        data.append("FechaFin", date);

        let Horarios = hours.Horario;
    
        let response = await getCitasFilter_request(4, data);
        if (response.ok) {
          response.data.forEach((cita) => {
            let hora = `${cita.HoraCita.substring(0, 1) === "0"
              ? cita.HoraCita.substring(1, 5)
              : cita.HoraCita.substring(0, 5)
              } ${cita.HoraCita.substring(
                cita.HoraCita.length - 5,
                cita.HoraCita.length
              ) === "a. m."
                ? "a.m"
                : "p.m"
              }`;
              if(Horarios.includes(hora)){
                const index =Horarios.indexOf(hora);
                Horarios.splice(index, 1);
              }
          });
          
          const comboData = getComboHorariosData(Horarios);
          setHorarios(comboData);
          
          setCitasAgendadas(response.data);
        } else {
            setCitasAgendadas([]);
            if (hours.Horario.length > 0) {
                const horarioCombo = getComboHorariosData(Horarios);
                setHorarios(horarioCombo);
            }
        }
      }

    const getHorarios = async(fecha) => {
        setLoaderDetalle(true);
    
        let data = { Fecha: fecha };
        let response = await getHorarios_request(data);
        if (response.ok) {
          await getCitasDisponibles(fecha, { Horario: response.data });
        } else {
          setHorarios([]);
          getCitasDisponibles(fecha, { Horario: [] });
            dispatch(showSnackbar({
                show: true,
                text: response.mensaje,
                type: "error",
            }));
        }
        setLoaderDetalle(false);
      }

    const selectDateHandler = (e) => {
        let temp = e.$d;
        let mesObtenido = temp.getMonth() + 1;
        let mesCalculo =
            mesObtenido.toString().length === 1
            ? `0${mesObtenido}`
            : mesObtenido;
        let fecha_enviar =
            temp.getFullYear() +
            "-" +
            mesCalculo +
            "-" +
            temp.getDate();
        setLoaderDetalle(true);
        dispatch(SelectCitaDate(fecha_enviar));
    }

      async function saveCita() {
        setLoaderDetalle(true);
        dispatch(ModalBusy(true));
    
        if (tipoCita === "3") {
          // TODO esto se usara?
        //   if (!!HorarioInicio && !!HorarioFin) {
        //     return;
        //   } else {
        //     setOpen(true);
        //     setTypeMessage("warning");
        //     setMessage("Faltan campos por completar");
        //     if (!!!AddTipocita) {
        //       setComboAddTipoCita("false");
        //     }
    
        //     if (!!!HorarioInicio || !!!HorarioFin) {
        //       setComboAddHorarioCita("false");
        //     }
        //   }
        } else {
            const DatosContacto = contactos.selectedContact;

          if (!!horarioCita && !!DatosContacto.id && !!tipoCita) {
            let data = new FormData();
            data.append("Nombre", DatosContacto.id);
            data.append("Fecha", selectedDay);
            data.append("Hora", horarioCita);
            data.append("Comentarios", motivo);
            data.append("Adicional", comentario);
            data.append("TipoCita", tipoCita);

            let response = await addCita_request(data);
            if (response.ok) {
              const responseSync = await syncGoogleCalendar();
              Citas.refetch();
              ReactGA.event({
                category: "Citas",
                action: "Cita_exitosa",
                label: "Cita agregada exitosamente", // optional
                value: 99, // optional, must be a number
                nonInteraction: true, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image
              });
              
                dispatch(showSnackbar({
                    show:true,
                    text: 'Cita creada',
                    type:'success'
                }));
              dispatch(HideModal(ModalScreen.ADD_CITA));
            } else {
              ReactGA.event({
                category: "Citas",
                action: "Cita_erronea",
                label: `${response.mensaje}`, // optional
                value: 99, // optional, must be a number
                nonInteraction: true, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image
              });

                dispatch(showSnackbar({
                    show: true,
                    text: response.mensaje,
                    type: 'error'
                }))
            }
          } else {
            if (!!!horarioCita) {
              setComboHorarioCita("false");
            }
    
            if (!!!DatosContacto.id) {
              setMensajeContacto("false");
            }
    
            if (!!!tipoCita) {
              setComboTipoCita("false");
            }

            dispatch(showSnackbar({
                show: true,
                text: 'Faltan campos por completar',
                type: "warning",
             }));
          }
        }
        setLoaderDetalle(false);
        dispatch(ModalBusy(false));
        
      }

    const contentTabs = [
        {   label: 'Nueva',
            key:'1',
            children:
            <form style={{ padding: "0 10px" }}>
                <Row
                style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                {tipoCita === "3" ? (
                    <Campos
                    label="Seleciona un rango de hora"
                    textError="Debe seleccionar un rango de hora"
                    valueError={ComboHorarioCita}
                    >
                    <TimePicker.RangePicker
                        inputReadOnly
                        placeholder={["Hora inicio", "Hora fin"]}
                        style={{ width: "100%" }}
                        value={[
                        !!HorarioInicio
                            ? dayjs(HorarioInicio, "h:mm A")
                            : "",
                        !!HorarioFin ? dayjs(HorarioFin, "h:mm A") : "",
                        ]}
                        use12Hours={true}
                        format="h:mm A"
                        onChange={(e) => {
                        // if (!!e) {
                        //     setHorarioInicio(e[0].format("h:mm A"));
                        //     setHorarioFin(e[1].format("h:mm A"));
                        //     setComboAddHorarioCita("true");
                        // } else {
                        //     setHorarioInicio("");
                        //     setHorarioFin("");
                        //     setComboAddHorarioCita("false");
                        // }
                        }}
                        status={ComboHorarioCita === "false" && "error"}
                        locale={locale}
                    />
                    </Campos>
                ) : (
                    <SelectAntd
                        label="Horario:"
                        requiredICon={true}
                        placeholder={
                            horarios.length === 0
                            ? "Sin horarios"
                            : "Elige un horario"
                        }
                        options={horarios}
                        setStateValue={setHorarioCita}
                        setStateError={setComboHorarioCita}
                        textError="Debe seleccionar un horario"
                        value={horarioCita}
                        valueError={ComboHorarioCita}
                        style={{ width: "100%", fontWeight: "normal" }}
                        styleDiv={{ width: "45%" }}
                        disabled={horarios.length === 0 && true}
                        vacio={false}
                    />
                )}
                <Campos
                    label="Tipo cita:"
                    requiredICon={true}
                    valueError={ComboTipoCita}
                    textError="Debe seleccionar un tipo"
                    styleDiv={{ width: "45%" }}
                    vacio={false}
                >
                    <Select
                    value={tipoCita}
                    status={ComboTipoCita === "false" && "error"}
                    style={{ width: "100%", fontWeight: "normal" }}
                    placeholder="Elige un tipo de cita"
                    options={CitaTipos}
                    onSelect={(e) => {
                        if (e === "3") {
                        // setHorario(null);
                        // setMensajeAddNombrecontacto("true");
                        // setMensajeAddApellidoPcontacto("true");
                        // setMensajeAddApellidoMcontacto("true");
                        // setMensajeAddCelularcontacto("true");
                        // setMensajeAddCorreocontacto("true");
                        // setMensajeContacto("true");
                        }
                        setTipoCita(e);
                        setComboTipoCita("true");
                    }}
                    />
                </Campos>
                </Row>

                <SelectAntd
                    label="Contacto:"
                    requiredICon={true}
                    value={
                        !!contactos.selectedContact.id
                        ? {
                            value: "",
                            label: (
                            <div style={{ display: "flex", gap: "1" }}>
                                <img
                                className="ImgMedicoSelect"
                                src={
                                    !!contactos.selectedContact.imagen
                                    ? `https://${contactos.selectedContact.imagen}`
                                    : ""
                                }
                                />{" "}
                                <div style={{ marginLeft: "1%" }}>
                                {contactos.selectedContact.nombre}
                                </div>
                            </div>
                            ),
                        }
                        : null
                    }
                    style={{
                        width: "100%",
                        margin: "1% 0",
                    }}
                    placeholder="Selecciona un contacto"
                    onClick={() => {
                        dispatch(ShowModal(ModalScreen.SELECT_CONTACT));
                    }}
                    setStateError={setMensajeContacto}
                    valueError={MensajeContacto}
                    textError="Debe seleccionar un contacto"
                    readOnly={true}
                    open={false}
                    vacio={false}
                />

                <TextAreaAntd
                label="Motivo de cita:"
                value={motivo}
                placeholder="Escriba un motivo de cita (opcional)"
                style={{
                    marginBottom: ".5%",
                    fontFamily: "sans-serif",
                }}
                columns="30"
                autoSize={true}
                setStateValue={setMotivo}
                />

                <TextAreaAntd
                label="Comentarios o notas:"
                value={comentario}
                placeholder="Escriba un comentario o nota (opcional)"
                style={{
                    marginBottom: "2%",
                    fontFamily: "sans-serif",
                }}
                columns="30"
                autoSize={true}
                setStateValue={setComentario}
                />
            </form>
        },
        {   label: `Agendadas`,
            key:'2',
            children:
                <div style={{ background: "#fff" }}>
                    <CitasAgendadasCard listCitas={citasAgendadas} />
                </div>
        }
    ]

    return(
        <>
            <div className="ModalAddCita">
            {loaderDetalle ? (
                <LoaderModals className={"ModalAddCita"} />
            ) : (
                <>
                <header
                    style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    width: "100%",
                    background: "white",
                    padding: "0 10px",
                    display: "flex",
                    justifyContent: "end",
                    }}
                >
                    <Button
                    type="button"
                    onClick={() => {
                        saveCita();
                    }}
                    style={{
                        width: "120px",
                        backgroundColor: "#148f9f",
                        color: "white",
                    }}
                    >
                    Crear
                    </Button>
                </header>

                <Content
                    className="site-layout"
                    style={{
                    padding: "0",
                    width: "100%",
                    overflow: "initial",
                    }}
                >
                    <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                    >
                    <Calendar
                        locale={locale}
                        value={dayjs(selectedDay)}
                        fullscreen={false}
                        onSelect={(e) => selectDateHandler(e)}
                        mode="month"
                    />
                    </div>
                    <Tabs
                        centered
                        defaultActiveKey="1"
                        items={contentTabs}
                        style={{ background: "white" }}
                    />
                </Content>
                </>
            )}
            </div>
        </>
    );
}
export default AddCitasContent;