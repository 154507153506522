/*Importando Librerias*/
import ReactPlayer from "react-player";
import React, { useState, useEffect } from "react";
import * as ReactDOM from "react-dom";
import * as Heroicons from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Avatarr from "@mui/material/Avatar";
import Badgee from "@mui/material/Badge";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

import InputAntd from "../GeneralComponents/InputAntd";

// importando cargador
import { Loader } from "./Loader";

/*Importando logo*/
import logo from "../../logo.svg";
import Logowhite from "../../logoBlanco.svg";
import IcoFactura from "../../Img/Iconos/Factura.svg";
import Menu from "../../Img/Iconos/Menu.svg";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PersonIcon from "@mui/icons-material/Person";
import { ArrowBack, Notifications } from "@mui/icons-material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { DownOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";

//Importando drowdown
import DrowMenuPerfil from "./DrowPerfil";

/*Importando Componente de iconos*/
import Iconos from "../IconosDeSitio";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

/*Importando estilos*/
import "../comp-styles/Navbar.scss";
import "../comp-styles/Sidebar.scss";
import "../comp-styles/Sitio.scss";
import "../../pages/styles/Contactos.scss";
import "../comp-styles/notificaciones.scss";

// Solicitudes y notificaciones componente
import SolicitudesCard from "../../componentes/Notificaciones/solicitudes_card";
import NotificacionesCard from "../../componentes/Notificaciones/notificaciones_card";

// peticiones a api
import { logout_request } from "../../services/login_services";
import {
  getDoctorsLink_request,
  loginMedico_request,
} from "../../services/asistentes_services";
import { getNotificaciones_request } from "../../services/notificaciones_services";
import {
  getConfigRecetas_request,
  addConfigReceta_request,
} from "../../services/recetas_services";
import {
  acceptSolicitud_request,
  cancelSolicitud_request,
  solicitudDetail_request,
} from "../../services/vinculacion_services";

import {
  Dropdown,
  Row,
  Space,
  Button,
  Select,
  Layout,
  Drawer,
  Badge,
  Col,
  Image,
  DatePicker,
  Avatar,
} from "antd";
import MenuIcon from '@mui/icons-material/Menu';
import { ListItemDecorator } from "@mui/joy";

import ModalDetalle from "../GeneralComponents/ModalDetalle";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Popover } from "@mui/material";
import { UseModal } from "../../hooks/UseModal";
import LoaderModals from "./Loader_modals";
import TextAreaAntd from "./TextAreaAntd";
import InputFileSingle from "./InputFileSingle";
import NotificationSlide from "../Notificaciones/notificaciones_slide";
import ReactGA from "react-ga4";
import { useSelector, useDispatch } from 'react-redux';
import { showSnackbar } from '../../redux/snackbarSlice';

import { badges } from "../../redux/badges";
import { infoDoctorLog } from "../../redux/infoDoctorLog";
import { infoUser_request } from "../../services/perfil_services";
import { infoUser } from "../../redux/userSlice";
import { doctorsToAssistant } from "../../redux/doctorsToAssistant";
import DetailVinculacion from "../Vinculacion/detailVinculacion";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const { Header, Content, Footer } = Layout;
const { RangePicker } = DatePicker;

// Avatar activo asistentes
const StyledBadge = styled(Badgee)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

/*Variable para esconder*/

export default function NavbarNuevo({
  consulta,
  searchBar,
  mensajesError,
  detalle,
  modulo,
}) {
  // console.log(extra);
  // let loginAsistente = localStorage.getItem("loginAsistente");
  // let datosMedico = JSON.parse(localStorage.getItem("datosMedico"));
  // let userData = JSON.parse(sessionStorage.getItem("infoUser"));
  // console.log(userData);

  // Metodo de login
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let loginAsistente = localStorage.getItem("loginAsistente");
  let permisos = localStorage.getItem("permisos");
  // console.log(permisos);

  const notificaciones = useSelector((state) => state.notifications);
  // console.log(notificaciones);
  const userInfo = useSelector((state) => state.userInfo.usuario);
  const infoDoctor = useSelector((state) => state.infoDoctorLog);
  const badgesNumber = useSelector((state) => state.badges);
  // console.log(badgesNumber);

  const listaMedicosVinculados = useSelector((state) => state.doctorsLink.doctors);
  const listaMedicosVinculadosFilter = useSelector((state) => state.doctorsLink.doctors);

  // const bar = useSelector((state) => state.navBarProps.searchBar)
  // console.log(bar);

  const [infoSolicitud, setInfoSolicitud] = useState("");

  const [isOpenModal, OpenModal, CloseModal] = UseModal(false);
  const [isOpenConfig, OpenConfig, CloseConfig] = UseModal(false);
  const [isOpenDetalleVinc, OpenDetalleVinc, CloseDetalleVinc] = UseModal(false);

  // Splash de cambio de medico
  const [
    isOpenSplashChangeMedic,
    OpenSplashChangeMedic,
    CloseSplashChangeMedic,
  ] = UseModal(false);
  const [InfoUsersplash, setInfoUsersplash] = useState({
    nombre: "",
    imagen: "",
  });

  // state bienvenida
  const [isOpenBienvenida, OpenBienvenida, CloseBienvenida] = UseModal(true);

  // Mensajes form CONFIG RECETA
  const [MensajeLogo, setMensajeLogo] = useState("true");
  const [MensajeLogoDos, setMensajeLogoDos] = useState("true");
  const [MensajeEncabezado, setMensajeEncabezado] = useState("true");
  const [MensajePiePagina, setMensajePiePagina] = useState("true");
  const [MensajeFormato, setMensajeFormato] = useState("true");
  const [MensajeFirma, setMensajeFirma] = useState("true");

  const [ConfigReceta, setConfigReceta] = useState("");

  // Formulario configuracion
  const [Logo, setLogo] = useState({ name: "", url: "", file: "" });
  const [LogoDos, setLogoDos] = useState({ name: "", url: "", file: "" });
  const [Firma, setFirma] = useState({
    name: "",
    url: "",
    file: "",
    size: 0,
    formato: "",
  });
  const [Encabezado, setEncabezado] = useState("");
  const [PiePagina, setPiePagina] = useState("");
  const [FormatoReceta, setFormatoReceta] = useState("");
  // menu hamburgesa
  const [esconder, setEsconder] = useState(false);

  // Menu de camviar de medico
  const [EsconderChangeMedic, setEsconderChangeMedic] = useState(false);

  // desplegar menu perfil
  const [Perfil, setPerfil] = useState(false);

  const [Notificaciones, setNotificaciones] = useState({
    solicitudes: [],
    notificaciones: [],
  });

  const [loader, setLoader] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);

  const [MedicoLogin, setMedicoLogin] = useState("");

  const styleDet = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
    zIndex: "999",
  };
  const styleSplash = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    backgroundColor: "#148f9f",
    zIndex: "999",
  };

  // console.log(state);

  const onLogout = async () => {
    let response = await logout_request();
    if (response.ok) {
      navigate("/", {
        replace: true,
        state: {
          logged: false,
        },
      });
    } else {
      dispatch(showSnackbar({
        show: true,
        text: response.mensaje,
        type: "error",
      }));
    }
  };

  async function getNotifications() {
    setLoader(true);
    let response = await getNotificaciones_request();
    if (response.ok) {
      // console.log(response.data);

      setNotificaciones({
        solicitudes:
          response.data.Vinculacion !== undefined
            ? response.data.Vinculacion
            : [],
        notificaciones:
          response.data.Notificaciones !== undefined
            ? response.data.Notificaciones
            : [],
      });
    } else {
    }
    setLoader(false);
    return;
  }

  async function logInMedico(datos, idMedico) {
    // console.log(idMedico);
    // return;

    let response = await loginMedico_request(idMedico);
    if (response.ok) {
      // console.log(response);
      setMedicoLogin(idMedico);
      localStorage.setItem(
        "datosMedico",
        JSON.stringify({ id: idMedico, nombre: "", imagen: "" })
      );
      localStorage.setItem("permisos", JSON.stringify(response.data));
      localStorage.setItem("loginAsistente", 1);
      getDoctorsLink();
      getInfoUser();
      // navigate("/", { replace: true });
    } else {
      dispatch(showSnackbar({
        show: true,
        text: response.mensaje,
        type: "error",
      }));
    }

    return;
  }

  async function configReceta() {
    setLoader(true);
    let response = await getConfigRecetas_request();
    if (response.ok) {
      // console.log(response.data);
      // setConfigReceta(response.data);
      // setLogo({ name: "", url: "https://" + response.data.Logo, file: "" });
      // setLogoDos({ name: "", url: "https://" + response.data.Logo2, file: "" });
      // setFirma({
      //     name: "",
      //     url: "https://" + response.data.FirmaMedico,
      //     file: "",
      //     name: "",
      //     url: "https://" + response.data.FirmaMedico,
      //     file: "",
      //     size: "",
      //     formato: response.data.FirmaMedico.lastIndexOf(".",)
      // });
      if (!!response.data.Logo) {
        setLogo({
          name: "Logo",
          url: "https://" + response.data.Logo,
          file: "",
          size: 0,
          formato: response.data.Logo.substring(
            response.data.Logo.lastIndexOf(".") + 1,
            response.data.Logo.length
          ),
        });
      }
      // if (condition) {
      //     setLogoDos({ name: "Logo 2", url: "https://" + response.data.Logo2, file: "", size: 0, formato: response.data.Logo2.substring(response.data.Logo2.lastIndexOf(".") + 1, response.data.Logo2.length) });
      // }
      if (!!response.data.FirmaMedico) {
        setFirma({
          name: "Firma",
          url: "https://" + response.data.FirmaMedico,
          file: "",
          size: 0,
          formato: response.data.FirmaMedico.substring(
            response.data.FirmaMedico.lastIndexOf(".") + 1,
            response.data.FirmaMedico.length
          ),
        });
      }
      setEncabezado(response.data.Subtitulo);
      setPiePagina(response.data.Anexo);
      setFormatoReceta(response.data.Formato);
    } else {
    }
    setLoader(false);
    return;
  }

  async function saveConfig() {
    setLoader(true);
    // console.log(Logo);
    // console.log(LogoDos);
    // console.log(Firma);
    // console.log(Encabezado);
    // console.log(PiePagina);
    // console.log(!!FormatoReceta);
    // console.log(!!Firma.url);
    // setLoaderDetalle(false);
    // return;
    if (!!Encabezado && !!PiePagina) {
      let data = new FormData();
      if (Logo.file !== "") {
        data.append("Logo", Logo.file, Logo.name);
      }
      if (Firma.file !== "") {
        data.append("Firma", Firma.file, Firma.name);
      }
      data.append("Subtitulo", Encabezado);
      data.append("Anexo", PiePagina);
      let response = await addConfigReceta_request(data);
      if (response.ok) {
        dispatch(showSnackbar({
          show: true,
          text: "Configuración guardada",
          type: "success",
        }));
        configReceta();
        cleanInputsConfig();
      } else {
        dispatch(showSnackbar({
          show: true,
          text: response.mensaje,
          type: "error",
        }));
      }
    } else {
      if (!!!Logo.url) {
        setMensajeLogo("false");
      }
      if (!!!Firma.url) {
        setMensajeFirma("false");
      }
      if (!!!Encabezado) {
        setMensajeEncabezado("false");
      }
      if (!!!PiePagina) {
        setMensajePiePagina("false");
      }
      if (!!!FormatoReceta.toString()) {
        setMensajeFormato("false");
      }
      dispatch(showSnackbar({
        show: true,
        text: "Faltan campos por completar",
        type: "warning",
      }));
    }
    setLoader(false);
    return;
  }

  async function acceptSolicitudes(idSolicitud) {
    // console.log(idSolicitud);
    setLoader(true);
    // CloseDetalleVinc(true);
    // return;
    let response = await acceptSolicitud_request(idSolicitud);
    if (response.ok) {
      // console.log(response.data);
      dispatch(showSnackbar({
        show: true,
        text: "Solicitud aceptada",
        type: "success",
      }));
      CloseModal(true);
      getNotifications();
    } else {
      dispatch(showSnackbar({
        show: true,
        text: response.mensaje,
        type: "error",
      }));
    }
    setLoader(false);
    return;
  }

  async function cancelSolicitudes(idSolicitud) {
    setLoader(true);
    // CloseDetalleVinc(true);
    // return;
    let response = await cancelSolicitud_request(idSolicitud);
    if (response.ok) {
      // console.log(response.data);
      dispatch(showSnackbar({
        show: true,
        text: "Solicitud rechazada",
        type: "success",
      }));
      CloseModal(true);
      getNotifications();
    } else {
      dispatch(showSnackbar({
        show: true,
        text: response.mensaje,
        type: "error",
      }));
    }
    setLoader(false);
    return;
  }

  async function getInfoUser() {
    // localStorage.setItem("token", "");
    let response = await infoUser_request();
    if (response["ok"]) {
      // console.log("USUARIO");
      // console.log(response.data);
      // console.log("###########3");
      dispatch(infoUser(response.data));
      let info = response.data.Usuario;
      sessionStorage.setItem("infoUser", JSON.stringify(info));
      // console.log(localStorage.getItem("logged"));

      localStorage.setItem("paquete", response.data.Usuario.Paquete);
      localStorage.setItem("paqueteStatus", response.data.Usuario.PaqueteStatus);
      if (loginAsistente === "0") {
        navigate("/selectMedico", {
          replace: true,
          state: {
            logged: true
          },
        });
      } else {
        if (document.domain === "localhost") {
          console.log(info);
        }
        if (info.Paquete !== "0" && info.PaqueteStatus === 1) {
          navigate("/app/inicio", { replace: true });
          window.location.reload();
        } else {
          navigate("/app/licencia", { replace: true });
        }

        // navigate("/app/citas", { replace: true });
      }

    } else {
      if (response.status === 404) {
        sessionStorage.removeItem("infoUser");
        localStorage.removeItem("permisos");
        localStorage.setItem("loginAsistente", 0);
        localStorage.removeItem("token");
        localStorage.removeItem("tipoUsuario");
        localStorage.removeItem("datosMedico");
        localStorage.removeItem("logged");
        navigate("/login", {
          replace: true,
          state: {
            logged: false,
          },
        });
      } else {
        dispatch(showSnackbar({
          show: true,
          text: response.mensaje,
          type: "error",
        }));
      }
    }
    CloseSplashChangeMedic(true);
    return;
  }

  async function getDoctorsLink() {
    let response = await getDoctorsLink_request();
    if (response.ok) {
      // console.log(response.data);
      dispatch(doctorsToAssistant({ doctors: response.data }));

      response.data.forEach(element => {

        if (element.Logged === 1) {
          if (document.domain === "localhost") {
            console.log(element);
          }
          dispatch(infoDoctorLog(element));
        }
      });

      // setListaMedicosVinculados(array);
      // setListaMedicosVinculadosFilter(response.data);
    } else {
      // dispatch(doctorsToAssistant([]));
      dispatch(doctorsToAssistant({ doctors: [] }));
    }
  }

  const detalleSolicitud = async (idSolicitud) => {
    setLoaderModal(true);
    // console.log(idSolicitud);
    let response = await solicitudDetail_request(idSolicitud);
    // console.log(response);
    if (response.ok) {
      // console.log(response.data);
      setInfoSolicitud(response.data);
    } else {
      CloseDetalleVinc(true);
      dispatch(showSnackbar({
        show: true,
        text: response.mensaje,
        type: "error",
      }));
    }
    setLoaderModal(false);
  };

  function cleanInputsConfig() {
    setLogo({ name: "", url: "", archivo: "" });
    setLogoDos({ name: "", url: "", archivo: "" });
    setFirma({ name: "", url: "", archivo: "" });
    setEncabezado("");
    setPiePagina("");
    setFormatoReceta("");
    setMensajeLogo("true");
    setMensajeLogoDos("true");
    setMensajeEncabezado("true");
    setMensajePiePagina("true");
    setMensajeFormato("true");
    setMensajeFirma("true");
  }

  function redirecciones(modulo, notificacion) {
    console.log(modulo);
    switch (modulo) {
      case "citas":
        navigate("/app/citas", {
          replace: true,
          state: {
            id: notificacion.contactUserId,
            imagen: notificacion.userImg,
            nombre: notificacion.userName,
            idCita: notificacion.subjectId,
          },
        });
        break;

      case "estudios":
        navigate("/app/estudios", {
          replace: true,
          state: {
            id: notificacion.contactUserId,
            imagen: notificacion.userImg,
            nombre: notificacion.userName,
            idEstudio: notificacion.subjectId,
          },
        });
        break;

      case "vinculacion":
        CloseModal(true);
        OpenDetalleVinc(true);
        detalleSolicitud(notificacion.subjectId);
        break;

      default:
        dispatch(showSnackbar({
          show: true,
          text: "No existe redirección para esta notificación",
          type: "warning",
        }));
        break;
    }
  }

  useEffect(() => {
    // console.log(extra);
    // if (document.domain === "ygia.app") {
    //   getNotifications();
    // }
    configReceta();
  }, []);

  //   Variable para controlar el video

  const [Stop, setStop] = useState(true);
  const [StatusBienvenida, setStatusBienvenida] = useState(false);
  const [SelectedEnlase, setSelectedEnlace] = useState(true);

  // arreglo de submenu

  let items = [
    {
      key: "1",
      label:

        <li
          style={
            modulo === "Contactos"
              ? {
                width: "100%",
                // background: "#f4f5f6",
                background: "#f4f5f6",
                borderRadius: "1rem",
              }
              : {}
          }
          className={modulo === "Contactos" ? "SubMenuActive" : ""}
          onClick={() => {
            if (consulta !== undefined) {
              consulta("/app/Contactos");
            }
          }}
        >
          <Link to={consulta === undefined ? "/app/Contactos" : ""}>
            Contactos
          </Link>
        </li>
    },
    {
      key: "2",
      label:

        <li
          style={
            modulo === "Recetas"
              ? {
                width: "100%",
                background: "#f4f5f6",
                // padding: "0 10px",
                borderRadius: "1rem",
                // textAlign: "center",
              }
              : {}
          }
          className={modulo === "Recetas" ? "SubMenuActive" : ""}
          onClick={() => {
            if (consulta !== undefined) {
              consulta("/app/Recetas");
            }
          }}
        >
          <Link to={consulta === undefined ? "/app/Recetas" : ""}>
            Recetas
          </Link>
        </li >
    },
    {
      key: "3",
      label:
        <li
          style={
            modulo === "Ordenes"
              ? {
                width: "100%",
                background: "#f4f5f6",
                padding: "0 10px",
                borderRadius: "1rem",
                textAlign: "center",
              }
              : {}
          }
          className={modulo === "Ordenes" ? "SubMenuActive" : ""}
          onClick={() => {
            if (consulta !== undefined) {
              consulta("/app/Ordenes");
            }
          }}
        >
          <Link to={consulta === undefined ? "/app/Ordenes" : ""}>
            Ordenes
          </Link>
        </li>
    },
    {
      key: "4",
      label:
        <Row>
          <li
            style={
              modulo === "Estudios"
                ? {
                  width: "80%",
                  background: "#f4f5f6",
                  // padding: "0 10px",
                  borderRadius: "1rem",
                  // textAlign: "center",
                }
                : {
                  width: "80%",
                }
            }
            className={modulo === "Estudios" ? "SubMenuActive" : ""}
            onClick={() => {
              if (consulta !== undefined) {
                consulta("/app/Estudios");
              }
            }}
          >
            <Link to={consulta === undefined ? "/app/Estudios" : ""}>
              Estudios
            </Link>
          </li>
          <Space size="small">
            <Badge count={badgesNumber.estudios}>
            </Badge>
          </Space>
        </Row >

    },
    {
      key: "5",
      label:
        <li
          style={
            modulo === "Asistentes"
              ? {
                width: "100%",
                background: "#f4f5f6",
                padding: "0 10px",
                borderRadius: "1rem",
                textAlign: "center",
              }
              : {}
          }
          className={modulo === "Asistentes" ? "SubMenuActive" : ""}
          onClick={() => {
            if (consulta !== undefined) {
              consulta("/app/Asistentes");
            }
          }}
        >
          <Link to={consulta === undefined ? "/app/Asistentes" : ""}>Asistentes</Link>
        </li>
    },
    {
      key: "6",
      label:
        <li
          style={
            modulo === "PuntoDeVenta"
              ? {
                width: "100%",
                background: "#f4f5f6",
                padding: "0 10px",
                borderRadius: "1rem",
                textAlign: "center",
              }
              : {}
          }
          className={modulo === "PuntoDeVenta" ? "SubMenuActive" : ""}
          onClick={() => {
            if (consulta !== undefined) {
              consulta("/app/PuntoDeVenta");
            }
          }}
        >
          <Link to={consulta === undefined ? "/app/PuntoDeVenta" : ""}>Punto de venta</Link>
        </li>
    },
    {
      key: "7",
      label:
        <li
          style={
            modulo === "Ingresos"
              ? {
                width: "100%",
                background: "#f4f5f6",

                borderRadius: "1rem",
                textAlign: "center",
                margin: "2px 0",
              }
              : {}
          }
          className={modulo === "Ingresos" ? "SubMenuActive" : ""}
          onClick={() => {
            if (consulta !== undefined) {
              consulta("/app/IngresosyEgresos");
            }
          }}
        >
          <Link to={consulta === undefined ? "/app/IngresosyEgresos" : ""}>
            Ingresos y egresos
          </Link>
        </li>
    },
    {
      key: "8",
      label:
        <li
          style={
            modulo === "Reportes"
              ? {
                width: "100%",
                background: "#f4f5f6",
                padding: "0 10px",
                borderRadius: "1rem",
                textAlign: "center",
              }
              : {}
          }
          className={modulo === "Reportes" ? "SubMenuActive" : ""}
          onClick={() => {
            if (consulta !== undefined) {
              consulta("/app/Reportes");
            }
          }}
        >
          <Link to={consulta === undefined ? "/app/Reportes" : ""}>Reportes</Link>
        </li>
    },
    {
      key: "9",
      label:
        <Row>
          <li
            style={
              modulo === "vinculacion"
                ? {
                  width: "80%",
                  background: "#f4f5f6",
                  // padding: "0 10px",
                  borderRadius: "1rem",
                  // textAlign: "center",
                }
                : {
                  width: "80%",
                }
            }
            className={modulo === "vinculacion" ? "SubMenuActive" : ""}
            onClick={() => {
              if (consulta !== undefined) {
                consulta("/app/vinculacion");
              }
            }}
          >
            <Link to={consulta === undefined ? "/app/vinculacion" : ""}>
              Vinculación
            </Link>
          </li>
          <Space size="small">
            <Badge count={badgesNumber.vinculacion}>
            </Badge>
          </Space>
        </Row >
    },
    // {
    //   key: "9",
    //   label:
    //     consulta !== undefined ? (
    //       <li
    //         onClick={() => {
    //           consulta("/app/Anuncios");
    //         }}
    //       >
    //         <Link to="">Anuncios</Link>
    //       </li>
    //     ) : (
    //       <li
    //         style={
    //           modulo === "Anuncios"
    //             ? { width: "100%", background: "#f4f5f6", padding: "0 10px", borderRadius: "1rem", textAlign: "center" }
    //             : {}
    //         }
    //         className={modulo === "Anuncios" && "SubMenuActive"}
    //       >
    //         <Link to="/app/Anuncios">Anuncios</Link>
    //       </li>
    //     ),
    // },
  ];

  return (
    <>
      <header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          background: "white",
        }}
      >
        <div
          style={{
            width: "100%",
            // margin:"0",
            padding: "0 3%",
            // display: extra === undefined ? 'flex' : "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "white",
            height: "50px",
          }}
        >
          <div className="Menu-Hamburg">
            <Space size="small">
              <Badge count={badgesNumber.citas + badgesNumber.chat + badgesNumber.estudios + badgesNumber.vinculacion}>
                <MenuIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setPerfil(false);
                    setEsconder(!esconder);
                  }}
                />
              </Badge>
            </Space>
          </div>

          <img
            style={{ width: "5rem", cursor: "pointer" }}
            src={logo}
            alt=""
            onClick={() => {
              if (consulta !== undefined) {
                consulta("/");
              } else {
                navigate("/", { replace: true });
              }
            }}
          />

          {(userInfo.Tipo === 1 && userInfo.Paquete !== "0" && userInfo.PaqueteStatus === 1) && (
            <ul className="Menu_principal" style={{ margin: "0" }}>
              <li
                className={modulo === "inicio" ? "claseMenu" : ""}
                onClick={() => {
                  if (consulta !== undefined) {
                    consulta("/app/inicio");
                    setSelectedEnlace(false);
                  }

                }}
              >
                <Link to={consulta === undefined ? "/app/inicio" : ""}>Inicio</Link>
              </li>

              <Space size="small">
                <Badge count={badgesNumber.citas}>
                  <li
                    className={modulo === "Citas" ? "claseMenu" : ""}
                    style={{ paddingTop: ".15rem" }}
                    onClick={() => {
                      if (consulta !== undefined) {
                        consulta("/app/citas");
                      }

                    }}
                  >
                    <Link to={consulta === undefined ? "/app/citas" : ""}>Citas</Link>
                  </li>
                </Badge>
              </Space>

              <li
                className={modulo === "Consultas" ? "claseMenu" : ""}
                onClick={() => {
                  if (consulta !== undefined) {
                    consulta("/app/consultas");
                    setSelectedEnlace(false);
                  }

                }}
              >
                <Link to={consulta === undefined ? "/app/consultas" : ""}>Consultas</Link>
              </li>


              <li className={modulo === "Expedientes" ? "claseMenu" : ""}
                onClick={() => {
                  if (consulta !== undefined) {
                    consulta("/app/ExpedientesNuevo");
                  }
                }}
              >
                <Link to={consulta === undefined ? "/app/ExpedientesNuevo" : ""}>Expedientes</Link>
              </li>

              <Space size="small">
                <Badge count={badgesNumber.chat}>
                  {/* {(document.domain === "localhost" ||
                    document.domain === "dev.ygia.app") && ( */}
                  <li className={modulo === "Chat" ? "claseMenu" : ""}
                    onClick={() => {
                      if (consulta !== undefined) {
                        consulta("/app/chats");
                      }
                    }}
                  >
                    <Link to={consulta === undefined ? "/app/chats" : ""}>Chat</Link>
                  </li>
                  {/* )} */}
                </Badge>
              </Space>
              {/* <li> */}
                <Space>
                  <Badge count={badgesNumber.menu}>
                    <Dropdown menu={{ items }}>
                      <li>Menú</li>
                    </Dropdown>
                  </Badge>
                </Space>
              {/* </li> */}
            </ul>
          )}

          {(userInfo.Tipo === 4 && userInfo.Paquete !== "0" && userInfo.PaqueteStatus === 1) && (
            <ul className="Menu_principal" style={{ margin: "0" }}>

              <li
                className={modulo === "Inicio" ? "claseMenu" : ""}
                onClick={() => {
                  if (consulta !== undefined) {
                    consulta("/app/inicio");
                    setSelectedEnlace(false);
                  }

                }}
              >
                <Link to={consulta === undefined ? "/app/inicio" : ""}>Inicio</Link>
              </li>

              <Space size="small">
                <Badge count={badgesNumber.citas}>
                  <li
                    className={modulo === "Citas" ? "claseMenu" : ""}
                    style={{ paddingTop: ".15rem" }}
                    onClick={() => {
                      if (consulta !== undefined) {
                        consulta("/app/citas");
                      }

                    }}
                  >
                    <Link to={consulta === undefined ? "/app/citas" : ""}>Citas</Link>
                  </li>
                </Badge>
              </Space>

              <li className={modulo === "Expedientes" ? "claseMenu" : ""}
                onClick={() => {
                  if (consulta !== undefined) {
                    consulta("/app/ExpedientesNuevo");
                  }
                }}
              >
                <Link to={consulta === undefined ? "/app/ExpedientesNuevo" : ""}>Expedientes</Link>
              </li>

              <Space size="small">
                <Badge count={badgesNumber.chat}>
                  {/* {(document.domain === "localhost" ||
                    document.domain === "dev.ygia.app") && ( */}
                  <li className={modulo === "Chat" ? "claseMenu" : ""}
                    onClick={() => {
                      if (consulta !== undefined) {
                        consulta("/app/chats");
                      }
                    }}
                  >
                    <Link to={consulta === undefined ? "/app/chats" : ""}>Chat</Link>
                  </li>
                  {/* )} */}
                </Badge>
              </Space>

              <li className={modulo === "PuntoDeVenta" ? "claseMenu" : ""}
                onClick={() => {
                  if (consulta !== undefined) {
                    consulta("/app/PuntoDeVenta");
                  }
                }}
              >
                <Link to={consulta === undefined ? "/app/PuntoDeVenta" : ""}>Punto de venta</Link>
              </li>


              <li className="ListaDeMenu" style={{ position: "relative" }}>
                <span
                // onClick={()=>{MenuPinsipal === true ? setMenuPinsipal(false): setMenuPinsipal(true)}}
                >
                  Menú
                </span>
                <div className="ActivarMenuPins">
                  <ul
                    style={{
                      borderRadius: ".2rem",
                      margin: "0",
                      // border: "1px solid #d7d7d7",
                      padding: "10px",
                      display: "block",
                      position: "absolute",
                      background: "#fff",
                      zIndex: "999",
                    }}
                    className="BoxSadow"
                  >

                    <li
                      style={
                        modulo === "Contactos"
                          ? {
                            width: "100%",
                            background: "#f4f5f6",
                            padding: "0 10px",
                            borderRadius: "1rem",
                            textAlign: "center",
                            margin: "2px 0",
                          }
                          : {}
                      }
                      className={modulo === "Contactos" && "SubMenuActive"}
                      onClick={() => {
                        if (consulta !== undefined) {
                          consulta("/app/Contactos");
                        }
                      }}
                    >
                      <Link to={consulta === undefined ? "/app/Contactos" : ""}>Contactos</Link>
                    </li>

                    <li
                      style={
                        modulo === "Ingresos"
                          ? {
                            width: "100%",
                            background: "#f4f5f6",

                            borderRadius: "1rem",
                            textAlign: "center",
                            margin: "2px 0",
                          }
                          : {}
                      }
                      className={modulo === "Ingresos" && "SubMenuActive"}
                      onClick={() => {
                        if (consulta !== undefined) {
                          consulta("/app/IngresosyEgresos");
                        }
                      }}
                    >
                      <Link to={consulta === undefined ? "/app/IngresosyEgresos" : ""}>
                        Ingresos y egresos
                      </Link>
                    </li>

                    <li
                      style={
                        modulo === "vinculacion"
                          ? {
                            width: "100%",
                            background: "#f4f5f6",
                            padding: "0 10px",
                            borderRadius: "1rem",
                            textAlign: "center",
                            margin: "3px 0",
                          }
                          : {}
                      }
                      className={modulo === "vinculacion" && "SubMenuActive"}
                      onClick={() => {
                        if (consulta !== undefined) {
                          consulta("/app/vinculacion");
                        }
                      }}
                    >
                      <Link to={consulta === undefined ? "/app/vinculacion" : ""}>Vinculación</Link>
                    </li>

                    {/* {(consulta !== undefined) ?
                                        <li onClick={() => {
                                            consulta("/app/Anuncios");
                                        }}>
                                            <Link to="" >Anuncios</Link>
                                        </li> :
                                        <li>
                                            <Link to="/app/Anuncios">Anuncios</Link>
                                        </li>
                                    } */}
                  </ul>
                </div>
              </li>
            </ul>
          )}

          <Row style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>
            {(userInfo.Paquete !== "0" && userInfo.PaqueteStatus === 1) && <Col>
              <Space size="small">
                <Badge count={badgesNumber.notificaciones}>
                  <Notifications
                    color="disabled"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      OpenModal(true);
                    }}
                  />
                </Badge>
              </Space>
            </Col>}

            <Col>
              <DrowMenuPerfil
                setEsconderChangeMedic={setEsconderChangeMedic}
                OpenConfig={OpenConfig}
                consulta={consulta}
                // imagenPerfil={InfoUsersplash.imagen}
                onLogout={onLogout}
              />
            </Col>
          </Row>
        </div>

        <div style={{ background: "#eaeef0", padding: ".3% 0%" }}>
          {userInfo.Tipo === 4 && (
            <>
              <div
                style={{
                  width: "100%",
                  padding: "0 3%",
                  background: "white",
                  marginBottom: "4px ",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Row style={{ gap: "10px", alignItems: "center" }}>
                  <Stack direction="row" spacing={2}>
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      variant="dot"
                    >
                      <Avatarr
                        alt={infoDoctor.Nombre}
                        src={`https://${infoDoctor.Imagen}`}
                      />
                    </StyledBadge>
                  </Stack>
                  <div>
                    <p className="NombreMedicoChange">{infoDoctor.Nombre}</p>
                    <p
                      style={{ fontWeight: "normal" }}
                      className="NombreMedicoChange"
                    >
                      {infoDoctor.Profesion}
                    </p>
                  </div>
                </Row>
                <Button
                  onClick={() => setEsconderChangeMedic(true)}
                  style={{
                    width: "100px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "5px",
                  }}
                  type="button"
                  className="GeneralBoton"
                >
                  Cambiar <ChangeCircleIcon fontSize="90" />{" "}
                </Button>
              </div>
            </>
          )}
          {searchBar}
          {/* {bar} */}
          {/* <div>{bar}</div> */}
          {/* {} */}
        </div>

        {/* Drawer menu hamburguesa */}
        <Drawer
          title={
            <div
              style={{
                display: "flex",
                color: "white",
                justifyContent: "space-between",
              }}
              onClick={() => setEsconder(false)}
            >
              Menú <ArrowBack />
            </div>
          }
          placement="left"
          closable={false}
          onClose={() => setEsconder(false)}
          open={esconder}
          key="left"
          width="300"
          style={{ background: "#148f9f" }}

        >
          {userInfo.Tipo === 1 && (
            <div className="drawerStyle">
              <ul>

                <li
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (consulta !== undefined) {
                      consulta("/app/inicio");
                    }
                    setEsconder(false);
                  }}
                >

                  <div className="LinkAcomodo" style={{ paddingTop: "5%" }}>
                    {/* <img
                      style={{ height: "20px" }}
                      src={Iconos[7]}
                      alt=""
                    /> */}
                    <Link style={{ width: "100%" }} to={consulta === undefined ? "/app/inicio" : ""}>
                      <HomeOutlined style={{ fontSize: '20px', marginRight: '5px' }} />
                      <span>Inicio</span>
                    </Link>
                  </div>
                </li>

                <li
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (consulta !== undefined) {
                      consulta("/app/consultas");
                    }
                    setEsconder(false);
                  }}
                >

                  <div className="LinkAcomodo">
                    <Link style={{ width: "100%" }} to={consulta === undefined ? "/app/consultas" : ""}>
                      <img
                        style={{ height: "20px" }}
                        src={Iconos[7]}
                        alt=""
                      />
                      <span>Consultas</span>
                    </Link>
                  </div>
                </li>

                <li
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (consulta !== undefined) {
                      consulta("/app/Citas");
                    }
                    setEsconder(false);
                  }}
                >
                  <div className="LinkAcomodo">
                    <Link style={{ width: "100%" }} to={consulta === undefined ? "/app/Citas" : ""}>
                      <img src={Iconos[3]} alt="" />
                      <span>Citas</span>
                    </Link>
                    <Space size="small" style={{ marginLeft: "10px" }}>
                      <Badge count={badgesNumber.citas}>
                      </Badge>
                    </Space>
                  </div>
                </li>

                <li
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (consulta !== undefined) {
                      consulta("/app/ExpedientesNuevo");
                    }
                    setEsconder(false);
                  }}
                >
                  <div className="LinkAcomodo">
                    <Link style={{ width: "100%" }} to={consulta === undefined ? "/app/ExpedientesNuevo" : ""}>
                      <img
                        style={{ height: "20px" }}
                        src={Iconos[10]}
                        alt=""
                      />
                      <span>Expedientes</span>
                    </Link>
                  </div>
                </li>

                <li
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (consulta !== undefined) {
                      consulta("/app/Contactos");
                    }
                    setEsconder(false);
                  }}
                >
                  <div className="LinkAcomodo">
                    <Link style={{ width: "100%" }} to={consulta === undefined ? "/app/Contactos" : ""}>
                      <img
                        style={{ height: "17px" }}
                        src={Iconos[4]}
                        alt=""
                      />
                      <span>Contactos</span>
                    </Link>
                  </div>
                </li>

                {/* {consulta !== undefined ? (
                  <li
                    onClick={() => {
                      consulta("/app/Anuncios");
                      setEsconder(false);
                    }}
                  >
                    <Link>
                      <div className="LinkAcomodo">
                        <img
                          style={{ height: "15px" }}
                          src={Iconos[5]}
                          alt=""
                        />
                        <span>Anuncios</span>
                      </div>
                    </Link>
                  </li>
                ) : (
                  <li onClick={() => setEsconder(false)}>
                    <Link to="/app/Anuncios">
                      <div className="LinkAcomodo">
                        <img
                          style={{ height: "15px" }}
                          src={Iconos[5]}
                          alt=""
                        />
                        <span>Anuncios</span>
                      </div>
                    </Link>
                  </li>
                )} */}

                <li
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (consulta !== undefined) {
                      consulta("/app/chats");
                    }
                    setEsconder(false);
                  }}
                >
                  <div className="LinkAcomodo">
                    <Link style={{ width: "100%" }} to={consulta === undefined ? "/app/chats" : ""}>
                      <img src={Iconos[6]} alt="" />
                      <span>Chat</span>
                    </Link>
                    <Space size="small" style={{ marginLeft: "10px" }}>
                      <Badge count={badgesNumber.chat}>
                      </Badge>
                    </Space>
                  </div>
                </li>

                <li
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (consulta !== undefined) {
                      consulta("/app/Recetas");
                    }
                    setEsconder(false);
                  }}
                >
                  <div className="LinkAcomodo">
                    <Link style={{ width: "100%" }} to={consulta === undefined ? "/app/Recetas" : ""}>
                      <img
                        style={{ height: "20px" }}
                        src={Iconos[8]}
                        alt=""
                      />
                      <span>Recetas</span>
                    </Link>
                  </div>
                </li>

                <li
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (consulta !== undefined) {
                      consulta("/app/estudios");
                    }
                    setEsconder(false);
                  }}
                >
                  <div className="LinkAcomodo">
                    <Link style={{ width: "100%" }} to={consulta === undefined ? "/app/estudios" : ""}>
                      <img
                        style={{ height: "20px" }}
                        src={Iconos[9]}
                        alt=""
                      />
                      <span>Estudios</span>
                    </Link >
                    <Space size="small" style={{ marginLeft: "10px" }}>
                      <Badge count={badgesNumber.estudios}>
                      </Badge>
                    </Space>
                  </div>
                </li>

                <li
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (consulta !== undefined) {
                      consulta("/app/Asistentes");
                    }
                    setEsconder(false);
                  }}
                >
                  <div className="LinkAcomodo">
                    <Link style={{ width: "100%" }} to={consulta === undefined ? "/app/Asistentes" : ""}>
                      <img
                        style={{ height: "20px" }}
                        src={Iconos[11]}
                        alt=""
                      />
                      <span>Asistentes</span>
                    </Link >
                  </div>
                </li>

                <li
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (consulta !== undefined) {
                      consulta("/app/PuntoDeVenta");
                    }
                    setEsconder(false);
                  }}
                >
                  <div className="LinkAcomodo">
                    <Link style={{ width: "100%" }} to={consulta === undefined ? "/app/PuntoDeVenta" : ""}>
                      <img
                        style={{ height: "20px" }}
                        src={Iconos[12]}
                        alt=""
                      />
                      <span>Punto de venta</span>
                    </Link >
                    {/* <Space size="small" style={{marginLeft:"10px"}}>
                          <Badge count={badgesNumber.}>
                          </Badge>
                        </Space> */}
                  </div>
                </li>

                <li
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (consulta !== undefined) {
                      consulta("/app/IngresosyEgresos");
                    }
                    setEsconder(false);
                  }}
                >
                  <div className="LinkAcomodo">
                    <Link style={{ width: "100%" }} to={consulta === undefined ? "/app/IngresosyEgresos" : ""}>
                      <img
                        style={{ height: "20px" }}
                        src={Iconos[13]}
                        alt=""
                      />
                      <span>Ingresos y egresos</span>
                    </Link >
                  </div>
                </li>

                <li
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (consulta !== undefined) {
                      consulta("/app/MetodosDePago");
                    }
                    setEsconder(false);
                  }}
                >
                  <div className="LinkAcomodo">
                    <Link style={{ width: "100%" }} to={consulta === undefined ? "/app/MetodosDePago" : ""}>
                      <img
                        style={{ height: "20px" }}
                        src={Iconos[14]}
                        alt=""
                      />
                      <span>Métodos de pago</span>
                    </Link >
                  </div>
                </li>

                <li
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (consulta !== undefined) {
                      consulta("/app/Reportes");
                    }
                    setEsconder(false);
                  }}
                >
                  <div className="LinkAcomodo">
                    <Link style={{ width: "100%" }} to={consulta === undefined ? "/app/Reportes" : ""}>
                      <img
                        style={{ height: "20px" }}
                        src={Iconos[15]}
                        alt=""
                      />
                      <span>Reportes</span>
                    </Link >
                  </div>
                </li>

                <li
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (consulta !== undefined) {
                      consulta("/app/vinculacion");
                    }
                    setEsconder(false);
                  }}
                >
                  <div className="LinkAcomodo">
                    <Link style={{ width: "100%" }} to={consulta === undefined ? "/app/vinculacion" : ""}>
                      <img
                        style={{ height: "20px" }}
                        src={Iconos[16]}
                        alt=""
                      />
                      <span>Vinculación</span>
                    </Link>
                    <Space size="small" style={{ marginLeft: "10px" }}>
                      <Badge count={badgesNumber.vinculacion}>
                      </Badge>
                    </Space>
                  </div>
                </li>

                {/* {(consulta !== undefined) ?
                                <li onClick={() => {
                                    consulta("/app/Facturacion");
                                    setEsconder(false)
                                }}>

                                    <Link>
                                        <div
                                            className="LinkAcomodo"
                                            style={{ marginBottom: "30%" }}
                                        >
                                            <img
                                                style={{ height: "20px" }}
                                                src={IcoFactura}
                                                alt=""
                                            />
                                            <span>Facturación</span>
                                        </div>
                                    </Link>
                                </li> :
                                <li onClick={() => setEsconder(false)}>
                                    <Link to="/app/Facturacion">
                                        <div
                                            className="LinkAcomodo"
                                            style={{ marginBottom: "30%" }}
                                        >
                                            <img
                                                style={{ height: "20px" }}
                                                src={IcoFactura}
                                                alt=""
                                            />
                                            <span>Facturación</span>
                                        </div>
                                    </Link>
                                </li>
                            } */}
              </ul >
            </div >
          )
          }

          {
            userInfo.Tipo === 4 && (
              <div className="drawerStyle">
                <ul>

                  <li style={{ width: "100%" }}>
                    <div className="LinkAcomodo" style={{ paddingTop: "5%" }}>
                      <Link style={{ width: "100%" }} to={"/app/inicio"}>
                        <HomeOutlined style={{ fontSize: '20px', marginRight: '5px' }} />
                        <span>Inicio</span>
                      </Link>
                    </div>
                  </li>

                  <li style={{ width: "100%" }}>
                    <div className="LinkAcomodo">
                      <Link style={{ width: "100%" }} to={"/app/Citas"}>
                        <img src={Iconos[3]} alt="" />
                        <span>Citas</span>
                      </Link>
                      <Space size="small" style={{ marginLeft: "10px" }}>
                        <Badge count={badgesNumber.citas}>
                        </Badge>
                      </Space>
                    </div>
                  </li>

                  <li style={{ width: "100%" }}>
                    <div className="LinkAcomodo">
                      <Link style={{ width: "100%" }} to={"/app/ExpedientesNuevo"}>
                        <img
                          style={{ height: "20px" }}
                          src={Iconos[10]}
                          alt=""
                        />
                        <span>Expedientes</span>
                      </Link>
                    </div>
                  </li>

                  <li style={{ width: "100%" }}>
                    <div className="LinkAcomodo">
                      <Link style={{ width: "100%" }} to={"/app/chats"}>
                        <img src={Iconos[6]} alt="" />
                        <span>Chat</span>
                      </Link>
                      <Space size="small" style={{ marginLeft: "10px" }}>
                        <Badge count={badgesNumber.chat}>
                        </Badge>
                      </Space>
                    </div>
                  </li>

                  <li style={{ width: "100%" }}>
                    <div className="LinkAcomodo">
                      <Link style={{ width: "100%" }} to={"/app/PuntoDeVenta"}>
                        <img
                          style={{ height: "20px" }}
                          src={Iconos[12]}
                          alt=""
                        />
                        <span>Punto de venta</span>
                      </Link>
                    </div>
                  </li>

                  <li style={{ width: "100%" }}>
                    <div className="LinkAcomodo">
                      <Link style={{ width: "100%" }} to={"/app/Contactos"}>
                        <img
                          style={{ height: "17px" }}
                          src={Iconos[4]}
                          alt=""
                        />
                        <span>Contactos</span>
                      </Link>
                    </div>
                  </li>

                  <li style={{ width: "100%" }}>
                    <div className="LinkAcomodo">
                      <Link style={{ width: "100%" }} to={"/app/IngresosyEgresos"}>
                        <img
                          style={{ height: "20px" }}
                          src={Iconos[13]}
                          alt=""
                        />
                        <span>Ingresos y egresos</span>
                      </Link>
                    </div>
                  </li>

                  <li style={{ width: "100%" }}>
                    <div className="LinkAcomodo">
                      <Link style={{ width: "100%" }} to={"/app/vinculacion"}>
                        <img
                          style={{ height: "20px" }}
                          src={Iconos[16]}
                          alt=""
                        />
                        <span>Vinculación</span>
                      </Link>
                      <Space size="small" style={{ marginLeft: "10px" }}>
                        <Badge count={badgesNumber.vinculacion}>
                        </Badge>
                      </Space>
                    </div>
                  </li>
                </ul >
              </div >
            )
          }
        </Drawer >

        {/* Drawer chambio de medico de Asistentes */}
        < Drawer
          title={"Seleccionar médico"}
          closeIcon={< ArrowBack />}
          placement="right"
          closable={true}
          onClose={() => setEsconderChangeMedic(false)}
          open={EsconderChangeMedic}
          key="right"
          width="300"
        >
          <>
            {listaMedicosVinculados.map((medico) => (
              <>
                <Row
                  className="ListaDeMedicosDrawer"
                  onClick={() => {
                    if (medico.Logged !== 1) {
                      setInfoUsersplash({
                        nombre: medico.Nombre,
                        imagen: medico.Imagen
                      })
                      logInMedico(medico, medico.IdMedico);
                      setEsconderChangeMedic(false);
                      OpenSplashChangeMedic(true);
                    } else {
                      dispatch(showSnackbar({
                        show: true,
                        text: "Médico con sesión iniciada",
                        type: "warning",
                      }));
                    }
                  }}
                >
                  <Stack direction="row" spacing={2}>
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      variant={medico.Logged !== 1 ? "standard" : "dot"}
                    >
                      <Avatarr alt={medico.Imagen} src={`https://${medico.Imagen}`} />
                    </StyledBadge>
                  </Stack>

                  <div>
                    <span>{medico.Nombre}</span>
                  </div>
                </Row>
              </>
            ))}
          </>
        </Drawer >
      </header >

      <ModalDetalle
        styleDet={styleDet}
        optionClose={true}
        isOpen={isOpenModal}
        closeModal={CloseModal}
      >
        <div>
          <div className="TopModal">
            <b>Notificaciones</b>{" "}
            <CloseIcon style={{ cursor: "pointer", color: "white", fontSize: "20" }} onClick={CloseModal} />

          </div>
          {loader ? (
            <LoaderModals className={"DetalModalNotif"} />
          ) : (
            <div className="DetalModalNotif">
              <div>
                <p style={{ fontWeight: "500" }}>Solicitudes</p>
                <SolicitudesCard
                  solicitudes={Notificaciones.solicitudes}
                  aceptar={acceptSolicitudes}
                  cancelar={cancelSolicitudes}
                />
                {Notificaciones.solicitudes.length === 0 && (
                  <p style={{ textAlign: "center" }}>Sin solicitudes</p>
                )}
              </div>
              <div>
                <p style={{ fontWeight: "500" }}>Notificaciones</p>
                <NotificacionesCard
                  // notificaciones={Notificaciones.notificaciones}
                  // notificaciones={ Notificaciones.notificaciones}
                  redireccion={redirecciones}
                  notificaciones={notificaciones.notificationsList.slice().sort((a, b) => new Date(`${b.createdTimeUtc.substring(6, 10)}-${b.createdTimeUtc.substring(3, 5)}-${b.createdTimeUtc.substring(0, 2)}`) - new Date(`${a.createdTimeUtc.substring(6, 10)}-${a.createdTimeUtc.substring(3, 5)}-${a.createdTimeUtc.substring(0, 2)}`))}
                />
                {notificaciones.notificationsList.length === 0 && (
                  <p style={{ textAlign: "center" }}>Sin notificaciones</p>
                )}
                {/* {Notificaciones.notificaciones.length === 0 && (
                    <p style={{ textAlign: "center" }}>Sin notificaciones</p>
                  )} */}
              </div>
            </div>
          )}
        </div>
      </ModalDetalle>

      <ModalDetalle
        optionClose={true}
        styleDet={styleDet}
        isOpen={isOpenConfig}
        closeModal={CloseConfig}
      >
        <div className="Topdetail" style={{ color: "white" }}>
          Configuración de formatos de impresión
          <CloseIcon
            style={{ cursor: "pointer", color: "white", fontSize: "20px" }}
            onClick={() => {
              CloseConfig(true);
            }}
          />
        </div>
        {loader ? (
          <LoaderModals />
        ) : (
          <div className="configReceta">
            <form action="" style={{ padding: "0" }}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  className="GeneralBoton"
                  onClick={() => {
                    saveConfig();
                  }}
                  style={{
                    width: "100px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  Guardar
                  <CheckCircleIcon style={{ fontSize: "15px" }} />
                </Button>
              </div>
              <div className="contenedorimg">
                <div title="Imagen de identificación de su empresa">
                  {/* <Tooltip> */}
                  <label htmlFor="logo">
                    <img
                      style={{ cursor: "pointer" }}
                      src={Logo.url !== "" ? Logo.url : Iconos[19]}
                      alt=""
                    />
                  </label>
                  <input
                    type="file"
                    id="logo"
                    accept="image/png,image/jpeg"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.type.substring(0, 5) === "image") {
                        setLogo({
                          name: e.target.value,
                          url: URL.createObjectURL(e.target.files[0]),
                          file: e.target.files[0],
                        });
                      } else {
                        dispatch(showSnackbar({
                          show: true,
                          text: "Formato no válido (solo imagenes)",
                          type: "warning",
                        }));
                      }
                    }}
                  />
                  {/* </Tooltip> */}
                </div>
              </div>
              <div style={{ margin: "1% 2%" }}>
                {/* <InputAntd
                  className="inputblue"
                  value={Encabezado}
                  setStateValue={setEncabezado}
                  label="Encabezado:"
                  placeholder="Nombre de clinica, hospital, etc."
                  vacio={false}
                  setStateError={setMensajeEncabezado}
                  valueError={MensajeEncabezado}
                  textError={["Debe escribir un encabezado"]}
                /> */}
                <TextAreaAntd
                  label="Encabezado:"
                  value={Encabezado}
                  placeholder="Nombre de clinica, hospital, etc."
                  style={{
                    marginBottom: ".5%",
                    fontFamily: "sans-serif",
                  }}
                  columns="30"
                  autoSize={true}
                  setStateValue={setEncabezado}
                  setStateError={setMensajeEncabezado}
                  valueError={MensajeEncabezado}
                  textError={["Debe escribir un encabezado"]}
                  vacio={false}
                />
                <TextAreaAntd
                  label="Pie de pagina:"
                  value={PiePagina}
                  placeholder="Direccion, horario, telefono, etc."
                  style={{
                    marginBottom: ".5%",
                    fontFamily: "sans-serif",
                  }}
                  columns="30"
                  autoSize={true}
                  setStateValue={setPiePagina}
                  setStateError={setMensajePiePagina}
                  valueError={MensajePiePagina}
                  textError={["Debe escribir un pie de pagina"]}
                  vacio={false}
                />

                {/* <SelectAntd
                                    label="Formato de impresión:"
                                    value={FormatoReceta}
                                    style={{ width: "100%" }}
                                    placeholder="Seleccione un formato"
                                    options={[{ value: "0", label: "Media carta (216 x 140 mm)" }, { value: "1", label: "Carta (216 x 279 mm)" }]}
                                    setStateValue={setFormatoReceta}
                                    setStateError={setMensajeFormato}
                                    valueError={MensajeFormato}
                                    textError={["Debe seleccionar un formato"]}
                                /> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "15px 0",
                  }}
                >
                  <InputFileSingle
                    archivoValue={Firma}
                    texto={"Adjuntar firma"}
                    archivo={setFirma}
                    formatos={5}
                    extras={[
                      "",
                      "",
                      "",
                      setMensajeFirma,
                    ]}
                    mensajes={{
                      "dispatch": dispatch,
                      "snackbar": showSnackbar,
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </ModalDetalle>

      {/* Modal de splash cambio de medico */}
      <ModalDetalle
        styleDet={styleSplash}
        isOpen={isOpenSplashChangeMedic}
        closeModal={CloseSplashChangeMedic}
      >
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <Avatar
              size={100}
              style={{ margin: "10px" }}
              src={`https://${InfoUsersplash.imagen}`}
            />

            <div>
              <img
                style={{ color: "white", width: "100px" }}
                src={Logowhite}
                alt=""
              />
            </div>
            <p style={{ fontWeight: "500" }}>
              Cambiando a {InfoUsersplash.nombre}...
            </p>
          </div>
        </div>
      </ModalDetalle>

      {
        StatusBienvenida === true && (
          <ModalDetalle
            styleDet={styleDet}
            isOpen={isOpenBienvenida}
            closeModal={CloseBienvenida}
          >
            <div
              style={{
                color: "white",
                padding: "1% 2%",
                width: "50vw",
                background: "#148f9f",
                display: "flex",
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  CloseBienvenida(true);
                  setStop(false);
                }}
              >
                x
              </div>
            </div>
            {isOpenBienvenida === true && (
              <div style={{ width: "100%", height: "60vh" }}>
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=pLBuFxMYkx8"
                  width="100%"
                  height="100%"
                  playing={Stop}
                  onPause={() => {
                    setStop(false);
                  }}
                  onPlay={() => {
                    setStop(true);
                  }}
                  controls
                  onStart={() => {
                    // console.log("Si ENTRA EL VIDEO");
                  }}
                  volume={0.5}
                />
              </div>
            )}
          </ModalDetalle>
        )
      }

      {
        (navigator.userAgent.match(/Windows/i) ||
          navigator.userAgent.match(/macintosh/i)) && (
          <>
            <NotificationSlide navigate={navigate} detalle={detalle} />
          </>
        )
      }

      <DetailVinculacion
        isOpen={isOpenDetalleVinc}
        close={CloseDetalleVinc}
        style={styleDet}
        optionClose={true}
        infoSolicitud={infoSolicitud}
        setInfoSolicitud={setInfoSolicitud}
        loader={loaderModal}
        setLoader={setLoaderModal}
      />
    </>
  );
}
