import React, { useEffect } from "react";
import { useState } from "react";
import "../comp-styles/Perfil.scss";

// Importando Iconos
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from "@mui/icons-material/Close";

// Componentes del formulario
import {
  LeyendaError,
} from "../GeneralComponents/Formularios";
import SelectAntd from "../GeneralComponents/SelectAntd";
import InputAntd from "../GeneralComponents/InputAntd";
import DatePickerAntd from "../GeneralComponents/DatePickerAntd";

// Modal
import Modal from "../GeneralComponents/ModalDetalle";
import { UseModal } from "../../hooks/UseModal";

// Perfil Tarjeta
import PerfilCardClass from "./PerfilCard";
import { Loader } from "../GeneralComponents/Loader";

import dayjs from "dayjs";
import "dayjs/locale/es";

import {
  addCedula_request,
  modifyProfile_request,
  deleteCedula_request,
  infoUser_request,
} from "../../services/perfil_services";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoaderModals from "../GeneralComponents/Loader_modals";
import InputFileSingle from "../GeneralComponents/InputFileSingle";
import { Avatar, Button, Image, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import NavbarNuevo from "../GeneralComponents/Navbar_Nuevo";
import { Edit } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { infoUser } from "../../redux/userSlice";
import { useNavigate } from "react-router-dom";
import InputDate from "../GeneralComponents/InputDate";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Perfil() {
  // ********** DECLARACION DE VARIABLES **********
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo.usuario);
  const data = useSelector((state) => state.userInfo.profesiones);
  let cedulas = [];
  if (userInfo.Tipo === 1) {
    cedulas = data;
  }
  // console.log(userInfo);

  // Validacion de formularios
  const expresiones = {
    nombre: /^[a-zA-ZÀ-ÿ\s]{1,}$/, // Letras y espacios, pueden llevar acentos.
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    telefono: /^\d{1,}$/, // 7 a 14 numeros.
    ApellPaterno: /^[a-zA-ZÀ-ÿ\s]{1,}$/, // Letras y espacios, pueden llevar acentos.
    ApellMaterno: /^[a-zA-ZÀ-ÿ\s]{1,}$/, // Letras y espacios, pueden llevar acentos.
    Comercial: /^[a-zA-Z0-9À-ÿ\s]{1,}$/, // Letras y espacios, pueden llevar acentos.
  };

  let profesiones = [
    { value: "Medicina Familiar", label: "Medicina Familiar" },
    { value: "Médicina Interna", label: "Médicina Interna" },
    { value: "Pediatría", label: "Pediatría" },
    { value: "Gineco obstetricia", label: "Gineco obstetricia" },
    { value: "Cirugía", label: "Cirugía" },
    { value: "Psiquiatría", label: "Psiquiatría" },
    { value: "Cardiología", label: "Cardiología" },
    { value: "Dermatología", label: "Dermatología" },
    { value: "Endocrinología", label: "Endocrinología" },
    { value: "Gastroenterología", label: "Gastroenterología" },
    { value: "Infectología", label: "Infectología" },
    { value: "Nefrología", label: "Nefrología" },
    { value: "Oftalmología", label: "Oftalmología" },
    { value: "Otorrinolaringología", label: "Otorrinolaringología" },
    { value: "Neumología", label: "Neumología" },
    { value: "Neurología", label: "Neurología" },
    { value: "Radiología", label: "Radiología" },
    { value: "Anestesiología", label: "Anestesiología" },
    { value: "Oncología", label: "Oncología" },
    { value: "Patología", label: "Patología" },
    { value: "Urología", label: "Urología" },
    {
      value: "Medicina física y rehabilitación",
      label: "Medicina física y rehabilitación",
    },
    { value: "Medicina Intensiva", label: "Medicina Intensiva" },
  ];

  // ********** USE STATES **********
  // Modal de agregar cedula UseState
  const [isOpenModal, openModal, CloseModal] = UseModal(false);
  const styleDet = {

    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",

  };

  const [loader, setLoader] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);

  const [inputsEditable, setInputsEditable] = useState(true);

  // FORM ADD CEDULA
  const [TipoCedula, setTipoCedula] = useState(null);
  const [ProfesionCedula, setProfesionCedula] = useState(null);
  const [NumCedula, setNumCedula] = useState("");
  const [ArchivoCedula, setArchivoCedula] = useState({
    name: "",
    url: "",
    file: "",
    size: 0,
    formato: "",
  });

  // FORM PERFIL
  const [Comercial, setComercial] = useState("");
  const [Nombre, setNombre] = useState("");
  const [APaterno, setAPaterno] = useState("");
  const [AMaterno, setAMaterno] = useState("");
  const [Nacimiento, setNacimiento] = useState("");
  const [Especialidad, setEspecialidad] = useState("");
  const [Telefono, setTelefono] = useState("");
  const [Correo, setCorreo] = useState("");
  const [Emergencia, setEmergencia] = useState("");
  const [TelOficina, setTelOficina] = useState("");
  const [Direccion, setDireccion] = useState("");
  const [Prefijo, setPrefijo] = useState("");
  const [Genero, setGenero] = useState("");
  const [ImagenPerfil, setImagenPerfil] = useState({
    name: "",
    url: "",
    file: "",
    size: 0,
    formato: "",
  });

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [MensajeComercial, setMensajeComercial] = useState("true");
  const [MensajeNombre, setMensajeNombre] = useState("true");
  const [MensajeAPaterno, setMensajeAPaterno] = useState("true");
  const [MensajeAMaterno, setMensajeAMaterno] = useState("true");
  const [MensajeNacimiento, setMensajeNacimiento] = useState("true");
  const [MensajeEspecialidad, setMensajeEspecialidad] = useState("true");
  const [MensajeTelefono, setMensajeTelefono] = useState("true");
  const [MensajeCorreo, setMensajeCorreo] = useState("true");
  const [MensajeEmergencia, setMensajeEmergencia] = useState("true");
  const [MensajeTelOficina, setMensajeTelOficina] = useState("true");
  const [MensajeDireccion, setMensajeDireccion] = useState("true");
  const [MensajePrefijo, setMensajePrefijo] = useState("true");
  const [MensajeGenero, setMensajeGenero] = useState("true");

  const [MensajeTipoCedula, setMensajeTipoCedula] = useState("true");
  const [MensajeProfesionCedula, setMensajeProfesionCedula] = useState("true");
  const [MensajeNumCedula, setMensajeNumCedula] = useState("true");
  const [MensajeArchivoCedula, setMensajeArchivoCedula] = useState("true");

  // ********** FUNCIONES **********

  function cambiarinputs(info) {
    if (info !== undefined) {
      setComercial(!!info.NombreComercial ? info.NombreComercial : "");
      setNombre(!!info.Nombre ? info.Nombre : "");
      setAPaterno(!!info.ApellidoPaterno ? info.ApellidoPaterno : "");
      setAMaterno(!!info.ApellidoMaterno ? info.ApellidoMaterno : "");
      setNacimiento(!!info.Nacimiento ? info.Nacimiento : "");
      setTelefono(!!info.Celular ? info.Celular : "");
      setCorreo(!!info.Correo ? info.Correo : "");
      setEmergencia(!!info.TelefonoEmergencia ? info.TelefonoEmergencia : "");
      setTelOficina(!!info.TelefonoOficina ? info.TelefonoOficina : "");
      setDireccion(!!info.Direccion ? info.Direccion : "");
      setPrefijo(!!info.Prefijo ? info.Prefijo : "");
      setGenero(!!info.Sexo ? info.Sexo : "");
    } else {
      setComercial(!!userInfo.NombreComercial ? userInfo.NombreComercial : "");
      setNombre(!!userInfo.Nombre ? userInfo.Nombre : "");
      setAPaterno(!!userInfo.ApellidoPaterno ? userInfo.ApellidoPaterno : "");
      setAMaterno(!!userInfo.ApellidoMaterno ? userInfo.ApellidoMaterno : "");
      setNacimiento(!!userInfo.Nacimiento ? userInfo.Nacimiento : "");
      setEspecialidad("");
      setTelefono(!!userInfo.Celular ? userInfo.Celular : "");
      setCorreo(!!userInfo.Correo ? userInfo.Correo : "");
      setEmergencia(!!userInfo.TelefonoEmergencia ? userInfo.TelefonoEmergencia : "");
      setTelOficina(!!userInfo.TelefonoOficina ? userInfo.TelefonoOficina : "");
      setDireccion(!!userInfo.Direccion ? userInfo.Direccion : "");
      setPrefijo(!!userInfo.Prefijo ? userInfo.Prefijo : "");
      setGenero(!!userInfo.Sexo ? userInfo.Sexo : "");
    }

    setMensajeComercial("true");
    setMensajeNombre("true");
    setMensajeAPaterno("true");
    setMensajeAMaterno("true");
    setMensajeNacimiento("true");
    setMensajeEspecialidad("true");
    setMensajeTelefono("true");
    setMensajeCorreo("true");
    setMensajeEmergencia("true");
    setMensajeTelOficina("true");
    setMensajeDireccion("true");
    setMensajePrefijo("true");
    setMensajeGenero("true");

  }

  const addCedula = async (e) => {
    e.preventDefault();
    // console.log("AGREGANDO CEDULA");
    setLoaderModal(true);
    if (
      !!ProfesionCedula &&
      MensajeProfesionCedula === "true" &&
      !!NumCedula &&
      MensajeNumCedula === "true" &&
      !!TipoCedula &&
      MensajeTipoCedula === "true" &&
      !!ArchivoCedula.file &&
      ArchivoCedula
    ) {
      let data = new FormData();
      data.append("Profesion", ProfesionCedula);
      data.append("Cedula", NumCedula);
      data.append("Tipo", TipoCedula);
      data.append("CedulaFile", ArchivoCedula.file, ArchivoCedula.name);
      // setLoader(true);
      let response = await addCedula_request(data);
      if (response["ok"]) {
        getuserInfo();
        CloseModal(true);
        setOpen(true);
        setTypeMessage("success");
        setMessage("Cédula guardada");
        cleanInputsCedula();
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!ProfesionCedula) {
        setMensajeProfesionCedula("false");
      }
      if (!!!NumCedula) {
        setMensajeNumCedula("false");
      }
      if (!!!TipoCedula) {
        setMensajeTipoCedula("false");
      }
      if (!!!ArchivoCedula.file) {
        setMensajeArchivoCedula("false");
      }
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }
    setLoaderModal(false);
    return;
  };

  async function editProfile() {
    setLoader(true);
    if (userInfo.Tipo === 1) {
      if (
        !!Nombre &&
        MensajeNombre === "true" &&
        !!Correo &&
        MensajeCorreo === "true" &&
        !!Telefono &&
        MensajeTelefono === "true" &&
        !!Prefijo &&
        MensajePrefijo === "true" &&
        !!APaterno &&
        MensajeAPaterno === "true" &&
        MensajeAMaterno === "true" &&
        MensajeNacimiento === "true" &&
        MensajeEspecialidad === "true" &&
        MensajeTelefono === "true" &&
        MensajeCorreo === "true" &&
        MensajeEmergencia === "true" &&
        MensajeTelOficina === "true" &&
        MensajeDireccion === "true" &&
        MensajePrefijo === "true" &&
        MensajeGenero === "true"
      ) {
        // console.log("EDITANDO...");
        let data = new FormData();
        data.append("Prefijo", Prefijo);
        data.append("NombreComercial", Comercial);
        data.append("Nombre", Nombre);
        data.append("ApellidoPaterno", APaterno);
        data.append("ApellidoMaterno", AMaterno);
        data.append("Sexo", Genero);
        data.append("Nacimiento", Nacimiento);
        data.append("Celular", Telefono);
        data.append("Email", Correo);
        data.append("Emergencia", Emergencia);
        data.append("TelefonoOficina", TelOficina);
        data.append("Direccion", Direccion);
        if (!!ImagenPerfil.file) {
          data.append("Imagen", ImagenPerfil.file, ImagenPerfil.name);
        }

        let response = await modifyProfile_request(data);
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Perfil editado");
          setInputsEditable(true);
          getuserInfo();
        } else {
          if (!!!Nombre) {
            setMensajeNombre("false");
          }

          if (!!!Correo) {
            setMensajeCorreo("false");
          }

          if (!!!Telefono) {
            setMensajeTelefono("false");
          }

          if (!!!Prefijo) {
            setMensajePrefijo("false");
          }

          if (!!!APaterno) {
            setMensajeAPaterno("false");
          }

          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
      }
    } else {
      if (
        !!Nombre &&
        MensajeNombre === "true" &&
        !!Correo &&
        MensajeCorreo === "true" &&
        !!Telefono &&
        MensajeTelefono === "true" &&
        !!APaterno &&
        MensajeAPaterno === "true"
      ) {
        // console.log("EDITANDO...");
        let data = new FormData();
        data.append("Prefijo", "");
        data.append("NombreComercial", "");
        data.append("Nombre", Nombre);
        data.append("ApellidoPaterno", APaterno);
        data.append("ApellidoMaterno", AMaterno);
        data.append("Sexo", Genero);
        data.append("Nacimiento", Nacimiento);
        data.append("Celular", Telefono);
        data.append("Email", Correo);
        data.append("Emergencia", "");
        data.append("TelefonoOficina", "");
        data.append("Direccion", "");
        if (!!ImagenPerfil.file) {
          data.append("Imagen", ImagenPerfil.file, ImagenPerfil.name);
        }

        let response = await modifyProfile_request(data);
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Perfil editado");
          setInputsEditable(true);
          getuserInfo();
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
      }
    }
    setLoader(false);
    return;
  }

  async function eliminarCedula(idCedula) {
    // console.log("CEDULA A ELIMINAR");
    // console.log(idCedula);
    let response = await deleteCedula_request(idCedula);
    if (response["ok"]) {
      setOpen(true);
      setTypeMessage("success");
      setMessage("Cédula elminada");
      getuserInfo();
      // funcion();
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
  }

  async function getuserInfo() {
    setLoader(true);
    let response = await infoUser_request();
    if (response["ok"]) {
      // console.log("USUARIO");
      // console.log(response.data);
      // console.log("###########3");
      dispatch(infoUser(response.data));
      let info = response.data.Usuario;
      sessionStorage.setItem("userInfo", JSON.stringify(info));
      setComercial(info.NombreComercial);
      setNombre(info.Nombre);
      setAPaterno(info.ApellidoPaterno);
      setAMaterno(info.ApellidoMaterno);
      setNacimiento(info.Nacimiento);
      setTelefono(info.Celular);
      setCorreo(info.Correo);
      setEmergencia(info.TelefonoEmergencia);
      setTelOficina(info.TelefonoOficina);
      setDireccion(info.Direccion);
      setPrefijo(info.Prefijo);
      setGenero(info.Sexo);
      // sessionStorage.setItem("cedulas", JSON.stringify(response.data.Profesiones));
      cambiarinputs(info);
    } else {
      if (response.codigo === 401) {
        sessionStorage.removeItem("infoUser");
        localStorage.removeItem("permisos");
        localStorage.setItem("loginAsistente", 0);
        localStorage.removeItem("token");
        localStorage.removeItem("tipoUsuario");
        localStorage.removeItem("datosMedico");
        localStorage.removeItem("logged");
        navigate("/login", {
          replace: true,
          state: {
            logged: false,
          },
        });
        // navigate('/login', { replace: true });
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    }
    setLoader(false);
    return;
  }

  async function setuserInfoInit(info) {
    // console.log(info);
    setComercial(info.NombreComercial);
    setNombre(info.Nombre);
    setAPaterno(info.ApellidoPaterno);
    setAMaterno(info.ApellidoMaterno);
    setNacimiento(info.Nacimiento);
    setTelefono(info.Celular);
    setCorreo(info.Correo);
    setEmergencia(info.TelefonoEmergencia);
    setTelOficina(info.TelefonoOficina);
    setDireccion(info.Direccion);
    setPrefijo(info.Prefijo);
    setGenero(info.Sexo);
    return;
  }

  function validacionImagen(e, campoImagen) {
    // 1 - Imagen Perfil
    // 2 - Archvio cedula
    //aquí evaluamos si ya hay imagenes antes de este input, para saber en dónde debe empezar el index del proximo array
    let formato = e.currentTarget.files[0].type.split("/");
    // console.log(formato);
    if (formato[1] === "pdf" || formato[1] === "jpeg" || formato[1] === "png") {
      if (e.currentTarget.files[0].size <= 5000000) {
        const files = e.currentTarget.files;

        //el array con las imagenes nuevas
        let arrayImages = "";

        Object.keys(files).forEach((i) => {
          const file = files[i];
          let url = URL.createObjectURL(file);
          let formato = file.type.split("/");
          arrayImages = {
            name: file.name,
            url: url,
            file: file,
            size: file.size,
            formato: formato[1],
          };
        });
        if (campoImagen === 1) {
          setImagenPerfil(arrayImages);
        } else {
          setArchivoCedula(arrayImages);
        }
      } else {
        setOpen(true);
        setTypeMessage("warning");
        setMessage("El archivo excede el tamaño maximo permitido (5MB)");
      }
    } else {
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Solo se permiten imagenes (jpg, jpeg, png)");
    }
    if (campoImagen === 1) {
      document.getElementById("imagenPerfil").value = "";
    } else {
      document.getElementById("archivoCedula").value = "";
    }
  }

  function cleanInputsCedula() {
    setTipoCedula(null);
    setProfesionCedula(null);
    setNumCedula("");
    setArchivoCedula({
      name: "",
      url: "",
      file: "",
      size: 0,
      formato: "",
    });
    setMensajeTipoCedula("true");
    setMensajeProfesionCedula("true");
    setMensajeNumCedula("true");
    setMensajeArchivoCedula("true");
  }

  useEffect(() => {
    setuserInfoInit(data);
  }, []);

  return (
    <Stack>
      <NavbarNuevo
        mensajesError={{
          setOpen: setOpen,
          setTypeMessage: setTypeMessage,
          setMessage: setMessage,
        }}
      />
      <Content
        className="site-layout"
        style={{
          padding: "0 .5%",
          width: "100%",
          overflow: "initial",
        }}
      >
        {loader ? (
          <Loader />
        ) : (
          <>
            <div className="Container2">
              <div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "10px"
                    }}
                  >
                    <div>
                      {inputsEditable ? (
                        <label htmlFor="imagenPerfil">
                          <Avatar style={{ width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", }} src={

                            <Image

                              src={
                                !!ImagenPerfil.file
                                  ? ImagenPerfil.url
                                  : "https://" + userInfo.Imagen_Url
                              }
                              alt=""
                            />

                          } />
                        </label>
                      ) : (
                        <>
                          <label htmlFor="imagenPerfil">
                            <Avatar
                              style={{ width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                              src={
                                <img
                                  src={
                                    !!ImagenPerfil.file
                                      ? ImagenPerfil.url
                                      : "https://" + userInfo.Imagen_Url
                                  }
                                  alt=""
                                />
                              }

                            />
                          </label>
                          <input
                            type="file"
                            id="imagenPerfil"
                            accept="image/png,image/jpeg"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (
                                file &&
                                file.type.substring(0, 5) === "image"
                              ) {
                                validacionImagen(e, 1);
                              } else {
                                setOpen(true);
                                setTypeMessage("warning");
                                setMessage("Solo puede cargar imagenes (jpg, jpeg, png)");
                              }
                            }}
                          />
                        </>
                      )}
                    </div>

                    <div>
                      <div>
                        <b>{`${!!userInfo.Prefijo ? userInfo.Prefijo : ""} ${!!userInfo.Nombre ? userInfo.Nombre : ""
                          } ${!!userInfo.ApellidoPaterno
                            ? userInfo.ApellidoPaterno
                            : ""
                          } ${!!userInfo.ApellidoMaterno
                            ? userInfo.ApellidoMaterno
                            : ""
                          }`}</b>
                      </div>
                      <div>
                        <b>{userInfo.id}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <form style={{ width: "100%" }} action="" onSubmit={editProfile}>
                <div>
                  <Row className="RowInfoPerfil">
                    {userInfo.Tipo === 1 && (
                      <>

                        <SelectAntd
                          label="Prefijo:"
                          value={Prefijo}
                          requiredICon={true}
                          options={[
                            { value: "Dr.", lable: "Dr." },
                            { value: "Dra.", lable: "Dra." },
                            { value: "Sr.", lable: "Sr." },
                            { value: "Sra.", lable: "Sra." },
                            { value: "Lic.", lable: "Lic." },
                            { value: "Mtro.", lable: "Mtro." },
                            { value: "Mtra.", lable: "Mtra." },
                          ]}
                          style={{
                            margin: "2% 0",
                          }}
                          placeholder="Selecciona un prefijo"
                          setStateValue={setPrefijo}
                          setStateError={setMensajePrefijo}
                          valueError={MensajePrefijo}
                          textError="Debe seleccionar un prefijo"
                          vacio={false}
                          disabled={inputsEditable}
                        />
                      </>
                    )}

                    <InputAntd
                      value={Nombre}
                      setStateValue={setNombre}
                      requiredICon={true}
                      label="Nombre:"
                      style={{ margin: "2% 0" }}
                      placeholder="Escribe tu nombre"
                      setStateError={setMensajeNombre}
                      textError={[
                        "Debe escribir un nombre",
                        "Solo se permiten letras",
                      ]}
                      type="text"
                      valueError={MensajeNombre}
                      readOnly={inputsEditable}
                      expresionRegular={expresiones.nombre}
                      maxLength={40}
                      vacio={false}
                    />

                    <InputAntd
                      value={APaterno}
                      setStateValue={setAPaterno}
                      requiredICon={true}
                      label="Apellido paterno:"
                      style={{ margin: "2% 0" }}
                      placeholder="Escribe tu apellido paterno"
                      setStateError={setMensajeAPaterno}
                      textError={[
                        "Debes escribir un apellido paterno",
                        "Solo se permiten letras",
                      ]}
                      type="text"
                      valueError={MensajeAPaterno}
                      readOnly={inputsEditable}
                      expresionRegular={expresiones.ApellPaterno}
                      maxLength={25}
                      vacio={false}
                    />

                    <InputAntd
                      value={AMaterno}
                      setStateValue={setAMaterno}
                      label="Apellido materno:"
                      style={{ margin: "2% 0" }}
                      placeholder="Escribe tu segundo apellido"
                      setStateError={setMensajeAMaterno}
                      textError={["", "Solo se permiten letras"]}
                      type="text"
                      valueError={MensajeAMaterno}
                      readOnly={inputsEditable}
                      expresionRegular={expresiones.ApellMaterno}
                      maxLength={25}
                    />

                    {/* <DatePickerAntd
                      label="Fecha de nacimiento:"
                      placeholder="Selecciona una fecha"
                      style={{ background: "white", margin: "2% 0" }}
                      value={!!Nacimiento ? dayjs(Nacimiento) : ""}
                      setStateValue={setNacimiento}
                      setStateError={setMensajeNacimiento}
                      valueError={MensajeNacimiento}
                      textError={[
                        "Debe seleccionar una fecha",
                        "Debe ser mayor de edad",
                      ]}
                      mayorEdad={true}
                      disabled={inputsEditable}
                    /> */}

                    <InputDate
                      label="Fecha de nacimiento:"
                      placeholder="DD/MM/AAAA"
                      style={{ background: "white", margin: "2% 0" }}
                      value={!!Nacimiento ? Nacimiento : ""}
                      setStateValue={setNacimiento}
                      setStateError={setMensajeNacimiento}
                      valueError={MensajeNacimiento}
                      textError={["Debe seleccionar una fecha", "Debe ser mayor de edad"]}
                      mayorEdad={true}
                      disabled={inputsEditable}
                    />

                    <SelectAntd
                      label="Género:"
                      value={Genero}
                      options={[
                        { value: "Masculino", lable: "Masculino" },
                        { value: "Femenino", lable: "Femenino" },
                        { value: "Otro", lable: "Otro" },
                      ]}
                      style={{
                        margin: "2% 0",
                      }}
                      placeholder="Selecciona un género"
                      setStateValue={setGenero}
                      setStateError={setMensajeGenero}
                      valueError={MensajeGenero}
                      textError="Debe seleccionar un género"
                      vacio={false}
                      disabled={inputsEditable}
                    />

                    <InputAntd
                      style={{ marginBottom: "2% 0" }}
                      value={Telefono}
                      requiredICon={true}
                      id="celular"
                      setStateValue={setTelefono}
                      label="Teléfono:"
                      placeholder="escribe un teléfono"
                      valueError={MensajeTelefono}
                      setStateError={setMensajeTelefono}
                      textError={[
                        "Debe escribir un teléfono",
                        "Solo se permiten numeros",
                        "Debe contener 10 digitos",
                      ]}
                      vacio={false}
                      expresionRegular={expresiones.telefono}
                      maxLength={10}
                      readOnly={inputsEditable}
                    />

                    {userInfo.Tipo === 1 && (
                      <InputAntd
                        style={{ marginBottom: "2% 0" }}
                        id="celular"
                        value={TelOficina}
                        setStateValue={setTelOficina}
                        label="Teléfono de oficina:"
                        placeholder="escribe un teléfono de oficina"
                        valueError={MensajeTelOficina}
                        setStateError={setMensajeTelOficina}
                        textError={[
                          "",
                          "Solo se permiten numeros",
                          "Debe contener 10 digitos",
                        ]}
                        vacio={true}
                        expresionRegular={expresiones.telefono}
                        maxLength={10}
                        readOnly={inputsEditable}
                      />
                    )}

                    {userInfo.Tipo === 1 && (
                      <InputAntd
                        style={{ marginBottom: "1%" }}
                        id="celular"
                        value={Emergencia}
                        setStateValue={setEmergencia}
                        label="Teléfono de emergencia:"
                        placeholder="escribe un teléfono de emergencia"
                        valueError={MensajeEmergencia}
                        setStateError={setMensajeEmergencia}
                        textError={[
                          "",
                          "Solo se permiten numeros",
                          "Debe contener 10 digitos",
                        ]}
                        vacio={true}
                        expresionRegular={expresiones.telefono}
                        maxLength={10}
                        readOnly={inputsEditable}
                      />
                    )}

                    <InputAntd
                      style={{ marginBottom: "2% 0" }}
                      value={Correo}
                      type="email"
                      setStateValue={setCorreo}
                      requiredICon={true}
                      label="Correo electrónico:"
                      placeholder="escribe tu correo"
                      valueError={MensajeCorreo}
                      setStateError={setMensajeCorreo}
                      textError={[
                        "Este campo es obligatorio",
                        "Formato correo erroneo",
                        "",
                      ]}
                      vacio={false}
                      expresionRegular={expresiones.correo}
                      readOnly={inputsEditable}
                    />

                    <InputAntd
                      className="inputblue"
                      value={Comercial}
                      setStateValue={setComercial}
                      label="Nombre comercial:"
                      style={{ margin: "2% 0" }}
                      placeholder="Escribe tu nombre comercial"
                      setStateError={setMensajeComercial}
                      textError={["", "Solo se permiten letras y numeros"]}
                      type="text"
                      valueError={MensajeComercial}
                      readOnly={inputsEditable}
                      expresionRegular={expresiones.Comercial}
                      maxLength={40}
                    />

                    <InputAntd
                      className="inputblue"
                      value={Direccion}
                      setStateValue={setDireccion}
                      label="Dirección:"
                      style={{ margin: "2% 0" }}
                      placeholder="Escribe tu dirección"
                      // setStateError={setMensajeDireccion}
                      // textError={["", "Solo se permiten letras (max. 20 caracteres)"]}
                      type="text"
                      // valueError={MensajeComercial}
                      readOnly={inputsEditable}
                    // expresionRegular={expresiones.Comercial}
                    // maxLength={70}
                    />
                  </Row>
                </div>
                <div className="BotonesPerfil" >
                  {inputsEditable ? (
                    <div></div>
                  ) : (
                    <Button
                      htmlType="submit"
                      style={{ background: "#148f9f", color: "white", width: "100px", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                    >
                      Guardar <CheckCircleIcon style={{ fontSize: 15 }} />
                    </Button>
                  )}
                  {inputsEditable ? (

                    <Button
                      type="button"
                      style={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "#148f9f", color: "white", width: "100px" }}
                      onClick={() => {
                        cambiarinputs();
                        if (inputsEditable) {
                          setInputsEditable(false);
                        } else {
                          setInputsEditable(true);
                        }
                      }}
                    >

                      Editar <Edit style={{ fontSize: 15 }} />
                    </Button>
                  ) : (

                    <Button
                      type="button"
                      style={{ background: "white", color: "#148f9f", border: "1px solid #148f9f", width: "100px" }}
                      onClick={() => {
                        cambiarinputs();
                        if (inputsEditable) {
                          setInputsEditable(false);
                        } else {
                          setInputsEditable(true);
                        }
                      }}
                    >

                      Cancelar
                    </Button>
                  )}
                </div>
              </form>
            </div>
            {userInfo.Tipo === 1 && (
              <div className="Container2" style={{ marginTop: ".5%" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: "600", fontSize: "16px" }}>
                      Cédulas profesionales
                    </span>
                  </div>
                  <Button style={{ background: "#148f9f", color: "white", width: "100px", display: "flex", justifyContent: "space-between", alignItems: "center" }} onClick={() => { cleanInputsCedula(); openModal(true); }}>
                    Agregar <AddCircleIcon style={{ fontSize: 15 }} />
                  </Button>
                </div>
                <hr />
                <>
                  {loader ? (
                    <Loader Loading={loader} />
                  ) : (
                    <Row className="CedulasPerfil">
                      <PerfilCardClass
                        cedulas={cedulas ?? []}
                        eliminarCedula={eliminarCedula}
                      />
                    </Row>
                  )}
                </>
              </div>
            )}
          </>
        )}
      </Content>

      <Modal isOpen={isOpenModal} closeModal={CloseModal} styleDet={styleDet}>
        <div className="TopModal">
          Nueva cédula
          <CloseIcon style={{ fontSize: 20, cursor: "pointer" }} onClick={() => { cleanInputsCedula(); CloseModal(true); }} />

        </div>
        <div className="ModalConScroll">
          {loaderModal ? (
            <LoaderModals />
          ) : (
            <form action="" onSubmit={addCedula}>
              <SelectAntd
                label="Tipo:"
                value={TipoCedula}
                options={[
                  { value: "Profesión", lable: "Profesión" },
                  { value: "Especialidad", lable: "Especialidad" },
                ]}
                style={{
                  width: "100%",
                  margin: "1% 0",
                }}
                placeholder="Selecciona un tipo"
                setStateValue={setTipoCedula}
                setStateError={setMensajeTipoCedula}
                valueError={MensajeTipoCedula}
                textError="Debe seleccionar un tipo"
                vacio={false}
              />
              <SelectAntd
                label="Profesión o especialidad:"
                value={ProfesionCedula}
                options={profesiones}
                style={{
                  width: "100%",
                  margin: "1% 0",
                }}
                placeholder="Selecciona una profesión"
                setStateValue={setProfesionCedula}
                setStateError={setMensajeProfesionCedula}
                valueError={MensajeProfesionCedula}
                textError="Debe seleccionar una profesión"
                vacio={false}
              />

              <InputAntd
                className="inputblue"
                value={NumCedula}
                setStateValue={setNumCedula}
                label="Número de cédula:"
                style={{ margin: "2% 0" }}
                placeholder="Escribe tu cédula"
                setStateError={setMensajeNumCedula}
                textError={["Debe escribir una cédula", ""]}
                type="text"
                valueError={MensajeNumCedula}
                vacio={false}
              />

              <InputFileSingle
                id={"archivo_cedula"}
                archivo={setArchivoCedula}
                archivoValue={ArchivoCedula}
                texto="Ajuntar"
                formatos={4}
                extras={[
                  setOpen,
                  setTypeMessage,
                  setMessage,
                  setMensajeArchivoCedula,
                ]}
              />
              <LeyendaError valido={MensajeArchivoCedula}>
                Debe adjuntar un archivo
              </LeyendaError>

              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={addCedula}
                  style={{
                    color: "white",
                    margin: "1% 0",
                    width: "95%",
                    textAlign: "center",
                    background: "#148f9f",
                  }}
                >
                  Agregar
                </Button>
              </div>
            </form>
          )}
        </div>
      </Modal>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

    </Stack>
  );
}
