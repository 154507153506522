import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css?20240807_pro';
import App from './App?20240807_pro';
import { Provider } from "react-redux";
import store from "./redux/store";
import { GoogleOAuthProvider } from '@react-oauth/google';

// navigator.serviceWorker.register("firebase-messaging-sw.js");
const root = ReactDOM.createRoot(document.getElementById('root'));
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('firebase-messaging-sw.js')
    .then(function (registration) {
      // console.log('Service Worker registrado con éxito:', registration);
    })
    .catch(function (error) {
      console.error('Error al registrar el Service Worker:', error);
    });
}
root.render(
  
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT}>
    <Provider store={store}>
      <App />
    </Provider>
  </GoogleOAuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

