import React, { useEffect } from "react";
import { useState } from "react";
import { UseModal } from "../hooks/UseModal";

//importacion de scss
import "../pages/styles/MetodosDePago.scss";

//Importacion de iconos
import Agregar from "../Img/Iconos/AgregarBlanco.svg";
import visa from "../Img/visa-svg.svg";
import mastercard from "../Img/mastercard-svg.svg";
import Logo from "../logo.svg";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Empty, Input, InputNumber, Row, Select, Tabs, Modal, Popover } from "antd";
import {
  Formulario,
  Label,
  ContenedorBotonCentrado,
  Boton,
  LeyendaError,
} from "../componentes/GeneralComponents/Formularios2Grids";
import InputAntd from "../componentes/GeneralComponents/InputAntd";
import SelectAntd from "../componentes/GeneralComponents/SelectAntd";
import TarjetasCard from "../componentes/Metodos-Pagos/tarjetasCard";
import { Loader } from "../componentes/GeneralComponents/Loader";
import LoaderModals from "../componentes/GeneralComponents/Loader_modals";
import InputFileSingle from "../componentes/GeneralComponents/InputFileSingle";
import DatePickerAntd from "../componentes/GeneralComponents/DatePickerAntd";
import Campos from "../componentes/GeneralComponents/campos";
import { Content } from "antd/es/layout/layout";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";

//Importacion de modal
import Modalagregar from "../componentes/GeneralComponents/ModalDetalle";

// Servicios
import {
  getTarjetas_request,
  addTarjeta_request,
  deleteTarjeta_request,
  getClabeInterbancaria_request,
  addClabeInterbancaria_request,
  defaultCard_request,
} from "../services/metodosPago_services";
import {
  getEstado_request,
  getLocalidad_request,
} from "../services/expediente_service";

import dayjs from "dayjs";
import "dayjs/locale/es";

import Stack from "@mui/material/Stack";
import CuentasCard from "../componentes/Metodos-Pagos/cuentasCard";
import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ResibosCard from "../componentes/Metodos-Pagos/RecibosCard";
import SnackBar from "../componentes/GeneralComponents/Snackbar";
import { SnackbarProvider } from "notistack";
import { AddCard, AddCardOutlined, AddCardRounded, AddCardSharp, AddCardTwoTone, Help } from "@mui/icons-material";
import { buySubscription_request, cancelSubscription_request, getSubscriptionTickets_request } from "../services/suscripcion_services";
import { useSelector, useDispatch } from 'react-redux';
import { showSnackbar } from '../redux/snackbarSlice';
import { infoUser_request } from "../services/perfil_services";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import InputDate from "../componentes/GeneralComponents/InputDate";

function MetodosDePago() {
  const dispatch = useDispatch();
  // ******** VARIABLES ********
  const styleAgregarT = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };
  const styleAñadirC = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  const expresiones = {
    Numeros: /^[0-9]{1,16}$/, // numeros.
  };

  const infoUser = useSelector((state) => state.userInfo.usuario);
  // console.log(infoUser);
  // ******** USE STATES ********
  //  Modales states usemodal
  const [isOpenAgregarT, OpenAgregarT, CloseAgregarT] = UseModal(false);
  const [isOpenAñadirC, OpenAñadirC, CloseAñadirC] = UseModal(false);
  const [isOpenSelecTrjeta, OpenSelecTrjeta, CloseSelecTrjeta] = UseModal(false)

  const [ModalStatusCancelSub, setModalStatusCancelSub] = useState(false);

  const [TabsMetodosPago, setTabsMetodosPago] = useState((document.domain !== "ygia.app") ? "1" : "2");

  const [TarjetasList, setTarjetasList] = useState([]);
  const [CuentasList, setCuentasList] = useState("");
  const [RecibosList, setRecibosList] = useState([]);
  const [IdTarjeta, setIdTarjeta] = useState("");

  const [InfoSub, setInfoSub] = useState({
    paquete: "",
    paqueteStatus: "",
    file: "",
    size: 0,
    formato: "",
  });

  const [EstadosList, setEstadosList] = useState([]);
  const [LocalidadesList, setLocalidadesList] = useState([]);

  // FORM TARJETA
  const [NumeroTarjetaInput, setNumeroTarjetaInput] = useState("");
  const [NumeroTarjeta, setNumeroTarjeta] = useState("");
  const [ExpiracionMes, setExpiracionMes] = useState(null);
  const [ExpiracionYear, setExpiracionYear] = useState("");
  const [CVV, setCVV] = useState("");

  // FORM CLABE INTERBANCARIA
  const [Estado, setEstado] = useState(null);
  const [Localidad, setLocalidad] = useState(null);
  const [DireccionUno, setDireccionUno] = useState("");
  const [DireccionDos, setDireccionDos] = useState("");
  const [Cp, setCp] = useState("");
  const [Nacimiento, setNacimiento] = useState("");
  const [Genero, setGenero] = useState(null);
  const [Rfc, setRfc] = useState("");
  const [Telefono, setTelefono] = useState("");
  const [CredencialFileFront, setCredencialFileFront] = useState({
    name: "",
    url: "",
    file: "",
    size: 0,
    formato: "",
  });
  const [CredencialFileBack, setCredencialFileBack] = useState({
    name: "",
    url: "",
    file: "",
    size: 0,
    formato: "",
  });
  const [Url, setUrl] = useState("");
  const [NombreTitular, setNombreTitular] = useState("");
  const [ApellidosTitular, setApellidosTitular] = useState("");
  const [Clabe, setClabe] = useState("");

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  // Mensajes de errores
  const [MensajeNumeroTarjeta, setMensajeNumeroTarjeta] = useState("true");
  const [MensajeExpiracion, setMensajeExpiracion] = useState("true");
  const [MensajeCVV, setMensajeCVV] = useState("true");

  const [MensajeEstado, setMensajeEstado] = useState("true");
  const [MensajeLocalidad, setMensajeLocalidad] = useState("true");
  const [MensajeDireccionUno, setMensajeDireccionUno] = useState("true");
  const [MensajeDireccionDos, setMensajeDireccionDos] = useState("true");
  const [MensajeCp, setMensajeCp] = useState("true");
  const [MensajeNacimiento, setMensajeNacimiento] = useState("true");
  const [MensajeGenero, setMensajeGenero] = useState("true");
  const [MensajeRfc, setMensajeRfc] = useState("true");
  const [MensajeTelefono, setMensajeTelefono] = useState("true");
  const [MensajeCredencialFront, setMensajeCredencialFront] = useState("true");
  const [MensajeCredencialBack, setMensajeCredencialBack] = useState("true");
  const [MensajeUrl, setMensajeUrl] = useState("true");
  const [MensajeClabe, setMensajeClabe] = useState("true");
  const [MensajeNombreTitular, setMensajeNombreTitular] = useState("true");
  const [MensajeApellidosTitular, setMensajeApellidosTitular] =
    useState("true");

  const [loader, setLoader] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);

  // usestate de tarjeta

  const [TarjPredetermindada, setTarjPredetermindada] = useState("");

  // ******** FUNCIONES ********

  async function getRecibos() {
    setLoader(true);
    let response = await getSubscriptionTickets_request();
    if (response.ok) {
      // console.log(response.data);
      setRecibosList(response.data);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function getTarjetas() {
    setLoader(true);
    let response = await getTarjetas_request();
    if (response.ok) {
      // console.log(response.data);
      setTarjetasList(response.data);
      response.data.forEach(Tarjeta => {
        if (Tarjeta.Predeterminado === 1) {
          setTarjPredetermindada(Tarjeta);
        }
      });
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function saveTarjeta(e) {
    e.preventDefault();
    setLoaderModal(true);
    if (
      !!NumeroTarjeta &&
      MensajeNumeroTarjeta !== "false" &&
      !!ExpiracionMes &&
      !!ExpiracionYear &&
      MensajeExpiracion !== "false" &&
      !!CVV &&
      MensajeCVV !== "false" &&
      NumeroTarjeta.length === 16
    ) {
      let data = new FormData();

      data.append("Number", NumeroTarjeta);
      data.append("Fecha", `${ExpiracionMes}/${ExpiracionYear}`);
      data.append("Cvc", CVV);

      let response = await addTarjeta_request(data);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("Tarjeta agregada");
        getTarjetas();
        cleanInputs();
        CloseAgregarT(true);
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!NumeroTarjeta || NumeroTarjeta.length < 16) {
        setMensajeNumeroTarjeta("false");
      }
      if (!!!ExpiracionMes || !!!ExpiracionYear) {
        setMensajeExpiracion("false");
      }
      if (!!!CVV) {
        setMensajeCVV("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }
    setLoaderModal(false);
    return;
  }

  async function deleteTarjeta() {
    setLoader(true);
    let response = await deleteTarjeta_request(IdTarjeta);
    if (response.ok) {
      setOpen(true);
      setTypeMessage("success");
      setMessage("Tarjeta eliminada");
      getTarjetas();
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function getCuentas() {
    setLoader(true);
    let response = await getClabeInterbancaria_request();
    if (response.ok) {
      console.log(response.data);
      setCuentasList(response.data);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function saveClabeInterbancaria(e) {
    setLoaderModal(true);
    if (
      !!Estado &&
      !!Localidad &&
      !!DireccionUno &&
      !!DireccionDos &&
      !!Cp &&
      !!Nacimiento &&
      !!Genero &&
      !!Rfc &&
      !!Telefono &&
      !!CredencialFileFront.file &&
      !!CredencialFileBack.file &&
      !!Url &&
      !!Clabe &&
      MensajeEstado !== "false" &&
      MensajeLocalidad !== "false" &&
      MensajeDireccionUno !== "false" &&
      MensajeDireccionDos !== "false" &&
      MensajeCp !== "false" &&
      MensajeNacimiento !== "false" &&
      MensajeGenero !== "false" &&
      MensajeRfc !== "false" &&
      MensajeTelefono !== "false" &&
      MensajeCredencialFront !== "false" &&
      MensajeCredencialBack !== "false" &&
      MensajeUrl !== "false"
    ) {
      let data = new FormData();
      let array_fecha = Nacimiento.split("-");
      let dia = array_fecha[2];
      let mes = array_fecha[1];
      let year = array_fecha[0];

      data.append("Ciudad", Estado);
      data.append("Estado", Localidad);
      data.append("Direccion1", DireccionUno);
      data.append("Direccion2", DireccionDos);
      data.append("PostalCode", Cp);
      data.append("Dia", dia);
      data.append("Mes", mes);
      data.append("Año", year);
      data.append("genero", Genero);
      data.append("RFC", Rfc);
      data.append("telefono", Telefono);
      data.append("front", CredencialFileFront.file, CredencialFileFront.name);
      data.append("back", CredencialFileBack.file, CredencialFileBack.name);
      data.append("url", Url);
      data.append("CLABE", Clabe);
      data.append("nombre", NombreTitular);
      data.append("apellidos", ApellidosTitular);

      let response = await addClabeInterbancaria_request(data);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("Cuenta agregada");
        getCuentas();
        setEstado(null);
        setLocalidad(null);
        setDireccionUno("");
        setDireccionDos("");
        setCp("");
        setGenero(null);
        setRfc("");
        setTelefono("");
        setCredencialFileFront({ name: "", url: "", file: "" });
        setCredencialFileBack({ name: "", url: "", file: "" });
        setUrl("");
        setClabe("");
        CloseAñadirC(true);
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!Estado) {
        setMensajeEstado("false");
      }
      if (!!!Localidad) {
        setMensajeLocalidad("false");
      }
      if (!!!DireccionUno) {
        setMensajeDireccionUno("false");
      }
      if (!!!DireccionDos) {
        setMensajeDireccionDos("false");
      }
      if (!!!Cp) {
        setMensajeCp("false");
      }
      if (!!!Nacimiento) {
        setMensajeNacimiento("false");
      }
      if (!!!Genero) {
        setMensajeGenero("false");
      }
      if (!!!Rfc) {
        setMensajeRfc("false");
      }
      if (!!!Telefono) {
        setMensajeTelefono("false");
      }
      if (!!!CredencialFileFront) {
        setMensajeCredencialFront("false");
      }
      if (!!!CredencialFileBack) {
        setMensajeCredencialBack("false");
      }
      if (!!!Url) {
        setMensajeUrl("false");
      }

      if (!!!Clabe) {
        setMensajeClabe("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }
    setLoaderModal(false);
    return;
  }

  async function getEstados() {
    let response = await getEstado_request();
    if (response.ok) {
      // console.log(response.data);
      let estados_temp = []
      response.data.forEach(estado => {
        estados_temp.push({ value: estado.id.toString(), label: estado.Estado });
      });
      setEstadosList(estados_temp);
    } else {
      setEstadosList([]);
    }
  }

  async function getLocalidades(estado) {
    let response = await getLocalidad_request(estado);
    if (response.ok) {
      // console.log(response.data);
      let localidades_temp = []
      response.data.forEach(estado => {
        localidades_temp.push({ value: estado, label: estado });
      });
      setLocalidadesList(localidades_temp);
    } else {
      setLocalidadesList([]);
    }
  }

  async function selectDefaultCard() {
    let response = await defaultCard_request();

    if (response.ok) {
      setOpen(true);
      setTypeMessage("success");
      setMessage("Nuevo metodo de pago seleccionado");
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
  }

  async function PaySubscription() {
    let response = await buySubscription_request();
    if (response.ok) {
      dispatch(showSnackbar({
        show: true,
        text: "Pago completado",
        type: "success",
      }));
      getInfoUser();
    } else {
      dispatch(showSnackbar({
        show: true,
        text: response.mensaje,
        type: "error",
      }));
    }
    setLoader(false);
    return;
  }

  async function getInfoUser() {
    let response = await infoUser_request();
    if (response["ok"]) {
      dispatch(infoUser(response.data));
      let info = response.data.Usuario;
      sessionStorage.setItem("infoUser", JSON.stringify(info));
    } else {
      dispatch(showSnackbar({
        show: true,
        text: response.mensaje,
        type: "error",
      }));
    }
    return;
  }

  async function cancelSub() {
    setLoader(true);
    let response = await cancelSubscription_request();
    if (response.ok) {
      dispatch(showSnackbar({
        show: true,
        text: "Suscripción cancelada",
        type: "success",
      }));
    } else {
      dispatch(showSnackbar({
        show: true,
        text: response.mensaje,
        type: "error",
      }));
    }
    setLoader(false);
    return;
  }

  function cleanInputs() { }

  function filterOptions(input, option) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    return (
      option?.label
        .replace(expr, function (e) {
          return chars[e];
        })
        .toLowerCase()
        .indexOf(
          input
            .replace(expr, function (e) {
              return chars[e];
            })
            .toLowerCase()
        ) > -1
    );
  }

  function filterSort(optionA, optionB) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    return (
      optionA?.label
        .replace(expr, function (e) {
          return chars[e];
        })
        .toLowerCase() ?? ""
    )
      .replace(expr, function (e) {
        return chars[e];
      })
      .toLowerCase()
      .localeCompare(
        (
          optionB?.label
            .replace(expr, function (e) {
              return chars[e];
            })
            .toLowerCase() ?? ""
        )
          .replace(expr, function (e) {
            return chars[e];
          })
          .toLowerCase()
      );
  }
  function setInfo(info) {

  }

  const [modal, ModalCancelSub] = Modal.useModal();
  const confirm = () => {
    modal.confirm({
      title: "¿Desea cancelar su suscripción?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: () => cancelSub(),
      onCancel: () => setModalStatusCancelSub(false),
      open: ModalStatusCancelSub,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>Al cancelar su suscripción, perderá acceso a su cuenta un día después de la fecha en que se realizaría su próxima renovación</p>
        </div>
      ),
    });
  };

  useEffect(() => {
    let dataInit = JSON.parse(sessionStorage.getItem("infoUser"));
    // console.log(dataInit);
    setInfo(dataInit);
    // (document.domain !== "ygia.app") && getTarjetas();
    getTarjetas();
    getRecibos();
    getEstados();
    return;
  }, []);

  return (
    <Stack>
      <NavbarNuevo
        mensajesError={{
          setOpen: setOpen,
          setTypeMessage: setTypeMessage,
          setMessage: setMessage,
        }}
        modulo={"MetodosDePago"}
        searchBar={
          <>
            <div className="tabsContainer">
              <div
                className={
                  TabsMetodosPago === "1"
                    ? "tabsMetodosPago active_tabs"
                    : "tabsMetodosPago"
                }
                onClick={() => setTabsMetodosPago("1")}
              >
                Mi subscripción
              </div>

              <div
                className={
                  TabsMetodosPago === "2"
                    ? "tabsMetodosPago active_tabs"
                    : "tabsMetodosPago"
                }
                onClick={() => {
                  getCuentas();
                  setTabsMetodosPago("2");
                }}
              >
                Cuenta
              </div>

            </div>

            {TabsMetodosPago === "2" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "1% 3% ",
                  margin: "5px 0",
                  alignItems: "center",
                  background: "#fff"
                }}
              >
                <div style={{ color: "#424242" }}>
                  <h3>Recepción de Pagos</h3>
                  <span>Cuenta para recibir pagos de pacientes Ygia</span>
                </div>
                {!!CuentasList ? (
                  <div></div>
                ) : (
                  <Button className="BotonesStyle" style={{ color: "white" }} onClick={OpenAñadirC} >
                    <p>Nueva</p>
                    <img style={{ width: "12px" }} src={Agregar} alt="" />
                  </Button>
                )}

              </div>
            )}

          </>
        }
      />

      <Content
        className="site-layout"
        style={{
          padding: "0 3%",
          width: "100%",
          overflow: "initial",
        }}
      >
        {loader ? (
          <Loader />
        ) : (
          <>
            {TabsMetodosPago === "1" && (
              <>
                <Row style={{ width: "100%", margin: "1% 0", display: "flex", justifyContent: "space-between" }}>
                  <div
                    className="AliniarLeftMembresia"

                  >
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <img style={{ width: "70px" }} src={Logo} alt="" />
                      {infoUser.Paquete === "2" && infoUser.PaqueteStatus === 1 ? <Popover
                        title="Detalle de suscripción IOS"
                        content={
                          <div style={{ width: "200px" }}>
                            <p>Suscripción contratada por medio de IOS, en caso de requerir una cancelacion, debera realizarlo desde un dispositivo IOS con su Apple id</p>
                          </div>
                        }
                      >
                        <Help sx={{ color: "#148f9f" }} />
                      </Popover> : <Button onClick={() => {
                        if (TarjetasList.length > 0) {
                          if (infoUser.Paquete === "1" && infoUser.PaqueteStatus === 1) {
                            confirm();
                          } else {

                          }
                        } else {
                          OpenAgregarT(true);
                        }
                      }} style={{ background: "#148f9f", color: "#fff" }}>
                        {infoUser.Paquete === "1" && infoUser.PaqueteStatus === 1 ? "Cancelar" : "Renovar"}
                      </Button>}
                    </div>
                    <div>
                      <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", margin: "1% 0" }}>
                        <p style={{ fontWeight: "500" }}>
                          Mi plan{" "}
                          <span style={{ color: "#148f9f" }}>Ygia Superior</span>
                        </p>
                        <p style={{ fontWeight: "500" }}>$399.00 al mes.</p>
                      </div>

                      {/* <p>
                        Este es el plan gratuito que cuenta con 2 meses de
                        vigencia.
                      </p> */}

                    </div>
                    <div style={{ margin: "1% 0" }}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} l>
                          <Typography>
                            Informacion general sobre mi plan
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1% 0", borderBottom: "1px solid #d7d7d7" }}>
                            <div>Funcionalidades básicas</div>
                            <div>Ilimitado</div>
                          </Row>
                          <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1% 0", borderBottom: "1px solid #d7d7d7" }}>
                            <div>Consultorio</div>
                            <div>Ilimitado</div>
                          </Row>
                          <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1% 0", borderBottom: "1px solid #d7d7d7" }}>
                            <div>Expediente médico electrónico</div>
                            <div>Ilimitado</div>
                          </Row>
                          <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1% 0", borderBottom: "1px solid #d7d7d7" }}>
                            <div>Administración de agenda</div>
                            <div>Ilimitado</div>
                          </Row>
                          {/* <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1% 0", borderBottom: "1px solid #d7d7d7" }}>
                            <div>Almacenamiento</div>
                            <div>5GB</div>
                          </Row> */}
                          <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1% 0", borderBottom: "1px solid #d7d7d7" }}>
                            <div>Asesoria</div>
                            <div>24/7</div>
                          </Row>

                        </AccordionDetails>
                      </Accordion>
                    </div>

                  </div>
                  <div
                    className="AliniarightMembresia"
                  >
                    <Row style={{ width: "100%", color: "#424242", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
                      <div>
                        <h3>Domiciliar pago</h3>
                        <u style={{ color: "#148f9f", cursor: "pointer" }} onClick={OpenSelecTrjeta}>Cambiar método de pago</u>
                      </div>
                      <div>
                        <ButtonAntd
                          tipo={1}
                          style={{ width: "100px" }}
                          onClick={OpenAgregarT}
                          children={
                            <Row
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>Nueva</span>
                              <AddCard />
                              {/* <img style={{ width: "12px" }} src={Agregar} alt="" /> */}
                            </Row>
                          }
                        />
                      </div>
                    </Row>

                    {TarjetasList.length > 0 ? (
                      <div style={{ width: "200px", display: "flex", alignItems: "center", justifyContent: "space-between", margin: "1% 0" }}>
                        <img style={{ width: "50px" }} src={TarjPredetermindada.Tipo === "Visa" ? visa : mastercard} alt="" />
                        <div>
                          <p>**** **** **** {TarjPredetermindada.UltimosDigitos}</p>
                          <p style={{ color: "#148f9f", fontSize: "80%", fontWeight: "500" }}>{TarjPredetermindada.TipoTarjeta}</p>
                        </div>
                      </div>
                    ) : (
                      <p>No cuenta con tarjetas agregadas aún</p>
                    )
                    }
                    {/* <u style={{ color: "#148f9f", cursor:"pointer" }}>Cambiar datos de facturación</u> */}
                  </div>
                </Row>
                {
                  infoUser.Paquete === "2" && infoUser.PaqueteStatus === 1 ?
                    <div>
                      <p style={{ fontWeight: "500" }}>Suscripción contratado mediante IOS</p>
                      <p style={{ fontWeight: "500" }}>Sus recibos de suscripción podra visualizarlos desde un dispositivo IOS</p>
                    </div> : (
                      <>
                        <div style={{ background: "white", padding: "1%" }}>
                          <p style={{ fontWeight: "500" }}>
                            Recibos de Pago
                          </p>
                        </div>
                        {/* {document.domain === "localhost" && ( */}
                        <div>
                          <ResibosCard recibos={RecibosList} />
                          {RecibosList.length === 0 && (
                            <Empty
                              description={
                                <span style={{ color: "black" }}>Sin recibos</span>
                              }
                            />
                            // <p className="noCitas">Sin citas</p>
                          )}
                        </div>
                        {/* )} */}
                      </>
                    )
                }

              </>
            )}


            {TabsMetodosPago === "2" && (
              <>
                <div>
                  {CuentasList.length === 0 ? (
                    <Empty
                      description={
                        <span style={{ color: "black" }}>Sin cuentas</span>
                      }
                    />
                  ) : (
                    <CuentasCard cuentas={CuentasList} />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </Content>

      {/* Modal de cancelacion de sub */}
      {ModalCancelSub}

      {/* AGREGAR TARJETA NUEVA */}
      <Modalagregar
        styleDet={styleAgregarT}
        isOpen={isOpenAgregarT}
        closeModal={CloseAgregarT}
      >
        <div className="Topmodaldetalle">
          <p>Nueva tarjeta</p>
          <CloseIcon style={{ cursor: "pointer", fontSize: 20 }} onClick={CloseAgregarT} />

        </div>
        {loaderModal ? (
          <LoaderModals />
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="contenidomodalA">
              <img style={{ width: "50px", marginRight: "10px" }} src={visa} alt="" />
              <img style={{ width: "50px", marginLeft: "10px" }} src={mastercard} alt="" />
              <form action="" style={{ padding: "0" }} onSubmit={saveTarjeta}>
                <div style={{ padding: "1% 2%" }}>
                  {/* <h2 style={{ textAlign: "center", padding: "2.5% 0" }}><b>Nueva tarjeta</b></h2> */}
                  <div style={{ textAlign: "left" }}>
                    {/* <p style={{ paddingBottom: "1.5%" }}>Número de tarjeta:</p> */}
                    <InputAntd
                      className="inputblue"
                      value={NumeroTarjeta}
                      setStateValue={setNumeroTarjeta}
                      label="Número de tarjeta:"
                      id="tarjeta"
                      style={{ margin: "2% 0", width: "100%" }}
                      placeholder="**** **** **** ****"
                      setStateError={setMensajeNumeroTarjeta}
                      textError={[
                        "Debe escribir un num. de tarjeta",
                        "Solo puede contener numeros",
                        "Maximo 16 dígitos",
                      ]}
                      type="text"
                      valueError={MensajeNumeroTarjeta}
                      vacio={false}
                      disabled={false}
                      expresionRegular={expresiones.Numeros}
                      maxLength={16}
                    />
                  </div>
                  {/* <LeyendaError valido={MensajeNumeroTarjeta} style={{ display: MensajeNumeroTarjeta === "false" ? "flex" : "none" }}>Debe escribir número de tarjeta</LeyendaError> */}
                  <p style={{ textAlign: "left", paddingBottom: "1.5%" }}>
                    Fecha de expiración:
                  </p>
                  <div
                    style={{
                      display: "flex",
                      gap: "1.5%",
                      alignItems: "center",
                    }}
                  >
                    <SelectAntd
                      // label="Género:"
                      value={ExpiracionMes}
                      style={{ width: "100%" }}
                      placeholder="MM"
                      options={[
                        { value: "01", label: "01" },
                        { value: "02", label: "02" },
                        { value: "03", label: "03" },
                        { value: "04", label: "04" },
                        { value: "05", label: "05" },
                        { value: "06", label: "06" },
                        { value: "07", label: "07" },
                        { value: "08", label: "08" },
                        { value: "09", label: "09" },
                        { value: "10", label: "10" },
                        { value: "11", label: "11" },
                        { value: "12", label: "12" },
                      ]}
                      setStateValue={setExpiracionMes}
                      setStateError={setMensajeExpiracion}
                    // valueError={MensajeExpiracion}
                    // textError={["Debe completar fecha de expiración"]}
                    />
                    <p style={{ marginBottom: "2%" }}>/</p>
                    <Input
                      value={ExpiracionYear}
                      style={{ marginBottom: "1%", width: "20%" }}
                      type="text"
                      placeholder="AA"
                      maxLength={2}
                      onChange={(e) => {
                        setExpiracionYear(e.target.value);
                        if (e.target.value.length > 0 && !!ExpiracionMes) {
                          setMensajeExpiracion("true");
                        } else {
                          setMensajeExpiracion("false");
                        }
                      }}
                    />
                  </div>
                  <LeyendaError
                    valido={MensajeExpiracion}
                    style={{
                      display: MensajeExpiracion === "false" ? "flex" : "none",
                    }}
                  >
                    Debe completar fecha de expiración
                  </LeyendaError>
                  <div style={{ textAlign: "left" }}>
                    <p style={{ paddingBottom: "1.5%" }}>CVV:</p>
                    <Input
                      value={CVV}
                      style={{ marginBottom: "2%", width: "20%" }}
                      type="text"
                      placeholder="***"
                      maxLength={4}
                      onChange={(e) => {
                        setCVV(e.target.value);
                        if (e.target.value.length > 0) {
                          setMensajeCVV("true");
                        } else {
                          setMensajeCVV("false");
                        }
                      }}
                    />
                  </div>
                  <LeyendaError
                    valido={MensajeCVV}
                    style={{
                      display: MensajeCVV === "false" ? "flex" : "none",
                    }}
                  >
                    Debe escribir código CVV
                  </LeyendaError>
                  <Row style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Boton
                      type="submit"
                      style={{
                        width: "100%",
                        height: "30px",
                        backgroundColor: "#148f9f",
                        color: "white",
                        borderRadius: ".2rem",
                        cursor: "pointer",

                      }}
                    >
                      Añadir
                    </Boton>
                  </Row>
                </div>
              </form>
            </div>
          </div>
        )}
      </Modalagregar>

      {/* AGREGAR CLABE INTERBANCARIA */}
      <Modalagregar
        styleDet={styleAñadirC}
        isOpen={isOpenAñadirC}
        closeModal={CloseAñadirC}
      >
        <div className="Topmodaldetalle">
          <p>Cuenta nueva</p>

          <CloseIcon style={{ fontSize: 20, cursor: "pointer" }} onClick={CloseAñadirC} />
        </div>
        <div className="contenidomodal">
          {loaderModal ? (
            <LoaderModals />
          ) : (
            <form action="" style={{ padding: "0" }}>
              <div style={{ padding: "1% 2%" }}>
                <Campos
                  label="Estado:"
                  textError="Debe seleccionar un estado"
                  valueError={MensajeEstado}
                >
                  <Select
                    showSearch
                    style={{
                      width: "100%",
                      margin: ".5% 0",
                    }}
                    placeholder="Estado"
                    optionFilterProp="children"
                    filterOption={filterOptions}
                    filterSort={filterSort}
                    value={Estado}
                    onSelect={(e) => {
                      setEstado(e);
                      getLocalidades(e);
                      setMensajeEstado("true");
                    }}
                    options={EstadosList}
                  />
                </Campos>

                <Campos
                  label="Localidad:"
                  textError="Debe seleccionar una localidad"
                  valueError={MensajeLocalidad}
                >
                  <Select
                    showSearch
                    style={{
                      width: "100%",
                      margin: ".5% 0",
                    }}
                    placeholder="Localidad"
                    optionFilterProp="children"
                    filterOption={filterOptions}
                    filterSort={filterSort}
                    value={Localidad}
                    onSelect={(e) => {
                      setLocalidad(e);
                      setMensajeLocalidad("true");
                    }}
                    options={LocalidadesList}
                    disabled={LocalidadesList.length === 0 ? true : false}
                  />
                </Campos>

                <InputAntd
                  label="Direccion 1:"
                  type=""
                  placeholder="Escriba una dirección"
                  value={DireccionUno}
                  setStateValue={setDireccionUno}
                  vacio={false}
                  setStateError={setMensajeDireccionUno}
                  textError={["Debe escribir una dirección", ""]}
                  valueError={MensajeDireccionUno}
                  styleDiv={{ width: "100%" }}
                />

                <InputAntd
                  label="Direccion 2:"
                  type=""
                  placeholder="Escriba una dirección"
                  value={DireccionDos}
                  setStateValue={setDireccionDos}
                  vacio={false}
                  setStateError={setMensajeDireccionDos}
                  textError={["Debe escribir una dirección", ""]}
                  valueError={MensajeDireccionDos}
                  styleDiv={{ width: "100%" }}
                />

                <InputAntd
                  label="Codigo postal:"
                  type=""
                  placeholder="Escriba un código postal"
                  value={Cp}
                  setStateValue={setCp}
                  vacio={false}
                  setStateError={setMensajeCp}
                  textError={["Debe escribir un código postal", ""]}
                  valueError={MensajeCp}
                  styleDiv={{ width: "100%" }}
                  maxLength={10}
                />

                {/* <DatePickerAntd
                  label="Fecha de nacimiento:"
                  placeholder="DD/MM/AAAA"
                  style={{ width: "100%", background: "white" }}
                  value={!!Nacimiento ? dayjs(Nacimiento) : ""}
                  setStateValue={setNacimiento}
                  styleDiv={{ width: "100%" }}
                  setStateError={setMensajeNacimiento}
                  valueError={MensajeNacimiento}
                  textError={"Debe elegir una fecha de nacimiento"}
                /> */}

                <InputDate
                  label="Fecha de nacimiento:"
                  requiredICon={true}
                  placeholder="DD/MM/AAAA"
                  style={{ width: "100%", background: "white" }}
                  value={!!Nacimiento ? Nacimiento : ""}
                  setStateValue={setNacimiento}
                  styleDiv={{ width: "100%" }}
                  setStateError={setMensajeNacimiento}
                  valueError={MensajeNacimiento}
                  textError={["Debe elegir una fecha de nacimiento", "Fecha no válida"]}
                  vacio={false}
                />

                <SelectAntd
                  label="Género:"
                  value={Genero}
                  style={{ width: "100%" }}
                  placeholder="Género"
                  options={[
                    { value: "female", label: "Femenino" },
                    { value: "male", label: "Masculino" },
                  ]}
                  setStateValue={setGenero}
                  styleDiv={{ width: "100%" }}
                  setStateError={setMensajeGenero}
                  valueError={MensajeGenero}
                  textError={"Debe seleccionar un género"}
                />

                <InputAntd
                  label="RFC:"
                  type=""
                  placeholder="Escriba un rfc"
                  value={Rfc}
                  setStateValue={setRfc}
                  vacio={false}
                  setStateError={setMensajeRfc}
                  textError={["Debe escribir un rfc", ""]}
                  valueError={MensajeRfc}
                  styleDiv={{ width: "100%" }}
                />

                <InputAntd
                  label="Télefono:"
                  type=""
                  placeholder="Escriba una télefono"
                  value={Telefono}
                  setStateValue={setTelefono}
                  vacio={false}
                  setStateError={setMensajeTelefono}
                  textError={["Debe escribir una télefono", ""]}
                  valueError={MensajeTelefono}
                  styleDiv={{ width: "100%" }}
                />

                <Campos
                  label="Frente de credencial de elector"
                  textError="Debe adjuntar una imagen del frente de su credencial"
                  valueError={MensajeCredencialFront}
                >
                  <InputFileSingle
                    id="front"
                    archivoValue={CredencialFileFront}
                    texto={"Ajuntar"}
                    archivo={setCredencialFileFront}
                    formatos={4}
                    extras={[
                      setOpen,
                      setTypeMessage,
                      setMessage,
                      setMensajeCredencialFront,
                    ]}
                  />
                </Campos>

                <Campos
                  label="Reverso de credencial de elector"
                  textError="Debe adjuntar una imagen del reverso de su credencial de elector"
                  valueError={MensajeCredencialBack}
                >
                  <InputFileSingle
                    id="back"
                    archivoValue={CredencialFileBack}
                    texto={"Ajuntar"}
                    archivo={setCredencialFileBack}
                    formatos={4}
                    extras={[
                      setOpen,
                      setTypeMessage,
                      setMessage,
                      setMensajeCredencialBack,
                    ]}
                  />
                </Campos>

                <InputAntd
                  label="URL:"
                  type=""
                  placeholder="Escriba una url de tu pagina de promoción o red social"
                  value={Url}
                  setStateValue={setUrl}
                  vacio={false}
                  setStateError={setMensajeUrl}
                  textError={["Debe escribir una url", ""]}
                  valueError={MensajeUrl}
                  styleDiv={{ width: "100%" }}
                />

                <InputAntd
                  label="Nombre del titular:"
                  type=""
                  placeholder="Escriba su nombre"
                  value={NombreTitular}
                  setStateValue={setNombreTitular}
                  vacio={true}
                  // setStateError={setMensajeNombreTitular}
                  // textError={["Debe escribir un nombre", ""]}
                  // valueError={MensajeNombreTitular}
                  styleDiv={{ width: "100%" }}
                />

                <InputAntd
                  label="Apellidos del titular:"
                  type=""
                  placeholder="Escriba sus apellidos"
                  value={ApellidosTitular}
                  setStateValue={setApellidosTitular}
                  vacio={true}
                  // setStateError={setMensajeApellidosTitular}
                  // textError={["Debe escribir un apellido", ""]}
                  // valueError={MensajeApellidosTitular}
                  styleDiv={{ width: "100%" }}
                />

                <InputAntd
                  label="CLABE interbancaria:"
                  type=""
                  placeholder="Escriba una CLABE interbancaria"
                  value={Clabe}
                  setStateValue={setClabe}
                  vacio={false}
                  setStateError={setMensajeClabe}
                  textError={["Debe escribir una CLABE interbancaria", ""]}
                  valueError={MensajeClabe}
                  styleDiv={{ width: "100%" }}
                />

                <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "10px 0" }}>
                  <Boton
                    onClick={() => {
                      saveClabeInterbancaria();
                    }}
                    type="button"
                    style={{
                      width: "100%",
                      height: "30px",
                      backgroundColor: "#148f9f",
                      color: "white",
                    }}
                  >
                    Añadir
                  </Boton>
                </Row>
              </div>
            </form>
          )}
        </div>
      </Modalagregar>

      {/* Modal de elejir tarjeta como metodo de pago */}
      <Modalagregar styleDet={styleAgregarT} isOpen={isOpenSelecTrjeta} closeModal={CloseSelecTrjeta}  >
        <>
          <div className="Topmodaldetalle">
            <p>Selecciona nuevo metodo de pago</p>
            <CloseIcon style={{ cursor: "pointer", fontSize: 20 }} onClick={CloseSelecTrjeta} />
          </div>
          <div className="contenidomodal">
            <div style={{ padding: "3%" }}>

              <TarjetasCard
                tarjetas={TarjetasList}
                setIdTarjeta={setIdTarjeta}
                deleteTarjeta={deleteTarjeta}
                selectDefaultCard={selectDefaultCard}
              />
              {TarjetasList.length === 0 && (
                <Empty
                  description={
                    <span style={{ color: "black" }}>Sin tarjetas</span>
                  }
                />
              )}
              <div style={{ textAlign: "center" }}>
              </div>
            </div>

          </div>

        </>
      </Modalagregar>

      <SnackbarProvider maxSnack={3}>
        <SnackBar
          setOpen={setOpen}
          open={open}
          typeMessage={typeMessage}
          message={message}
        />
      </SnackbarProvider>

    </Stack>
  );
}

export default MetodosDePago;
