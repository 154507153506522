import React from "react";
import { useState, useEffect } from "react";
import "../pages/styles/Ingresosyegresos.scss";
import Ingyegrecard from "../componentes/Ingresos-Egresos/IngYEgreCard";
import { UseModal } from "../hooks/UseModal";
import { Input, Row, Select, InputNumber, Button, Empty } from "antd";
import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";
import ListaVar from "../componentes/GeneralComponents/General-Json";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Label,
  ContenedorBotonCentrado,
  Boton,
  LeyendaError,
} from "../componentes/GeneralComponents/Formularios2Grids";
import ModalAdd from "../componentes/GeneralComponents/ModalDetalle";
import Modaldetail from "../componentes/GeneralComponents/ModalDetalle";
import ModalFiltro from "../componentes/GeneralComponents/ModalFiltro";
import {
  getMovimientos_request,
  detailMovimiento_request,
  cancelMovimiento_request,
  addMovimiento_request,
  editMovimiento_request,
  getMovimientosFilter_request,
} from "../services/IngresosyEgresos_services";
import { Loader } from "../componentes/GeneralComponents/Loader";
import LoaderModals from "../componentes/GeneralComponents/Loader_modals";
//Importacion de iconos
import Agregar from "../Img/Iconos/AgregarBlanco.svg";
import Filtro from "../Img/Iconos/Filtro.svg";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Cancel } from "@mui/icons-material";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";
import { Content } from "antd/es/layout/layout";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function IngresosyEgresos() {
  const location = useLocation();
  // ******** VARIABLES ********
  const styleAgregarIyE = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  const styledetail = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  // ******** USE STATES ********

  // Modales
  const [isOpenFiltro, OpenFiltro, CloseFiltro] = UseModal(false);
  const [isOpenAgregarIyE, OpenAgregarIyE, CloseAgregarIyE] = UseModal(false);
  const [isOpenDetalleIngyegre, OpenDetalleIngyegre, CloseDetalleIngyegre] =
    UseModal(false);

  // listados de movimientos
  const [ListIngresosFilter, setListIngresosFilter] = useState([]);
  const [ListIngresos, setListIngresos] = useState([]);

  // Manejo de mensajes flotantes
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  // manejo de loaders
  const [loader, setLoader] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);

  // otros
  const [FechaInicio, setFechaInicio] = useState("");
  const [FechaFin, setFechaFin] = useState("");
  const [EstadoFiltro, setEstadoFiltro] = useState("Todos");
  const [FiltroActive, setFiltroActive] = useState(false);

  const [IdMovimiento, setIdMovimiento] = useState("");
  const [DetalleMovimiento, setDetalleMovimiento] = useState("");

  const [Concepto, setConcepto] = useState("");
  const [TipoMovimiento, setTipoMovimiento] = useState(null);
  const [MontoMovimiento, setMontoMovimiento] = useState(0);

  const [ValidacionMonto, setValidacionMonto] = useState("");

  const [Accion, setAccion] = useState(0);

  const [MensajeConcepto, setMensajeConcepto] = useState("true");
  const [MensajeTipoMovimiento, setMensajeTipoMovimiento] = useState("true");
  const [MensajeMontoMovimiento, setMensajeMontoMovimiento] = useState("true");
  const [MensajeFechaInicio, setMensajeFechaInicio] = useState("true");
  const [MensajeFechaFin, setMensajeFechaFin] = useState("true");

  // ******** FUNCIONES ********

  async function getMovimientos() {
    setLoader(true);
    let response = await getMovimientos_request();
    if (response.ok) {
      // console.log(response.data);
      setListIngresos(response.data);
      setListIngresosFilter(response.data);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function getMovimientosFilter(type) {
    setLoader(true);
    if (type === 4) {
      if (!!!FechaInicio || !!!FechaFin) {
        if (!!!FechaInicio) {
          setMensajeFechaInicio("false");
        }
        if (!!!FechaFin) {
          setMensajeFechaFin("false");
        }
        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
      } else {
        let data = new FormData();
        data.append("Estado", EstadoFiltro);
        data.append("FechaInicio", FechaInicio);
        data.append("FechaFin", FechaFin);

        // console.log(type);

        let response = await getMovimientosFilter_request(type, data);
        if (response.ok) {
          // console.log(response.data);
          // return;
          setListIngresosFilter(response.data);
          setListIngresos(response.data);
          // setBotonCancelFiltro(1);
          CloseFiltro(true);
          setFiltroActive(true);
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      }
    } else {
      let data = new FormData();
      data.append("Estado", EstadoFiltro);

      // console.log(type);

      let response = await getMovimientosFilter_request(type, data);
      if (response.ok) {
        // console.log(response.data);
        // return;
        setListIngresosFilter(response.data);
        setListIngresos(response.data);
        // setBotonCancelFiltro(1);
        CloseFiltro(true);
        setFiltroActive(true);
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    }
    setLoader(false);
    return;
  }

  async function detailMovimiento(idMovimiento) {
    setLoaderModal(true);
    let response = await detailMovimiento_request(idMovimiento);
    if (response.ok) {
      // console.log(response.data);
      setDetalleMovimiento(response.data);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoaderModal(false);
  }

  async function addMovimiento() {
    setLoaderModal(true);
    if (
      !!Concepto &&
      !!TipoMovimiento &&
      !!MontoMovimiento &&
      MontoMovimiento > 0
    ) {
      let data = new FormData();
      data.append("Concepto", Concepto);
      data.append("Tipo", TipoMovimiento);
      data.append("Monto", MontoMovimiento);
      let response = await addMovimiento_request(data);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("Movimiento realizado");
        clearInputs();
        CloseAgregarIyE(true);
        getMovimientos();
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!Concepto) {
        setMensajeConcepto("false");
      }
      if (!!!TipoMovimiento) {
        setMensajeTipoMovimiento("false");
      }
      if (!!!MontoMovimiento) {
        setValidacionMonto("Debe ingresar un monto");
        setMensajeMontoMovimiento("false");
      } else if (MontoMovimiento === 0) {
        setValidacionMonto("El monto debe ser mayor a $0");
        setMensajeMontoMovimiento("false");
      }
    }
    setLoaderModal(false);
    return;
  }

  async function editMovimiento() {
    setLoaderModal(true);
    if (
      !!Concepto &&
      !!TipoMovimiento &&
      !!MontoMovimiento &&
      MontoMovimiento > 0
    ) {
      let data = new FormData();
      data.append("Concepto", Concepto);
      data.append("Tipo", TipoMovimiento);
      data.append("Monto", MontoMovimiento);
      let response = await editMovimiento_request(data, IdMovimiento);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("Movimiento editado");
        clearInputs();
        CloseAgregarIyE(true);
        getMovimientos();
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!Concepto) {
        setMensajeConcepto("false");
      }
      if (!!!TipoMovimiento) {
        setMensajeTipoMovimiento("false");
      }
      if (!!!MontoMovimiento) {
        setValidacionMonto("Debe ingresar un monto");
        setMensajeMontoMovimiento("false");
      } else if (MontoMovimiento === 0) {
        setValidacionMonto("El monto debe ser mayor a $0");
        setMensajeMontoMovimiento("false");
      }
    }

    setLoaderModal(false);
    return;
  }

  async function cancelMovimiento() {
    setLoader(true);
    let response = await cancelMovimiento_request(IdMovimiento);
    if (response.ok) {
      setOpen(true);
      setTypeMessage("success");
      setMessage("Movimiento cancelado");
      getMovimientos();
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  function filterList(text) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    let filter = [];

    ListIngresosFilter.forEach((movimiento) => {
      if (
        movimiento.Concepto.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1 ||
        movimiento.Fecha.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1
      ) {
        filter.push(movimiento);
      }
    });
    setListIngresos(filter);
  }

  function clearInputs() {
    setConcepto("");
    setTipoMovimiento(null);
    setMontoMovimiento(0);
    setValidacionMonto("");
    setMensajeConcepto("true");
    setMensajeTipoMovimiento("true");
    setMensajeMontoMovimiento("true");
    setAccion(0);
  }

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
  });
    getMovimientos();
  }, []);

  return (
    <Stack>
      {loader ? (
        <Loader />
      ) : (
        <>
          <NavbarNuevo
          modulo={"Ingresos"}
            mensajesError={{
              setOpen: setOpen,
              setTypeMessage: setTypeMessage,
              setMessage: setMessage,
            }}
            searchBar={
              <div className="EncabezadoIyE">
                <div className="espaciadoIyE">
                  <div>
                    <b>Ingresos y egresos</b>
                    <br />
                  </div>

                  <ButtonAntd
                    tipo={1}
                    style={{ width: "100px" }}
                    onClick={() => {
                      OpenAgregarIyE(true);
                      setAccion(0);
                    }}
                    children={
                      <Row style={{ gap: ".5rem",width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center" }}>
                        Nuevo
                        <img style={{ width: "12px" }} src={Agregar} alt="" />
                      </Row>
                    }
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "1%" }}>
                  <Input
                    style={{ width: "45%" }}
                    type="text"
                    className="inputblue"
                    placeholder="Buscar por concepto o fecha(DD-MM-AAAA)"
                    onChange={filterList}
                  />
                  <div style={{ display: "flex", width: "100%" }}>
                    <img
                      onClick={() => {
                        OpenFiltro(true);
                        setMensajeFechaInicio("true");
                        setMensajeFechaFin("true");
                      }}
                      style={{ width: "30px", cursor: "pointer" }}
                      src={Filtro}
                      alt=""
                    />
                    {FiltroActive && (
                      <Button
                        style={{
                          background: "white",
                          color: "#148F9F",
                          border: "0",
                          height: "30px",
                          padding: "0 1%",
                          margin: ".8% 0",
                          // marginLeft: "3%",
                          marginRight: "0",
                        }}
                        onClick={() => {
                          setFiltroActive(false);
                          setEstadoFiltro("Todos");
                          setFechaInicio("");
                          setFechaFin("");
                          getMovimientos();
                        }}
                      >
                        <Cancel sx={{ color: "#148f9f" }} />
                        Filtro
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            } />

          <Content
            className="site-layout"
            style={{
              padding: '.5% 3%',
              width: "100%",
              overflow: 'initial',
            }}
          >
            <Ingyegrecard
              OpendetailIngyegr={OpenDetalleIngyegre}
              Datos={ListIngresos}
              extras={[
                setIdMovimiento,
                setDetalleMovimiento,
                cancelMovimiento,
                setAccion,
                setConcepto,
                setTipoMovimiento,
                setMontoMovimiento,
                OpenAgregarIyE,
              ]}
            />
          </Content>
        </>
      )}
      <ModalFiltro
        isOpen={isOpenFiltro}
        closeModal={CloseFiltro}
        titulos={["Ordena tus movimientos", "Hoy", "Ayer", "Semana"]}
        OptionsSelect={[
          { label: "Todos", value: "Todos" },
          { label: "Ingresos", value: "Ingresos" },
          { label: "Egresos", value: "Egresos" },
        ]}
        functions={[getMovimientos, getMovimientosFilter]}
        statesVariables={{
          estadoFiltro: setEstadoFiltro,
          fechaInicio: setFechaInicio,
          mensajeFechaInicio: setMensajeFechaInicio,
          fechaFin: setFechaFin,
          mensajeFechaFin: setMensajeFechaFin,
        }}
        variables={{
          estadoFiltro: EstadoFiltro,
          fechaInicio: FechaInicio,
          mensajeFechaInicio: MensajeFechaInicio,
          fechaFin: FechaFin,
          mensajeFechaFin: MensajeFechaFin,
        }}
      />
      <ModalAdd
        styleDet={styleAgregarIyE}
        isOpen={isOpenAgregarIyE}
        closeModal={CloseAgregarIyE}
      >
        <div className="TopdetailIyE">
          {Accion === 0 ? (
            <span>Nuevo movimiento</span>
          ) : (
            <span>Editar movimiento</span>
          )}
          <CloseIcon
           style={{ cursor: "pointer", fontSize:"20", color:"white" }}
           onClick={() => {
             CloseAgregarIyE(true);
             clearInputs();
           }}
          />
        </div>
        {loaderModal ? (
          <LoaderModals className="Contenedormodal"/>
        ) : (
          <div className="Contenedormodal">

            <form action="" style={{ padding: "0" }}>
            
              <div style={{ padding: "1% 2%" }}>
              <header style={{display:"flex", justifyContent:"end"}}>
              <Boton
                  className="GeneralBoton"
                    type="button"
                    style={{
                      width: "100px",
                      color: "white",
                      height:"32px",
                      display:"flex", justifyContent:"space-evenly", alignItems:"center"
                    }}
                    onClick={() => {
                      if (Accion === 0) {
                        addMovimiento();
                      } else {
                        editMovimiento();
                      }
                    }}
                  >
                    Guardar
                    <CheckCircleIcon fontSize="15px"/>
                  </Boton>
              </header>
                <p style={{ textAlign: "left", paddingBottom: "1.5%" }}>
                  Concepto:
                </p>
                <Input
                  value={Concepto}
                  style={{ marginBottom: "2%" }}
                  type="text"
                  placeholder="Nombre del concepto"
                  onChange={(e) => {
                    setConcepto(e.target.value);
                    if (e.target.value.length > 0) {
                      setMensajeConcepto("true");
                    } else {
                      setMensajeConcepto("false");
                    }
                  }}
                />
                <LeyendaError
                  valido={MensajeConcepto}
                  style={{
                    display: MensajeConcepto === "false" ? "flex" : "none",
                  }}
                >
                  Debe escribir un concepto
                </LeyendaError>
                <br />
                <br />
                <p style={{ textAlign: "left", paddingBottom: "2%" }}>
                  Tipo de movimiento:
                </p>
                <Select
                  value={TipoMovimiento}
                  // className="inputblue"
                  id="tamañorec"
                  placeholder="Elige una opción"
                  style={{ width: "100%", textAlign: "start" }}
                  options={[
                    { label: "Ingreso", value: "0" },
                    { label: "Egreso", value: "1" },
                  ]}
                  onSelect={(e) => {
                    setTipoMovimiento(e);
                    setMensajeTipoMovimiento("true");
                  }}
                />
                <LeyendaError
                  valido={MensajeTipoMovimiento}
                  style={{
                    display:
                      MensajeTipoMovimiento === "false" ? "flex" : "none",
                  }}
                >
                  Debe seleccionar tipo de movimiento
                </LeyendaError>
                <br />
                <br />
                <p style={{ textAlign: "left", paddingBottom: "1.5%" }}>
                  Monto del movimiento:
                </p>
                <InputNumber
                  prefix="$"
                  value={MontoMovimiento}
                  // type="number"
                  placeholder="0.00"
                  className="inputblue"
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    `${value}`
                      // .replace(/\D/g, "")
                      // .replace(/([0-9])([0-9]{2})$/, '$1.$2')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={(e) => {
                    // console.log(e);
                    // console.log(formateadorMoneda.format(e.target.value));
                    // let precio = formateadorMoneda.format(e.target.value);
                    setMontoMovimiento(e);
                    if (e > 0) {
                      setMensajeMontoMovimiento("true");
                    } else if (e === 0) {
                      setValidacionMonto("El monto debe ser mayor a $0");
                      setMensajeMontoMovimiento("false");
                    }
                    // setPrecio(e.target.value.replace(/\D/g, "")
                    //   .replace(/([0-9])([0-9]{2})$/, '$1.$2')
                    //   .replace(/\B(?=(\d{7})+(?!\d)\.?)/g, ","));
                  }}
                />
                <LeyendaError
                  valido={MensajeMontoMovimiento}
                  style={{
                    display:
                      MensajeMontoMovimiento === "false" ? "flex" : "none",
                  }}
                >
                  {ValidacionMonto}
                </LeyendaError>
            
             
              </div>
            </form>
          </div>
        )}
      </ModalAdd>
      <Modaldetail
        styleDet={styledetail}
        isOpen={isOpenDetalleIngyegre}
        closeModal={CloseDetalleIngyegre}
        optionClose={true}
        extras={[11, setDetalleMovimiento]}
      >
        <div className="TopdetailIyE">
          <span>Información del movimiento</span>
          <CloseIcon 
           style={{ cursor: "pointer", fontSize:"20", color:"white" }}
           onClick={() => {
             CloseDetalleIngyegre(true);
             setDetalleMovimiento("");
           }}/>
       
        </div>
        <div className="Contenedormodal">
          <form action="" style={{ padding: "0" }}>
            <div style={{ padding: "1% 2%" }}>
            <div
                style={{
               
                  display: "flex",
                  justifyContent: "space-between",
                  padding:"1%"  , borderBottom:"1px solid #d7d7d7"
                }}
              >
                <p style={{fontWeight:"500"}}>Tipo:</p>
                <p>{DetalleMovimiento.TipoMovimiento}</p>
              </div>
              <div
                style={{
                  width:"100%", textAlign:"left",
                  padding:"1%"
                  , borderBottom:"1px solid #d7d7d7"
                }}
              >
                <p style={{fontWeight:"500"}}>Concepto:</p>
                <p style={{color:"#424242"}}>{DetalleMovimiento.Concepto}</p>
              </div>
            
              <div
                  style={{
               
                    display: "flex",
                    justifyContent: "space-between",
                    padding:"1%"  , borderBottom:"1px solid #d7d7d7"
                  }}
              >
                <p style={{fontWeight:"500"}}>Estado:</p>
                {/* <p>{DetalleMovimiento.Estado}</p> */}
                {DetalleMovimiento.Estado === "Activo" ? (
                  <p style={{ color: "green" }}>{DetalleMovimiento.Estado}</p>
                ) : (
                  <p style={{ color: "red" }}>{DetalleMovimiento.Estado}</p>
                )}
              </div>
              <div
                style={{
               
                  display: "flex",
                  justifyContent: "space-between",
                  padding:"1%"  , borderBottom:"1px solid #d7d7d7"
                }}
              >
                <p style={{fontWeight:"500"}}>Monto:</p>
                {/* <p>${DetalleMovimiento.Cantidad}</p> */}
                {DetalleMovimiento.TipoMovimiento === "Ingreso" ? (
                  <p style={{ color: "green", textAlign: "right" }}>
                    + ${DetalleMovimiento.Cantidad}
                  </p>
                ) : (
                  <p style={{ color: "red", textAlign: "right" }}>
                    - ${DetalleMovimiento.Cantidad}
                  </p>
                )}
              </div>
              {!!DetalleMovimiento.FolioOrigen && (
                <div
                style={{
               
                  display: "flex",
                  justifyContent: "space-between",
                  padding:"1%"  , borderBottom:"1px solid #d7d7d7"
                }}
                >
                  <p style={{fontWeight:"500"}}>Folio:</p>
                  <p>{DetalleMovimiento.FolioOrigen}</p>
                </div>
              )}
              <div
                style={{
               
                  display: "flex",
                  justifyContent: "space-between",
                  padding:"1%"  , borderBottom:"1px solid #d7d7d7"
                }}
              >
                <p style={{fontWeight:"500"}}>Origen:</p>
                <p>{DetalleMovimiento.Origen}</p>
              </div>
              <div
               style={{
               
                display: "flex",
                justifyContent: "space-between",
                padding:"1%"  , borderBottom:"1px solid #d7d7d7"
              }}
              >
                <p>Fecha</p>
                <p>{DetalleMovimiento.Fecha}</p>
              </div>
            </div>
          </form>
        </div>
      </Modaldetail>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
export default IngresosyEgresos;
