import React, { useEffect, useState } from 'react'

import { useNavigate, useLocation } from "react-router-dom";

import Editar from "../../Img/Iconos/editarID.svg";
import Icono from "../../componentes/IconosDeSitio";
import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { getHistoriasClinicas_request } from "../../services/expediente_service";
import {
    getCIIEDiagnosticos_request,
    addConsulta_request,
    detailConsultas_request,
    getMedicamentos_request,
    vistaPreviaInterconsulta_request,
    vistaPreviaReceta_request,
    previewResumenMedico_request,
    vistaPreviaOrdenEstudios_request,
    addResumenMedico_request,
    addConsultaFormData_request
} from "../../services/consultas_services";
import { getDetailEstudios_request } from "../../services/estudios_services";
import { getEstado_request, getLocalidad_request } from "../../services/expediente_service";
import { getContacts_request } from "../../services/contactos_services";
import { getConfigRecetas_request, addConfigReceta_request, getDetailRecetas_request, sendRecetas_request } from "../../services/recetas_services";

// aUTOCOMPLETE
import Autosuggest from "react-autosuggest";

// importando Iconos
import CheckGuardar from "../../Img/Iconos/checkmark_white.svg";
import Iconos from "../../componentes/IconosDeSitio";
import Eliminar from "../../Img/Iconos/eliminar.svg";
import Agregar from "../../Img/Iconos/Agregar.svg";
import AddIcon from "@mui/icons-material/AddCircle";
import { Add, CancelRounded, CheckCircle, OndemandVideo } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { ExclamationCircleOutlined, LoadingOutlined, SettingOutlined } from '@ant-design/icons';

import "../../pages/styles/Consultas.scss"
import { Checkbox, DatePicker, Form, InputNumber, Popconfirm, Table, Row, Col, Button, Modal, Select, Input, QRCode, Empty, AutoComplete, message, Avatar, Image, Space, Spin } from "antd";
import InputMensaje from "antd/lib/message";
import { LeyendaError, Label, IconoValidacion } from "../../componentes/GeneralComponents/Formularios";
import InputFileList from "../GeneralComponents/InputFileList";
import InputFileSingle from '../GeneralComponents/InputFileSingle';
import TextArea from "antd/es/input/TextArea";
import InputAntd from "../../componentes/GeneralComponents/InputAntd"
import SelectAntd from "../../componentes/GeneralComponents/SelectAntd"
import TextAreaAntd from "../../componentes/GeneralComponents/TextAreaAntd"
import DatePickerAntd from '../GeneralComponents/DatePickerAntd';

// Importando metodo de modales
import { UseModal } from "../../hooks/UseModal";

import NavbarNuevo, { cerrarConsulta } from "../GeneralComponents/Navbar_Nuevo"

// Acordion libreria mui
import Accordionn from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from 'dayjs';
import "dayjs/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import { Loader } from "../../componentes/GeneralComponents/Loader";
import LoaderModals from '../GeneralComponents/Loader_modals';

import ModalDetalleConsulta from "../../componentes/GeneralComponents/ModalDetalle";
import ModalDetalleEstudio from "../../componentes/GeneralComponents/ModalDetalle";
import ModalCostoConsulta from "../../componentes/GeneralComponents/ModalDetalle";
import ModalResumenMedico from "../../componentes/GeneralComponents/ModalDetalle";
import ModalConfig from "../../componentes/GeneralComponents/ModalDetalle";
import ModalAlertConfirm from "../../componentes/GeneralComponents/ModalDetalle";
import ModalSelectContact from "../../componentes/GeneralComponents/ModalDetalle";
import ModalRecetasDetalle from "../../componentes/GeneralComponents/ModalDetalle";

import Stack from '@mui/material/Stack';
import PdfView from '../GeneralComponents/pdfViewer';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Content } from 'antd/es/layout/layout';
import Campos from '../GeneralComponents/campos';
import ModalMedicos from './ModalMedicos';
import ReactGA from 'react-ga4';
import SnackBar from '../GeneralComponents/Snackbar';
import { useSelector } from 'react-redux';
import ButtonAntd from '../GeneralComponents/ButtonAntd';
import InputDate from '../GeneralComponents/InputDate';

function AddConsulta() {


    const location = useLocation();
    const navigate = useNavigate();

    const data = useSelector((state) => state.userInfo.profesiones);
    let cedulas = [];
    data.forEach(cedula => {
        cedulas.push({ label: `${cedula.Cedula} - ${cedula.Profesion}`, value: cedula.Cedula })
    });

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];

    const expresiones = {
        textos: /^[a-zA-ZÀ-ÿ\s]{1,}$/, // Letras y espacios, pueden llevar acentos.
        // password: /^.{8,}$/, // 4 a 12 digitos.
        password: /^[a-zA-Z0-9]{8,}$/, // Letras y numeros.
        correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        numerosYPunto: /^[0-9.]{1,}$/, // 7 a 14 numeros.
        numerosYguionDiag: /^[0-9/-]{1,}$/, // 7 a 14 numeros.
        sangre: /^[a-zA-Z-+]{1,}$/, // 7 a 14 numeros.
        codigo: /^\d{1,4}$/, // 7 a 14 numeros.
        textNumber: /^[a-zA-Z0-9À-ÿ\s]{5,100}$/, // Letras, Numeros y espacios, pueden llevar acentos.
        direccion: /^[-/#a-zA-Z0-9À-ÿ\s]{10,100}$/, // Letras, Numeros y espacios, pueden llevar acentos.
        // altura: /[0-9]{1,1}.[0-9]{1,2}/
        // nacimiento: getEdad, // Letras y espacios, pueden llevar acentos.
    };

    const styleDet = {

        height: "clac(100vh - 52px)",
        width: "clac(100vw - 52px)",
        margin: "0 2%",

    };

    const styleAnalisis = {
        height: "clac(100vh - 52px)",
        width: "clac(100vw - 52px)",
        margin: "0 2%",
    };

    const styleMot = {
        height: "clac(100vh - 52px)",
        width: "clac(100vw - 52px)",
        margin: "0 2%",
    };

    const styleResMed = {
        height: "clac(100vh - 52px)",
        width: "clac(100vw - 52px)",
        margin: "0 2%",
    };

    const styleDetail = {
        height: "clac(100vh - 52px)",
        width: "clac(100vw - 52px)",
        // minWidth :"clac(70vw - 52px)",
        margin: "0 2%",
    };

    const styleConfirm = {
        height: "clac(100vh - 52px)",
        width: "clac(100vw - 52px)",
        margin: "0 2%",
    };

    const columns = [
        {
            title: 'Medicamento',
            dataIndex: 'Concepto',
            key: 'Concepto',
        },
        {
            title: 'Dosis',
            dataIndex: 'Descripcion',
            key: 'Descripcion',
        }
    ];

    const RecetaDetalleModal = {
        height: "clac(100vh - 52px)",
        width: "clac(100vw - 52px)",
        margin: "0 2%",
    };

    let fecha_format = new Date(Date.now());
    let mes = fecha_format.getMonth() + 1;
    let mes_correcto = mes.toString().length === 1 ? `0${mes}` : mes;
    let fechaActual = `${fecha_format.getFullYear()}-${mes_correcto}-${fecha_format.getDate()}`;

    const [IsOpenEstudio, openEstudio, CloseEstudio] = UseModal(false);
    const [IsOpeNotaDetalle, OpenNotaDetalle, CloseNotaDetalle] = UseModal(false);
    const [IsOpenCostoConsulta, OpenCostoConsulta, ClosedCostoConsulta] = UseModal(false);
    const [IsOpenResumenMedico, OpenResumenMedico, ClosedResumenMedico] = UseModal(false);
    const [isOpenConfig, OpenConfig, CloseConfig] = UseModal(false);
    const [IsOpenAlertConfirm, OpenAlertConfirm, ClosedAlertConfirm] = UseModal(false);
    const [IsOpenModalContactos, OpenModalContactos, ClosedModalContactos] = UseModal(false);
    const [IsOpenAlertFinConsulta, OpenAlertFinConsulta, ClosedAlertFinConsulta] = UseModal(false);
    const [isOpenModalRecetaDetalle, OpenModalRecetaDetalle, CloseModalRecetaDetalle] = UseModal(false);

    const [EstatusModalSalirConsulta, setEstatusModalSalirConsulta] = useState(false);

    const [DatosContacto, setDatosContacto] = useState(!!location.state ? { id: location.state.id, nombre: location.state.nombre, imagen: location.state.imagen } : { id: "", nombre: "", imagen: "" });
    const [DatosMedico, setDatosMedico] = useState({ id: "", nombre: "", imagen: "" });

    const [Medicamentos, setMedicamentos] = useState([]);
    const [MedicamentosFilter, setMedicamentosFilter] = useState([]);
    const [MedcSelection, setMedcSelection] = useState({});
    const [MedcSelectionDos, setMedcSelectionDos] = useState({});

    const [MedicosList, setMedicosList] = useState([]);
    const [MedicosListFilter, setMedicosListFilter] = useState([]);

    const [EstadosList, setEstadosList] = useState([]);
    const [LocalidadesList, setLocalidadesList] = useState([]);

    // Estados para visualizaciones
    const [Consulta, setConsulta] = useState(false);
    const [opcionModal, setopcionModal] = useState(0);

    // VARIABLES HISTORIA CLINICA
    const [TipoSangre, setTipoSangre] = useState("");
    const [Alergias, setAlergias] = useState("");
    const [Refiere, setRefiere] = useState("");
    const [ObservacionesPaciente, setObservacionesPaciente] = useState("");
    const [EstadoCivil, setEstadoCivil] = useState(null);
    const [Ocupacion, setOcupacion] = useState("");
    const [Sexo, setSexo] = useState(null);
    const [Nacimiento, setNacimiento] = useState("");
    const [Edad, setEdad] = useState(0);
    const [Nacionalidad, setNacionalidad] = useState("");
    const [Estado, setEstado] = useState(null);
    const [Localidad, setLocalidad] = useState(null);
    const [CodigoPostal, setCodigoPostal] = useState("");
    const [NombrePareja, setNombrePareja] = useState("");
    const [EdadPareja, setEdadPareja] = useState("");
    const [OcupacionPareja, setOcupacionPareja] = useState("");
    const [NotasPareja, setNotasPareja] = useState("");
    const [AHeredoFamiliares, setAHeredoFamiliares] = useState("");
    const [APersonalesPatologicos, setAPersonalesPatologicos] = useState("");
    const [APersonalesNoPatologicos, setAPersonalesNoPatologicos] = useState("");
    const [AAndroGineGeneral, setAAndroGineGeneral] = useState("");
    const [AAndroGine, setAAndroGine] = useState("");
    const [Embarazada, setEmbarazada] = useState(0);
    const [SignosSintomas, setSignosSintomas] = useState("");
    const [AparatoCardiovascular, setAparatoCardiovascular] = useState("");
    const [AparatoRespiratorio, setAparatoRespiratorio] = useState("");
    const [AparatoDigestivo, setAparatoDigestivo] = useState("");
    const [SistemaNefrourologico, setSistemaNefrourologico] = useState("");
    const [SistemaEndocrino, setSistemaEndocrino] = useState("");
    const [SistemaHematoproyetico, setSistemaHematoproyetico] = useState("");
    const [SistemaNervioso, setSistemaNervioso] = useState("");
    const [MusculoEsqueletico, setMusculoEsqueletico] = useState("");
    const [PielTegumentos, setPielTegumentos] = useState("");
    const [OrganosSentidos, setOrganosSentidos] = useState("");
    const [EsferaPsiquica, setEsferaPsiquica] = useState("");
    const [Genitales, setGenitales] = useState("");
    const [NotasEvolucion, setNotasEvolucion] = useState([]);
    const [EstudiosAnalisis, setEstudiosAnalisis] = useState([]);
    const [Recetas, setRecetas] = useState([]);

    // #### INICIO DE VARIABLES CONSULTA/NOTA EVOLUCION ####

    const [CostoConsulta, setCostoConsulta] = useState(null);
    const [ConsultaSinCargo, setConsultaSinCargo] = useState(false);
    const [TipoConsulta, setTipoConsulta] = useState(!!location.state ? location.state.tipoConsulta : "");

    const [FechaConsulta, setFechaConsulta] = useState(fechaActual);
    // MOTIVO CONSULTA
    const [MotivoConsulta, setMotivoConsulta] = useState(!!location.state ? location.state.motivo : "");
    // EVOLUCION DEL PADECIMIENTO
    const [EvolucionPadecimiento, setEvolucionPadecimiento] = useState("");
    // EXPLORACION FISICA
    const [TArterial, setTArterial] = useState("");
    const [FCardiaca, setFCardiaca] = useState("");
    const [FRespiratoria, setFRespiratoria] = useState("");
    const [Temperatura, setTemperatura] = useState("");
    const [Altura, setAltura] = useState("");
    const [Peso, setPeso] = useState("");
    const [IMC, setIMC] = useState("");
    const [Talla, setTalla] = useState("");
    const [SOxigeno, setSOxigeno] = useState("");
    const [ExploracionFisica, setExploracionFisica] = useState("");
    // OBSERVACIONES
    const [Observaciones, setObservaciones] = useState("");
    // DIAGNOSTICO CLINICO
    const [ListaDiagnosticos, setListaDiagnosticos] = useState([]);
    const [DiagnosticosSeleccionados, setDiagnosticosSeleccionados] = useState([]);
    const [Diagnostico, setDiagnostico] = useState(null);
    const [DiagnosticosSeleccionadosMostrados, setDiagnosticosSeleccionadosMostrados] = useState([]);
    const [OtrosDiagnosticos, setOtrosDiagnosticos] = useState("");
    // PLAN
    const [Plan, setPlan] = useState("");
    // RECETA
    const [ListaRecetas, setListaRecetas] = useState([]);

    const [Cedula, setCedula] = useState(cedulas.length > 0 ? cedulas[0].value : "");
    const [Cantidad, setCantidad] = useState(1);
    const [Medicamento, setMedicamento] = useState("");
    const [MedicamentoId, setMedicamentoId] = useState("");
    const [Dosis, setDosis] = useState("");
    const [Indicaciones, setIndicaciones] = useState("");
    const [ListaMedicamentos, setListaMedicamentos] = useState([]);

    // ESTUDIOS Y ANALISIS
    const [EnviarLabTest, setEnviarLabTest] = useState(true);
    const [DescripcionLabTest, setDescripcionLabTest] = useState("");
    const [ListaArchivos, setListaArchivos] = useState([]);

    // ORDEN DE ESTUDIOS DE LABORATORIO
    const [ListaOrdenLabs, setListaOrdenLabs] = useState([]);
    const [OrdenLabTest, setOrdenLabTest] = useState("");

    // INTERCONSULTA
    const [ListaOrdenInter, setListaOrdenInter] = useState([]);
    const [InterconsultaAdjunta, setInterconsultaAdjunta] = useState(0);
    const [DoctorSelect, setDoctorSelect] = useState(null);
    const [CheckNuevo, setCheckNuevo] = useState(false);
    const [NombreMedico, setNombreMedico] = useState("");
    const [ApellidoPMedico, setApellidoPMedico] = useState("");
    const [ApellidoMMedico, setApellidoMMedico] = useState("");
    const [CelularMedico, setCelularMedico] = useState("");
    const [EmailMedico, setEmailMedico] = useState("");
    const [EspecialidadMedico, setEspecialidadMedico] = useState("");
    const [MotivoInterconsulta, setMotivoInterconsulta] = useState("");
    const [DiagnosticoInterconsulta, setDiagnosticoInterconsulta] = useState("");
    const [TratamientoInterconsulta, setTratamientoInterconsulta] = useState("");

    // RECOMENDACIONES
    const [Recomendaciones, setRecomendaciones] = useState("");

    // #### FIN DE VARIABLES CONSULTA/NOTA DE EVOLUCION ####

    const [DetalleConsulta, setDetalleConsulta] = useState("");
    const [DetalleEstudio, setDetalleEstudio] = useState("");
    const [DetailRecetas, setDetailRecetas] = useState("");

    const [EstatusModalEnviarReceta, setEstatusModalEnviarReceta] =
        useState(false);

    const [RowsReceta, setRowsReceta] = useState([]);

    const [ResumenMedico, setResumenMedico] = useState("");

    const [CierreModalResumen, setCierreModalResumen] = useState(false);

    const [CedulasMedico, setCedulasMedico] = useState(cedulas.length > 0 ? cedulas : []);

    const [PalabraClave, setPalabraClave] = useState("");

    const [InputDisabled, setInputDisabled] = useState(true);

    const [DisabledInputs, setDisabledInputs] = useState(true);

    const [loader, setLoader] = useState(false);
    const [loaderSearch, setLoaderSearch] = useState(false);
    const [loaderInterconsulta, setLoaderInterconsulta] = useState(false);
    const [loaderResumenMedico, setLoaderResumenMedico] = useState(false);
    const [loaderReceta, setLoaderReceta] = useState(false);
    const [loaderOrdenLabTest, setLoaderOrdenLabTest] = useState(false);
    const [LoaderModal, setLoaderModal] = useState(false);
    const [loaderRecetaDetalle, setLoaderRecetaDetalle] = useState(false);
    const [loaderConsultaDetalle, setLoaderConsultaDetalle] = useState(false);

    const [ConfigReceta, setConfigReceta] = useState("");
    // Formulario configuracion
    const [Logo, setLogo] = useState({ name: "", url: "", file: "" });
    const [LogoDos, setLogoDos] = useState({ name: "", url: "", file: "" });
    const [Firma, setFirma] = useState({ name: "", url: "", file: "", size: 0, formato: "" });
    const [Encabezado, setEncabezado] = useState("");
    const [PiePagina, setPiePagina] = useState("");
    const [FormatoReceta, setFormatoReceta] = useState("");

    const [Link, setLink] = useState("");

    const [LocationValue, setLocationValue] = useState({ id: "", imagen: "", nombre: "", motivo: "", tipoConsulta: "", idCita: "" });

    const [AcCionResumen, setAccionResumen] = useState(0);

    const [IncludeFirma, setIncludeFirma] = useState(true);

    const [ExpFisicaCheck, setExpFisicaCheck] = useState(false);

    // MANEJA DE MENSAJES
    const [open, setOpen] = useState(false);
    const [openMensajeConfig, setOpenMensajeConfig] = useState(false);
    const [typeMessage, setTypeMessage] = useState("");
    const [Message, setMessage] = useState("");

    const [inputPrueba, setinputPrueba] = useState("");

    // MENSAJES INFORMACION GENERAL
    const [MensajeNacimiento, setMensajeNacimiento] = useState("true");
    const [MensajeSexo, setMensajeSexo] = useState("true");
    const [MensajeTipoSangre, setMensajeTipoSangre] = useState("true");
    // Estatus mensajes DATOS CONSULTA
    const [MensajeMotivoConsulta, setMensajeMotivoConsulta] = useState("true");
    // MENSAJE EXPLORACION FISICA
    const [MensajeTArterial, setMensajeTArterial] = useState("true");
    const [MensajeFCardiaca, setMensajeFCardiaca] = useState("true");
    const [MensajeFRespiratoria, setMensajeFRespiratoria] = useState("true");
    const [MensajeTemperatura, setMensajeTemperatura] = useState("true");
    const [MensajeAltura, setMensajeAltura] = useState("true");
    const [MensajePeso, setMensajePeso] = useState("true");
    const [MensajeIMC, setMensajeIMC] = useState("true");
    const [MensajeTalla, setMensajeTalla] = useState("true");
    const [MensajeSOxigeno, setMensajeSOxigeno] = useState("true");
    const [MensajeExpFisica, setMensajeExpFisica] = useState("true");
    // MESNAJE DIAGNOSTICO
    const [MensajeDiagnosticoCIIE, setMensajeDiagnosticoCIIE] = useState("true");
    const [MensajeOtrosDiagnosticos, setMensajeOtrosDiagnosticos] = useState("true");
    // ERRORES EN ACCORDION
    const [ErrorExploFisica, setErrorExploFisica] = useState(true);
    const [ErrorPlanAccordion, setErrorPlanAccordion] = useState(true);
    const [ErrorDiagnosticoAccordion, setErrorDiagnosticoAccordion] = useState(true);
    // MENSAJE PLAN
    const [MensajePlan, setMensajePlan] = useState("true");
    // MENSAJE RECETA
    const [MensajeCedula, setMensajeCedula] = useState("true");
    const [MensajeMedicamento, setMensajeMedicamento] = useState("true");
    const [MensajeDosis, setMensajeDosis] = useState("true");
    const [MensajeListaMedicamentos, setMensajeListaMedicamentos] = useState("true");
    const [MensajeRecetaNoAgregada, setMensajeRecetaNoAgregada] = useState("true");

    // MENSAJE DIAGNOSTICO
    const [MensajePalabraClave, setMensajePalabraClave] = useState("true");

    // MENSAJES INTERCONSULTA
    const [MensajeDoctorSelect, setMensajeDoctorSelect] = useState("true");
    const [MensajeNombreMedico, setMensajeNombreMedico] = useState("true");
    const [MensajeApellidoPMedico, setMensajeApellidoPMedico] = useState("true");
    const [MensajeEspecialidadMedico, setMensajeEspecialidadMedico] = useState("true");
    const [MensajeMotivoInterconsulta, setMensajeMotivoInterconsulta] = useState("true");
    const [MensajeDiagnosticoInterconsulta, setMensajeDiagnosticoInterconsulta] = useState("true");
    const [MensajeOrdenInterNoAgregada, setMensajeOrdenInterNoAgregada] = useState("true");

    const [MensajeOrdenLabTest, setMensajeOrdenLabTest] = useState("true");
    const [MensajeOrdenLabNoAgregada, setMensajeOrdenLabNoAgregada] = useState("true");


    const [MensajeResumenMedico, setMensajeResumenMedico] = useState("true");

    // Mensajes form CONFIG RECETA
    const [MensajeLogo, setMensajeLogo] = useState("true");
    const [MensajeLogoDos, setMensajeLogoDos] = useState("true");
    const [MensajeEncabezado, setMensajeEncabezado] = useState("true");
    const [MensajePiePagina, setMensajePiePagina] = useState("true");
    const [MensajeFormato, setMensajeFormato] = useState("true");
    const [MensajeFirma, setMensajeFirma] = useState("true");

    // ##### Acordiones de detalle de consulta #####

    const [expandedConsulta, setExpandedConsulta] = useState(false);
    const [expandedReceta, setExpandedReceta] = useState(false);

    const handleChange = (panel, id, accion) => (event, isExpanded) => {
        switch (accion) {
            case 'consulta':
                if (isExpanded) {
                    setExpandedConsulta(panel);
                    detailConsulta(id, panel);
                } else {
                    setExpandedConsulta(false);
                }
                break;
            case 'receta':
                if (isExpanded) {
                    setExpandedReceta(panel);
                    detailReceta(id);
                } else {
                    setExpandedReceta(false);
                }
                break;
        }


        // setExpanded(isExpanded ? panel : false);
    };

    // #############################################

    async function getHistoriaClinica() {
        setLoader(true);
        let response = await getHistoriasClinicas_request(DatosContacto.id);
        if (response.ok) {
            if (document.domain === "localhost") {
                console.log(response.data);
            }
            if (response.data.consultas !== null) {
                setNotasEvolucion(response.data.consultas);
            }
            if (response.data.estudios !== null) {
                let array = [];
                response.data.estudios.forEach(element => {
                    array.push({ id: element.id, descripcion: !!element.descripcion ? element.descripcion : "Sin descripción", fecha: element.fecha })
                });

                const mapa = new Map();
                const arraySinDuplicados = array.filter((obj) => {
                    return !mapa.has(obj.id) && mapa.set(obj.id, 1);
                });
                setEstudiosAnalisis(arraySinDuplicados.slice().sort((a, b) => new Date(`${b.fecha.substring(6, 10)}-${b.fecha.substring(3, 5)}-${b.fecha.substring(0, 2)}`) - new Date(`${a.fecha.substring(6, 10)}-${a.fecha.substring(3, 5)}-${a.fecha.substring(0, 2)}`)))
            }
            if (response.data.recetas !== null) {
                setRecetas(response.data.recetas);
            }

            if (response.data.ficha !== null) {
                let fichaId = response.data.ficha;
                setSexo(fichaId.sexo);

                if (!!fichaId.fechaNacimiento) {
                    let arrayFechaTemp = fichaId.fechaNacimiento.split("-");
                    let fechaTemp = `${arrayFechaTemp[2]}-${arrayFechaTemp[1]}-${arrayFechaTemp[0]}`
                    let fechaTemp_dos = `${arrayFechaTemp[0]}/${arrayFechaTemp[1]}/${arrayFechaTemp[2]}`
                    getEdad(fechaTemp);
                    setNacimiento(fechaTemp_dos);

                }
                if (!!fichaId.fechaNacimiento && !!fichaId.sexo) {
                    setConsulta(true);
                } else {
                    setConsulta(false);
                }
                setNacionalidad(fichaId.nacionalidad);
                if (!!fichaId.estado) {
                    setEstado(fichaId.estado);
                    getLocalidades(fichaId.estado);
                    setLocalidad(fichaId.localidad);
                }
                setCodigoPostal(fichaId.cp);
            }
            if (response.data.historial !== null) {
                let historial = response.data.historial;
                setTipoSangre(historial.tipoSangre);
                setAlergias(historial.alergias);
                setRefiere(historial.refiere);
                setObservacionesPaciente(historial.observaciones);
                setAHeredoFamiliares(historial.aHereditarios);
                setAPersonalesPatologicos(historial.aPatologicos);
                setAPersonalesNoPatologicos(historial.aNoPatologicos);
                setAAndroGineGeneral(historial.aBiologicoGeneral);
                setAAndroGine(historial.aBiologicoEspecifico);
                setSignosSintomas(historial.sGenerales);
                setAparatoCardiovascular(historial.aCardiovascular);
                setAparatoRespiratorio(historial.aRespiratorio);
                setAparatoDigestivo(historial.aDigestivo);
                setSistemaNefrourologico(historial.sNefrourologico);
                setSistemaEndocrino(historial.sEndocrino);
                setSistemaHematoproyetico(historial.sHematopoyetico);
                setSistemaNervioso(historial.sNervioso);
                setMusculoEsqueletico(historial.sMusculo);
                setPielTegumentos(historial.piel);
                setOrganosSentidos(historial.oSentidos);
                setEsferaPsiquica(historial.ePsiquica);
                setGenitales(historial.sGenitales);

                setTArterial(historial.tArterial !== "N/A" ? historial.tArterial : "");
                setFCardiaca(historial.fCardiaca !== "N/A" ? historial.fCardiaca : "");
                setFRespiratoria(historial.fRespiratoria !== "N/A" ? historial.fRespiratoria : "");
                setTemperatura(historial.temperatura !== "N/A" ? historial.temperatura : "");
                setPeso(historial.peso !== "N/A" ? historial.peso : "");
                setEstadoCivil(historial.estadoCivil !== null && historial.estadoCivil !== undefined ? historial.estadoCivil.toString() : null);
                setOcupacion(historial.ocupacion);
                setNombrePareja(historial.nombrePareja);
                setEdadPareja(historial.edadPareja);
                setOcupacionPareja(historial.ocupacionPareja);
                setNotasPareja(historial.nota);
                if (!!historial.peso && !!historial.altura && historial.peso !== "N/A" && historial.altura !== "N/A") {
                    let peso_temp = historial.peso;
                    let altura_temp = historial.altura;
                    let imc = peso_temp / (altura_temp * altura_temp);
                    setIMC(imc.toFixed(2));
                }
                setSOxigeno(historial.sOxigeno !== "N/A" ? historial.sOxigeno : "")

                setAltura(!!historial.altura && historial.altura !== "N/A" ? historial.altura : "");
            }

        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
        setLoader(false);
        return;
    }

    async function detailConsulta(idConsulta) {
        setLoaderConsultaDetalle(true);
        let response = await detailConsultas_request(idConsulta);
        if (response.ok) {
            setDetalleConsulta(response.data);
            if (!!response.data.Consulta.Peso && !!response.data.Consulta.Altura) {
                let peso_temp = response.data.Consulta.Peso;
                let altura_temp = response.data.Consulta.Altura;
                let imc = peso_temp / (altura_temp * altura_temp);
                setIMC(imc.toFixed(2));
            }
            setDatosContacto({ id: response.data.Consulta.IdContacto, nombre: response.data.Consulta.Contacto, imagen: response.data.Consulta.Imagen_Url });
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
        setLoaderConsultaDetalle(false);
    }

    async function newAddConsultaFormData() {
        setLoader(true);
        let data = new FormData();
        if (!!MotivoConsulta && DiagnosticosSeleccionadosMostrados.length > 0 && !!Plan) {
            let diagnosticos_temp = []
            if (DiagnosticosSeleccionadosMostrados.length > 0) {
                DiagnosticosSeleccionadosMostrados.forEach(element => {
                    diagnosticos_temp.push(element.diagnostico);
                });
            }

            if (ListaRecetas.length > 0) {
                ListaRecetas.forEach((receta, index) => {
                    data.append(`Receta[${index}][TipoReceta]`, receta.TipoReceta);
                    data.append(`Receta[${index}][Cedula]`, receta.Cedula);
                    receta.Concepto.forEach((concepto, ind) => {
                        // id
                        // Concepto
                        // Cantidad
                        // Descripcion
                        data.append(`Receta[${index}][Concepto][${ind}][id]`, concepto.id);
                        data.append(`Receta[${index}][Concepto][${ind}][Concepto]`, concepto.Concepto);
                        data.append(`Receta[${index}][Concepto][${ind}][Cantidad]`, concepto.Cantidad);
                        data.append(`Receta[${index}][Concepto][${ind}][Descripcion]`, concepto.Descripcion);
                    });
                    data.append(`Receta[${index}][Firma]`, document.getElementById(`check_firma_${receta.Index}`).checked ? 0 : 1);
                    data.append(`Receta[${index}][Enviar]`, document.getElementById(`check_enviarReceta_${receta.Index}`).checked ? 0 : 1);
                    data.append(`Receta[${index}][MostrarDiagnostico]`, document.getElementById(`check_${receta.Index}`).checked ? 1 : 0);
                    data.append(`Receta[${index}][Diagnostico]`, diagnosticos_temp.toString());
                    data.append(`Receta[${index}][MostrarIndicaciones]`, document.getElementById(`check_${receta.Index}`).checked ? 1 : 0);
                    data.append(`Receta[${index}][Indicaciones]`, receta.Indicaciones);
                });
            }

            if (ListaArchivos.length > 0) {
                ListaArchivos.forEach((archivo, index) => {
                    data.append(`Archivo[${index}]`, archivo.file, archivo.name)
                });
            }

            let combineOrdenes = ListaOrdenInter.concat(ListaOrdenLabs);

            if (combineOrdenes.length > 0) {
                combineOrdenes.forEach((element, index) => {

                    data.append(`Order[${index}][tipoOrden]`, element.tipoOrden);

                    if (element.idContactoM !== undefined) {
                        data.append(`Order[${index}][idContactoM]`, element.idContactoM);
                        element.idContactoM.forEach((element, ind) => {
                            // id
                            // nombre
                            // apellidoPaterno
                            // apellidoMaterno
                            // celular
                            // email
                            // especialidad
                            data.append(`Order[${index}][idContactoM][${ind}][id]`, element.id);
                            data.append(`Order[${index}][idContactoM][${ind}][nombre]`, element.nombre);
                            data.append(`Order[${index}][idContactoM][${ind}][apellidoPaterno]`, element.apellidoPaterno);
                            data.append(`Order[${index}][idContactoM][${ind}][apellidoMaterno]`, element.apellidoMaterno);
                            data.append(`Order[${index}][idContactoM][${ind}][celular]`, element.celular);
                            data.append(`Order[${index}][idContactoM][${ind}][email]`, element.email);
                            data.append(`Order[${index}][idContactoM][${ind}][especialidad]`, element.especialidad);
                        });
                    }
                    if (element.mOrden !== undefined) {
                        data.append(`Order[${index}][mOrden]`, element.mOrden);
                    }
                    if (element.diagnostico !== undefined) {
                        data.append(`Order[${index}][diagnostico]`, element.diagnostico);
                    }
                    if (element.tratamiento !== undefined) {
                        data.append(`Order[${index}][tratamiento]`, element.tratamiento);
                    }
                    if (element.descripcion !== undefined) {
                        data.append(`Order[${index}][descripcion]`, element.descripcion);

                    }
                });
            }

            let estado = "";
            EstadosList.forEach(element => {
                if (element.id === Estado) {
                    estado = element.Estado;
                }
            });

            data.append("TipoConsulta", "1");
            data.append("IdUsuario", DatosContacto.id);
            data.append("fecha", FechaConsulta);
            data.append("Motivo", MotivoConsulta);
            data.append("Evolucion", EvolucionPadecimiento);
            data.append("Peso", ExpFisicaCheck ? "" : Peso);
            data.append("Altura", ExpFisicaCheck ? "" : Altura);
            data.append("Talla", Talla);
            data.append("TensionArterial", ExpFisicaCheck ? "" : TArterial);
            data.append("FrecuenciaCardiaca", ExpFisicaCheck ? "" : FCardiaca);
            data.append("FrecuenciaRespiratoria", ExpFisicaCheck ? "" : FRespiratoria);
            data.append("SaturacionOxigeno", ExpFisicaCheck ? "" : SOxigeno);
            data.append("Temperatura", ExpFisicaCheck ? "" : Temperatura);
            data.append("ExploracionFisica", ExpFisicaCheck ? "" : ExploracionFisica);
            data.append("Diagnostico", diagnosticos_temp.toString());
            data.append("Recomendaciones", Recomendaciones);
            data.append("Cedula", Cedula);
            data.append("Costo", CostoConsulta);
            data.append("Status", TipoConsulta);
            data.append("DescripcionEstudio", DescripcionLabTest);
            data.append("AntecedentesHereditarios", AHeredoFamiliares);
            data.append("AntecedentesPatologicos", APersonalesPatologicos);
            data.append("AntecedentesNoPatologicos", APersonalesNoPatologicos);
            data.append("SintomasGenerales", SignosSintomas);
            data.append("AparatoRespiratorio", AparatoRespiratorio);
            data.append("AparatoDigestivo", AparatoDigestivo);
            data.append("AparatoCardiovascular", AparatoCardiovascular);
            data.append("SistemaNefrourologico", SistemaNefrourologico);
            data.append("SistemaEndocrino", SistemaEndocrino);
            data.append("SistemaHematopoyetico", SistemaHematoproyetico);
            data.append("SistemaNervioso", SistemaNervioso);
            data.append("SistemaMusculo", MusculoEsqueletico);
            data.append("Piel", PielTegumentos);
            data.append("OrganosSentidos", OrganosSentidos);
            data.append("EsferaPsiquica", EsferaPsiquica);
            data.append("Alergias", Alergias);
            data.append("Observaciones", ObservacionesPaciente);
            data.append("Refiere", Refiere);
            data.append("Sexo", Sexo);
            data.append("Nacimiento", Nacimiento);
            data.append("nacionalidad", Nacionalidad);
            data.append("estado", estado);
            data.append("localidad", Localidad);
            data.append("cp", CodigoPostal);
            data.append("Plan", Plan);
            data.append("ResumenClinico", ResumenMedico);
            data.append("embarazo", Embarazada);
            data.append("aBiologicoGeneral", AAndroGineGeneral);
            data.append("aBiologicoEspecifico", AAndroGine);
            data.append("sGenitales", Genitales);
            data.append("tipoSangre", TipoSangre);
            data.append("IdCita", LocationValue.idCita);
            data.append("ocupacion", Ocupacion);
            data.append("estadoCivil", EstadoCivil);
            data.append("nombrePareja", NombrePareja);
            data.append("edadPareja", EdadPareja);
            data.append("ocupacionPareja", OcupacionPareja);
            data.append("nota", NotasPareja);
            data.append("Enviar", EnviarLabTest ? 1 : 0);

            let response = await addConsultaFormData_request(data);
            if (response.ok) {
                ReactGA.event({
                    category: "Consultas",
                    action: "Finalizar_consulta",
                    label: "Consulta finalizada con exito", // optional
                    value: 99, // optional, must be a number
                    nonInteraction: true, // optional, true/false
                    transport: "xhr", // optional, beacon/xhr/image
                });
                setOpen(true);
                setTypeMessage("success");
                setMessage("Consulta finalizada");
                window.onbeforeunload = null;
                if (!!Link) {
                    navigate(Link, { replace: true });
                } else {
                    navigate("/app/consultas", { replace: true });
                }

            } else {
                ReactGA.event({
                    category: "Consultas",
                    action: "Finalizar consulta erronea",
                    label: `${response.mensaje}`, // optional
                    value: 99, // optional, must be a number
                    nonInteraction: true, // optional, true/false
                    transport: "xhr", // optional, beacon/xhr/image
                });
                setOpen(true);
                setTypeMessage("error");
                setMessage(response.mensaje);
            }
        } else {
            if (!!!MotivoConsulta) {
                setMensajeMotivoConsulta("false");
            }
            if (DiagnosticosSeleccionadosMostrados.length === 0) {
                setErrorDiagnosticoAccordion(false);
            }
            if (!!!Plan) {
                setErrorPlanAccordion(false);
            }

            setOpen(true);
            setTypeMessage("warning");
            setMessage("Faltan campos por completar");
        }
        setLoader(false);
        return;
    }

    async function vistaPreviaResumenMedico() {
        setLoaderResumenMedico(true);
        let data = new FormData();
        data.append("resumen", ResumenMedico);
        data.append("Cedula", Cedula);
        data.append("IdUsuario", DatosContacto.id);
        data.append("Sexo", Sexo);
        data.append("Nacimiento", Nacimiento);
        if (!!ResumenMedico) {
            let response = await previewResumenMedico_request(data);
            if (response.ok) {
                window.open(response.data.resumen, "_blank");
            } else {
                setOpen(true);
                setTypeMessage("error");
                setMessage(response.mensaje);
            }
        } else {
            setMensajeResumenMedico("false");
            setOpen(true);
            setTypeMessage("warning");
            setMessage("Faltan campos por completar");
        }
        setLoaderResumenMedico(false);
        return;
    }

    async function getEstados() {
        let response = await getEstado_request();
        if (response.ok) {
            let estados_temp = []
            response.data.forEach(estado => {
                estados_temp.push({ value: estado.id.toString(), label: estado.Estado });
            });
            setEstadosList(estados_temp);
        } else {
            setEstadosList([]);
        }
    }

    async function getLocalidades(estado) {
        let response = await getLocalidad_request(estado);
        if (response.ok) {
            let localidades_temp = []
            response.data.forEach(estado => {
                localidades_temp.push({ value: estado, label: estado });
            });
            setLocalidadesList(localidades_temp);
        } else {
            setLocalidadesList([]);
        }
    }

    async function detailEstudios(id) {
        setLoaderModal(true);
        let response = await getDetailEstudios_request(id);
        if (response.ok) {
            setDetalleEstudio(response.data);
            openEstudio(true);

        } else {
            setDetalleEstudio("");
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
        setLoaderModal(false);
    }

    async function detailReceta(id) {
        setLoaderRecetaDetalle(true);
        let response = await getDetailRecetas_request(id);
        if (response.ok) {
            // console.log(response.data);
            setDetailRecetas(response.data);
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
        setLoaderRecetaDetalle(false);
        return;
    }

    async function enviarReceta(idReceta) {
        // console.log(idReceta);
        setLoaderRecetaDetalle(true);
        let response = await sendRecetas_request(idReceta);
        if (response.ok) {
            setOpen(true);
            setTypeMessage("success");
            setMessage("Receta enviada");
            detailReceta(DetailRecetas.Receta.IdReceta);
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
        setLoaderRecetaDetalle(false);
    }

    async function getCIIEDiagnosticos() {
        setLoaderSearch(true);
        if (!!PalabraClave) {
            let response = await getCIIEDiagnosticos_request(PalabraClave);
            if (response.ok) {
                let list = [];
                if (!!response.data[0]) {
                    response.data.forEach((diagnostico, index) => {
                        list.push({ key: index, value: diagnostico, label: diagnostico })
                    });
                    setListaDiagnosticos(list);
                } else {
                    setListaDiagnosticos([]);
                    setOpen(true);
                    setTypeMessage("warning");
                    setMessage("Sin resultados");
                }
            } else {
                setOpen(true);
                setTypeMessage("error");
                setMessage(response.mensaje);
            }
        } else {

        }
        setLoaderSearch(false);
        return;
    }

    async function getMedicamentos() {
        let response = await getMedicamentos_request();
        if (response.ok) {
            // console.log(response.data);
            let medi_temp = [];
            response.data.forEach(medi => {
                medi_temp.push({ Numero: medi.id, Opcion: `${medi.nombreActivo} - ${medi.id}` })
            });
            // console.log(medi_temp);
            setMedicamentos(medi_temp);
            setMedicamentosFilter(medi_temp);
        } else {

        }
    }

    async function getMedicamentosNuevo() {
        let response = await getMedicamentos_request();
        if (response.ok) {
            let medi_temp = [];
            response.data.forEach(medi => {
                medi_temp.push({ value: medi.id, label: `${medi.nombreActivo}` })
            });
            setMedicamentos(medi_temp);
            setMedicamentosFilter(medi_temp);
        } else {

        }
    }

    async function vistaPreviaInter(orden) {
        setLoaderInterconsulta(true);
        let data = new FormData();
        data.append("mOrden", orden.mOrden);
        data.append("diagnostico", orden.diagnostico);
        data.append("Cedula", Cedula);
        data.append("IdUsuario", DatosContacto.id);
        data.append("Sexo", Sexo);
        data.append("Nacimiento", Nacimiento);
        let response = await vistaPreviaInterconsulta_request(data);
        if (response.ok) {
            window.open(response.data.orden, "_blank");
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
        setLoaderInterconsulta(false);
        return;
    }

    async function vistaPreviaReceta(receta, checkDiagnosticos, checkFirma, checkIndicaciones) {
        setLoaderReceta(true);

        let diagnosticos_temp = []
        if (DiagnosticosSeleccionadosMostrados.length > 0) {
            DiagnosticosSeleccionadosMostrados.forEach(element => {
                diagnosticos_temp.push(element.diagnostico);
            });
        }


        let data = {
            "Concepto": JSON.stringify(receta.Concepto),
            "Cedula": receta.Cedula,
            "IdUsuario": DatosContacto.id,
            "MostrarDiagnostico": checkDiagnosticos,
            "diagnostico": checkDiagnosticos ? diagnosticos_temp.toString() : "",
            "Firma": checkFirma ? 0 : 1,
            "MostrarIndicaciones": checkIndicaciones,
            "Indicaciones": Recomendaciones
        };

        let response = await vistaPreviaReceta_request(data);
        if (response.ok) {
            window.open(response.data.receta, "_blank");
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
        setLoaderReceta(false);
        return;
    }

    async function getContactos() {
        let response = await getContacts_request();
        if (response.ok) {
            let array = [];
            let medicos = [
                {
                    label: "Contactos",
                    options: []
                }
            ];
            let medicos_filter = [];
            response.data.forEach((element) => {
                if (element.Type === 1) {
                    medicos_filter.push(element);
                }
            });
            setMedicosList(medicos_filter);
            setMedicosListFilter(medicos_filter);
        } else {
        }
    }

    async function vistaPreviaOrdenDeEstudios(orden) {
        setLoaderOrdenLabTest(true);
        let data = new FormData();
        data.append("descripcion", orden.descripcion);
        data.append("Cedula", Cedula);
        data.append("IdUsuario", DatosContacto.id);
        data.append("Sexo", Sexo);
        data.append("Nacimiento", Nacimiento);
        let response = await vistaPreviaOrdenEstudios_request(data);
        if (response.ok) {
            window.open(response.data.ordenEstudio, "_blank");
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
        setLoaderOrdenLabTest(false);
        return;
    }

    async function configReceta() {
        setLoaderModal(true);
        let response = await getConfigRecetas_request();
        if (response.ok) {
            setConfigReceta(response.data);
            let logo = response.data.Logo;
            let logoDos = response.data.Logo2;
            let firma = response.data.FirmaMedico;

            if (!!logo) {
                setLogo({ name: "Logo 1", url: "https://" + logo, file: "", size: 0, formato: logo.substring(logo.lastIndexOf(".") + 1, logo.length) });
            }
            if (!!logoDos) {
                setLogoDos({ name: "Logo 2", url: "https://" + logoDos, file: "", size: 0, formato: logoDos.substring(logoDos.lastIndexOf(".") + 1, logoDos.length) });
            }
            if (!!firma) {
                setFirma({ name: "Firma", url: "https://" + firma, file: "", size: 0, formato: firma.substring(firma.lastIndexOf(".") + 1, firma.length) });
            }

            setEncabezado(response.data.Subtitulo);
            setPiePagina(response.data.Anexo);
        } else {
        }
        setLoaderModal(false);
        return;
    }

    async function saveConfig() {
        setLoaderModal(true);
        if (
            !!Encabezado &&
            !!PiePagina
        ) {
            let data = new FormData();
            if (Logo.file !== "") {
                data.append("Logo", Logo.file, Logo.name);
            }
            if (LogoDos.file !== "") {
                data.append("Logo2", LogoDos.file, LogoDos.name);
            }
            if (Firma.file !== "") {
                data.append("Firma", Firma.file, Firma.name);
            }
            data.append("Subtitulo", Encabezado);
            data.append("Anexo", PiePagina);
            data.append("Formato", "1");
            let response = await addConfigReceta_request(data);
            if (response.ok) {
                setOpen(true);
                setTypeMessage("success");
                setMessage("Configuración guardada");
                CloseConfig(true);
                configReceta();
                // cleanInputsConfig();
            } else {
                setOpen(true);
                setTypeMessage("error");
                setMessage(response.mensaje);
            }
        } else {
            // if (!!!Logo.url) {
            // console.log("Logo 1");
            //     setMensajeLogo("false");
            // }
            // if (!!!LogoDos.url) {
            // console.log("Logo 2");
            //     setMensajeLogoDos("false");
            // }
            // if (!!!Firma.url) {
            // console.log("Firma");
            //     setMensajeFirma("false");
            // }
            if (!!!Encabezado) {
                // console.log("Encabezado");
                setMensajeEncabezado("false");
            }
            if (!!!PiePagina) {
                // console.log("Pie pagina");
                setMensajePiePagina("false");
            }
            setOpen(true);
            setTypeMessage("warning");
            setMessage("Faltan campos por completar");
        }
        setLoaderModal(false);
        return;
    }

    async function addResumenMedico() {
        let data = new FormData();
        data.append("resumen", ResumenMedico);
        data.append("IdUsuario", DatosContacto.id);
        let response = await addResumenMedico_request(data);
        if (response.ok) {
            setOpen(true);
            setTypeMessage("success");
            setMessage("Resumen médico creado");
            cleanInputsDetalleConsulta();
            ClosedResumenMedico(true);
            window.open(response.data, "_blank");
        } else {
            setOpen(true);
            setTypeMessage("error");
            setMessage(response.mensaje);
        }
    }

    function cleanInputsDetalleConsulta() {
        setDetalleConsulta("");
    }

    // RECETA-MEDICAMENTOS
    function addMedicamento() {

        if (!!Medicamento && !!Dosis) {
            let array = ListaMedicamentos;
            if (array.length > 0) {
                array.push({
                    index: ListaMedicamentos[ListaMedicamentos.length - 1].index + 1,
                    Id: MedicamentoId,
                    Concepto: Medicamento,
                    Cantidad: Cantidad,
                    Descripcion: Dosis
                });
            } else {
                array.push({
                    index: 1,
                    Id: MedicamentoId,
                    Concepto: Medicamento,
                    Cantidad: Cantidad,
                    Descripcion: Dosis
                });
            }

            setListaMedicamentos(array);

            setMedicamento("");
            setMedicamentoId("");
            setCantidad(1);
            setDosis("");
            setMensajeListaMedicamentos("true");
        } else {
            if (!!!Medicamento) {
                setMensajeMedicamento("false");
            }
            if (!!!Dosis) {
                setMensajeDosis("false");
            }
            setOpen(true);
            setTypeMessage("warning");
            setMessage("Faltan campos por completar");

        }

        return;
    }

    function eliminarMedicamento(posicion) {
        let array = ListaMedicamentos;

        let arrayDos = [];

        let arrayTres = [];

        arrayDos = array.filter((element) => { return element.index !== posicion; });
        // console.log(arrayDos);
        arrayDos.forEach((element, index) => {
            arrayTres.push({
                index: index + 1,
                Concepto: element.Concepto,
                Cantidad: element.Cantidad,
                Descripcion: element.Descripcion
            });
        });

        setListaMedicamentos(arrayTres);
        // console.log(arrayTres);
    }

    // LISTA DE RECETAS
    function addReceta() {


        if (!!Cedula && ListaMedicamentos.length > 0) {

            let array = ListaRecetas;
            let medis = [];
            if (array.length > 0) {

                ListaMedicamentos.forEach(medicamento => {
                    medis.push({
                        id: medicamento.Id,
                        Concepto: medicamento.Concepto,
                        Cantidad: medicamento.Cantidad,
                        Descripcion: medicamento.Descripcion,

                    })
                });
                array.push({
                    Index: ListaRecetas[ListaRecetas.length - 1].Index + 1,
                    TipoReceta: "0",
                    Cedula: Cedula,
                    Concepto: medis,
                    Firma: IncludeFirma,
                    Indicaciones: Indicaciones
                });
            } else {
                ListaMedicamentos.forEach(medicamento => {
                    medis.push({
                        id: medicamento.Id,
                        Concepto: medicamento.Concepto,
                        Cantidad: medicamento.Cantidad,
                        Descripcion: medicamento.Descripcion,

                    })
                });
                array.push({
                    Index: 1,
                    TipoReceta: "0",
                    Cedula: Cedula,
                    Concepto: medis,
                    Firma: IncludeFirma,
                    Indicaciones: Indicaciones
                });
            }

            setListaRecetas(array);


            setListaMedicamentos([]);
            setMensajeListaMedicamentos("true");
            setErrorPlanAccordion(true);
            setMensajeRecetaNoAgregada("true");
            setIndicaciones("");
        } else {
            if (ListaMedicamentos.length === 0) {
                setMensajeListaMedicamentos("false");
            }
            if (!!!Cedula) {
                setMensajeCedula("false");
            }
            setOpen(true);
            setTypeMessage("warning");
            setMessage("Faltan campos por completar");

        }

        return;
    }

    function eliminarReceta(posicion) {
        let array = ListaRecetas;

        let arrayDos = [];

        let arrayTres = [];

        arrayDos = array.filter((element) => { return element.Index !== posicion; });
        arrayDos.forEach((element, index) => {
            arrayTres.push({
                Index: index + 1,
                TipoReceta: "0",
                Cedula: element.Cedula,
                Concepto: element.Concepto,
                Indicaciones: element.Indicaciones
            });
        });

        setListaRecetas(arrayTres);
    }

    // LISTA DE ORDEN DE LABORATORIOS
    function addOrdenLab() {

        if (!!OrdenLabTest) {
            let array = ListaOrdenLabs;
            if (array.length > 0) {
                array.push({
                    Index: ListaOrdenLabs[ListaOrdenLabs.length - 1].Index + 1,
                    descripcion: OrdenLabTest,
                    tipoOrden: "1",
                });
            } else {
                array.push({
                    Index: 1,
                    descripcion: OrdenLabTest,
                    tipoOrden: "1",
                });
            }

            setListaOrdenLabs(array);


            setOrdenLabTest("");
            setMensajeOrdenLabTest("true");
            setErrorPlanAccordion(true);
            setMensajeOrdenLabNoAgregada("true");
        } else {
            setMensajeOrdenLabTest("false");
            setOpen(true);
            setTypeMessage("warning");
            setMessage("Faltan campos por completar");

        }

        return;
    }

    function eliminarOrdenLab(posicion) {
        let array = ListaOrdenLabs;

        let arrayDos = [];

        let arrayTres = [];

        arrayDos = array.filter((element) => { return element.Index !== posicion; });
        arrayDos.forEach((element, index) => {
            arrayTres.push({
                Index: index + 1,
                descripcion: element.descripcion,
                tipoOrden: element.tipoOrden,
            });
        });

        setListaOrdenLabs(arrayTres);
    }

    // LISTA DE ORDEN DE LABORATORIOS
    function addOrdenInter() {
        let array = ListaOrdenInter;
        if (!!DoctorSelect) {
            if (!!NombreMedico && !!ApellidoPMedico && !!EspecialidadMedico && !!MotivoInterconsulta) {
                if (array.length > 0) {
                    array.push({
                        Index: ListaOrdenInter[ListaOrdenInter.length - 1].Index + 1,
                        idContactoM: [
                            {
                                id: DoctorSelect,
                                nombre: NombreMedico,
                                apellidoPaterno: ApellidoPMedico,
                                apellidoMaterno: ApellidoMMedico,
                                celular: CelularMedico,
                                email: EmailMedico,
                                especialidad: EspecialidadMedico,
                            }
                        ],
                        tipoOrden: "0",
                        mOrden: MotivoInterconsulta,
                        diagnostico: DiagnosticoInterconsulta,
                        tratamiento: TratamientoInterconsulta
                    });
                } else {
                    array.push({
                        Index: 1,
                        idContactoM: [
                            {
                                id: DoctorSelect,
                                nombre: NombreMedico,
                                apellidoPaterno: ApellidoPMedico,
                                apellidoMaterno: ApellidoMMedico,
                                celular: CelularMedico,
                                email: EmailMedico,
                                especialidad: EspecialidadMedico,
                            }
                        ],
                        tipoOrden: "0",
                        mOrden: MotivoInterconsulta,
                        diagnostico: DiagnosticoInterconsulta,
                        tratamiento: TratamientoInterconsulta
                    });
                }

                setListaOrdenInter(array);

                setDoctorSelect(null);
                setDatosMedico({ id: "", imagen: "", nombre: "" });
                setCheckNuevo(false);
                setNombreMedico("");
                setApellidoPMedico("");
                setApellidoMMedico("");
                setCelularMedico("");
                setEmailMedico("");
                setEspecialidadMedico("");
                setMotivoInterconsulta("");
                setDiagnosticoInterconsulta("");
                setInterconsultaAdjunta(0);

                setErrorPlanAccordion(true);
                setMensajeOrdenInterNoAgregada("true");
            } else {
                if (!!!NombreMedico) {
                    setMensajeNombreMedico("false");
                }
                if (!!!ApellidoPMedico) {
                    setMensajeApellidoPMedico("false");
                }
                if (!!!EspecialidadMedico) {
                    setMensajeEspecialidadMedico("false");
                }
                if (!!!MotivoInterconsulta) {
                    setMensajeMotivoInterconsulta("false");
                }

                setOpen(true);
                setTypeMessage("warning");
                setMessage(`Faltan campos por llenar`);
            }


        } else {
            if (!!NombreMedico && !!ApellidoPMedico && !!EspecialidadMedico && !!MotivoInterconsulta) {
                if (array.length > 0) {
                    array.push({
                        Index: ListaOrdenInter[ListaOrdenInter.length - 1].Index + 1,
                        idContactoM: [
                            {
                                id: "",
                                nombre: NombreMedico,
                                apellidoPaterno: ApellidoPMedico,
                                apellidoMaterno: ApellidoMMedico,
                                celular: CelularMedico,
                                email: EmailMedico,
                                especialidad: EspecialidadMedico,
                            }
                        ],
                        tipoOrden: "0",
                        mOrden: MotivoInterconsulta,
                        diagnostico: DiagnosticoInterconsulta,
                        tratamiento: TratamientoInterconsulta
                    });
                } else {
                    array.push({
                        Index: 1,
                        idContactoM: [
                            {
                                id: "",
                                nombre: NombreMedico,
                                apellidoPaterno: ApellidoPMedico,
                                apellidoMaterno: ApellidoMMedico,
                                celular: CelularMedico,
                                email: EmailMedico,
                                especialidad: EspecialidadMedico,
                            }
                        ],
                        tipoOrden: "0",
                        mOrden: MotivoInterconsulta,
                        diagnostico: DiagnosticoInterconsulta,
                        tratamiento: TratamientoInterconsulta
                    });
                }

                setListaOrdenInter(array);

                setDoctorSelect(null);
                setCheckNuevo(false);
                setNombreMedico("");
                setApellidoPMedico("");
                setApellidoMMedico("");
                setCelularMedico("");
                setEmailMedico("");
                setEspecialidadMedico("");
                setMotivoInterconsulta("");
                setDiagnosticoInterconsulta("");
                setInterconsultaAdjunta(0);

                setErrorPlanAccordion(true);
                setMensajeOrdenInterNoAgregada("true");
            } else {

                setOpen(true);
                setTypeMessage("warning");
                if (CheckNuevo) {
                    if (!!!NombreMedico) {
                        setMensajeNombreMedico("false");
                    }
                    if (!!!ApellidoPMedico) {
                        setMensajeApellidoPMedico("false");
                    }
                    if (!!!EspecialidadMedico) {
                        setMensajeEspecialidadMedico("false");
                    }
                    if (!!!MotivoInterconsulta) {
                        setMensajeMotivoInterconsulta("false");
                    }

                    setMessage(`Faltan campos por llenar`);

                } else {
                    setMensajeDoctorSelect("false");
                    setMessage(`Debe elegir un médico`);
                }
            }



        }

        return;
    }

    function eliminarOrdenInter(posicion) {
        let array = ListaOrdenInter;

        let arrayDos = [];

        let arrayTres = [];

        arrayDos = array.filter((element) => { return element.Index !== posicion; });
        arrayDos.forEach((element, index) => {
            if (!!element.idContactoM[0].id) {
                arrayTres.push({
                    Index: index + 1,
                    idContactoM: [
                        {
                            id: element.id,
                        }
                    ],
                    tipoOrden: "0",
                    mOrden: element.mOrden,
                    diagnostico: element.diagnostico,
                    tratamiento: element.tratamiento
                });
            } else {
                arrayTres.push({
                    Index: index + 1,
                    idContactoM: [
                        {
                            id: "",
                            nombre: element.nombre,
                            apellidoPaterno: element.apellidoPaterno,
                            apellidoMaterno: element.apellidoMaterno,
                            celular: element.celular,
                            email: element.email,
                            especialidad: element.especialidad,
                        }
                    ],
                    tipoOrden: "0",
                    mOrden: element.mOrden,
                    diagnostico: element.diagnostico,
                    tratamiento: element.tratamiento
                });
            }

        });

        setListaOrdenInter(arrayTres);
    }

    // DIAGNOSTICOS
    function addDiagnosticoCIIE() {
        if (DiagnosticosSeleccionados.length > 0) {
            DiagnosticosSeleccionados.forEach((diagnostico, index) => {
                let array = DiagnosticosSeleccionadosMostrados;
                if (array.length > 0) {
                    array.push({
                        index: DiagnosticosSeleccionadosMostrados[DiagnosticosSeleccionadosMostrados.length - 1].index + 1,
                        diagnostico: diagnostico
                    });
                    setDiagnosticosSeleccionadosMostrados(array);

                } else {
                    array.push({
                        index: 1,
                        diagnostico: diagnostico
                    });

                    setDiagnosticosSeleccionadosMostrados(array);
                }
            });

            setDiagnosticosSeleccionados([]);
            setErrorDiagnosticoAccordion(true);
        } else {
            if (DiagnosticosSeleccionados.length === 0) {
                setMensajeDiagnosticoCIIE("false");
            }
            setOpen(true);
            setTypeMessage("warning");
            setMessage("Faltan campos por completar");

        }

        return;
    }

    function addDiagnosticoOtro() {
        if (!!OtrosDiagnosticos) {
            let array = DiagnosticosSeleccionadosMostrados;
            if (array.length > 0) {
                array.push({
                    index: DiagnosticosSeleccionadosMostrados[DiagnosticosSeleccionadosMostrados.length - 1].index + 1,
                    diagnostico: OtrosDiagnosticos
                });
            } else {
                array.push({
                    index: 1,
                    diagnostico: OtrosDiagnosticos
                });
            }

            setDiagnosticosSeleccionadosMostrados(array);
            setOtrosDiagnosticos("");
            setErrorDiagnosticoAccordion(true);
        } else {
            if (!!!OtrosDiagnosticos) {
                setMensajeOtrosDiagnosticos("false");
            }
            setOpen(true);
            setTypeMessage("warning");
            setMessage("Faltan campos por completar");

        }

        return;
    }

    function eliminarDiagnostico(posicion) {
        let array = DiagnosticosSeleccionadosMostrados;

        let arrayDos = [];

        let arrayTres = [];

        arrayDos = array.filter((element) => { return element.index !== posicion; });
        arrayDos.forEach((element, index) => {
            arrayTres.push({
                index: index + 1,
                diagnostico: element.diagnostico
            });
        });

        setDiagnosticosSeleccionadosMostrados(arrayTres);
    }

    function filterOptions(input, option) {
        var chars = {
            "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
            "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
            "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
            "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
        }
        var expr = /[áàéèíìóòúùñ]/ig;
        return option?.label.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(input.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1
    }

    function filterSort(optionA, optionB) {
        var chars = {
            "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
            "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
            "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
            "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
        }
        var expr = /[áàéèíìóòúùñ]/ig;
        return (optionA?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase().localeCompare((optionB?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase())
    }

    function crearResumenMedico() {

        let medicamentos_temp = [];
        if (ListaRecetas.length > 0) {
            ListaRecetas.forEach((receta) => {
                receta.Concepto.forEach((medicamento) => {
                    medicamentos_temp.push(medicamento);
                });
            });
        }
        let diagnosticos_temp = []
        if (DiagnosticosSeleccionadosMostrados.length > 0) {
            DiagnosticosSeleccionadosMostrados.forEach(element => {
                diagnosticos_temp.push(element.diagnostico);
            });
        }
        let resumen =
            `Paciente: \n${DatosContacto.nombre}\n
            \nEdad:\n${!!Edad ? Edad : "No especificada"}\n
            \nGénero:\n${Sexo}\n
            \nMotivo de consulta:\n${MotivoConsulta}\n
            \nEvolución del padecimiento:\n${!!EvolucionPadecimiento ? EvolucionPadecimiento : "Sin evolución del padecimiento"}\n
            \nExploración física:\n${!!ExploracionFisica ? ExploracionFisica : "Sin exploración física"}\n
            \nDiagnósticos:\n${diagnosticos_temp.toString()}\n`;
        resumen += `\n\nTratamientos:`
        if (medicamentos_temp.length > 0) {
            medicamentos_temp.forEach(element => {
                resumen += `\n${element.Concepto} - ${element.Descripcion}`;
            });
            resumen += `\n\n`
        } else {
            resumen += `\nSin tratamiento\n\n`;
        }

        resumen += `\nIndicaciones: \n${!!Recomendaciones ? Recomendaciones : "Sin indicaciones"} `;

        setResumenMedico(resumen);

        OpenResumenMedico(true);

    }

    function getEdad(dateString) {
        let hoy = new Date();
        let fNacimiento = new Date(dateString.replace(/-/g, "/"));
        let edad = hoy.getFullYear() - fNacimiento.getFullYear()
        let diferenciaMeses = hoy.getMonth() - fNacimiento.getMonth()
        if (
            diferenciaMeses < 0 ||
            (diferenciaMeses === 0 && hoy.getDate() < fNacimiento.getDate())
        ) {
            edad--
        }
        setEdad(edad);
    }

    function salirConsulta(link, funcion) {
        if (funcion !== undefined) {

            funcion();
        } else {
            setLink(link);
            OpenAlertConfirm(true);
        }
    }

    function areYouSure(e) {
        var confirmationMessage = "\o/";

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage;
    }

    function filterContacts(text) {
        var chars = {
            á: "a",
            é: "e",
            í: "i",
            ó: "o",
            ú: "u",
            à: "a",
            è: "e",
            ì: "i",
            ò: "o",
            ù: "u",
            ñ: "n",
            Á: "A",
            É: "E",
            Í: "I",
            Ó: "O",
            Ú: "U",
            À: "A",
            È: "E",
            Ì: "I",
            Ò: "O",
            Ù: "U",
            Ñ: "N",
        };
        var expr = /[áàéèíìóòúùñ]/gi;
        let filter = [];

        MedicosListFilter.forEach((contacto) => {
            if (
                contacto.usuario
                    .replace(expr, function (e) {
                        return chars[e];
                    })
                    .toLowerCase()
                    .indexOf(
                        text.target.value
                            .replace(expr, function (e) {
                                return chars[e];
                            })
                            .toLowerCase()
                    ) > -1
            ) {
                filter.push(contacto);
            }
        });

        setMedicosList(filter);
    }

    function filterMedicamentos(text) {
        var chars = {
            á: "a",
            é: "e",
            í: "i",
            ó: "o",
            ú: "u",
            à: "a",
            è: "e",
            ì: "i",
            ò: "o",
            ù: "u",
            ñ: "n",
            Á: "A",
            É: "E",
            Í: "I",
            Ó: "O",
            Ú: "U",
            À: "A",
            È: "E",
            Ì: "I",
            Ò: "O",
            Ù: "U",
            Ñ: "N",
        };
        var expr = /[áàéèíìóòúùñ]/gi;
        let filter = [];

        MedicamentosFilter.forEach((medicina) => {
            if (
                medicina.label
                    .replace(expr, function (e) {
                        return chars[e];
                    })
                    .toLowerCase()
                    .indexOf(
                        text
                            .replace(expr, function (e) {
                                return chars[e];
                            })
                            .toLowerCase()
                    ) > -1
            ) {
                filter.push(medicina);
            }
        });
        setMedicamentos(filter);
    }

    function clearExpFisica() {
        setTArterial("");
        setFCardiaca("");
        setFRespiratoria("");
        setTemperatura("");
        if (Altura === "N/A") {
            setAltura("");
        }
        setPeso("");
        setSOxigeno("");
        setExploracionFisica("");
    }

    const [messageApi, contextHolder] = InputMensaje.useMessage();
    const errorInput = (mensaje) => {
        messageApi.open({
            type: 'error',
            content: `${mensaje}`,
            className: 'custom-class',
            style: {
                marginTop: '40vh',
            },
        });
    }

    // MODAL ENVIAR RECETA
    const [modal, ModalEnviarReceta] = Modal.useModal();
    const confirm = () => {
        modal.confirm({
            title: `¿Desea enviar la receta al paciente ${DetailRecetas.Receta.Contacto}?`,
            okText: "Enviar",
            cancelText: "Cancelar",
            onOk: () => enviarReceta(DetailRecetas.Receta.IdReceta),
            onCancel: () => setEstatusModalEnviarReceta(false),
            open: EstatusModalEnviarReceta,
            icon: <ExclamationCircleOutlined />,
        });
    };

    useEffect(() => {
        if (!!!location.state) {
            navigate("/app/consultas");
        } else {
            setLocationValue(
                {
                    id: location.state.id,
                    imagen: location.state.imagen,
                    nombre: location.state.nombre,
                    motivo: location.state.motivo,
                    tipoConsulta: location.state.tipoConsulta,
                    idCita: location.state.idCita,
                }
            );
        }
        getEstados();
        getMedicamentosNuevo();
        getHistoriaClinica();
        getContactos();
        configReceta();
        window.onbeforeunload = areYouSure;

    }, [])

    return (
        <Stack>
            <NavbarNuevo
                mensajesError={{
                    setOpen: setOpen,
                    setTypeMessage: setTypeMessage,
                    setMessage: setMessage,
                }}
                consulta={salirConsulta} />

            {loader ? (<Loader />) : (
                <div className="GeneralContainerHistorial">
                    <h3>Nueva consulta</h3>
                    <div className="Row">
                        <Col className="Content">
                            <div className="Historialflexible">
                                <h3>Historial clínico</h3>
                                <div className="BotonGuardarActive">
                                    {Consulta ? (
                                        <Button
                                            type="button"
                                            size="md"
                                            style={{
                                                background: "#148F9F",
                                                padding: ".5% 5%",
                                                margin: "0",
                                                border: "#148F9F",
                                                color: "white"
                                            }}
                                            onClick={() => {
                                                if (!!MotivoConsulta && DiagnosticosSeleccionadosMostrados.length > 0 && !!Plan &&
                                                    !!Sexo && !!Nacimiento && ListaMedicamentos.length === 0 && !!!OrdenLabTest &&
                                                    InterconsultaAdjunta === 0) {
                                                    OpenAlertFinConsulta(true);
                                                } else {
                                                    let text = "Faltan campos por llenar\n";
                                                    if (!!!MotivoConsulta) {
                                                        setMensajeMotivoConsulta("false");
                                                    }
                                                    if (DiagnosticosSeleccionadosMostrados.length === 0) {
                                                        setErrorDiagnosticoAccordion(false);
                                                    }
                                                    if (!!!Plan) {
                                                        setErrorPlanAccordion(false);
                                                        setMensajePlan("false");
                                                    }
                                                    if (!!!Sexo) {
                                                        setMensajeSexo("false");
                                                    }
                                                    if (!!!Nacimiento) {
                                                        setMensajeNacimiento("false");
                                                    }

                                                    if (ListaMedicamentos.length > 0) {
                                                        setErrorPlanAccordion(false);
                                                        setMensajeRecetaNoAgregada("false");
                                                        text += "Tiene una receta pendiente de agregar\n"
                                                    }

                                                    if (!!OrdenLabTest) {
                                                        setErrorPlanAccordion(false);
                                                        setMensajeOrdenLabNoAgregada("false");
                                                        text += "Tiene una orden de laboratorio pendiente de agregar\n"
                                                    }

                                                    if (InterconsultaAdjunta === 1) {
                                                        setErrorPlanAccordion(false);
                                                        setMensajeOrdenInterNoAgregada("false");
                                                        text += "Tiene una orden interconsulta pendiente de agregar\n"
                                                    }
                                                    ClosedAlertFinConsulta(true);
                                                    setOpen(true);
                                                    setTypeMessage("warning");
                                                    setMessage(text);
                                                }
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "6px",
                                                }}
                                            >
                                                <span>Finalizar consulta</span>
                                                <img
                                                    style={{ width: "12px", height: "12px" }}
                                                    src={CheckGuardar}
                                                    alt=""
                                                />
                                            </div>
                                        </Button>
                                    ) : (
                                        <Button
                                            type="button"
                                            onClick={() => {

                                                if (!!Sexo && !!Nacimiento) {
                                                    setConsulta(true);
                                                } else {
                                                    if (!!!Sexo) {
                                                        setMensajeSexo("false");
                                                    }
                                                    if (!!!Nacimiento) {
                                                        setMensajeNacimiento("false");
                                                    }
                                                    setOpen(true);
                                                    setTypeMessage("warning");
                                                    setMessage("Faltan campos por completar");
                                                }
                                            }}
                                            size="md"
                                            style={{
                                                background: "#148F9F",
                                                padding: ".5% 5%",
                                                margin: "0",
                                                border: "#148F9F",
                                                color: "white"
                                            }}
                                        >
                                            Continuar
                                        </Button>
                                    )}
                                </div>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginRight: "3%",
                                    width: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "1%",
                                        marginBottom: "1%",
                                        width: "95%",
                                    }}

                                >
                                    <div>


                                    </div>
                                    <Avatar
                                        size={60}
                                        className='GeneralNoImage'
                                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                        src={
                                            <Image className="ImgHistorialPerf GeneralNoImag" src={!!DatosContacto.imagen ? `https://${DatosContacto.imagen}` : Icono[0]} alt="" />
                                        }
                                    />

                                    <div>
                                        <b style={{ fontWeight: "500", margin: "0" }}>
                                            {DatosContacto.nombre}
                                        </b>
                                        <div>
                                            <div>
                                                <span style={{ fontWeight: "500", fontSize: "12px" }}>
                                                    Edad: {Edad === 0 ? "No especificada" : `${Edad} años`}
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: "500", fontSize: "12px" }}>
                                                    Tipo de sangre: {!!TipoSangre ? TipoSangre : "No especificada"}
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: "500", fontSize: "12px" }}>
                                                    ID YGIA: {DatosContacto.id}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {Consulta && (
                                    DisabledInputs ?
                                        (
                                            <div style={{ width: "5%" }} onClick={() => { setDisabledInputs(false) }}>
                                                <div>
                                                    <img
                                                        style={{ width: "16px", cursor: "pointer" }}
                                                        src={Editar}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        ) :
                                        (
                                            <div style={{ width: "5%", cursor: "pointer" }} onClick={() => { setDisabledInputs(true) }}>
                                                <div>
                                                    <h3 style={{ color: "#148f9f" }}>x</h3>
                                                </div>
                                            </div>
                                        )
                                )}
                            </div>
                            {!Consulta ? (
                                // ### VISTA DE HISTORIA CLINICA NUEVA ###
                                <div>
                                    <b><b style={{ color: "#ff4d4f", fontSize: "14px" }}>*&nbsp;</b>{`Seccion(es)/Campo(s) obligatorio(s)`}</b>
                                    <InputAntd
                                        label="Tipo de sangre:"
                                        className="inputblue"
                                        value={TipoSangre}
                                        setStateValue={setTipoSangre}
                                        placeholder="Tipo de sangre"
                                        vacio={true}
                                    />
                                    <TextAreaAntd
                                        label="Alergias:"
                                        value={Alergias}
                                        placeholder="Alergias"
                                        style={{
                                            // margin: ".5% 0",
                                            marginBottom: ".5%",
                                            fontFamily: "sans-serif",
                                        }}
                                        columns="30"
                                        autoSize={true}
                                        setStateValue={setAlergias}
                                    />

                                    <TextAreaAntd
                                        label="Quien refiere:"
                                        value={Refiere}
                                        placeholder="Quien refiere"
                                        style={{
                                            // margin: ".5% 0",
                                            marginBottom: ".5%",
                                            fontFamily: "sans-serif",
                                        }}
                                        columns="30"
                                        autoSize={true}
                                        setStateValue={setRefiere}
                                    />

                                    <TextAreaAntd
                                        label="Observaciones sobre el paciente:"
                                        value={ObservacionesPaciente}
                                        placeholder="Observaciones sobre el paciente"
                                        style={{
                                            // margin: ".5% 0",
                                            marginBottom: ".5%",
                                            fontFamily: "sans-serif",
                                        }}
                                        columns="30"
                                        autoSize={true}
                                        setStateValue={setObservacionesPaciente}
                                    />

                                    <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                                        <SelectAntd
                                            label="Estado civil:"
                                            value={EstadoCivil}
                                            style={{ width: "100%" }}
                                            placeholder="Seleccione un estado civil"
                                            options={
                                                [
                                                    { value: "0", label: "Soltero/a" },
                                                    { value: "1", label: "Casado/a" },
                                                    { value: "2", label: "Viudo/a" },
                                                    { value: "3", label: "Divorciado/a" },
                                                    { value: "4", label: "Separado/a" },
                                                    { value: "5", label: "Concubinato" },
                                                ]
                                            }
                                            setStateValue={setEstadoCivil}
                                            styleDiv={{ width: "45%" }}
                                        // setStateError={setMensajeSexo}
                                        // valueError={MensajeSexo}
                                        // textError={"Debe seleccionar un estado civil"}
                                        />
                                        <InputAntd
                                            className="inputblue"
                                            value={Ocupacion}
                                            setStateValue={setOcupacion}
                                            label="Ocupación:"
                                            placeholder="Escribe una ocupación"
                                            // valueError={MensajeAddNombrecontacto}
                                            vacio={true}
                                            // disabled={false}
                                            // expresionRegular={expresiones.Letras}
                                            styleDiv={{ width: "45%" }}
                                        />
                                    </Row>

                                    <Row style={{ justifyContent: "space-between" }}>
                                        <SelectAntd
                                            label="Género:"
                                            requiredICon={true}
                                            value={Sexo}
                                            style={{ width: "100%" }}
                                            placeholder="Género"
                                            options={[{ value: "Femenino", label: "Femenino" }, { value: "Masculino", label: "Masculino" }]}
                                            setStateValue={setSexo}
                                            styleDiv={{ width: "45%" }}
                                            setStateError={setMensajeSexo}
                                            valueError={MensajeSexo}
                                            textError={"Debe seleccionar un género"}
                                            vacio={false}
                                        />
                                        {/* <DatePickerAntd
                                            label="Nacimiento:"
                                            requiredICon={true}
                                            placeholder="DD/MM/AA"
                                            style={{ width: "100%", background: "white" }}
                                            value={!!Nacimiento ? dayjs(Nacimiento) : ""}
                                            setStateValue={setNacimiento}
                                            styleDiv={{ width: "45%" }}
                                            setEdad={setEdad}
                                            setStateError={setMensajeNacimiento}
                                            valueError={MensajeNacimiento}
                                            textError={"Debe elegir una fecha de nacimiento"}
                                            vacio={false}
                                        /> */}
                                        <InputDate
                                            label="Nacimiento:"
                                            requiredICon={true}
                                            placeholder="DD/MM/AAAA"
                                            style={{ width: "100%", background: "white" }}
                                            value={!!Nacimiento ? Nacimiento : ""}
                                            setStateValue={setNacimiento}
                                            styleDiv={{ width: "45%" }}
                                            setEdad={setEdad}
                                            setStateError={setMensajeNacimiento}
                                            valueError={MensajeNacimiento}
                                            textError={["Debe elegir una fecha de nacimiento", "Fecha no válida"]}
                                            vacio={false}
                                        />
                                    </Row>
                                    {/* <Label>Nacionalidad:</Label> */}
                                    <InputAntd
                                        className="inputblue"
                                        value={Nacionalidad}
                                        setStateValue={setNacionalidad}
                                        label="Nacionalidad:"
                                        placeholder="Nacionalidad"
                                        vacio={true}
                                    />

                                    <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                                        <Col style={{ width: "45%" }}>
                                            <Label>Estado:</Label>
                                            <Select
                                                showSearch
                                                style={{
                                                    width: "100%",
                                                    margin: ".5% 0"
                                                }}
                                                placeholder="Estado"
                                                optionFilterProp="children"
                                                filterOption={filterOptions}
                                                filterSort={filterSort}
                                                value={Estado}
                                                onSelect={(e) => {
                                                    setEstado(e);
                                                    getLocalidades(e);
                                                }}
                                                options={EstadosList}
                                            />
                                        </Col>
                                        <Col style={{ width: "45%" }}>
                                            <Label>Localidad:</Label>
                                            <Select
                                                showSearch
                                                style={{
                                                    width: "100%",
                                                    margin: ".5% 0"
                                                }}
                                                placeholder="Localidad"
                                                optionFilterProp="children"
                                                filterOption={filterOptions}
                                                filterSort={filterSort}
                                                value={Localidad}
                                                onSelect={(e) => {
                                                    setLocalidad(e);
                                                }}
                                                options={LocalidadesList}
                                                disabled={LocalidadesList.length === 0 ? true : false}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                // ### VISTA EN CONSULTA DE HISTORIA CLINICA ###
                                <div className="ScrollConsultas">
                                    {/* INFORMACION GENERAL */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>
                                                {(MensajeSexo === "false" && MensajeNacimiento === "false") && (<><CancelRounded color="error" />&nbsp;</>)}
                                                <b style={{ color: "#ff4d4f", fontSize: "14px", fontFamily: "SimSun,sans-serif" }}>*&nbsp;</b>
                                                Información general
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <InputAntd
                                                label="Tipo de sangre:"
                                                className="inputblue"
                                                value={TipoSangre}
                                                setStateValue={setTipoSangre}
                                                expresionRegular={expresiones.sangre}
                                                setStateError={setMensajeTipoSangre}
                                                valueError={MensajeTipoSangre}
                                                textError={["", "Solo se permiten letras y simbolos(- +)"]}
                                                placeholder="Tipo de sangre"
                                                vacio={true}
                                                readOnly={DisabledInputs}
                                                style={{ width: "30%" }}
                                                maxLength={4}
                                            />
                                            <TextAreaAntd
                                                label="Alergias:"
                                                value={Alergias}
                                                placeholder="Alergias"
                                                style={{
                                                    // margin: ".5% 0",
                                                    marginBottom: ".5%",
                                                    fontFamily: "sans-serif",
                                                }}
                                                columns="30"
                                                autoSize={true}
                                                setStateValue={setAlergias}
                                                readOnly={DisabledInputs}
                                            />

                                            <TextAreaAntd
                                                label="Quien Refiere:"
                                                value={Refiere}
                                                placeholder="Quien Refiere"
                                                style={{
                                                    // margin: ".5% 0",
                                                    marginBottom: ".5%",
                                                    fontFamily: "sans-serif",
                                                }}
                                                columns="30"
                                                autoSize={true}
                                                setStateValue={setRefiere}
                                                readOnly={DisabledInputs}
                                            />

                                            <TextAreaAntd
                                                label="Observaciones sobre el paciente:"
                                                value={ObservacionesPaciente}
                                                placeholder="Observaciones sobre el paciente"
                                                style={{
                                                    // margin: ".5% 0",
                                                    marginBottom: ".5%",
                                                    fontFamily: "sans-serif",
                                                }}
                                                columns="30"
                                                autoSize={true}
                                                setStateValue={setObservacionesPaciente}
                                                readOnly={DisabledInputs}
                                            />

                                            <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                                                <SelectAntd
                                                    label="Estado civil:"
                                                    value={EstadoCivil}
                                                    style={{ width: "100%" }}
                                                    placeholder="Seleccione un estado civil"
                                                    options={
                                                        [
                                                            { value: "0", label: "Soltero/a" },
                                                            { value: "1", label: "Casado/a" },
                                                            { value: "2", label: "Viudo/a" },
                                                            { value: "3", label: "Divorciado/a" },
                                                            { value: "4", label: "Separado/a" },
                                                            { value: "5", label: "Concubinato" },
                                                        ]
                                                    }
                                                    setStateValue={setEstadoCivil}
                                                    disabled={DisabledInputs}
                                                    styleDiv={{ width: "45%" }}
                                                // setStateError={setMensajeSexo}
                                                // valueError={MensajeSexo}
                                                // textError={"Debe seleccionar un estado civil"}
                                                />
                                                <InputAntd
                                                    className="inputblue"
                                                    value={Ocupacion}
                                                    setStateValue={setOcupacion}
                                                    label="Ocupación:"
                                                    placeholder="Escribe una ocupación"
                                                    // valueError={MensajeAddNombrecontacto}
                                                    vacio={true}
                                                    // disabled={false}
                                                    // expresionRegular={expresiones.Letras}
                                                    readOnly={DisabledInputs}
                                                    styleDiv={{ width: "45%" }}
                                                />
                                            </Row>

                                            <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                                                <SelectAntd
                                                    label="Género:"
                                                    requiredICon={true}
                                                    value={Sexo}
                                                    style={{ width: "100%" }}
                                                    placeholder="Género"
                                                    options={[{ value: "Femenino", label: "Femenino" }, { value: "Masculino", label: "Masculino" }]}
                                                    setStateValue={setSexo}
                                                    disabled={DisabledInputs}
                                                    styleDiv={{ width: "45%" }}
                                                    setStateError={setMensajeSexo}
                                                    valueError={MensajeSexo}
                                                    textError={"Debe seleccionar un género"}
                                                    vacio={false}
                                                />
                                                {/* <DatePickerAntd
                                                    label="Nacimiento:"
                                                    requiredICon={true}
                                                    placeholder="DD/MM/AAAA"
                                                    style={{ width: "100%", background: "white" }}
                                                    value={!!Nacimiento ? dayjs(Nacimiento) : ""}
                                                    setStateValue={setNacimiento}
                                                    disabled={DisabledInputs}
                                                    styleDiv={{ width: "45%" }}
                                                    setEdad={setEdad}
                                                    setStateError={setMensajeNacimiento}
                                                    valueError={MensajeNacimiento}
                                                    textError={"Debe elegir una fecha de nacimiento"}
                                                    vacio={false}
                                                /> */}
                                                <InputDate
                                                    label="Nacimiento:"
                                                    requiredICon={true}
                                                    placeholder="DD/MM/AAAA"
                                                    style={{ width: "100%", background: "white" }}
                                                    value={!!Nacimiento ? Nacimiento : ""}
                                                    setStateValue={setNacimiento}
                                                    styleDiv={{ width: "45%" }}
                                                    setEdad={setEdad}
                                                    setStateError={setMensajeNacimiento}
                                                    valueError={MensajeNacimiento}
                                                    textError={["Debe elegir una fecha de nacimiento", "Fecha no válida"]}
                                                    vacio={false}
                                                    disabled={DisabledInputs}
                                                />
                                            </Row>

                                            <InputAntd
                                                className="inputblue"
                                                value={Nacionalidad}
                                                setStateValue={setNacionalidad}
                                                label="Nacionalidad:"
                                                placeholder="Nacionalidad"
                                                // valueError={MensajeAddNombrecontacto}
                                                vacio={true}
                                                // disabled={false}
                                                // expresionRegular={expresiones.Letras}
                                                readOnly={DisabledInputs}
                                            />

                                            <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                                                <Col style={{ width: "45%" }}>
                                                    <Label>Estado:</Label>
                                                    <Select
                                                        disabled={DisabledInputs}
                                                        showSearch
                                                        style={{
                                                            width: "100%",
                                                            margin: ".5% 0"
                                                        }}
                                                        placeholder="Estado"
                                                        optionFilterProp="children"
                                                        filterOption={filterOptions}
                                                        filterSort={filterSort}
                                                        value={Estado}
                                                        onSelect={(e) => {
                                                            setEstado(e);
                                                            getLocalidades(e);
                                                        }}
                                                        options={EstadosList}
                                                    />
                                                </Col>
                                                <Col style={{ width: "45%" }}>
                                                    <Label>Localidad:</Label>
                                                    <Select
                                                        showSearch
                                                        style={{
                                                            width: "100%",
                                                            margin: ".5% 0"
                                                        }}
                                                        placeholder="Localidad"
                                                        optionFilterProp="children"
                                                        filterOption={filterOptions}
                                                        filterSort={filterSort}
                                                        value={Localidad}
                                                        onSelect={(e) => {
                                                            setLocalidad(e);
                                                        }}
                                                        options={LocalidadesList}
                                                        disabled={LocalidadesList.length === 0 ? true : DisabledInputs ? true : false}
                                                    />
                                                </Col>
                                            </Row>
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* DATOS DE PAREJA */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>
                                                Datos de pareja
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <InputAntd
                                                label="Nombre:"
                                                className="inputblue"
                                                value={NombrePareja}
                                                setStateValue={setNombrePareja}
                                                placeholder="Escriba un nombre completo"
                                                vacio={true}
                                                readOnly={DisabledInputs}
                                                styleDiv={{ width: "100%" }}
                                            // maxLength={15}
                                            />
                                            <Row style={{ justifyContent: "space-between" }}>
                                                <InputAntd
                                                    className="inputblue"
                                                    value={OcupacionPareja}
                                                    setStateValue={setOcupacionPareja}
                                                    label="Ocupación:"
                                                    placeholder="Escribe una ocupación"
                                                    // valueError={MensajeAddNombrecontacto}
                                                    vacio={true}
                                                    // disabled={false}
                                                    // expresionRegular={expresiones.Letras}
                                                    readOnly={DisabledInputs}
                                                    styleDiv={{ width: "70%" }}
                                                />

                                                <InputAntd
                                                    label="Edad:"
                                                    className="inputblue"
                                                    value={EdadPareja}
                                                    setStateValue={setEdadPareja}
                                                    placeholder="Edad"
                                                    vacio={true}
                                                    readOnly={DisabledInputs}
                                                    styleDiv={{ width: "15%" }}
                                                    maxLength={2}
                                                />
                                            </Row>
                                            <TextAreaAntd
                                                label="Notas/Comentarios:"
                                                value={NotasPareja}
                                                placeholder="Escriba una nota o comentario"
                                                style={{
                                                    // margin: ".5% 0",
                                                    marginBottom: ".5%",
                                                    fontFamily: "sans-serif",
                                                }}
                                                columns="30"
                                                autoSize={true}
                                                setStateValue={setNotasPareja}
                                                readOnly={DisabledInputs}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* ANTECEDENTES HEREDO-FAMILIARES */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>
                                                Antecedentes heredo-familiares
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TextArea
                                                // readOnly
                                                placeholder="Antecedentes heredo-familiares"
                                                style={{
                                                    margin: ".5% 0",
                                                    fontFamily: "sans-serif",
                                                    border: "0",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                onChange={(e) => {
                                                    setAHeredoFamiliares(e.target.value);
                                                }}
                                                value={AHeredoFamiliares}
                                                autoSize
                                                readOnly={DisabledInputs}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* ANTECEDENTES PATOLOGICOS */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>
                                                Antecedentes personales patológicos
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TextArea
                                                // readOnly
                                                placeholder="Antecedentes personales patológicos"
                                                style={{
                                                    margin: ".5% 0",
                                                    fontFamily: "sans-serif",
                                                    border: "0",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                onChange={(e) => {
                                                    setAPersonalesPatologicos(e.target.value);
                                                }}
                                                value={APersonalesPatologicos}
                                                autoSize
                                                readOnly={DisabledInputs}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* ANTECEDENTES NO PATOLOGICOS */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography>
                                                Antecedentes personales no patológicos
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TextArea
                                                placeholder="Antecedentes personales no patológicos"
                                                style={{
                                                    margin: ".5% 0",
                                                    fontFamily: "sans-serif",

                                                    border: "0",
                                                }}
                                                onChange={(e) => {
                                                    setAPersonalesNoPatologicos(e.target.value);
                                                }}
                                                value={APersonalesNoPatologicos}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                readOnly={DisabledInputs}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* ANTECEDENTES ANDROLOGICOS O GINECO-OBSTETRICOS */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography>
                                                {Sexo === "Masculino" ? "Antecedentes andrológicos" : "Antecedentes gineco-obstetricos"}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {Sexo === "Femenino" && (
                                                <>
                                                    <Label>¿Paciente embarazada?</Label>
                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "30%" }}>
                                                        <Checkbox checked={Embarazada === 1 ? true : false} onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setEmbarazada(1);
                                                            }
                                                        }}>Si</Checkbox>
                                                        <Checkbox checked={Embarazada === 0 ? true : false} onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setEmbarazada(0);
                                                            }
                                                        }}>No</Checkbox>
                                                    </div>
                                                </>
                                            )}
                                            <label htmlFor="">Generales</label>
                                            <TextArea
                                                placeholder="Inicio de vida sexual, método de planificación familiar, orientación sexual, si sostiene relaciones sexuales de alto riesgo, alteraciones de la libido, si ha padecido enfermedades de transmisión sexual."
                                                style={{
                                                    margin: ".5% 0",
                                                    fontFamily: "sans-serif",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                onChange={(e) => {
                                                    setAAndroGineGeneral(e.target.value);
                                                }}
                                                value={AAndroGineGeneral}
                                                readOnly={DisabledInputs}
                                            />
                                            <label htmlFor="">{Sexo === "Masculino" ? "Andrológicos específicos" : "Ginecoobstetricos"}</label>
                                            <TextArea
                                                placeholder={Sexo === "Masculino" ? "Priapismo, alteraciones de la erección y/o eyaculación, secreción uretral, dolor testicular, alteraciones escrotales, enfermedades de transmisión sexual."
                                                    : "Menarca, Telarca, Pubarca, Gestas, Partos Cesáreas, Abortos, Dismenorreas, Ciclo menstrual."}
                                                style={{
                                                    margin: ".5% 0",
                                                    fontFamily: "sans-serif",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                onChange={(e) => {
                                                    setAAndroGine(e.target.value);
                                                }}
                                                value={AAndroGine}
                                                readOnly={DisabledInputs}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* INTERROGATORIO APARATOS Y SISTEMAS */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography>
                                                Interrogatorio por aparatos y sistemas
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {(!!SignosSintomas || !DisabledInputs) && (
                                                <>
                                                    <label htmlFor="">Signos y síntomas generales</label>
                                                    <TextArea
                                                        className="InputNormal"
                                                        placeholder="Fiebre, cefalea, astenia, adinamia y anorexia."
                                                        style={{
                                                            fontFamily: "sans-serif",
                                                            width: "100%",
                                                            padding: ".5%",
                                                        }}
                                                        name=""
                                                        id=""
                                                        cols="30"
                                                        autoSize
                                                        readOnly={DisabledInputs}
                                                        value={SignosSintomas}
                                                        onChange={(e) => {
                                                            setSignosSintomas(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )}

                                            {(!!AparatoCardiovascular || !DisabledInputs) && (
                                                <>
                                                    <label htmlFor="">Aparato cardiovascular</label>
                                                    <TextArea
                                                        className="InputNormal"
                                                        placeholder="Disnea, dolor precordial, palpitaciones, sincope, lipotimia, edema, cianosis, acúfenos, fosfenos. "
                                                        style={{
                                                            fontFamily: "sans-serif",
                                                            width: "100%",
                                                            padding: ".5%",
                                                        }}
                                                        name=""
                                                        id=""
                                                        cols="30"
                                                        autoSize
                                                        readOnly={DisabledInputs}
                                                        value={AparatoCardiovascular}
                                                        onChange={(e) => {
                                                            setAparatoCardiovascular(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )}

                                            {(!!AparatoRespiratorio || !DisabledInputs) && (
                                                <>
                                                    <label htmlFor="">Aparato respiratorio</label>
                                                    <TextArea
                                                        className="InputNormal"
                                                        placeholder="Rinorrea, rinolalia, tos, expectoración, disnea, dolor torácico, epistaxis, disfonía, hemoptisis, vómica, sibilancias audibles a distancia. "
                                                        style={{
                                                            fontFamily: "sans-serif",
                                                            width: "100%",
                                                            padding: ".5%",
                                                        }}
                                                        name=""
                                                        id=""
                                                        cols="30"
                                                        autoSize
                                                        readOnly={DisabledInputs}
                                                        value={AparatoRespiratorio}
                                                        onChange={(e) => {
                                                            setAparatoRespiratorio(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )}

                                            {(!!AparatoDigestivo || !DisabledInputs) && (
                                                <>
                                                    <label htmlFor="">Aparato digestivo</label>
                                                    <TextArea
                                                        className="InputNormal"
                                                        placeholder="Trastornos de la deglución, de la digestión, de la defecación, nausea, vómito, dolor abdominal, diarrea, constipación, ictericia, rumiación, regurgitación, pirosis, aerofagia, eructos, meteorismos, distención abdominal, flatulencia, hematemesis, características de las heces fecales, diarrea, estreñimiento, acolia, hipocolia, melena, rectorragia, lientería, pujo, tenesmo y prurito anal."
                                                        style={{
                                                            fontFamily: "sans-serif",
                                                            width: "100%",
                                                            padding: ".5%",
                                                        }}
                                                        name=""
                                                        id=""
                                                        cols="30"
                                                        autoSize
                                                        readOnly={DisabledInputs}
                                                        value={AparatoDigestivo}
                                                        onChange={(e) => {
                                                            setAparatoDigestivo(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )}

                                            {(!!SistemaNefrourologico || !DisabledInputs) && (
                                                <>
                                                    <label htmlFor="">Sistema nefrourologico</label>
                                                    <TextArea
                                                        className="InputNormal"
                                                        placeholder="Dolor renoureteral, hematuria, piuria, coluría, oliguria, tenesmo, control de esfínteres, cólico renal, edema, trastornos en el volumen urinario, nictámero, urgencia, características del chorro urinario."
                                                        style={{
                                                            fontFamily: "sans-serif",
                                                            width: "100%",
                                                            padding: ".5%",
                                                        }}
                                                        name=""
                                                        id=""
                                                        cols="30"
                                                        autoSize
                                                        readOnly={DisabledInputs}
                                                        value={SistemaNefrourologico}
                                                        onChange={(e) => {
                                                            setSistemaNefrourologico(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )}

                                            {(!!SistemaEndocrino || !DisabledInputs) && (
                                                <>
                                                    <label htmlFor="">Sistema endocrino y metabolismo</label>
                                                    <TextArea
                                                        className="InputNormal"
                                                        placeholder="Intolerancia al calor o al frio, pérdida o aumento de peso, alteraciones del color de la piel, vello corporal, distribución de la grasa corporal, astenia o adinamia, alteraciones del volumen urinario, amenorreas, ginecomastia, galactorrea, alteraciones de la libido, espasmos o calambres musculares."
                                                        style={{
                                                            fontFamily: "sans-serif",
                                                            width: "100%",
                                                            padding: ".5%",
                                                        }}
                                                        name=""
                                                        id=""
                                                        cols="30"
                                                        autoSize
                                                        readOnly={DisabledInputs}
                                                        value={SistemaEndocrino}
                                                        onChange={(e) => {
                                                            setSistemaEndocrino(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )}

                                            {(!!SistemaHematoproyetico || !DisabledInputs) && (
                                                <>
                                                    <label htmlFor="">Sistema hematopoyético</label>
                                                    <TextArea
                                                        className="InputNormal"
                                                        placeholder="Palidez, rubicundez, adenomegalias, hemorragias, fiebre, fatigabilidad, equimosis, petequias y adenomegalias."
                                                        style={{
                                                            fontFamily: "sans-serif",
                                                            width: "100%",
                                                            padding: ".5%",
                                                        }}
                                                        name=""
                                                        id=""
                                                        cols="30"
                                                        autoSize
                                                        readOnly={DisabledInputs}
                                                        value={SistemaHematoproyetico}
                                                        onChange={(e) => {
                                                            setSistemaHematoproyetico(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )}

                                            {(!!SistemaNervioso || !DisabledInputs) && (
                                                <>
                                                    <label htmlFor="">Sistema nervioso</label>
                                                    <TextArea
                                                        className="InputNormal"
                                                        placeholder="Cefalalgia, pérdida de conocimiento, mareos vértigo y trastornos del equilibrio, movimientos anormales involuntarios, debilidad muscular, convulsiones, trastornos de la visión, dolor hormigueo y adormecimiento, trastornos del sueño."
                                                        style={{
                                                            fontFamily: "sans-serif",
                                                            width: "100%",
                                                            padding: ".5%",
                                                        }}
                                                        name=""
                                                        id=""
                                                        cols="30"
                                                        autoSize
                                                        readOnly={DisabledInputs}
                                                        value={SistemaNervioso}
                                                        onChange={(e) => {
                                                            setSistemaNervioso(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )}

                                            {(!!MusculoEsqueletico || !DisabledInputs) && (
                                                <>
                                                    <label htmlFor="">Sistema musculo esquelético</label>
                                                    <TextArea
                                                        className="InputNormal"
                                                        placeholder="Mialgias, dolor óseo, artralgias, alteraciones en la marcha, hipotonía, disminución del volumenmuscular, limitación de movimientos y deformidades."
                                                        style={{
                                                            fontFamily: "sans-serif",
                                                            width: "100%",
                                                            padding: ".5%",
                                                        }}
                                                        name=""
                                                        id=""
                                                        cols="30"
                                                        autoSize
                                                        readOnly={DisabledInputs}
                                                        value={MusculoEsqueletico}
                                                        onChange={(e) => {
                                                            setMusculoEsqueletico(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )}

                                            {(!!PielTegumentos || !DisabledInputs) && (
                                                <>
                                                    <label htmlFor="">Piel y tegumentos</label>
                                                    <TextArea
                                                        className="InputNormal"
                                                        placeholder="Coloración, pigmentación, prurito, características del pelo, uñas, lesiones (primarias y secundarias), hiperhidrosis y xerodermia."
                                                        style={{
                                                            fontFamily: "sans-serif",
                                                            width: "100%",
                                                            padding: ".5%",
                                                        }}
                                                        name=""
                                                        id=""
                                                        cols="30"
                                                        autoSize
                                                        readOnly={DisabledInputs}
                                                        value={PielTegumentos}
                                                        onChange={(e) => {
                                                            setPielTegumentos(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )}

                                            {(!!OrganosSentidos || !DisabledInputs) && (
                                                <>
                                                    <label htmlFor="">Órganos de los sentidos</label>
                                                    <TextArea
                                                        className="InputNormal"
                                                        placeholder="Alteraciones de la visión, de la audición, del olfato, del gusto y del tacto (hipo, hiper o disfunción). Mareo y sensación de líquido en el oído."
                                                        style={{
                                                            fontFamily: "sans-serif",
                                                            width: "100%",
                                                            padding: ".5%",
                                                        }}
                                                        name=""
                                                        id=""
                                                        cols="30"
                                                        autoSize
                                                        readOnly={DisabledInputs}
                                                        value={OrganosSentidos}
                                                        onChange={(e) => {
                                                            setOrganosSentidos(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )}


                                            {(!!EsferaPsiquica || !DisabledInputs) && (
                                                <>
                                                    <label htmlFor="">Esfera psíquica</label>
                                                    <TextArea
                                                        className="InputNormal"
                                                        placeholder="Tristeza, euforia, alteraciones del sueño, , terrores nocturnos, ideaciones, miedo exagerado a situaciones comunes, irritabilidad, apatía."
                                                        style={{
                                                            fontFamily: "sans-serif",
                                                            width: "100%",
                                                            padding: ".5%",
                                                        }}
                                                        name=""
                                                        id=""
                                                        cols="30"
                                                        autoSize
                                                        readOnly={DisabledInputs}
                                                        value={EsferaPsiquica}
                                                        onChange={(e) => {
                                                            setEsferaPsiquica(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )}


                                            {(!!Genitales || !DisabledInputs) && (
                                                <>
                                                    <label htmlFor="">Genitourinario</label>
                                                    <TextArea
                                                        className="InputNormal"
                                                        placeholder="Hematuria, incontinencia, tenesmo vesical, disuria y ardor, poliuria, oliguria, anuria, polaquiuria, nicturia, opsiuria"
                                                        style={{
                                                            fontFamily: "sans-serif",
                                                            width: "100%",
                                                            padding: ".5%",
                                                        }}
                                                        name=""
                                                        id=""
                                                        cols="30"
                                                        autoSize
                                                        readOnly={DisabledInputs}
                                                        value={Genitales}
                                                        onChange={(e) => {
                                                            setGenitales(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* NOTAS DE EVOLUCION */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography>Notas de evolución</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <>
                                                {NotasEvolucion.map((nota, index) => (
                                                    // <div key={index} className="ItemCard" style={{ cursor: "pointer" }} onClick={() => { detailConsulta(nota.id) }}>
                                                    //     <div
                                                    //         className="CardNotasMed"
                                                    //         onClick={OpenNotaDetalle}
                                                    //     >
                                                    //         <span>{nota.motivo}</span> <span>{nota.fecha}</span>
                                                    //     </div>
                                                    // </div>
                                                    <Accordionn expanded={expandedConsulta === `panel${index}`} onChange={handleChange(`panel${index}`, nota.id, 'consulta')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id={`panel1a-header`}
                                                        >
                                                            {(loaderConsultaDetalle && expandedConsulta === `panel${index}`) && <Spin indicator={<LoadingOutlined spin style={{ color: '#148f9f' }} />} />}
                                                            <Typography style={{ width: "95%" }}>
                                                                <div className="CardNotasMed">
                                                                    <span>{nota.motivo}</span> <span>{nota.fecha}</span>
                                                                </div>
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {loaderConsultaDetalle && expandedConsulta === `panel${index}` ? (
                                                                <Spin indicator={<LoadingOutlined spin style={{ color: '#148f9f' }} />} />
                                                            ) : (
                                                                <div>
                                                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                                                        <Button type="button" style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", gap: "2%", background: "#148f9f", border: "0", color: "white" }} onClick={() => {
                                                                            setCierreModalResumen(true);
                                                                            setAccionResumen(1);
                                                                            crearResumenMedico();
                                                                        }}>Resumen médico <AddIcon /></Button>
                                                                    </div>
                                                                    <TextAreaAntd
                                                                        label="Motivo de consulta:"
                                                                        value={!!DetalleConsulta ? DetalleConsulta.Consulta.Motivo : ""}
                                                                        placeholder="Motivo de consulta"
                                                                        style={{
                                                                            marginBottom: ".5%",
                                                                            fontFamily: "sans-serif",
                                                                        }}
                                                                        columns="30"
                                                                        autoSize={true}
                                                                        readOnly={true}
                                                                    />
                                                                    {/* Evolucion del padecimiento */}
                                                                    <Accordionn>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                        >
                                                                            <Typography>Evolucion del padecimiento</Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            <TextArea
                                                                                value={!!DetalleConsulta ? DetalleConsulta.Consulta.EvolucionPadecimiento : ""}
                                                                                placeholder="Evolucion del padecimiento"
                                                                                style={{
                                                                                    margin: ".5% 0",
                                                                                    fontFamily: "sans-serif",
                                                                                }}
                                                                                name=""
                                                                                id=""
                                                                                cols="30"
                                                                                autoSize
                                                                                readOnly={true}
                                                                            />
                                                                        </AccordionDetails>
                                                                    </Accordionn>
                                                                    {/* Exploración Física */}
                                                                    <Accordionn>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                        >
                                                                            <Typography> Exploración física</Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            <div style={{ display: "flex" }}>
                                                                                <div>
                                                                                    <InputAntd
                                                                                        label="Altura:"
                                                                                        className="InputNormal"
                                                                                        style={{ width: "100%" }}
                                                                                        placeholder="Altura (Mtrs.)"
                                                                                        value={!!DetalleConsulta ? DetalleConsulta.Consulta.Altura : ""}
                                                                                        vacio={true}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    <InputAntd
                                                                                        label="Peso:"
                                                                                        className="InputNormal"
                                                                                        style={{ width: "100%" }}
                                                                                        type=""
                                                                                        placeholder="Peso (Kg.)"
                                                                                        value={!!DetalleConsulta ? DetalleConsulta.Consulta.Peso : ""}
                                                                                        vacio={true}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                                {!!IMC && (
                                                                                    <>
                                                                                        <div>
                                                                                            <InputAntd
                                                                                                label="IMC:"
                                                                                                className="InputNormal"
                                                                                                style={{ width: "100%" }}
                                                                                                type=""
                                                                                                placeholder="IMC"
                                                                                                value={IMC}
                                                                                                vacio={true}
                                                                                                readOnly={true}
                                                                                            />
                                                                                        </div>
                                                                                        <div>
                                                                                            <Label></Label>
                                                                                            <Input
                                                                                                className="InputNormal"
                                                                                                style={{ width: "100%" }}
                                                                                                type=""
                                                                                                placeholder="IMC"
                                                                                                value={!!IMC ? IMC < 18 ? "Insuficiente" : IMC >= 18 && IMC <= 25 ? "Peso normal" : IMC > 25 && IMC <= 30 ? "Sobrepeso" : IMC > 30 && IMC <= 40 ? "Obesidad" : IMC > 40 && "Obesidad mórbida" : ""}
                                                                                                readOnly={true}
                                                                                                status={!!IMC ? IMC < 18 ? "warning" : IMC >= 18 && IMC <= 25 ? "" : IMC > 25 && IMC <= 30 ? "warning" : IMC > 30 && IMC <= 40 ? "warning" : IMC > 40 && "error" : ""}
                                                                                            />
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            <div style={{ display: "flex" }}>
                                                                                <div>
                                                                                    <InputAntd
                                                                                        label="T. Arterial"
                                                                                        className="InputNormal"
                                                                                        style={{ width: "100%" }}
                                                                                        type=""
                                                                                        placeholder="T. Arterial"
                                                                                        value={!!DetalleConsulta ? DetalleConsulta.Consulta.TensionArterial : ""}
                                                                                        vacio={true}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    <InputAntd
                                                                                        label="F. Cardiaca"
                                                                                        className="InputNormal"
                                                                                        style={{ width: "100%" }}
                                                                                        type=""
                                                                                        placeholder="F. Cardiaca"
                                                                                        value={!!DetalleConsulta ? DetalleConsulta.Consulta.FrecuenciaCardiaca : ""}
                                                                                        vacio={true}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    <InputAntd
                                                                                        label="F. Respiratoria"
                                                                                        className="InputNormal"
                                                                                        style={{ width: "100%" }}
                                                                                        type=""
                                                                                        placeholder="F. Respiratoria"
                                                                                        value={!!DetalleConsulta ? DetalleConsulta.Consulta.FrecuenciaRespiratoria : ""}
                                                                                        vacio={true}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    <InputAntd
                                                                                        label="Temperatura"
                                                                                        className="InputNormal"
                                                                                        style={{ width: "100%" }}
                                                                                        type=""
                                                                                        placeholder="Temperatura"
                                                                                        value={!!DetalleConsulta ? DetalleConsulta.Consulta.Temperatura : ""}
                                                                                        vacio={true}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    <InputAntd
                                                                                        label="O2"
                                                                                        className="InputNormal"
                                                                                        style={{ width: "100%" }}
                                                                                        type=""
                                                                                        placeholder="O2"
                                                                                        value={!!DetalleConsulta ? DetalleConsulta.Consulta.SaturacionOxigeno : ""}
                                                                                        vacio={true}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <TextArea
                                                                                value={!!DetalleConsulta ? DetalleConsulta.Consulta.ExploracionFisica : ""}
                                                                                placeholder="Exploración Física"
                                                                                style={{
                                                                                    margin: ".5% 0",
                                                                                    fontFamily: "sans-serif",
                                                                                }}
                                                                                name=""
                                                                                id=""
                                                                                cols="30"
                                                                                autoSize
                                                                                readOnly={true}
                                                                            />

                                                                        </AccordionDetails>
                                                                    </Accordionn>
                                                                    {/* Estudios y analisis */}
                                                                    <Accordionn>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                        >
                                                                            <Typography>Estudios y análisis</Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            {(!!DetalleConsulta && !!DetalleConsulta.Estudios) ? (
                                                                                <>
                                                                                    <TextArea
                                                                                        value={DetalleConsulta.Estudios.Descripcion}
                                                                                        placeholder="Descripcion"
                                                                                        style={{
                                                                                            margin: ".5% 0",
                                                                                            fontFamily: "sans-serif",
                                                                                        }}
                                                                                        name=""
                                                                                        id=""
                                                                                        cols="30"
                                                                                        autoSize
                                                                                        readOnly={true}
                                                                                    />

                                                                                    <div>
                                                                                        <p style={{ fontWeight: "500" }}>Lista de archivos</p>

                                                                                        {DetalleConsulta.Documentos.map((documento) => (
                                                                                            <div className="ListaDetalleEsAna" onClick={() => { window.open(`https://${documento.Multimedia}`, "_blank") }}>
                                                                                                {documento.Nombre.substring(documento.Nombre.toString().length, documento.Nombre.toString().length - 3) !== "pdf" ?
                                                                                                    (
                                                                                                        <>
                                                                                                            <img style={{ width: "10%" }} src={documento.Multimedia} alt="" />
                                                                                                            <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                                                                                <span>{documento.Nombre}</span>
                                                                                                            </div>
                                                                                                            <div>

                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) :
                                                                                                    (
                                                                                                        <>
                                                                                                            <img style={{ width: "7%", marginRight: "3px" }} src={Icono[22]} />
                                                                                                            <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                                                                                <span>{documento.Nombre}</span>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )}
                                                                                            </div>
                                                                                        ))}


                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <p style={{ textAlign: "center" }}>Sin información</p>
                                                                            )}
                                                                        </AccordionDetails>
                                                                    </Accordionn>
                                                                    {/* Diagnóstico clínico */}
                                                                    <Accordionn>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                        >
                                                                            <Typography>Diagnóstico clínico</Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            <TextArea
                                                                                value={!!DetalleConsulta ? DetalleConsulta.Consulta.Diagnostico : ""}
                                                                                placeholder="Diagnóstico clínico"
                                                                                style={{
                                                                                    margin: ".5% 0",
                                                                                    fontFamily: "sans-serif",
                                                                                }}
                                                                                name=""
                                                                                id=""
                                                                                cols="30"
                                                                                autoSize
                                                                                readOnly={true}
                                                                            />
                                                                        </AccordionDetails>
                                                                    </Accordionn>
                                                                    {/* Plan */}
                                                                    <Accordionn>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel2a-content"
                                                                            id="panel2a-header"
                                                                        >
                                                                            <Typography>Plan</Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            <TextAreaAntd
                                                                                label="Plan médico"
                                                                                id="plan_medico"
                                                                                value={!!DetalleConsulta ? DetalleConsulta.Consulta.Plan : ""}
                                                                                placeholder="Plan"
                                                                                style={{
                                                                                    marginBottom: ".5%",
                                                                                    fontFamily: "sans-serif",
                                                                                }}
                                                                                columns="30"
                                                                                autoSize={true}
                                                                                vacio={false}
                                                                                readOnly={true}
                                                                            />
                                                                            <h6>Receta</h6>

                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                    width: "100%",
                                                                                    alignItems: "center",
                                                                                    gap: "1%",
                                                                                }}
                                                                            >
                                                                            </div>
                                                                            {!!DetalleConsulta && DetalleConsulta.Receta.map((receta, index) => (
                                                                                <>
                                                                                    {receta.partidas.length > 0 ? (
                                                                                        <Accordionn key={index}>
                                                                                            <AccordionSummary
                                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                                aria-controls="panel1a-content"
                                                                                                id="panel1a-header"
                                                                                            >
                                                                                                <Typography>Receta {index + 1} - Folio: {receta.folio}</Typography>
                                                                                            </AccordionSummary>
                                                                                            <AccordionDetails>
                                                                                                <Table columns={columns} dataSource={receta.partidas} scroll={{ x: 5 }} />
                                                                                            </AccordionDetails>
                                                                                        </Accordionn>

                                                                                    ) : (
                                                                                        <p style={{ textAlign: "center" }}>Sin información</p>
                                                                                    )}
                                                                                </>
                                                                            ))}


                                                                            <hr
                                                                                style={{
                                                                                    width: "100%",
                                                                                    border: "1px solid #148f9f",
                                                                                    opacity: "100%",
                                                                                    borderRadius: "5rem",
                                                                                }}
                                                                            />

                                                                            <Row style={{ justifyContent: "start", margin: "2% 0" }}>
                                                                                <h4 style={{ margin: "1% 0" }}>Ordenes</h4>
                                                                            </Row>
                                                                            {!!DetalleConsulta && DetalleConsulta.Orden.map((orden, index) => (
                                                                                <>
                                                                                    {DetalleConsulta.Orden.length > 0 ? (
                                                                                        <Accordionn key={index}>
                                                                                            <AccordionSummary
                                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                                aria-controls="panel1a-content"
                                                                                                id="panel1a-header"
                                                                                            >
                                                                                                <Typography>{orden.tipoOrden === 1 ? `Laboratorio` : `Interconsulta`}</Typography>
                                                                                            </AccordionSummary>
                                                                                            <AccordionDetails>
                                                                                                {
                                                                                                    orden.tipoOrden === 1 ? (
                                                                                                        <TextAreaAntd
                                                                                                            placeholder="Descripción"
                                                                                                            value={orden.descripcion}
                                                                                                            style={{
                                                                                                                marginBottom: ".5%",
                                                                                                                fontFamily: "sans-serif",
                                                                                                            }}
                                                                                                            columns="30"
                                                                                                            autoSize={true}
                                                                                                            true={true}
                                                                                                        />
                                                                                                    ) : (

                                                                                                        <>
                                                                                                            <b>Datos interconsulta</b>
                                                                                                            <TextAreaAntd
                                                                                                                label="Motivo:"
                                                                                                                value={orden.motivo}
                                                                                                                placeholder="Escriba un motivo"
                                                                                                                style={{
                                                                                                                    margin: ".5% 0",
                                                                                                                    fontFamily: "sans-serif",
                                                                                                                }}
                                                                                                                columns="30"
                                                                                                                autoSize={true}
                                                                                                                readOnly={true}
                                                                                                            />

                                                                                                            <TextAreaAntd
                                                                                                                label="Diagnóstico:"
                                                                                                                value={orden.diagnostico}
                                                                                                                placeholder="Escriba un diagnóstico"
                                                                                                                style={{
                                                                                                                    margin: ".5% 0",
                                                                                                                    fontFamily: "sans-serif",
                                                                                                                }}
                                                                                                                columns="30"
                                                                                                                autoSize={true}
                                                                                                                readOnly={true}
                                                                                                            />
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                            </AccordionDetails>
                                                                                        </Accordionn>

                                                                                    ) : (
                                                                                        <p style={{ textAlign: "center" }}>Sin información</p>
                                                                                    )}
                                                                                </>
                                                                            ))}
                                                                        </AccordionDetails>
                                                                    </Accordionn>
                                                                    {/* Recomendaciones */}
                                                                    <Accordionn>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                        >
                                                                            <Typography>Indicaciones</Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            <TextArea
                                                                                value={!!DetalleConsulta ? DetalleConsulta.Consulta.Recomendaciones : ""}
                                                                                placeholder="Indicaciones"
                                                                                style={{
                                                                                    margin: ".5% 0",
                                                                                    fontFamily: "sans-serif",
                                                                                }}
                                                                                name=""
                                                                                id=""
                                                                                cols="30"
                                                                                autoSize
                                                                                readOnly={true}
                                                                            />
                                                                        </AccordionDetails>
                                                                    </Accordionn>
                                                                </div>
                                                            )}
                                                        </AccordionDetails>
                                                    </Accordionn>
                                                ))}
                                            </>
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* ESTUDIOS Y ANALISIS */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography>Estudios</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <>
                                                {EstudiosAnalisis.map((labTest, index) => (
                                                    <div
                                                        key={index}
                                                        className="ItemCard"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => { detailEstudios(labTest.id) }}
                                                    >
                                                        <div className="CardNotasMed">
                                                            <span className='overflow-ellipsis'>{!!labTest.descripcion ? labTest.descripcion : '\"Sin descripción\"'}</span> <span>{labTest.fecha}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* RECETAS */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography>Recetas</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <>
                                                {Recetas.map((receta, index) => (
                                                    // <div
                                                    //     key={index}
                                                    //     className="ItemCard"
                                                    //     style={{ cursor: "pointer" }}
                                                    //     onClick={() => { detailReceta(receta.id) }}
                                                    // >
                                                    //     <div className="CardNotasMed">
                                                    //         <span className='overflow-ellipsis'>{receta.folio}</span> <span>{receta.fecha}</span>
                                                    //     </div>
                                                    // </div>

                                                    <Accordionn expanded={expandedReceta === `panel_receta_${index}`} onChange={handleChange(`panel_receta_${index}`, receta.id, 'receta')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id={`panel1a-header`}
                                                        >

                                                            <Typography style={{ width: "95%" }}>
                                                                <div className="CardNotasMed">
                                                                    <span className='overflow-ellipsis'>{receta.folio}</span> <span>{receta.fecha}</span>
                                                                    {(loaderRecetaDetalle && expandedReceta === `panel_receta_${index}`) && <Spin indicator={<LoadingOutlined spin style={{ color: '#148f9f' }} />} />}
                                                                </div>
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {loaderRecetaDetalle && expandedReceta === `panel_receta_${index}` ? (
                                                                <Spin indicator={<LoadingOutlined spin style={{ color: '#148f9f' }} />} />
                                                            ) : (
                                                                <>
                                                                    <Row style={{ justifyContent: "space-between", padding: "1.5%" }}>
                                                                        {!!DetailRecetas && (
                                                                            <>
                                                                                {DetailRecetas.Receta.Enviar === 0 ? (
                                                                                    <div></div>
                                                                                ) : (
                                                                                    <>
                                                                                        {loaderReceta ? (
                                                                                            <CircularProgress size={30} sx={{ color: "#148f9f" }} />
                                                                                        ) : (
                                                                                            <ButtonAntd onClick={confirm}>
                                                                                                Enviar a paciente
                                                                                            </ButtonAntd>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}

                                                                        <Button
                                                                            className="GeneralBoton"
                                                                            style={{ width: "79px", color: "white", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                                                            onClick={() => {
                                                                                if (!!DetailRecetas) {
                                                                                    window.open(DetailRecetas.Receta.Url, "_blank");
                                                                                }
                                                                            }}
                                                                        >
                                                                            Ver<PictureAsPdfIcon sx={{ fontSize: "20px", cursor: "pointer" }} />
                                                                        </Button>
                                                                    </Row>
                                                                    <div className="ContentMidRec">
                                                                        <p style={{ fontWeight: "500", color: "3a3a3a" }}>
                                                                            Medicamentos:
                                                                        </p>
                                                                        {!!DetailRecetas ? DetailRecetas.Descripcion.map((medicamento) => (
                                                                            <p style={{ marginLeft: "14%", marginRight: "14%" }}>
                                                                                {medicamento.Cantidad +
                                                                                    " " +
                                                                                    medicamento.Concepto +
                                                                                    ", " +
                                                                                    medicamento.Descripcion}
                                                                            </p>
                                                                        )) : <p style={{ fontWeight: "500", color: "3a3a3a" }}>
                                                                            Sin medicamentos
                                                                        </p>}
                                                                        <br />
                                                                        <p style={{ fontWeight: "500", color: "3a3a3a" }}>
                                                                            Indicaciones:
                                                                        </p>
                                                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                                                            <TextArea
                                                                                autoSize
                                                                                readOnly
                                                                                bordered={false}
                                                                                style={{
                                                                                    marginLeft: "10%",
                                                                                    marginRight: "10%",
                                                                                    width: "75%",
                                                                                    color: "black",
                                                                                    fontSize: "16px",
                                                                                }}
                                                                                value={!!DetailRecetas ? DetailRecetas.Receta.Indicaciones : ''}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </AccordionDetails>
                                                    </Accordionn>
                                                ))}
                                            </>
                                        </AccordionDetails>
                                    </Accordionn>
                                </div>
                            )}
                        </Col>
                        <Col className="Content">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    // alignItems: "center",
                                    marginRight: "3%",
                                }}
                            >
                                {!Consulta ? (
                                    <div></div>
                                ) : (
                                    <div>
                                        <h3>Nota de evolución</h3>
                                    </div>
                                )}
                                <div className="BotonGuardarActivation">
                                    {Consulta ? (
                                        <Button
                                            type="button"
                                            size="md"
                                            style={{
                                                background: "#148F9F",
                                                padding: ".5% 5%",
                                                margin: "0",
                                                border: "#148F9F",
                                                color: "white"
                                            }}
                                            onClick={() => {
                                                if (!!MotivoConsulta && DiagnosticosSeleccionadosMostrados.length > 0 && !!Plan &&
                                                    !!Sexo && !!Nacimiento && ListaMedicamentos.length === 0 && !!!OrdenLabTest &&
                                                    InterconsultaAdjunta === 0) {
                                                    OpenAlertFinConsulta(true);
                                                } else {
                                                    let text = "Faltan campos por llenar\n";
                                                    if (!!!MotivoConsulta) {
                                                        setMensajeMotivoConsulta("false");
                                                    }
                                                    if (DiagnosticosSeleccionadosMostrados.length === 0) {
                                                        setErrorDiagnosticoAccordion(false);
                                                    }
                                                    if (!!!Plan) {
                                                        setErrorPlanAccordion(false);
                                                        setMensajePlan("false");
                                                    }
                                                    if (!!!Sexo) {
                                                        setMensajeSexo("false");
                                                    }
                                                    if (!!!Nacimiento) {
                                                        setMensajeNacimiento("false");
                                                    }

                                                    if (ListaMedicamentos.length > 0) {
                                                        setErrorPlanAccordion(false);
                                                        setMensajeRecetaNoAgregada("false");
                                                        text += "Tiene una receta pendiente de agregar\n"
                                                    }

                                                    if (!!OrdenLabTest) {
                                                        setErrorPlanAccordion(false);
                                                        setMensajeOrdenLabNoAgregada("false");
                                                        text += "Tiene una orden de laboratorio pendiente de agregar\n"
                                                    }

                                                    if (InterconsultaAdjunta === 1) {
                                                        setErrorPlanAccordion(false);
                                                        setMensajeOrdenInterNoAgregada("false");
                                                        text += "Tiene una orden interconsulta pendiente de agregar\n"
                                                    }
                                                    ClosedAlertFinConsulta(true);
                                                    setOpen(true);
                                                    setTypeMessage("warning");
                                                    setMessage(text);
                                                }
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "6px",
                                                }}
                                            >
                                                <span>Finalizar consulta</span>
                                                <img
                                                    style={{ width: "12px", height: "12px" }}
                                                    src={CheckGuardar}
                                                    alt=""
                                                />
                                            </div>
                                        </Button>
                                    ) : (
                                        <Button
                                            type="button"
                                            onClick={() => {

                                                if (!!Sexo && !!Nacimiento) {
                                                    setConsulta(true);
                                                } else {
                                                    if (!!!Sexo) {
                                                        setMensajeSexo("false");
                                                    }
                                                    if (!!!Nacimiento) {
                                                        setMensajeNacimiento("false");
                                                    }
                                                    setOpen(true);
                                                    setTypeMessage("warning");
                                                    setMessage("Faltan campos por completar");
                                                }
                                            }}
                                            size="md"
                                            style={{
                                                background: "#148F9F",
                                                padding: ".5% 5%",
                                                margin: "0",
                                                border: "#148F9F",
                                                color: "white"
                                            }}
                                        >
                                            Continuar
                                        </Button>
                                    )}
                                </div>
                            </div>

                            {!Consulta ? (
                                // ### VISTA ANTECEDENTES EN HISTORIA CLINICA NUEVA ###
                                <div className="ScrollNotas" style={{ marginTop: "1%" }}>
                                    {/* DATOS DE PAREJA */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>
                                                Datos de pareja
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <InputAntd
                                                label="Nombre:"
                                                className="inputblue"
                                                value={NombrePareja}
                                                setStateValue={setNombrePareja}
                                                placeholder="Escriba un nombre completo"
                                                vacio={true}
                                                styleDiv={{ width: "100%" }}
                                            // maxLength={15}
                                            />
                                            <Row style={{ justifyContent: "space-between" }}>
                                                <InputAntd
                                                    className="inputblue"
                                                    value={OcupacionPareja}
                                                    setStateValue={setOcupacionPareja}
                                                    label="Ocupación:"
                                                    placeholder="Escribe una ocupación"
                                                    // valueError={MensajeAddNombrecontacto}
                                                    vacio={true}
                                                    // disabled={false}
                                                    // expresionRegular={expresiones.Letras}
                                                    styleDiv={{ width: "70%" }}
                                                />

                                                <InputAntd
                                                    label="Edad:"
                                                    className="inputblue"
                                                    value={EdadPareja}
                                                    setStateValue={setEdadPareja}
                                                    placeholder="Edad"
                                                    vacio={true}
                                                    styleDiv={{ width: "15%" }}
                                                    maxLength={2}
                                                />
                                            </Row>
                                            <TextAreaAntd
                                                label="Notas/Comentarios:"
                                                value={NotasPareja}
                                                placeholder="Escriba una nota o comentario"
                                                style={{
                                                    // margin: ".5% 0",
                                                    marginBottom: ".5%",
                                                    fontFamily: "sans-serif",
                                                }}
                                                columns="30"
                                                autoSize={true}
                                                setStateValue={setNotasPareja}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* ANTECEDENTES HEREDO-FAMILIARES */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography> Antecedentes heredo-familiares</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TextArea
                                                placeholder="Antecedentes heredo-familiares"
                                                style={{
                                                    margin: ".5% 0",
                                                    fontFamily: "sans-serif",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                onChange={(e) => {
                                                    setAHeredoFamiliares(e.target.value);
                                                }}
                                                value={AHeredoFamiliares}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* ANTECEDENTES PATOLOGICOS */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography>
                                                Antecedentes personales patológicos
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TextArea
                                                placeholder="Antecedentes personales patológicos"
                                                style={{
                                                    margin: ".5% 0",
                                                    fontFamily: "sans-serif",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                onChange={(e) => {
                                                    setAPersonalesPatologicos(e.target.value);
                                                }}
                                                value={APersonalesPatologicos}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* ANTECEDENTES NO PATOLOGICOS */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography>
                                                Antecedentes personales no patológicos
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TextArea
                                                placeholder="Antecedentes personales patológicos"
                                                style={{
                                                    margin: ".5% 0",
                                                    fontFamily: "sans-serif",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                onChange={(e) => {
                                                    setAPersonalesNoPatologicos(e.target.value);
                                                }}
                                                value={APersonalesNoPatologicos}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* ANTECEDENTES ANDROLOGICOS O GINECO-OBSTETRICOS */}
                                    {!!Sexo && (
                                        <Accordionn>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                            >
                                                <Typography>
                                                    {Sexo === "Masculino" ? "Antecedentes andrológicos" : "Antecedentes gineco-obstetricos"}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {Sexo !== "Masculino" && (
                                                    <>
                                                        <Label>¿Paciente embarazada?</Label>
                                                        <div style={{ display: "flex", justifyContent: "space-between", width: "30%" }}>
                                                            <Checkbox checked={Embarazada === 1 ? true : false} onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setEmbarazada(1);
                                                                }
                                                            }}>Si</Checkbox>
                                                            <Checkbox checked={Embarazada === 0 ? true : false} onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setEmbarazada(0);
                                                                }
                                                            }}>No</Checkbox>
                                                        </div>
                                                    </>
                                                )}
                                                <label htmlFor="">Generales</label>
                                                <TextArea
                                                    placeholder="Inicio de vida sexual, método de planificación familiar, orientación sexual, si sostiene relaciones sexuales de alto riesgo, alteraciones de la libido, si ha padecido enfermedades de transmisión sexual."
                                                    style={{
                                                        margin: ".5% 0",
                                                        fontFamily: "sans-serif",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    onChange={(e) => {
                                                        setAAndroGineGeneral(e.target.value);
                                                    }}
                                                    value={AAndroGineGeneral}
                                                />
                                                <label htmlFor="">{Sexo === "Masculino" ? "Andrológicos específicos" : "Ginecoobstetricos"}</label>
                                                <TextArea
                                                    placeholder={Sexo === "Masculino" ? "Priapismo, alteraciones de la erección y/o eyaculación, secreción uretral, dolor testicular, alteraciones escrotales, enfermedades de transmisión sexual."
                                                        : "Menarca, Telarca, Pubarca, Gestas, Partos Cesáreas, Abortos, Dismenorreas, Ciclo menstrual."}
                                                    style={{
                                                        margin: ".5% 0",
                                                        fontFamily: "sans-serif",
                                                    }}
                                                    name=""
                                                    id=""
                                                    cols="30"
                                                    autoSize
                                                    onChange={(e) => {
                                                        setAAndroGine(e.target.value);
                                                    }}
                                                    value={AAndroGine}
                                                />
                                            </AccordionDetails>
                                        </Accordionn>
                                    )
                                    }
                                    {/* INTERROGATORIO APARATOS Y SISTEMAS */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography>
                                                Interrogatorio por aparatos y sistemas
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <label htmlFor="">Signos y síntomas generales</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Fiebre, cefalea, astenia, adinamia y anorexia."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={SignosSintomas}
                                                onChange={(e) => {
                                                    setSignosSintomas(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Aparato cardiovascular</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Disnea, dolor precordial, palpitaciones, sincope, lipotimia, edema, cianosis, acúfenos, fosfenos. "
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={AparatoCardiovascular}
                                                onChange={(e) => {
                                                    setAparatoCardiovascular(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Aparato respiratorio</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Rinorrea, rinolalia, tos, expectoración, disnea, dolor torácico, epistaxis, disfonía, hemoptisis, vómica, sibilancias audibles a distancia. "
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={AparatoRespiratorio}
                                                onChange={(e) => {
                                                    setAparatoRespiratorio(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Aparato digestivo</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Trastornos de la deglución, de la digestión, de la defecación, nausea, vómito, dolor abdominal, diarrea, constipación, ictericia, rumiación, regurgitación, pirosis, aerofagia, eructos, meteorismos, distención abdominal, flatulencia, hematemesis, características de las heces fecales, diarrea, estreñimiento, acolia, hipocolia, melena, rectorragia, lientería, pujo, tenesmo y prurito anal."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={AparatoDigestivo}
                                                onChange={(e) => {
                                                    setAparatoDigestivo(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Sistema nefrourologico</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Dolor renoureteral, hematuria, piuria, coluría, oliguria, tenesmo, control de esfínteres, cólico renal, edema, trastornos en el volumen urinario, nictámero, urgencia, características del chorro urinario."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={SistemaNefrourologico}
                                                onChange={(e) => {
                                                    setSistemaNefrourologico(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Sistema endocrino y metabolismo</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Intolerancia al calor o al frio, pérdida o aumento de peso, alteraciones del color de la piel, vello corporal, distribución de la grasa corporal, astenia o adinamia, alteraciones del volumen urinario, amenorreas, ginecomastia, galactorrea, alteraciones de la libido, espasmos o calambres musculares."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={SistemaEndocrino}
                                                onChange={(e) => {
                                                    setSistemaEndocrino(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Sistema hematopoyético</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Palidez, rubicundez, adenomegalias, hemorragias, fiebre, fatigabilidad, equimosis, petequias y adenomegalias."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={SistemaHematoproyetico}
                                                onChange={(e) => {
                                                    setSistemaHematoproyetico(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Sistema nervioso</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Cefalalgia, pérdida de conocimiento, mareos vértigo y trastornos del equilibrio, movimientos anormales involuntarios, debilidad muscular, convulsiones, trastornos de la visión, dolor hormigueo y adormecimiento, trastornos del sueño."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={SistemaNervioso}
                                                onChange={(e) => {
                                                    setSistemaNervioso(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Sistema musculo esquelético</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Mialgias, dolor óseo, artralgias, alteraciones en la marcha, hipotonía, disminución del volumenmuscular, limitación de movimientos y deformidades."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={MusculoEsqueletico}
                                                onChange={(e) => {
                                                    setMusculoEsqueletico(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Piel y tegumentos</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Coloración, pigmentación, prurito, características del pelo, uñas, lesiones (primarias y secundarias), hiperhidrosis y xerodermia."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={PielTegumentos}
                                                onChange={(e) => {
                                                    setPielTegumentos(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Órganos de los sentidos</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Alteraciones de la visión, de la audición, del olfato, del gusto y del tacto (hipo, hiper o disfunción). Mareo y sensación de líquido en el oído."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={OrganosSentidos}
                                                onChange={(e) => {
                                                    setOrganosSentidos(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Esfera psíquica</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Tristeza, euforia, alteraciones del sueño, , terrores nocturnos, ideaciones, miedo exagerado a situaciones comunes, irritabilidad, apatía."
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={EsferaPsiquica}
                                                onChange={(e) => {
                                                    setEsferaPsiquica(e.target.value);
                                                }}
                                            />
                                            <label htmlFor="">Genitourianrio</label>
                                            <TextArea
                                                className="InputNormal"
                                                placeholder="Hematuria, incontinencia, tenesmo vesical, disuria y ardor, poliuria, oliguria, anuria, polaquiuria, nicturia, opsiuria"
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    width: "100%",
                                                    padding: ".5%",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={Genitales}
                                                onChange={(e) => {
                                                    setGenitales(e.target.value);
                                                }}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                </div>
                            ) : (
                                // ### VISTA DE CONSULTA NOTA DE EVOLUCION ###
                                <div className="ScrollNotas">
                                    <div className='headerDateConsulta'>
                                        <b><b style={{ color: "#ff4d4f", fontSize: "14px" }}>*&nbsp;</b>{`Seccion(es)/Campo(s) obligatorio(s)`}</b>
                                        <Campos
                                            label="Fecha:"
                                            // styleDiv={{width:"100%"}}
                                            classname="datepicker_consulta"
                                        >
                                            <DatePicker
                                                inputReadOnly={true}
                                                // style={{ width: "30%", background: "white" }}
                                                value={dayjs(FechaConsulta)}
                                                format="DD/MM/YYYY"
                                                locale={locale}
                                                onSelect={(e) => {
                                                    // console.log(e);
                                                    let temp = e.$d;
                                                    let mesObtenido = temp.getMonth() + 1;
                                                    // console.log(mesObtenido);
                                                    let mesCalculo = mesObtenido.toString().length === 1 ? `0${mesObtenido}` : mesObtenido;
                                                    // console.log(mesCalculo);
                                                    let fecha_enviar = temp.getFullYear() + "-" + mesCalculo + "-" + temp.getDate();
                                                    // console.log(fecha_enviar);
                                                    setFechaConsulta(fecha_enviar);
                                                }}
                                                onBlur={(e) => {
                                                    // console.log(e.target.value);
                                                    // console.log(e.target.value.split("-"));
                                                    let arrayFechaFormat = e.target.value.split("-");
                                                    let arrayFecha = e.target.value.split("/");
                                                    if (arrayFecha.length === 3) {
                                                        let fecha = new Date(`${arrayFecha[1]}/${arrayFecha[0]}/${arrayFecha[2]}`);
                                                        // let temp = e.$d
                                                        // console.log(fecha.getMonth());
                                                        let mesObtenido = fecha.getMonth() + 1;
                                                        // console.log(mesObtenido);
                                                        let mesCalculo = mesObtenido.toString().length === 1 ? `0${mesObtenido}` : mesObtenido;
                                                        // console.log(mesCalculo);
                                                        let fecha_enviar = fecha.getFullYear() + "-" + mesCalculo + "-" + fecha.getDate();
                                                        // console.log(fecha_enviar);
                                                        setFechaConsulta(fecha_enviar);
                                                    } else if (arrayFechaFormat.length === 3) {
                                                        let fecha = new Date(`${arrayFechaFormat[1]}/${arrayFechaFormat[0]}/${arrayFechaFormat[2]}`);
                                                        // let temp = e.$d
                                                        // console.log(fecha.getMonth());
                                                        let mesObtenido = fecha.getMonth() + 1;
                                                        // console.log(mesObtenido);
                                                        let mesCalculo = mesObtenido.toString().length === 1 ? `0${mesObtenido}` : mesObtenido;
                                                        // console.log(mesCalculo);
                                                        let fecha_enviar = fecha.getFullYear() + "-" + mesCalculo + "-" + fecha.getDate();
                                                        // console.log(fecha_enviar);
                                                        setFechaConsulta(fecha_enviar);
                                                    }

                                                }}
                                            />
                                        </Campos>
                                    </div>
                                    <TextAreaAntd
                                        label="Motivo de consulta:"
                                        requiredICon={true}
                                        value={MotivoConsulta}
                                        placeholder="Motivo de consulta"
                                        style={{
                                            marginBottom: ".5%",
                                            fontFamily: "sans-serif",
                                        }}
                                        columns="30"
                                        autoSize={true}
                                        setStateValue={setMotivoConsulta}
                                        setStateError={setMensajeMotivoConsulta}
                                        valueError={MensajeMotivoConsulta}
                                        textError={["Debe escribir un motivo de consulta"]}
                                        vacio={false}
                                    />
                                    {/* EVOLUCION PADECIMIENTO */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Evolución del padecimiento</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TextArea
                                                placeholder="Evolucion del padecimiento"
                                                style={{
                                                    margin: ".5% 0",
                                                    fontFamily: "sans-serif",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                value={EvolucionPadecimiento}
                                                onChange={(e) => {
                                                    setEvolucionPadecimiento(e.target.value);
                                                }}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* EXPLORACION FISICA */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>
                                                {!ExpFisicaCheck && (
                                                    <>
                                                        {!ErrorExploFisica && (<><CancelRounded color="error" />&nbsp;</>)}
                                                        {/* <b style={{ color: "#ff4d4f", fontSize: "14px", fontFamily: "SimSun,sans-serif" }}>*&nbsp;</b> */}
                                                    </>
                                                )}
                                                Exploración física <Checkbox
                                                    value={ExpFisicaCheck}
                                                    defaultChecked={ExpFisicaCheck}
                                                    style={{ marginLeft: "10px" }}
                                                    onChange={(e) => {
                                                        setExpFisicaCheck(e.target.checked);
                                                        if (e.target.checked) {
                                                            clearExpFisica();
                                                        }
                                                    }}
                                                >N/A</Checkbox></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {!ExpFisicaCheck && (
                                                <>
                                                    <div className="DisplayDetalle">
                                                        <Campos
                                                            label="Altura (mtrs.):"
                                                            styleLabel={{ fontSize: "14px" }}
                                                        // requiredICon={true}
                                                        // vacio={false}
                                                        // textError={"Campo obligatorio"}
                                                        // valueError={MensajeAltura}
                                                        >
                                                            <Input
                                                                placeholder="Altura (mtrs.)"
                                                                className="InputNormal"
                                                                style={{ width: "100%" }}
                                                                // status={MensajeAltura === "false" && "error"}
                                                                value={Altura}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    // console.log(expresiones.numerosYPunto.test(e.target.value));
                                                                    if (expresiones.numerosYPunto.test(e.target.value)) {
                                                                        // console.log(e.target.value);
                                                                        setOpen(false);
                                                                        // setMensajeAltura("true");
                                                                        setAltura(e.target.value);
                                                                        if (!!e.target.value && !!Peso) {
                                                                            let imc = Peso / (e.target.value * e.target.value);
                                                                            setIMC(imc.toFixed(2));
                                                                        } else {
                                                                            setIMC("");
                                                                        }
                                                                        // if (!!TArterial && !!FCardiaca && !!FRespiratoria && !!Temperatura &&
                                                                        //     !!e.target.value && !!Peso && !!SOxigeno && !!ExploracionFisica) {
                                                                        //     setErrorExploFisica("true");
                                                                        // }
                                                                    } else {
                                                                        if (!!e.target.value) {
                                                                            setIMC("");
                                                                            setOpen(true);
                                                                            setTypeMessage("warning");
                                                                            setMessage("Solo se permiten numeros y simbolos(.)");
                                                                        } else {
                                                                            setIMC("");
                                                                            setAltura("");
                                                                            // setMensajeAltura("false");
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </Campos>

                                                        <Campos
                                                            label="Peso:"
                                                            styleLabel={{ fontSize: "14px" }}
                                                        // requiredICon={true}
                                                        // vacio={false}
                                                        // textError={"Campo obligatorio"}
                                                        // valueError={MensajePeso}
                                                        >
                                                            <Input
                                                                placeholder="Peso"
                                                                className="InputNormal"
                                                                style={{ width: "100%" }}
                                                                // status={MensajePeso === "false" && "error"}
                                                                value={Peso}
                                                                onChange={(e) => {
                                                                    if (expresiones.numerosYPunto.test(e.target.value)) {
                                                                        // console.log(e.target.value);
                                                                        setPeso(e.target.value);
                                                                        // setMensajePeso("true");
                                                                        setOpen(false);
                                                                        if (!!e.target.value && !!Altura) {
                                                                            let imc = e.target.value / (Altura * Altura);
                                                                            setIMC(imc.toFixed(2));
                                                                        } else {
                                                                            setIMC("");
                                                                        }
                                                                        // if (!!TArterial && !!FCardiaca && !!FRespiratoria && !!Temperatura &&
                                                                        //     !!Altura && !!e.target.value && !!SOxigeno && !!ExploracionFisica) {
                                                                        //     setErrorExploFisica("true");
                                                                        // }
                                                                    } else {
                                                                        if (!!e.target.value) {
                                                                            setIMC("");
                                                                            setOpen(true);
                                                                            setTypeMessage("warning");
                                                                            setMessage("Solo se permiten numeros y simbolos(.)");
                                                                        } else {
                                                                            setIMC("");
                                                                            setPeso("");
                                                                            // setMensajePeso("false");
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </Campos>

                                                        <InputAntd
                                                            label="IMC:"
                                                            className="InputNormal"
                                                            style={{ width: "100%" }}
                                                            styleLabel={{ fontSize: "14px" }}
                                                            type=""
                                                            placeholder="IMC"
                                                            value={IMC}
                                                            setStateValue={setIMC}
                                                            vacio={true}
                                                            // disabled={true}
                                                            readOnly={true}
                                                        />

                                                        <Campos label={" "}>
                                                            <Input
                                                                className="InputNormal"
                                                                style={{ width: "100%" }}
                                                                type=""
                                                                placeholder="Tipo de peso"
                                                                value={!!IMC ? IMC < 18 ? "Insuficiente" : IMC >= 18 && IMC <= 25 ? "Peso normal" : IMC > 25 && IMC <= 30 ? "Sobrepeso" : IMC > 30 && IMC <= 40 ? "Obesidad" : IMC > 40 && "Obesidad mórbida" : ""}
                                                                readOnly={true}
                                                                status={!!IMC ? IMC < 18 ? "warning" : IMC >= 18 && IMC <= 25 ? "" : IMC > 25 && IMC <= 30 ? "warning" : IMC > 30 && IMC <= 40 ? "warning" : IMC > 40 && "error" : ""}
                                                            />
                                                        </Campos>
                                                    </div>
                                                    <div className="DisplayDetalle">
                                                        <Campos
                                                            label="T. Arterial"
                                                            styleLabel={{ fontSize: "14px" }}
                                                        // requiredICon={true}
                                                        // vacio={false}
                                                        // textError={"Campo obligatorio"}
                                                        // valueError={MensajeTArterial}
                                                        >
                                                            <Input
                                                                placeholder="T. Arterial"
                                                                className="InputNormal"
                                                                style={{ width: "100%" }}
                                                                // status={MensajeTArterial === "false" && "error"}
                                                                value={TArterial}
                                                                onChange={(e) => {
                                                                    if (expresiones.numerosYguionDiag.test(e.target.value)) {
                                                                        setOpen(false);
                                                                        // setMensajeTArterial("true");
                                                                        setTArterial(e.target.value);
                                                                        // if (!!e.target.value && !!FCardiaca && !!FRespiratoria && !!Temperatura &&
                                                                        //     !!Altura && !!Peso && !!SOxigeno && !!ExploracionFisica) {
                                                                        //     setErrorExploFisica("true");
                                                                        // }
                                                                    } else {
                                                                        if (!!e.target.value) {
                                                                            setOpen(true);
                                                                            setTypeMessage("warning");
                                                                            setMessage("Solo se permiten numeros y simbolos(- /)");
                                                                        } else {
                                                                            setTArterial("");
                                                                            // setMensajeTArterial("false");
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </Campos>
                                                        {/* <InputAntd
                                                            label="T. Arterial"
                                                            className="InputNormal"
                                                            style={{ width: "100%" }}
                                                            requiredICon={true}
                                                            styleLabel={{ fontSize: "14px" }}
                                                            type=""
                                                            placeholder="T. Arterial"
                                                            value={TArterial}
                                                            setStateValue={setTArterial}
                                                            expresionRegular={expresiones.numerosYguionDiag}
                                                            setStateError={setMensajeTArterial}
                                                            valueError={MensajeTArterial}
                                                            textError={["", "Solo se permiten numeros y simbolos(- /)"]}
                                                            vacio={false}
                                                            disabled={false}
                                                        /> */}
                                                        <Campos
                                                            label="F. Cardiaca"
                                                            styleLabel={{ fontSize: "14px" }}
                                                        // requiredICon={true}
                                                        // vacio={false}
                                                        // textError={"Campo obligatorio"}
                                                        // valueError={MensajeFCardiaca}
                                                        >
                                                            <Input
                                                                placeholder="F. Cardiaca"
                                                                className="InputNormal"
                                                                style={{ width: "100%" }}
                                                                status={MensajeFCardiaca === "false" && "error"}
                                                                value={FCardiaca}
                                                                onChange={(e) => {
                                                                    if (expresiones.numerosYguionDiag.test(e.target.value)) {
                                                                        setOpen(false);
                                                                        // setMensajeFCardiaca("true");
                                                                        setFCardiaca(e.target.value);
                                                                        // if (!!TArterial && !!e.target.value && !!FRespiratoria && !!Temperatura &&
                                                                        //     !!Altura && !!Peso && !!SOxigeno && !!ExploracionFisica) {
                                                                        //     setErrorExploFisica("true");
                                                                        // }
                                                                    } else {
                                                                        if (!!e.target.value) {
                                                                            setOpen(true);
                                                                            setTypeMessage("warning");
                                                                            setMessage("Solo se permiten numeros y simbolos(- /)");
                                                                        } else {
                                                                            setFCardiaca("");
                                                                            // setMensajeFCardiaca("false");
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </Campos>
                                                        {/* <InputAntd
                                                            label="F. Cardiaca"
                                                            className="InputNormal"
                                                            style={{ width: "100%" }}
                                                            requiredICon={true}
                                                            styleLabel={{ fontSize: "14px" }}
                                                            type=""
                                                            placeholder="F. Cardiaca"
                                                            value={FCardiaca}
                                                            setStateValue={setFCardiaca}
                                                            expresionRegular={expresiones.numerosYguionDiag}
                                                            setStateError={setMensajeFCardiaca}
                                                            valueError={MensajeFCardiaca}
                                                            textError={["", "Solo se permiten numeros y simbolos(- /)"]}
                                                            vacio={false}
                                                            disabled={false}
                                                        /> */}

                                                        <Campos
                                                            label="F. Respiratoria"
                                                            styleLabel={{ fontSize: "14px" }}
                                                        // requiredICon={true}
                                                        // vacio={false}
                                                        // textError={"Campo obligatorio"}
                                                        // valueError={MensajeFRespiratoria}
                                                        >
                                                            <Input
                                                                placeholder="F. Respiratoria"
                                                                className="InputNormal"
                                                                style={{ width: "100%" }}
                                                                // status={MensajeFRespiratoria === "false" && "error"}
                                                                value={FRespiratoria}
                                                                onChange={(e) => {
                                                                    if (expresiones.numerosYguionDiag.test(e.target.value)) {
                                                                        setOpen(false);
                                                                        // setMensajeFRespiratoria("true");
                                                                        setFRespiratoria(e.target.value);
                                                                        // if (!!TArterial && !!FCardiaca && !!e.target.value && !!Temperatura &&
                                                                        //     !!Altura && !!Peso && !!SOxigeno && !!ExploracionFisica) {
                                                                        //     setErrorExploFisica("true");
                                                                        // }
                                                                    } else {
                                                                        if (!!e.target.value) {
                                                                            setOpen(true);
                                                                            setTypeMessage("warning");
                                                                            setMessage("Solo se permiten numeros y simbolos(- /)");
                                                                        } else {
                                                                            setFRespiratoria("");
                                                                            // setMensajeFRespiratoria("false");
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </Campos>
                                                        {/* <InputAntd
                                                            label="F. Respiratoria"
                                                            className="InputNormal"
                                                            style={{ width: "100%" }}
                                                            styleLabel={{ fontSize: "14px", }}
                                                            type=""
                                                            placeholder="F. Respiratoria"
                                                            value={FRespiratoria}
                                                            setStateValue={setFRespiratoria}
                                                            expresionRegular={expresiones.numerosYguionDiag}
                                                            setStateError={setMensajeFRespiratoria}
                                                            valueError={MensajeFRespiratoria}
                                                            textError={["", "Solo se permiten numeros y simbolos(- /)"]}
                                                            vacio={false}
                                                            disabled={false}
                                                        /> */}

                                                        <Campos
                                                            label="Temperatura"
                                                            styleLabel={{ fontSize: "14px" }}
                                                        // requiredICon={true}
                                                        // vacio={false}
                                                        // textError={"Campo obligatorio"}
                                                        // valueError={MensajeTemperatura}
                                                        >
                                                            <Input
                                                                placeholder="Temperatura"
                                                                className="InputNormal"
                                                                style={{ width: "100%" }}
                                                                // status={MensajeTemperatura === "false" && "error"}
                                                                value={Temperatura}
                                                                onChange={(e) => {
                                                                    if (expresiones.numerosYPunto.test(e.target.value)) {
                                                                        setOpen(false);
                                                                        // setMensajeTemperatura("true");
                                                                        setTemperatura(e.target.value);
                                                                        // if (!!TArterial && !!FCardiaca && !!FRespiratoria && !!e.target.value &&
                                                                        //     !!Altura && !!Peso && !!SOxigeno && !!ExploracionFisica) {
                                                                        //     setErrorExploFisica("true");
                                                                        // }
                                                                    } else {
                                                                        if (!!e.target.value) {
                                                                            setOpen(true);
                                                                            setTypeMessage("warning");
                                                                            setMessage("Solo se permiten numeros y simbolos(.)");
                                                                        } else {
                                                                            setTemperatura("");
                                                                            // setMensajeTemperatura("false");
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </Campos>

                                                        {/* <InputAntd
                                                            label="Temperatura"
                                                            className="InputNormal"
                                                            style={{ width: "100%" }}
                                                            requiredICon={true}
                                                            styleLabel={{ fontSize: "14px" }}
                                                            type=""
                                                            placeholder="Temperatura"
                                                            value={Temperatura}
                                                            setStateValue={setTemperatura}
                                                            expresionRegular={expresiones.numerosYPunto}
                                                            setStateError={setMensajeTemperatura}
                                                            valueError={MensajeTemperatura}
                                                            textError={["", "Solo se permiten numeros y simbolos(.)"]}
                                                            vacio={false}
                                                            disabled={false}
                                                        /> */}

                                                        <Campos
                                                            label="O2"
                                                            styleLabel={{ fontSize: "14px" }}
                                                        // requiredICon={true}
                                                        // vacio={false}
                                                        // textError={"Campo obligatorio"}
                                                        // valueError={MensajeSOxigeno}
                                                        >
                                                            <Input
                                                                placeholder="O2"
                                                                className="InputNormal"
                                                                style={{ width: "100%" }}
                                                                status={MensajeSOxigeno === "false" && "error"}
                                                                value={SOxigeno}
                                                                onChange={(e) => {
                                                                    if (expresiones.numerosYguionDiag.test(e.target.value)) {
                                                                        setOpen(false);
                                                                        // setMensajeSOxigeno("true");
                                                                        setSOxigeno(e.target.value);
                                                                        // if (!!TArterial && !!FCardiaca && !!FRespiratoria && !!Temperatura &&
                                                                        //     !!Altura && !!Peso && !!e.target.value && !!ExploracionFisica) {
                                                                        //     setErrorExploFisica("true");
                                                                        // }
                                                                    } else {
                                                                        if (!!e.target.value) {
                                                                            setOpen(true);
                                                                            setTypeMessage("warning");
                                                                            setMessage("Solo se permiten numeros y simbolos(- /)");
                                                                        } else {
                                                                            setSOxigeno("");
                                                                            // setMensajeSOxigeno("false");
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </Campos>

                                                        {/* <InputAntd
                                                            label="O2"
                                                            className="InputNormal"
                                                            style={{ width: "100%" }}
                                                            requiredICon={true}
                                                            type=""
                                                            placeholder="O2"
                                                            styleLabel={{ fontSize: "14px" }}
                                                            value={SOxigeno}
                                                            setStateValue={setSOxigeno}
                                                            expresionRegular={expresiones.numerosYguionDiag}
                                                            setStateError={setMensajeSOxigeno}
                                                            valueError={MensajeSOxigeno}
                                                            textError={["", "Solo se permiten numeros y simbolos(- /)"]}
                                                            vacio={false}
                                                            disabled={false}
                                                        /> */}


                                                    </div>
                                                    <Campos
                                                        // label="O2"
                                                        styleLabel={{ fontSize: "14px" }}
                                                    // requiredICon={true}
                                                    // vacio={false}
                                                    // textError={"Campo obligatorio"}
                                                    // valueError={MensajeExpFisica}
                                                    >
                                                        <TextArea
                                                            placeholder="Exploración física"
                                                            value={ExploracionFisica}
                                                            style={{
                                                                marginBottom: ".5%",
                                                                fontFamily: "sans-serif",
                                                            }}
                                                            cols="30"
                                                            autoSize={true}
                                                            onChange={(e) => {
                                                                setExploracionFisica(e.target.value);
                                                                // if (!!e.target.value) {
                                                                //     setMensajeExpFisica("true");
                                                                // } else {
                                                                //     setMensajeExpFisica("false");
                                                                // }
                                                                // if (!!TArterial && !!FCardiaca && !!FRespiratoria && !!Temperatura &&
                                                                //     !!Altura && !!Peso && !!SOxigeno && !!e.target.value) {
                                                                //     setErrorExploFisica("true");
                                                                // }
                                                            }}
                                                        />
                                                    </Campos>
                                                    {/* <TextAreaAntd
                                                        requiredICon={true}
                                                        value={ExploracionFisica}
                                                        placeholder="Exploración física"
                                                        style={{
                                                            marginBottom: ".5%",
                                                            fontFamily: "sans-serif",
                                                        }}
                                                        columns="30"
                                                        autoSize={true}
                                                        setStateValue={setExploracionFisica}
                                                        setStateError={setMensajeExpFisica}
                                                        valueError={MensajeExpFisica}
                                                        textError={["Debe escribir una exploración física"]}
                                                        vacio={false}
                                                    /> */}
                                                </>
                                            )}

                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* ESTUDIOS Y ANALISIS */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Estudios y análisis</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Checkbox
                                                    defaultChecked
                                                    value={EnviarLabTest}
                                                    onChange={(e) => setEnviarLabTest(e.checked)}
                                                    style={{ marginBottom: "2%" }}
                                                >Enviar a paciente</Checkbox>
                                            </div>
                                            <TextArea
                                                value={DescripcionLabTest}
                                                placeholder="Descripcion"
                                                style={{
                                                    margin: ".5% 0",
                                                    fontFamily: "sans-serif",
                                                }}
                                                name=""
                                                id=""
                                                cols="30"
                                                autoSize
                                                onChange={(e) => {
                                                    setDescripcionLabTest(e.target.value);
                                                }}
                                            />
                                            <div style={{ textAlign: "center" }}>
                                                <InputFileList valueArrayImg={ListaArchivos} arrayImg={setListaArchivos} extras={[setOpen, setTypeMessage, setMessage]} />
                                                {ListaArchivos.length === 0 && (
                                                    <span>No hay archivos selecionados</span>
                                                )}
                                            </div>
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* DIAGNOSTICO CLINICO */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>
                                                {(!ErrorDiagnosticoAccordion) && (<><CancelRounded color="error" />&nbsp;</>)}
                                                <b style={{ color: "#ff4d4f", fontSize: "14px", fontFamily: "SimSun,sans-serif" }}>*&nbsp;</b>
                                                Diagnóstico clínico
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <LeyendaError valido={ErrorDiagnosticoAccordion.toString()}>Debe agregar un diagnóstico</LeyendaError>
                                            <Label valido={MensajeDiagnosticoCIIE}>Diagnósticos CIE</Label>
                                            <div style={{ display: "flex", gap: "1", margin: "1% 0" }}>
                                                <InputAntd
                                                    className="InputNormal"
                                                    type=""
                                                    placeholder="Palabra clave"
                                                    value={PalabraClave}
                                                    setStateValue={setPalabraClave}
                                                    vacio={true}
                                                    disabled={false}
                                                    style={{ width: "100%" }}
                                                    setStateError={setMensajePalabraClave}
                                                    textError={["Debe escribir una palabra clave", ""]}
                                                    valueError={MensajePalabraClave}
                                                />
                                                {loaderSearch ? (<CircularProgress size={35} sx={{ color: "#148f9f" }} />) : (
                                                    <Button
                                                        type="button"
                                                        style={{ margin: "0 1%", marginLeft: "1%", border: "0", background: "#148f9f", color: "white" }}
                                                        onClick={() => {
                                                            if (!!PalabraClave) {
                                                                getCIIEDiagnosticos();
                                                            } else {
                                                                setMensajePalabraClave("false");
                                                                setOpen(true);
                                                                setTypeMessage("warning");
                                                                setMessage("Debe escribir una palabra");
                                                            }
                                                        }}>Buscar</Button>
                                                )}
                                            </div>

                                            <div>
                                                {ListaDiagnosticos.length > 0 && (
                                                    <>
                                                        <div style={{ display: "flex", gap: "1rem" }}>
                                                            <Select
                                                                value={DiagnosticosSeleccionados}
                                                                mode="multiple"
                                                                placeholder="Seleciona tus diagnósticos"
                                                                style={{ width: "100%" }}
                                                                options={ListaDiagnosticos}
                                                                filterOption={filterOptions}
                                                                filterSort={filterSort}
                                                                onChange={(e) => {
                                                                    // console.log(e);
                                                                    setDiagnosticosSeleccionados(e);
                                                                }}
                                                            />
                                                            <Button
                                                                icon={<Add style={{ color: "white" }} />}
                                                                style={{ background: "#148f9f" }}
                                                                onClick={() => {
                                                                    addDiagnosticoCIIE();
                                                                }}
                                                            />
                                                        </div>
                                                        <LeyendaError valido={MensajeDiagnosticoCIIE}>Debe seleccionar un diagnóstico</LeyendaError>
                                                    </>
                                                )}
                                                <Label valido={MensajeOtrosDiagnosticos}>Otros diagnósticos</Label>
                                                <div style={{ display: "flex", gap: "1rem" }}>
                                                    <TextAreaAntd
                                                        value={OtrosDiagnosticos}
                                                        placeholder="Otros diagnósticos"
                                                        style={{
                                                            marginBottom: ".5%",
                                                            fontFamily: "sans-serif",
                                                        }}
                                                        columns="100"
                                                        autoSize={true}
                                                        setStateValue={setOtrosDiagnosticos}
                                                        setStateError={setMensajeOtrosDiagnosticos}
                                                        valueError={MensajeOtrosDiagnosticos}
                                                        textError={["Debe escribir un diagnósticos"]}
                                                        vacio={true}
                                                    />
                                                    <Button
                                                        icon={<Add style={{ color: "white" }} />}
                                                        style={{ background: "#148f9f" }}
                                                        onClick={() => {
                                                            addDiagnosticoOtro();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <b style={{ fontSize: "16px" }}>Lista de diagnósticos</b>
                                            {DiagnosticosSeleccionadosMostrados.length > 0 ? (
                                                <>
                                                    {DiagnosticosSeleccionadosMostrados.map((diagnostico, index) => (
                                                        // <div className="ItemCard">
                                                        //     <div className="CardNotasMed">
                                                        //         <span>{diagnostico.diagnostico}</span>
                                                        //         <img onClick={() => { eliminarDiagnostico(diagnostico.index) }} src={Eliminar} alt="" />
                                                        //     </div>
                                                        // </div>
                                                        <div className="ItemCard">
                                                            <Row style={{ justifyContent: "space-between" }}>
                                                                <span>{diagnostico.diagnostico}</span>
                                                                <img style={{ width: "14px", cursor: "pointer" }} onClick={() => { eliminarDiagnostico(diagnostico.index) }} src={Eliminar} alt="" />
                                                            </Row>
                                                        </div>

                                                    ))}
                                                </>
                                            ) : (
                                                <p style={{ textAlign: "center" }}>Sin diagnósticos agregados</p>
                                            )}
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* PLAN */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography>
                                                {!ErrorPlanAccordion && (<><CancelRounded color="error" />&nbsp;</>)}
                                                <b style={{ color: "#ff4d4f", fontSize: "14px", fontFamily: "SimSun,sans-serif" }}>*&nbsp;</b>
                                                Plan
                                                <Button
                                                    icon={<SettingOutlined style={{ color: "white" }} />}
                                                    style={{ background: "#148f9f", marginLeft: "10px" }}
                                                    onClick={() => {
                                                        OpenConfig(true);
                                                    }}
                                                />
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TextAreaAntd
                                                label="Plan médico"
                                                requiredICon={true}
                                                id="plan_medico"
                                                value={Plan}
                                                placeholder="Plan"
                                                style={{
                                                    marginBottom: ".5%",
                                                    fontFamily: "sans-serif",
                                                }}
                                                columns="30"
                                                autoSize={true}
                                                setStateValue={setPlan}
                                                setStateError={setMensajePlan}
                                                valueError={MensajePlan}
                                                textError={["Debe escribir un plan médico"]}
                                                vacio={false}
                                                extras={["acordionError", setErrorPlanAccordion]}
                                            />

                                            <Campos Campos
                                                label={"Cédula profesional"}
                                                textError={"Debe seleccionar una cédula"}
                                                valueError={MensajeCedula}
                                            >
                                                <Select
                                                    placeholder="Cédula profesional"
                                                    defaultValue={Cedula}
                                                    style={{
                                                        width: "100%",
                                                        marginBottom: ".5%",
                                                    }}
                                                    onSelect={(e) => {
                                                        setCedula(e);
                                                        setMensajeCedula("true");
                                                    }}
                                                    options={cedulas}
                                                />
                                            </Campos>
                                            <Row style={{ justifyContent: "space-between", margin: "2% 0", alignItems: "center" }}>
                                                <h4>Receta</h4>

                                                <Button
                                                    type="button"
                                                    className='GeneralBoton'
                                                    onClick={() => addReceta()}
                                                    style={MensajeRecetaNoAgregada === "false" ? ({
                                                        border: "1px solid red", boxShadow: "1px 1px 5px red",
                                                    }) : ({ border: "1px solid #148f9f" })}

                                                >
                                                    Adjuntar receta
                                                </Button>

                                            </Row>
                                            <LeyendaError valido={MensajeRecetaNoAgregada}>
                                                Tiene una receta por adjuntar, presione "Adjuntar receta" para agregar la receta o elimine el listado de medicamentos
                                            </LeyendaError>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "1%",
                                                    marginBottom: "20px"
                                                }}
                                            >
                                                <Campos
                                                    label={"Medicamento:"}
                                                    textError={"Debe agregar un medicamento"}
                                                    valueError={MensajeMedicamento}
                                                    styleDiv={{ width: "50%", height: "70px" }}
                                                >
                                                    <AutoComplete
                                                        placeholder="Medicamento"
                                                        value={Medicamento}
                                                        options={Medicamentos}
                                                        onSearch={filterMedicamentos}
                                                        style={{ width: "100%" }}
                                                        onSelect={(e, option) => {
                                                            setMedicamento(option.label);
                                                            setMedicamentoId(option.value);
                                                            setMensajeMedicamento("true");
                                                        }}
                                                        onChange={(e) => {
                                                            setMedicamento(e);
                                                            if (e.length > 0) {
                                                                setMensajeMedicamento("true");
                                                            }
                                                        }}
                                                        status={MensajeMedicamento === "false" && "error"}
                                                    />
                                                </Campos>

                                                <InputAntd
                                                    label={"Dósis:"}
                                                    styleDiv={{ width: "50%", height: "70px" }}
                                                    value={Dosis}
                                                    placeholder="Dósis"
                                                    setStateValue={setDosis}
                                                    setStateError={setMensajeDosis}
                                                    vacio={false}
                                                    textError={["Debe escribir una dósis"]}
                                                    valueError={MensajeDosis}
                                                />

                                                <Button
                                                    icon={<Add style={{ color: "white" }} />}
                                                    style={{ background: "#148f9f", marginTop: "30px" }}
                                                    onClick={() => {
                                                        if (!!Cedula) {
                                                            addMedicamento();
                                                        } else {
                                                            setMensajeCedula("false");
                                                            setOpen(true);
                                                            setTypeMessage("warning");
                                                            setMessage("Debe seleccionar una cédula");
                                                        }
                                                    }}
                                                />
                                            </div>

                                            {/* <TextAreaAntd
                                                label="Indicaciones:"
                                                requiredICon={true}
                                                value={Indicaciones}
                                                placeholder="Indicaciones"
                                                style={{
                                                    // margin: ".5% 0",
                                                    marginBottom: ".5%",
                                                    fontFamily: "sans-serif",
                                                }}
                                                columns="30"
                                                autoSize={true}
                                                setStateValue={setIndicaciones}
                                            /> */}
                                            <div style={{ display: "flex", justifyContent: "start", marginTop: "2%" }}>
                                                <b>Lista de medicamentos</b>
                                            </div>

                                            <div style={{ borderColor: "black" }}>
                                                {ListaMedicamentos.length > 0 ? (
                                                    <>
                                                        {ListaMedicamentos.map((medicamento, index) => (
                                                            <div className="ItemCard" key={index}>
                                                                <div className="CardNotasMed">
                                                                    <span>{`${medicamento.Concepto} - ${medicamento.Descripcion}`}</span>
                                                                    <img onClick={() => { eliminarMedicamento(medicamento.index) }} src={Eliminar} alt="" />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <p style={{ textAlign: "center" }}>Sin medicamentos agregados</p>
                                                )}
                                                <LeyendaError valido={MensajeListaMedicamentos}>Debe agregar medicamentos</LeyendaError>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                                                <b>Lista de recetas</b>
                                            </div>

                                            <div style={{ borderColor: "black" }}>
                                                {ListaRecetas.map((receta, index) => (
                                                    <Accordionn>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography style={{ width: "95%" }}>
                                                                <Row style={{ width: "100%", justifyContent: "space-between" }}>
                                                                    <div>Receta {receta.Index} - Cédula {receta.Cedula}</div>
                                                                    <img style={{ width: "14px", cursor: "pointer" }} onClick={() => { eliminarReceta(receta.Index) }} src={Eliminar} alt="" />
                                                                </Row>
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Row style={{ justifyContent: "end" }}>
                                                                <Checkbox
                                                                    defaultChecked={true}
                                                                    id={`check_enviarReceta_${receta.Index}`}
                                                                >Enviar a paciente</Checkbox>
                                                                <Checkbox
                                                                    defaultChecked={true}
                                                                    id={`check_${receta.Index}`}
                                                                >Diagnóstico</Checkbox>
                                                                <Checkbox
                                                                    defaultChecked={true}
                                                                    id={`check_indicaciones_${receta.Index}`}
                                                                >Indicaciones</Checkbox>
                                                                <Checkbox
                                                                    defaultChecked={true}
                                                                    id={`check_firma_${receta.Index}`}
                                                                >Firma</Checkbox>
                                                            </Row>
                                                            <Row style={{ justifyContent: "end" }}>
                                                                {loaderReceta ?
                                                                    <CircularProgress size={30} sx={{ color: "#148f9f" }} /> :
                                                                    <div>

                                                                        <Button type="button" style={{ background: "#148f9f", border: "0", marginTop: "0", color: "white" }} onClick={() => {
                                                                            if (!!ConfigReceta) {
                                                                                vistaPreviaReceta(receta, document.getElementById(`check_${receta.Index}`).checked, document.getElementById(`check_firma_${receta.Index}`).checked, document.getElementById(`check_indicaciones_${receta.Index}`).checked);
                                                                            } else {
                                                                                setOpenMensajeConfig(true);
                                                                                setTypeMessage("warning");
                                                                                setMessage("Debe agregar una configuración de formato");
                                                                            }
                                                                        }}>Vista previa</Button>
                                                                    </div>}
                                                            </Row>
                                                            {receta.Concepto.map((concepto) => (
                                                                <div>
                                                                    {concepto.Concepto} - {concepto.Descripcion}
                                                                    { }
                                                                </div>
                                                            ))}
                                                        </AccordionDetails>
                                                    </Accordionn>
                                                ))}
                                                {ListaRecetas.length === 0 && (<p style={{ textAlign: "center" }}>Sin recetas agregadas</p>)}

                                            </div>
                                            <hr
                                                style={{
                                                    width: "100%",
                                                    border: "1px solid #148f9f",
                                                    opacity: "100%",
                                                    borderRadius: "5rem"
                                                }}
                                            />

                                            <Row style={{ justifyContent: "space-between", margin: "2% 0" }}>
                                                <h4 style={{ margin: "1% 0" }}>Orden de estudios de laboratorio</h4>
                                                <Button
                                                    type="button"
                                                    onClick={() => addOrdenLab()}
                                                    style={{
                                                        background: "#148f9f",
                                                        border: "0",
                                                        color: "white",
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}

                                                >
                                                    Adjuntar orden
                                                </Button>
                                            </Row>
                                            <LeyendaError valido={MensajeOrdenLabNoAgregada}>
                                                Tiene una orden de laboratorio sin guardar, presione "Adjuntar orden" para agregarla o borre el texto del campo
                                            </LeyendaError>
                                            <TextAreaAntd
                                                placeholder="Descripción"
                                                value={OrdenLabTest}
                                                style={{
                                                    marginBottom: ".5%",
                                                    fontFamily: "sans-serif",
                                                }}
                                                columns="30"
                                                autoSize={true}
                                                setStateValue={setOrdenLabTest}
                                                setStateError={setMensajeOrdenLabTest}
                                                valueError={MensajeOrdenLabTest}
                                                textError={["Debe escribir una descripción"]}
                                                vacio={true}
                                            />

                                            <div style={{ borderColor: "black" }}>
                                                <b>Lista de ordenes de laboratorio</b>
                                                {ListaOrdenLabs.length > 0 ? (
                                                    <>
                                                        {ListaOrdenLabs.map((orden, index) => (
                                                            <Accordionn>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography style={{ width: "95%" }}>
                                                                        <Row style={{ width: "100%", justifyContent: "space-between" }}>
                                                                            <div>Orden de laboratorio {orden.Index}</div>
                                                                            <img style={{ width: "14px", cursor: "pointer" }} onClick={() => { eliminarOrdenLab(orden.Index) }} src={Eliminar} alt="" />
                                                                        </Row>
                                                                    </Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Row style={{ justifyContent: "end" }}>
                                                                        {loaderOrdenLabTest ?
                                                                            <CircularProgress size={30} sx={{ color: "#148f9f" }} /> :
                                                                            <div>
                                                                                <Button type="button" style={{ background: "#148f9f", border: "0", marginTop: "0", color: "white" }} onClick={() => {
                                                                                    if (!!ConfigReceta) {
                                                                                        vistaPreviaOrdenDeEstudios(orden);
                                                                                    } else {
                                                                                        setOpenMensajeConfig(true);
                                                                                        setTypeMessage("warning");
                                                                                        setMessage("Debe agregar una configuracion de formato");
                                                                                    }
                                                                                }}>Vista previa</Button>
                                                                            </div>}

                                                                    </Row>
                                                                    <TextAreaAntd
                                                                        label="Descripción"
                                                                        placeholder="Descripción"
                                                                        value={orden.descripcion}
                                                                        style={{
                                                                            marginBottom: ".5%",
                                                                            fontFamily: "sans-serif",
                                                                        }}
                                                                        columns="30"
                                                                        autoSize={true}
                                                                        readOnly={true}
                                                                    />
                                                                </AccordionDetails>
                                                            </Accordionn>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <p style={{ textAlign: "center" }}>Sin ordenes de laboratorio agregadas</p>
                                                )}

                                            </div>

                                            <hr
                                                style={{
                                                    width: "100%",
                                                    border: "1px solid #148f9f",
                                                    opacity: "100%",
                                                    borderRadius: "5rem"
                                                }}
                                            />

                                            <Row style={{ justifyContent: "space-between", margin: "2% 0" }}>
                                                <h4 style={{ margin: "1% 0" }}>Orden de interconsulta</h4>
                                                {InterconsultaAdjunta === 0 && (
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => setInterconsultaAdjunta(1)}
                                                        style={{
                                                            background: "#148f9f",
                                                            border: "0",
                                                            color: "white",
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}

                                                    >
                                                        <AddIcon sx={{ color: "white" }} /> Orden interconsulta
                                                    </Button>
                                                )}
                                            </Row>

                                            <LeyendaError valido={MensajeOrdenInterNoAgregada}>
                                                Tiene una orden de interconsulta sin guardar, presione "Guardar interconsulta" para agregarla o presione "eliminar"
                                            </LeyendaError>

                                            {InterconsultaAdjunta === 1 && (
                                                <Form>
                                                    <Row style={{ justifyContent: "space-between", margin: "2% 0" }}>
                                                        <Button
                                                            type="button"
                                                            onClick={() => addOrdenInter()}
                                                            style={{
                                                                // width: '60%',
                                                                background: "#148f9f",
                                                                border: "0",
                                                                color: "white",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}

                                                        >
                                                            Guardar interconsulta
                                                        </Button>
                                                        <Button type="button" style={{ background: "#148f9f", border: "0", color: "white" }} onClick={() => {
                                                            setDoctorSelect(null);
                                                            setDatosMedico({ id: "", imagen: "", nombre: "" });
                                                            setCheckNuevo(false);
                                                            setNombreMedico("");
                                                            setApellidoPMedico("");
                                                            setApellidoMMedico("");
                                                            setCelularMedico("");
                                                            setEmailMedico("");
                                                            setEspecialidadMedico("");
                                                            setMotivoInterconsulta("");
                                                            setDiagnosticoInterconsulta("");
                                                            setInterconsultaAdjunta(0);
                                                            setMensajeOrdenInterNoAgregada("true");
                                                            setErrorPlanAccordion(true);
                                                        }}>Eliminar</Button>
                                                    </Row>
                                                    <SelectAntd
                                                        label="Médico:"
                                                        value={
                                                            !!DatosMedico.id
                                                                ? {
                                                                    value: "",
                                                                    label: (
                                                                        <div style={{ display: "flex", gap: "1" }}>
                                                                            <img
                                                                                className="ImgMedicoSelect"
                                                                                src={
                                                                                    !!DatosMedico.imagen
                                                                                        ? `https://${DatosMedico.imagen}`
                                                                                        : ""
                                                                                }
                                                                            />{" "}
                                                                            <div style={{ marginLeft: "1%" }}>
                                                                                {DatosMedico.nombre}
                                                                            </div>
                                                                        </div>
                                                                    ),
                                                                }
                                                                : null
                                                        }
                                                        style={{
                                                            width: "100%",
                                                            margin: "1% 0",
                                                        }}
                                                        placeholder="Selecciona un médico"
                                                        onClick={() => {
                                                            OpenModalContactos(true);
                                                        }}
                                                        setStateError={setMensajeDoctorSelect}
                                                        valueError={MensajeDoctorSelect}
                                                        textError="Debe seleccionar un médico"
                                                        readOnly={true}
                                                        open={false}
                                                        vacio={false}
                                                    />
                                                    {/* <Select
                                                        value={DoctorSelect}
                                                        showSearch
                                                        style={{
                                                            width: "100%",
                                                            margin: "1% 0"
                                                        }}
                                                        placeholder="Selecciona un médico"
                                                        optionFilterProp="children"
                                                        filterOption={filterOptions}
                                                        filterSort={filterSort}
                                                        options={MedicosList}
                                                        onSelect={(e) => {
                                                            // setInputDisabled(false);
                                                            setDoctorSelect(e);
                                                            MedicosListFilter.forEach(element => {
                                                                if (e === element.IdContacto) {
                                                                    // console.log(element);
                                                                    setNombreMedico(element.Nombre);
                                                                    setApellidoPMedico(element.ApellidoPaterno);
                                                                    setApellidoMMedico(element.ApellidoMaterno);
                                                                    setCelularMedico(element.Celular);
                                                                    setEmailMedico(element.Email);
                                                                    // setEspecialidadMedico(element.Especialidad);
                                                                    setMensajeDoctorSelect("true");
                                                                    setMensajeNombreMedico("true");
                                                                    setMensajeApellidoPMedico("true");
                                                                    // setMensajeEspecialidadMedico("true");
                                                                }
                                                            });

                                                        }}
                                                    /> */}
                                                    <LeyendaError valido={MensajeDoctorSelect}>Debe seleccionar un medico</LeyendaError>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <b>Datos del médico</b>
                                                        {/* <Checkbox onChange={(e) => {
                                                            setInputDisabled(!InputDisabled);
                                                            setCheckNuevo(e.target.checked);
                                                            setDoctorSelect(null);
                                                            setNombreMedico("");
                                                            setApellidoPMedico("");
                                                            setApellidoMMedico("");
                                                            setCelularMedico("");
                                                            setEmailMedico("");
                                                            setMensajeDoctorSelect("true");
                                                        }}>Nuevo</Checkbox> */}
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                                                        <InputAntd
                                                            label="Nombre:"
                                                            type=""
                                                            placeholder="Escriba un nombre"
                                                            value={NombreMedico}
                                                            setStateValue={setNombreMedico}
                                                            vacio={false}
                                                            styleDiv={{ width: "100%" }}
                                                            setStateError={setMensajeNombreMedico}
                                                            textError={["Debe escribir un nombre", ""]}
                                                            valueError={MensajeNombreMedico}
                                                            disabled={InputDisabled}
                                                        />

                                                        <InputAntd
                                                            label="Apellido Paterno:"
                                                            type=""
                                                            placeholder="Escriba un apellido paterno"
                                                            value={ApellidoPMedico}
                                                            setStateValue={setApellidoPMedico}
                                                            vacio={false}
                                                            setStateError={setMensajeApellidoPMedico}
                                                            textError={["Debe escribir un apellido paterno", ""]}
                                                            valueError={MensajeApellidoPMedico}
                                                            styleDiv={{ width: "100%" }}
                                                            disabled={InputDisabled}
                                                        />

                                                        <InputAntd
                                                            label="Apellido Materno:"
                                                            type=""
                                                            placeholder="Escriba un apellido materno"
                                                            value={ApellidoMMedico}
                                                            setStateValue={setApellidoMMedico}
                                                            vacio={true}
                                                            styleDiv={{ width: "100%" }}
                                                            disabled={InputDisabled}
                                                        />

                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                                                        <InputAntd
                                                            label="Especialidad:"
                                                            type=""
                                                            placeholder="Escriba una Especialidad"
                                                            value={EspecialidadMedico}
                                                            setStateValue={setEspecialidadMedico}
                                                            vacio={false}
                                                            setStateError={setMensajeEspecialidadMedico}
                                                            textError={["Debe escribir una especialidad", ""]}
                                                            valueError={MensajeEspecialidadMedico}
                                                            styleDiv={{ width: "100%" }}
                                                            disabled={!!DoctorSelect ? false : true}
                                                        />

                                                        <InputAntd
                                                            label="Celular:"
                                                            type=""
                                                            placeholder="Escriba un celular"
                                                            value={CelularMedico}
                                                            setStateValue={setCelularMedico}
                                                            vacio={true}
                                                            styleDiv={{ width: "100%" }}
                                                            disabled={InputDisabled}
                                                        />

                                                        <InputAntd
                                                            label="Email:"
                                                            type=""
                                                            placeholder="Escriba un email"
                                                            value={EmailMedico}
                                                            setStateValue={setEmailMedico}
                                                            vacio={true}
                                                            styleDiv={{ width: "100%" }}
                                                            disabled={InputDisabled}
                                                        />

                                                    </div>

                                                    <b>Datos interconsulta</b>
                                                    <TextAreaAntd
                                                        label="Motivo:"
                                                        value={MotivoInterconsulta}
                                                        placeholder="Escriba un motivo"
                                                        style={{
                                                            margin: ".5% 0",
                                                            fontFamily: "sans-serif",
                                                        }}
                                                        columns="30"
                                                        autoSize={true}
                                                        setStateValue={setMotivoInterconsulta}
                                                        setStateError={setMensajeMotivoInterconsulta}
                                                        valueError={MensajeMotivoInterconsulta}
                                                        textError={["Debe escribir un motivo"]}
                                                    />

                                                    <TextAreaAntd
                                                        label="Diagnóstico sugerido:"
                                                        value={DiagnosticoInterconsulta}
                                                        placeholder="Escriba un diagnóstico"
                                                        style={{
                                                            margin: ".5% 0",
                                                            fontFamily: "sans-serif",
                                                        }}
                                                        columns="30"
                                                        autoSize={true}
                                                        setStateValue={setDiagnosticoInterconsulta}
                                                    />

                                                    <TextAreaAntd
                                                        label="Tratamiento sugerido:"
                                                        value={TratamientoInterconsulta}
                                                        placeholder="Escriba un tratamiento"
                                                        style={{
                                                            margin: ".5% 0",
                                                            fontFamily: "sans-serif",
                                                        }}
                                                        columns="30"
                                                        autoSize={true}
                                                        setStateValue={setTratamientoInterconsulta}
                                                    />

                                                </Form>
                                            )}
                                            <div style={{ borderColor: "black" }}>
                                                <b>Lista de ordenes de interconsulta</b>
                                                {ListaOrdenInter.length > 0 ? (
                                                    <>
                                                        {ListaOrdenInter.map((orden, index) => (
                                                            <Accordionn key={index}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography style={{ width: "95%" }}>
                                                                        <Row style={{ width: "100%", justifyContent: "space-between" }}>
                                                                            <div>Orden de interconsulta {orden.Index}</div>
                                                                            <img style={{ width: "14px", cursor: "pointer" }} onClick={() => { eliminarOrdenInter(orden.Index) }} src={Eliminar} alt="" />
                                                                        </Row>
                                                                    </Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Row style={{ justifyContent: "end" }}>
                                                                        {loaderInterconsulta ? (
                                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                                                <CircularProgress size={40} sx={{ color: "#148f9f" }} />
                                                                            </div>
                                                                        ) : (
                                                                            <Button type="button" style={{ background: "#148f9f", border: "0", marginTop: "0", color: "white" }} onClick={() => {

                                                                                if (!!ConfigReceta) {
                                                                                    vistaPreviaInter(orden);
                                                                                } else {
                                                                                    setOpenMensajeConfig(true);
                                                                                    setTypeMessage("warning");
                                                                                    setMessage("Debe agregar una configuracion de formato");
                                                                                }
                                                                            }}>Vista previa</Button>
                                                                        )}

                                                                    </Row>
                                                                    <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                                                                        <InputAntd
                                                                            label="Nombre:"
                                                                            type=""
                                                                            placeholder="Escriba un nombre"
                                                                            value={orden.idContactoM[0].nombre}
                                                                            setStateValue={setNombreMedico}
                                                                            vacio={false}
                                                                            styleDiv={{ width: "100%" }}
                                                                            setStateError={setMensajeNombreMedico}
                                                                            textError={["Debe escribir un nombre", ""]}
                                                                            valueError={MensajeNombreMedico}
                                                                            readOnly={true}
                                                                        />

                                                                        <InputAntd
                                                                            label="Apellido Paterno:"
                                                                            type=""
                                                                            placeholder="Escriba un apellido paterno"
                                                                            value={orden.idContactoM[0].apellidoPaterno}
                                                                            styleDiv={{ width: "100%" }}
                                                                            readOnly={true}
                                                                        />

                                                                        <InputAntd
                                                                            label="Apellido Materno:"
                                                                            type=""
                                                                            placeholder="Escriba un apellido materno"
                                                                            value={orden.idContactoM[0].apellidoMaterno}
                                                                            styleDiv={{ width: "100%" }}
                                                                            readOnly={true}
                                                                        />

                                                                    </div>
                                                                    <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                                                                        <InputAntd
                                                                            label="Especialidad:"
                                                                            type=""
                                                                            placeholder="Escriba una Especialidad"
                                                                            value={orden.idContactoM[0].especialidad}
                                                                            styleDiv={{ width: "100%" }}
                                                                            readOnly={true}
                                                                        />

                                                                        <InputAntd
                                                                            label="Celular:"
                                                                            type=""
                                                                            placeholder="Escriba un celular"
                                                                            value={orden.idContactoM[0].celular}
                                                                            styleDiv={{ width: "100%" }}
                                                                            readOnly={true}
                                                                        />

                                                                        <InputAntd
                                                                            label="Email:"
                                                                            type=""
                                                                            placeholder="Escriba un email"
                                                                            value={orden.idContactoM[0].email}
                                                                            styleDiv={{ width: "100%" }}
                                                                            readOnly={true}
                                                                        />

                                                                    </div>

                                                                    <b>Datos interconsulta</b>
                                                                    <TextAreaAntd
                                                                        label="Motivo:"
                                                                        value={orden.mOrden}
                                                                        placeholder="Escriba un motivo"
                                                                        style={{
                                                                            margin: ".5% 0",
                                                                            fontFamily: "sans-serif",
                                                                        }}
                                                                        columns="30"
                                                                        autoSize={true}
                                                                        readOnly={true}
                                                                    />

                                                                    <TextAreaAntd
                                                                        label="Diagnóstico:"
                                                                        value={orden.diagnostico}
                                                                        placeholder="Escriba un diagnóstico"
                                                                        style={{
                                                                            margin: ".5% 0",
                                                                            fontFamily: "sans-serif",
                                                                        }}
                                                                        columns="30"
                                                                        autoSize={true}
                                                                        readOnly={true}
                                                                    />


                                                                </AccordionDetails>
                                                            </Accordionn>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <p style={{ textAlign: "center" }}>Sin ordenes de interconsulta</p>
                                                )}

                                            </div>
                                        </AccordionDetails>
                                    </Accordionn>
                                    {/* RECOMENDACIONES */}
                                    <Accordionn>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Indicaciones</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TextAreaAntd
                                                value={Recomendaciones}
                                                placeholder="Indicaciones"
                                                style={{
                                                    margin: ".5% 0",
                                                    fontFamily: "sans-serif",
                                                }}
                                                columns="30"
                                                autoSize={true}
                                                setStateValue={setRecomendaciones}
                                            />
                                        </AccordionDetails>
                                    </Accordionn>
                                </div>
                            )}
                        </Col>
                    </div >
                </div >
            )
            }

            <ModalDetalleEstudio isOpen={IsOpenEstudio} closeModal={CloseEstudio} styleDet={styleAnalisis} optionClose={true}>
                <div className="TopModalNewEstud">
                    Detalle de estudio de laboratorio
                    <CloseIcon onClick={CloseEstudio}
                        style={{ cursor: "pointer", fontSize: "20" }} />
                </div>
                {LoaderModal ? (<LoaderModals />) : (
                    <div className="MidModalEstud">
                        <Campos
                            label="Descripción:"
                            children={
                                <TextArea
                                    placeholder="Descripción"

                                    style={{
                                        margin: "2% 0",
                                        fontFamily: "sans-serif",
                                    }}
                                    columns="30"
                                    autoSize={true}
                                    value={!!DetalleEstudio ? !!DetalleEstudio.Estudio.Descripcion ? DetalleEstudio.Estudio.Descripcion : '\"Sin descripción\"' : ""}
                                    readOnly={true}
                                />
                            }
                        />
                        <div>
                            <b>Lista de archivos</b>
                            {(!!DetalleEstudio && DetalleEstudio.Documentos !== undefined) ? (
                                DetalleEstudio.Documentos.map((documento, index) => (
                                    <>
                                        <div style={{
                                            padding: "1% 3%",
                                            gap: "3%",
                                            border: "1px solid #d7d7d7",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between", cursor: "pointer"
                                        }} onClick={() => { window.open("https://" + documento.Multimedia, "_blank") }}>
                                            {documento.Multimedia.substring(documento.Multimedia.toString().length, documento.Multimedia.toString().length - 3) === "jpeg" || documento.Multimedia.substring(documento.Multimedia.toString().length, documento.Multimedia.toString().length - 3) === "png"
                                                || documento.Multimedia.substring(documento.Multimedia.toString().length, documento.Multimedia.toString().length - 3) === "jpg" ?
                                                (
                                                    <Row style={{ justifyContent: "space-between", cursor: "pointer" }} onClick={() => { window.open("https://" + documento.Multimedia, "_blank") }}>
                                                        <img style={{ width: "10%" }} src={"https://" + documento.Multimedia} alt="" />
                                                        <div style={{ width: "60%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                            <span>{DetalleEstudio.Estudio.Paciente + "_" + DetalleEstudio.Estudio.Tipo + "_" + DetalleEstudio.Estudio.Fecha + "_" + index + 1}</span>
                                                        </div>
                                                        <div>

                                                        </div>
                                                    </Row>
                                                ) : documento.Multimedia.substring(documento.Multimedia.toString().length, documento.Multimedia.toString().length - 3) === "pdf" ?
                                                    (
                                                        <Row style={{ justifyContent: "space-between", cursor: "pointer" }} onClick={() => { window.open("https://" + documento.Multimedia, "_blank") }}>
                                                            <img style={{ width: "7%", marginRight: "3px" }} src={Iconos[22]} />
                                                            <div style={{ width: "60%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                                {/* <span>{DatosContact.nombre + "_" + TipoEstudioAnalisis + "_" + DetailEstudio.Fecha + "_" + index + 1}</span> */}
                                                                <span>{DetalleEstudio.Estudio.Paciente + "_" + DetalleEstudio.Estudio.Tipo + "_" + DetalleEstudio.Estudio.Fecha + "_" + index + 1}</span>
                                                            </div>
                                                            <div>

                                                            </div>
                                                        </Row>
                                                    ) :
                                                    (
                                                        <Row style={{ justifyContent: "space-between", cursor: "pointer" }} onClick={() => { window.open(documento.Multimedia, "_blank") }}>
                                                            <OndemandVideo sx={{ color: "#148F9F" }} />
                                                            <div style={{ width: "60%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                                {/* <span>{DatosContact.nombre + "_" + TipoEstudioAnalisis + "_" + DetailEstudio.Fecha + "_" + index + 1}</span> */}
                                                                <span>{DetalleEstudio.Estudio.Paciente + "_" + DetalleEstudio.Estudio.Tipo + "_" + DetalleEstudio.Estudio.Fecha + "_" + index + 1}</span>
                                                            </div>
                                                            <div>

                                                            </div>
                                                        </Row>
                                                    )
                                            }
                                        </div>
                                        <br />
                                    </>
                                ))
                            ) :
                                (
                                    <Empty description={<span style={{ color: "black" }}>Sin archivos</span>} />
                                )}

                        </div>
                    </div>
                )}
            </ModalDetalleEstudio>

            <ModalDetalleConsulta isOpen={IsOpeNotaDetalle} closeModal={CloseNotaDetalle} optionClose={true} styleDet={styleDet} extras={["detalle_consulta", setDetalleConsulta, setIMC, cleanInputsDetalleConsulta]}>
                <div
                    style={{
                        width: "100%",
                        background: "#148f9f",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "1% 2%",
                    }}
                >
                    Detalle de consulta
                    <CloseIcon style={{ cursor: "pointer", fontSize: "20" }} onClick={() => {
                        CloseNotaDetalle(true);
                        setDetalleConsulta("");
                        setIMC("");
                    }} />

                </div>
                <div>
                    <div className="scrolDetalleNotas">
                        <div style={{ display: "flex", justifyContent: "end" }}>
                            <Button type="button" style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", gap: "2%", background: "#148f9f", border: "0", color: "white" }} onClick={() => {
                                CloseNotaDetalle(true);
                                setCierreModalResumen(true);
                                setAccionResumen(1);
                                crearResumenMedico();
                            }}>Resumen médico <AddIcon /></Button>
                        </div>
                        <TextAreaAntd
                            label="Motivo de consulta:"
                            value={!!DetalleConsulta ? DetalleConsulta.Consulta.Motivo : ""}
                            placeholder="Motivo de consulta"
                            style={{
                                marginBottom: ".5%",
                                fontFamily: "sans-serif",
                            }}
                            columns="30"
                            autoSize={true}
                            readOnly={true}
                        />
                        {/* Evolucion del padecimiento */}
                        <Accordionn>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Evolucion del padecimiento</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextArea
                                    value={!!DetalleConsulta ? DetalleConsulta.Consulta.EvolucionPadecimiento : ""}
                                    placeholder="Evolucion del padecimiento"
                                    style={{
                                        margin: ".5% 0",
                                        fontFamily: "sans-serif",
                                    }}
                                    name=""
                                    id=""
                                    cols="30"
                                    autoSize
                                    readOnly={true}
                                />
                            </AccordionDetails>
                        </Accordionn>
                        {/* Exploración Física */}
                        <Accordionn>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography> Exploración física</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        <InputAntd
                                            label="Altura:"
                                            className="InputNormal"
                                            style={{ width: "100%" }}
                                            placeholder="Altura (Mtrs.)"
                                            value={!!DetalleConsulta ? DetalleConsulta.Consulta.Altura : ""}
                                            vacio={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div>
                                        <InputAntd
                                            label="Peso:"
                                            className="InputNormal"
                                            style={{ width: "100%" }}
                                            type=""
                                            placeholder="Peso (Kg.)"
                                            value={!!DetalleConsulta ? DetalleConsulta.Consulta.Peso : ""}
                                            vacio={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    {!!IMC && (
                                        <>
                                            <div>
                                                <InputAntd
                                                    label="IMC:"
                                                    className="InputNormal"
                                                    style={{ width: "100%" }}
                                                    type=""
                                                    placeholder="IMC"
                                                    value={IMC}
                                                    vacio={true}
                                                    readOnly={true}
                                                />
                                            </div>
                                            <div>
                                                <Label></Label>
                                                <Input
                                                    className="InputNormal"
                                                    style={{ width: "100%" }}
                                                    type=""
                                                    placeholder="IMC"
                                                    value={!!IMC ? IMC < 18 ? "Insuficiente" : IMC >= 18 && IMC <= 25 ? "Peso normal" : IMC > 25 && IMC <= 30 ? "Sobrepeso" : IMC > 30 && IMC <= 40 ? "Obesidad" : IMC > 40 && "Obesidad mórbida" : ""}
                                                    readOnly={true}
                                                    status={!!IMC ? IMC < 18 ? "warning" : IMC >= 18 && IMC <= 25 ? "" : IMC > 25 && IMC <= 30 ? "warning" : IMC > 30 && IMC <= 40 ? "warning" : IMC > 40 && "error" : ""}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        <InputAntd
                                            label="T. Arterial"
                                            className="InputNormal"
                                            style={{ width: "100%" }}
                                            type=""
                                            placeholder="T. Arterial"
                                            value={!!DetalleConsulta ? DetalleConsulta.Consulta.TensionArterial : ""}
                                            vacio={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div>
                                        <InputAntd
                                            label="F. Cardiaca"
                                            className="InputNormal"
                                            style={{ width: "100%" }}
                                            type=""
                                            placeholder="F. Cardiaca"
                                            value={!!DetalleConsulta ? DetalleConsulta.Consulta.FrecuenciaCardiaca : ""}
                                            vacio={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div>
                                        <InputAntd
                                            label="F. Respiratoria"
                                            className="InputNormal"
                                            style={{ width: "100%" }}
                                            type=""
                                            placeholder="F. Respiratoria"
                                            value={!!DetalleConsulta ? DetalleConsulta.Consulta.FrecuenciaRespiratoria : ""}
                                            vacio={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div>
                                        <InputAntd
                                            label="Temperatura"
                                            className="InputNormal"
                                            style={{ width: "100%" }}
                                            type=""
                                            placeholder="Temperatura"
                                            value={!!DetalleConsulta ? DetalleConsulta.Consulta.Temperatura : ""}
                                            vacio={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div>
                                        <InputAntd
                                            label="O2"
                                            className="InputNormal"
                                            style={{ width: "100%" }}
                                            type=""
                                            placeholder="O2"
                                            value={!!DetalleConsulta ? DetalleConsulta.Consulta.SaturacionOxigeno : ""}
                                            vacio={true}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <TextArea
                                    value={!!DetalleConsulta ? DetalleConsulta.Consulta.ExploracionFisica : ""}
                                    placeholder="Exploración Física"
                                    style={{
                                        margin: ".5% 0",
                                        fontFamily: "sans-serif",
                                    }}
                                    name=""
                                    id=""
                                    cols="30"
                                    autoSize
                                    readOnly={true}
                                />

                            </AccordionDetails>
                        </Accordionn>
                        {/* Estudios y analisis */}
                        <Accordionn>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Estudios y análisis</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {(!!DetalleConsulta && !!DetalleConsulta.Estudios) ? (
                                    <>
                                        <TextArea
                                            value={DetalleConsulta.Estudios.Descripcion}
                                            placeholder="Descripcion"
                                            style={{
                                                margin: ".5% 0",
                                                fontFamily: "sans-serif",
                                            }}
                                            name=""
                                            id=""
                                            cols="30"
                                            autoSize
                                            readOnly={true}
                                        />

                                        <div>
                                            <p style={{ fontWeight: "500" }}>Lista de archivos</p>

                                            {DetalleConsulta.Documentos.map((documento) => (
                                                <div className="ListaDetalleEsAna" onClick={() => { window.open(`https://${documento.Multimedia}`, "_blank") }}>
                                                    {documento.Nombre.substring(documento.Nombre.toString().length, documento.Nombre.toString().length - 3) !== "pdf" ?
                                                        (
                                                            <>
                                                                <img style={{ width: "10%" }} src={documento.Multimedia} alt="" />
                                                                <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                                    <span>{documento.Nombre}</span>
                                                                </div>
                                                                <div>

                                                                </div>
                                                            </>
                                                        ) :
                                                        (
                                                            <>
                                                                <img style={{ width: "7%", marginRight: "3px" }} src={Icono[22]} />
                                                                <div style={{ width: "40%", height: "20px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                                    <span>{documento.Nombre}</span>
                                                                </div>
                                                                <div>
                                                                </div>
                                                            </>
                                                        )}
                                                </div>
                                            ))}


                                        </div>
                                    </>
                                ) : (
                                    <p style={{ textAlign: "center" }}>Sin información</p>
                                )}
                            </AccordionDetails>
                        </Accordionn>
                        {/* Diagnóstico clínico */}
                        <Accordionn>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Diagnóstico clínico</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextArea
                                    value={!!DetalleConsulta ? DetalleConsulta.Consulta.Diagnostico : ""}
                                    placeholder="Diagnóstico clínico"
                                    style={{
                                        margin: ".5% 0",
                                        fontFamily: "sans-serif",
                                    }}
                                    name=""
                                    id=""
                                    cols="30"
                                    autoSize
                                    readOnly={true}
                                />
                            </AccordionDetails>
                        </Accordionn>
                        {/* Plan */}
                        <Accordionn>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Plan</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextAreaAntd
                                    label="Plan médico"
                                    id="plan_medico"
                                    value={!!DetalleConsulta ? DetalleConsulta.Consulta.Plan : ""}
                                    placeholder="Plan"
                                    style={{
                                        marginBottom: ".5%",
                                        fontFamily: "sans-serif",
                                    }}
                                    columns="30"
                                    autoSize={true}
                                    vacio={false}
                                    readOnly={true}
                                />
                                <h6>Receta</h6>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignItems: "center",
                                        gap: "1%",
                                    }}
                                >
                                </div>
                                {!!DetalleConsulta && DetalleConsulta.Receta.map((receta, index) => (
                                    <>
                                        {receta.partidas.length > 0 ? (
                                            <Accordionn key={index}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Receta {index + 1} - Folio: {receta.folio}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Table columns={columns} dataSource={receta.partidas} scroll={{ x: 5 }} />
                                                </AccordionDetails>
                                            </Accordionn>

                                        ) : (
                                            <p style={{ textAlign: "center" }}>Sin información</p>
                                        )}
                                    </>
                                ))}


                                <hr
                                    style={{
                                        width: "100%",
                                        border: "1px solid #148f9f",
                                        opacity: "100%",
                                        borderRadius: "5rem",
                                    }}
                                />

                                <Row style={{ justifyContent: "start", margin: "2% 0" }}>
                                    <h4 style={{ margin: "1% 0" }}>Ordenes</h4>
                                </Row>
                                {!!DetalleConsulta && DetalleConsulta.Orden.map((orden, index) => (
                                    <>
                                        {DetalleConsulta.Orden.length > 0 ? (
                                            <Accordionn key={index}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>{orden.tipoOrden === 1 ? `Laboratorio` : `Interconsulta`}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {
                                                        orden.tipoOrden === 1 ? (
                                                            <TextAreaAntd
                                                                placeholder="Descripción"
                                                                value={orden.descripcion}
                                                                style={{
                                                                    marginBottom: ".5%",
                                                                    fontFamily: "sans-serif",
                                                                }}
                                                                columns="30"
                                                                autoSize={true}
                                                                true={true}
                                                            />
                                                        ) : (

                                                            <>
                                                                <b>Datos interconsulta</b>
                                                                <TextAreaAntd
                                                                    label="Motivo:"
                                                                    value={orden.motivo}
                                                                    placeholder="Escriba un motivo"
                                                                    style={{
                                                                        margin: ".5% 0",
                                                                        fontFamily: "sans-serif",
                                                                    }}
                                                                    columns="30"
                                                                    autoSize={true}
                                                                    readOnly={true}
                                                                />

                                                                <TextAreaAntd
                                                                    label="Diagnóstico:"
                                                                    value={orden.diagnostico}
                                                                    placeholder="Escriba un diagnóstico"
                                                                    style={{
                                                                        margin: ".5% 0",
                                                                        fontFamily: "sans-serif",
                                                                    }}
                                                                    columns="30"
                                                                    autoSize={true}
                                                                    readOnly={true}
                                                                />
                                                            </>
                                                        )
                                                    }
                                                </AccordionDetails>
                                            </Accordionn>

                                        ) : (
                                            <p style={{ textAlign: "center" }}>Sin información</p>
                                        )}
                                    </>
                                ))}
                            </AccordionDetails>
                        </Accordionn>
                        {/* Recomendaciones */}
                        <Accordionn>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Indicaciones</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextArea
                                    value={!!DetalleConsulta ? DetalleConsulta.Consulta.Recomendaciones : ""}
                                    placeholder="Indicaciones"
                                    style={{
                                        margin: ".5% 0",
                                        fontFamily: "sans-serif",
                                    }}
                                    name=""
                                    id=""
                                    cols="30"
                                    autoSize
                                    readOnly={true}
                                />
                            </AccordionDetails>
                        </Accordionn>
                    </div>

                </div>
            </ModalDetalleConsulta>

            <ModalRecetasDetalle
                optionClose={true}
                isOpen={isOpenModalRecetaDetalle}
                closeModal={CloseModalRecetaDetalle}
                styleDet={RecetaDetalleModal}
            >
                <div className="topCloseModalRec">
                    Información de receta
                    <CloseIcon style={{ cursor: "pointer", fontSize: "20px" }} onClick={CloseModalRecetaDetalle} />

                </div>
                <div className="TamañoRecet">
                    {DetailRecetas === "" || loaderRecetaDetalle ? (
                        <LoaderModals className={"TamañoRecet"} />
                    ) : (
                        <>
                            <Row style={{ justifyContent: "space-between", padding: "1.5%" }}>
                                {DetailRecetas.Receta.Enviar === 0 ? (
                                    <div></div>
                                ) : (
                                    <>
                                        {loaderReceta ? (
                                            <CircularProgress size={30} sx={{ color: "#148f9f" }} />
                                        ) : (
                                            <ButtonAntd onClick={confirm}>
                                                Enviar a paciente
                                            </ButtonAntd>
                                        )}
                                    </>
                                )}
                                <Button
                                    className="GeneralBoton"
                                    style={{ width: "79px", color: "white", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                    onClick={() => {
                                        if (!!DetailRecetas) {
                                            window.open(DetailRecetas.Receta.Url, "_blank");
                                            // convertirAPDF(DetailRecetas.Receta.Url);
                                            // crearPDF()
                                            // crearPDF(DetailRecetas.Receta.Url);
                                        }
                                    }}
                                >
                                    Ver<PictureAsPdfIcon sx={{ fontSize: "20px", cursor: "pointer" }} />
                                </Button>
                            </Row>
                            <div className="DosImagenes">
                                <img
                                    src={
                                        !!DetailRecetas.Receta.Logo
                                            ? "https://" + DetailRecetas.Receta.Logo
                                            : Iconos[0]
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="CentContentRece">
                                <div className="ContentTopRece">
                                    <h3 style={{ color: "#3AABEA", marginBottom: "1.5%" }}>
                                        {DetailRecetas.Receta.Medico}
                                    </h3>
                                    <p style={{ margin: "0" }}>
                                        {DetailRecetas.Receta.Profesion}
                                    </p>
                                    <p style={{ margin: "0" }}>
                                        Cedula: {DetailRecetas.Receta.Cedula}
                                    </p>
                                    <p style={{ margin: "0" }}>
                                        YGIA ID: {DetailRecetas.Receta.IdContacto}
                                    </p>

                                    <p style={{ margin: "1.5%" }}>
                                        {DetailRecetas.Receta.Subtitulo}
                                    </p>
                                </div>
                                <div className="ContentMidRec">
                                    <p style={{ fontWeight: "500", color: "3a3a3a" }}>
                                        Medicamentos:
                                    </p>
                                    {DetailRecetas.Descripcion.map((medicamento) => (
                                        <p style={{ marginLeft: "14%", marginRight: "14%" }}>
                                            {medicamento.Cantidad +
                                                " " +
                                                medicamento.Concepto +
                                                ", " +
                                                medicamento.Descripcion}
                                        </p>
                                    ))}
                                    <br />
                                    <p style={{ fontWeight: "500", color: "3a3a3a" }}>
                                        Indicaciones:
                                    </p>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <TextArea
                                            autoSize
                                            readOnly
                                            bordered={false}
                                            style={{
                                                marginLeft: "10%",
                                                marginRight: "10%",
                                                width: "75%",
                                                color: "black",
                                                fontSize: "16px",
                                            }}
                                            value={DetailRecetas.Receta.Indicaciones}
                                        />
                                    </div>
                                    <p style={{ fontWeight: "500", color: "3a3a3a" }}>
                                        Paciente:
                                    </p>
                                    <p style={{ color: "#0e67a1", fontWeight: "500" }}>
                                        {" "}
                                        {DetailRecetas.Receta.Contacto}
                                    </p>
                                </div>
                                <div className="ContentMidRec">
                                    <p>Receta: {DetailRecetas.Receta.Folio}</p>
                                    <p>Fecha: {DetailRecetas.Receta.Fecha}</p>
                                </div>
                                <div className="ContentMidRec">
                                    <p>{DetailRecetas.Receta.Anexo}</p>
                                </div>
                                <div className="ContentMidRec">
                                    <p>Firma</p>
                                    {!!DetailRecetas.Receta.FirmaMedico ? (
                                        <img
                                            style={{ width: "30%", height: "10vh" }}
                                            src={"https://" + DetailRecetas.Receta.FirmaMedico}
                                            alt=""
                                        />
                                    ) : (
                                        <p>Sin Firma</p>
                                    )}
                                    <p>QR Receta</p>
                                    <Space direction="vertical" align="center">
                                        <QRCode value={DetailRecetas.Receta.Url} size={100} />
                                    </Space>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </ModalRecetasDetalle>


            <ModalCostoConsulta isOpen={IsOpenCostoConsulta} closeModal={ClosedCostoConsulta} optionClose={true} styleDet={styleMot}>
                <div
                    style={{
                        width: "100%",
                        background: "#148f9f",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "1% 2%",
                    }}
                >
                    Costo de consulta
                    <CloseIcon style={{ cursor: "pointer", fontSize: "20px" }} onClick={ClosedCostoConsulta} />

                </div>
                <div className="scrolCostoConsulta">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <InputNumber
                            prefix="$"
                            value={CostoConsulta}
                            placeholder="0.00"
                            className="inputblue"
                            style={{ width: "100%", margin: "3% 0" }}
                            formatter={(value) => `${value}`
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(e) => {
                                // console.log(e);
                                setCostoConsulta(e);

                            }}
                            disabled={ConsultaSinCargo}
                        />
                    </div>
                    <Checkbox
                        style={{ margin: "3% 0" }}
                        onChange={(e) => {
                            setConsultaSinCargo(e.target.checked);
                            if (e.target.checked) {
                                setCostoConsulta(0);
                            } else {
                                setCostoConsulta(null);
                            }
                        }}
                    >Consulta sin costo</Checkbox>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            style={{
                                background: "#148F9F",
                                border: "#148F9F",
                                color: "white"
                            }}
                            onClick={() => {
                                if (!!CostoConsulta || ConsultaSinCargo) {
                                    ClosedCostoConsulta(true);
                                    newAddConsultaFormData();
                                } else {
                                    setOpen(true);
                                    setTypeMessage("warning");
                                    setMessage('Añada un costo a la consulta o marque las casilla "Consulta sin costo"');
                                }
                            }}>Continuar</Button>
                    </div>
                </div>
            </ModalCostoConsulta>

            <ModalResumenMedico isOpen={IsOpenResumenMedico} closeModal={ClosedResumenMedico} optionClose={false} styleDet={styleResMed}>
                <div
                    style={{
                        width: "100%",
                        background: "#148f9f",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "1% 2%",
                    }}
                >
                    Resumen médico
                    <CloseIcon style={{ cursor: "pointer", fontSize: "20px" }} onClick={() => { ClosedResumenMedico(true); setResumenMedico(""); }} />

                </div>
                <div className="scrolResumenDeConsulta">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <TextAreaAntd
                            label=""
                            value={ResumenMedico}
                            placeholder="Resumen médico"
                            style={{
                                marginBottom: ".5%",
                                fontFamily: "sans-serif",
                            }}
                            columns="100"
                            autoSize={true}
                            setStateValue={setResumenMedico}
                            setStateError={setMensajeResumenMedico}
                            valueError={MensajeResumenMedico}
                            textError={["Debe escribir un resumen médico"]}
                            vacio={true}
                        />
                    </div>
                    {loaderResumenMedico ? (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress size={40} sx={{ color: "#148f9f" }} />
                        </div>
                    ) : (
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <Button
                                className='GeneralBotonWhite'
                                onClick={() => {
                                    if (!!ConfigReceta) {
                                        vistaPreviaResumenMedico();
                                    } else {
                                        setOpenMensajeConfig(true);
                                        setTypeMessage("warning");
                                        setMessage("Debe agregar una configuracion de formato");
                                    }
                                }}>Vista previa</Button>
                            {AcCionResumen === 0 ? (
                                <Button
                                    className='GeneralBoton'
                                    onClick={() => {
                                        if (!!ResumenMedico) {
                                            ClosedResumenMedico(true);
                                            OpenCostoConsulta(true);

                                        } else {
                                            setMensajeResumenMedico("false");
                                            setOpen(true);
                                            setTypeMessage("warning");
                                            setMessage("Faltan campos por completar");
                                        }
                                    }}>Continuar</Button>
                            ) : (
                                <Button
                                    className='GeneralBoton'
                                    onClick={() => {
                                        if (!!ResumenMedico) {
                                            ClosedResumenMedico(true);
                                            addResumenMedico();
                                        } else {
                                            setMensajeResumenMedico("false");
                                            setOpen(true);
                                            setTypeMessage("warning");
                                            setMessage("Faltan campos por completar");
                                        }
                                    }}>Crear</Button>
                            )}

                        </div>
                    )}



                </div>
            </ModalResumenMedico>

            <ModalConfig
                optionClose={true}
                styleDet={styleDetail}
                isOpen={isOpenConfig}
                closeModal={CloseConfig}
            >
                <div className="Topdetail" style={{ color: "white" }}>
                    Configuración de formatos de impresión
                    <CloseIcon style={{ cursor: "pointer", color: "white", fontSize: "20" }}
                        onClick={() => {
                            CloseConfig(true);
                        }} />

                </div>
                {LoaderModal ? (
                    <LoaderModals />
                ) : (
                    <div className="configReceta">
                        <form action="" style={{ padding: "0" }}>
                            <div className="contenedorimg">
                                <div title='Imagen de identificación de su empresa'>
                                    {/* <Tooltip> */}
                                    <label htmlFor="logoFromConsulta" >

                                        <img
                                            style={{ cursor: "pointer" }}
                                            src={Logo.url !== "" ? Logo.url : Iconos[19]}
                                            alt=""
                                        />
                                    </label>
                                    <input
                                        type="file"
                                        id="logoFromConsulta"
                                        accept="image/png,image/jpeg"
                                        style={{ display: "none" }}
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file && file.type.substring(0, 5) === "image") {
                                                setLogo({
                                                    name: e.target.value,
                                                    url: URL.createObjectURL(e.target.files[0]),
                                                    file: e.target.files[0],
                                                });
                                            } else {
                                                setOpen(true);
                                                setTypeMessage("warning");
                                                setMessage("Formato no válido (solo imagenes)");
                                            }
                                        }}
                                    />
                                    {/* </Tooltip> */}
                                </div>
                            </div>
                            <div style={{ margin: "1% 2%" }}>
                                <InputAntd
                                    className="inputblue"
                                    value={Encabezado}
                                    setStateValue={setEncabezado}
                                    label="Encabezado:"
                                    placeholder="Nombre de clinica, hospital, etc."
                                    vacio={false}
                                    setStateError={setMensajeEncabezado}
                                    valueError={MensajeEncabezado}
                                    textError={["Debe escribir un encabezado"]}
                                />
                                <TextAreaAntd
                                    label="Pie de pagina:"
                                    value={PiePagina}
                                    placeholder="Direccion, horario, telefono, etc."
                                    style={{
                                        marginBottom: ".5%",
                                        fontFamily: "sans-serif",
                                    }}
                                    columns="30"
                                    autoSize={true}
                                    setStateValue={setPiePagina}
                                    setStateError={setMensajePiePagina}
                                    valueError={MensajePiePagina}
                                    textError={["Debe escribir un pie de pagina"]}
                                    vacio={false}
                                />
                                <div style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}>
                                    <InputFileSingle id={"logoFromConsultaPlan"} archivoValue={Firma} texto={"Adjuntar firma"} archivo={setFirma} formatos={5} extras={[setOpen, setTypeMessage, setMessage, setMensajeFirma]} />
                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Button

                                        onClick={() => {
                                            saveConfig();
                                        }}
                                        style={{
                                            width: "45%",
                                            height: "30px",
                                            backgroundColor: "#148f9f",
                                            color: "white",
                                        }}>Guardar</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </ModalConfig>

            <ModalAlertConfirm
                isOpen={IsOpenAlertConfirm}
                closeModal={ClosedAlertConfirm}
                optionClose={true}
                styleDet={styleConfirm}
            >
                <div
                    style={{
                        width: "100%",
                        background: "#148f9f",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "1% 2%",
                    }}
                >
                    ¡Advertencia!
                    <CloseIcon style={{ cursor: "pointer", fontSize: "20px" }} onClick={() => { ClosedAlertConfirm(); setLink(""); }} />

                </div>
                <div className="ModalListContactosConsult" style={{ padding: "2%" }}>
                    <div style={{ display: "flex", justifyContent: "center", margin: "10px 0" }}>
                        <b>¿Desea salir de la consulta?</b>
                    </div>
                    <div style={{ display: "flex", gap: "1%" }}>
                        <Button
                            style={{
                                width: "100%",
                                background: "#148f9f",
                                borderColor: "#148f9f",
                                color: "white",
                            }}
                            size="sm"
                            onClick={() => {
                                ClosedAlertConfirm(true);
                                setLink("");
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            style={{
                                width: "100%",
                                background: "#148f9f",
                                borderColor: "#148f9f",
                                color: "white",
                            }}
                            size="sm"
                            onClick={() => {
                                ReactGA.event({
                                    category: "Consultas",
                                    action: "Salir consulta",
                                    label: "Accion salir a otro modulo, sin guardar", // optional
                                    value: 99, // optional, must be a number
                                    nonInteraction: true, // optional, true/false
                                    transport: "xhr", // optional, beacon/xhr/image
                                });
                                ClosedAlertConfirm(true);
                                window.onbeforeunload = null;
                                navigate(Link, { replace: true });
                            }}
                        >
                            Salir
                        </Button>
                        <Button
                            style={{
                                width: "100%",
                                background: "#148f9f",
                                borderColor: "#148f9f",
                                color: "white",
                            }}
                            size="sm"
                            onClick={() => {
                                ReactGA.event({
                                    category: "Consultas",
                                    action: "Salir consulta",
                                    label: "Accion salir a otro modulo, guardando consulta", // optional
                                    value: 99, // optional, must be a number
                                    nonInteraction: true, // optional, true/false
                                    transport: "xhr", // optional, beacon/xhr/image
                                });
                                if (!!MotivoConsulta && DiagnosticosSeleccionadosMostrados.length > 0 && !!Plan) {
                                    ClosedAlertConfirm(false);
                                    OpenCostoConsulta(true);
                                } else {
                                    setEstatusModalSalirConsulta(false);
                                    if (!!!MotivoConsulta) {
                                        setMensajeMotivoConsulta("false");
                                    }
                                    if (DiagnosticosSeleccionadosMostrados.length === 0) {
                                        setErrorDiagnosticoAccordion(false);
                                    }
                                    if (!!!Plan) {
                                        setErrorPlanAccordion(false);
                                        setMensajePlan("false");
                                    }

                                    setOpen(true);
                                    setTypeMessage("warning");
                                    setMessage("Faltan campos por completar");
                                }
                            }}
                        >
                            Salir y finalizar
                        </Button>
                    </div>
                </div>
            </ModalAlertConfirm>

            {/* Comienza alert de finalizar consulta */}
            <ModalAlertConfirm
                isOpen={IsOpenAlertFinConsulta}
                closeModal={ClosedAlertFinConsulta}
                optionClose={true}
                styleDet={styleConfirm}
            >
                <div
                    style={{
                        width: "100%",
                        background: "#148f9f",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "1% 2%",
                    }}
                >
                    ¡Aviso!
                    <CloseIcon style={{ cursor: "pointer", fontSize: "20px" }} onClick={() => { ClosedAlertFinConsulta(true); }} />

                </div>
                <div className="ModalListContactosConsult" style={{ padding: "2%" }}>
                    <div style={{ display: "flex", justifyContent: "center", margin: "10px 0" }}>
                        <b>¿Desea crear un resumen médico?</b>
                    </div>
                    <div style={{ display: "flex", gap: "1%" }}>
                        <Button
                            style={{
                                width: "100%",
                                background: "#148f9f",
                                borderColor: "#148f9f",
                                color: "white",
                            }}
                            size="sm"
                            onClick={() => {
                                ClosedAlertFinConsulta(true);
                                OpenCostoConsulta(true);
                            }}
                        >
                            No
                        </Button>
                        <Button
                            style={{
                                width: "100%",
                                background: "#148f9f",
                                borderColor: "#148f9f",
                                color: "white",
                            }}
                            size="sm"
                            onClick={() => {
                                ClosedAlertFinConsulta(true);
                                setAccionResumen(0);
                                setCierreModalResumen(false);
                                crearResumenMedico();
                            }}
                        >
                            Si
                        </Button>
                    </div>
                </div>
            </ModalAlertConfirm>
            {/* Termina alert de finalizar consulta */}

            <ModalSelectContact
                isOpen={IsOpenModalContactos}
                closeModal={ClosedModalContactos}
                optionClose={true}
                styleDet={styleDet}
            >
                <div
                    style={{
                        width: "100%",
                        background: "#148f9f",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "1% 2%",
                    }}
                >
                    Selecciona un médico
                    <CloseIcon style={{ cursor: "pointer", color: "white", fontSize: "20" }}
                        onClick={() => {
                            ClosedModalContactos(true);
                        }} />

                </div>
                <div className="ModalListContactosConsult">
                    <p>Nombre</p>
                    <Input
                        placeholder="Buscar..."
                        type="search"
                        style={{ width: "100%", marginBottom: "10px" }}
                        onChange={filterContacts}
                    />
                    <br />
                    <p>Médicos:</p>
                    <div
                        className="contCardModal"
                        style={{ borderTop: "1px solid #d5d5d5" }}
                    >
                        <ModalMedicos
                            contactList={MedicosList}
                            closeModal={ClosedModalContactos}
                            setDatosMedico={setDatosMedico}
                            extras={
                                {
                                    setDoctorSelect: setDoctorSelect,
                                    setNombreMedico: setNombreMedico,
                                    setApellidoPMedico: setApellidoPMedico,
                                    setApellidoMMedico: setApellidoMMedico,
                                    setCelularMedico: setCelularMedico,
                                    setEmailMedico: setEmailMedico,
                                    setEspecialidadMedico: setEspecialidadMedico,
                                    setMensajeDoctorSelect: setMensajeDoctorSelect,
                                    setMensajeNombreMedico: setMensajeNombreMedico,
                                    setMensajeApellidoPMedico: setMensajeApellidoPMedico,
                                }
                            }
                        />
                    </div>
                </div>
            </ModalSelectContact>

            {contextHolder}
            <SnackBar
                open={open}
                message={Message}
                setOpen={setOpen}
                typeMessage={typeMessage}

            />

            <SnackBar
                open={openMensajeConfig}
                message={Message}
                setOpen={setOpenMensajeConfig}
                typeMessage={typeMessage}
            />



        </Stack >
    )
}

export default AddConsulta