import { Stack } from "@mui/material";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";
import "./styles/HomeView.scss"
import { useEffect, useState } from "react";
import { Avatar, Carousel, ConfigProvider, Progress, Row } from "antd";
import IconoReceta from "../Img/home_view/Recetas_bold.png"
import IconoChat from "../Img/home_view/Chat.png"
import IconoExpediente from "../Img/home_view/Expedientes.png"
import IconoSoporte from "../Img/home_view/Soporte.png"
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import { getEstadisticasMedico } from "../services/perfil_services";
import ReactGA from "react-ga4";

function HomeView() {
    const navigate = useNavigate();
    const location = useLocation();

    const data = useSelector((state) => state.userInfo.usuario);

    const [EstadisticasMedico, setEstadisticasMedico] = useState({ pacientes: 0, citas: 0, recetas: 0 });

    async function estadisticasMedico() {
        let response = await getEstadisticasMedico();
        if (response.ok) {
            setEstadisticasMedico({ pacientes: response.data.Pacientes ?? 0, citas: response.data.Citas ?? 0, recetas: response.data.Recetas ?? 0 });
        }
    }

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname,
            title: location.pathname,
        });
        estadisticasMedico();
        return () => {

        }
    }, [])

    return (
        <Stack>
            <NavbarNuevo
                modulo="inicio"
            />

            <Content
                className="site-layout"
                style={{
                    // padding: "0 %",
                    width: "100%",
                    overflow: "initial",
                    backgroundColor: 'white'
                }}>

                {/* APARTADO DE SALUDO Y GRAFICA */}
                <Row className="stadisticsContainer">
                    <div>
                        <h1 className="title">¡Hola!</h1>
                        <h2 className="doctorName">{data.Prefijo} {data.Nombre} {data.ApellidoPaterno} {data.ApellidoMaterno}</h2>
                    </div>
                    {/* <div className="chartContainer">
                        <div className="firstContainer">
                            <div>
                                <ConfigProvider theme={{ components: { Progress: { circleTextColor: "#fff" } } }}>
                                    <Progress type="circle" percent={70} trailColor="grey" strokeColor={"#ff940a"} strokeWidth={"10"} />
                                </ConfigProvider>
                                <div style={{ backgroundColor: "#ff940a", color: "#fff", borderRadius: "20px", textAlign: "center", marginTop: "10px" }}>&nbsp;&nbsp;Completa tu cuenta&nbsp;&nbsp;</div>
                            </div>

                        </div>
                        <div className="secondContainer"></div>
                    </div> */}
                </Row>

                {/* APARTADO DE CARRUSEL */}
                <ConfigProvider theme={{ token: { colorBgContainer: "#ff940a", colorText: "#d6d6d6" } }}>
                    <Carousel afterChange={() => { }} style={{ marginTop: "5vh", padding: '0 8%' }}>
                        <div >
                            <div className="cardsContainer">
                                <div className="firstCard">
                                    <div className="gradient-top-left"></div>
                                    <div className="containerContent">
                                        <img src={IconoReceta} />
                                        <p>Genera tu <br /> primera receta</p>
                                    </div>
                                    <div className="gradient-bottom-right"></div>
                                </div>

                                {/* <div className="secondCard">
                                        <div className="gradient-top-left"></div>
                                        <Container className="site-layout"
                                            style={{
                                                padding: "0 3%",
                                                width: "100%",
                                                overflow: "initial",
                                            }}>
                                            <div style={{ marginLeft: "45px", marginTop: "10px", textAlign: "start", flexDirection: "column" }}>
                                                <h3>Expedientes</h3>
                                                <div><Avatar >U1</Avatar></div>
                                                <div><Avatar >U2</Avatar></div>
                                                <div><Avatar >U3</Avatar></div>
                                                <div><Avatar >U4</Avatar></div>
                                                <div><Avatar >U5</Avatar></div>
                                            </div>
                                        </Container>
                                        <div className="gradient-bottom-right"></div>
                                    </div> */}
                            </div>
                        </div>
                        <div >
                            <div className="cardsContainer">
                                <div className="firstCard">
                                    <div className="gradient-top-left"></div>
                                    <h2>2</h2>
                                    <div className="gradient-bottom-right"></div>
                                </div>

                                {/* <div className="secondCard">
                                        <div className="gradient-top-left"></div>
                                        <h2>2</h2>
                                        <div className="gradient-bottom-right"></div>
                                    </div> */}
                            </div>
                        </div>
                        <div >
                            <div className="cardsContainer">
                                <div className="firstCard">
                                    <div className="gradient-top-left"></div>
                                    <h2>3</h2>
                                    <div className="gradient-bottom-right"></div>
                                </div>

                                {/* <div className="secondCard">
                                        <div className="gradient-top-left"></div>
                                        <h2>3</h2>
                                        <div className="gradient-bottom-right"></div>
                                    </div> */}
                            </div>
                        </div>
                        <div >
                            <div className="cardsContainer">
                                <div className="firstCard">
                                    <div className="gradient-top-left"></div>
                                    <h2>4</h2>
                                    <div className="gradient-bottom-right"></div>
                                </div>

                                {/* <div className="secondCard">
                                        <div className="gradient-top-left"></div>
                                        <h2>4</h2>
                                        <div className="gradient-bottom-right"></div>
                                    </div> */}
                            </div>
                        </div>
                        <div >
                            <div className="cardsContainer">
                                <div className="firstCard">
                                    <div className="gradient-top-left"></div>
                                    <h2>5</h2>
                                    <div className="gradient-bottom-right"></div>
                                </div>

                                {/* <div className="secondCard">
                                        <div className="gradient-top-left"></div>
                                        <h2>5</h2>
                                        <div className="gradient-bottom-right"></div>
                                    </div> */}
                            </div>
                        </div>
                    </Carousel>
                </ConfigProvider>

                {/* APARTADO DE "RESUMEN DE TU CUENTA" */}
                <div className="imgContainerResumen">
                    <div className="colorBlurContainer">
                        <h2 style={{ color: '#fff' }}>Resumen de tu cuenta:</h2>
                        <div style={{ display: 'flex', justifyContent: "space-around", paddingTop: '5%' }}>
                            <div className="resumeContainer" onClick={() => {
                                navigate("/app/Contactos", { replace: false });
                            }}>
                                <div className="cardNumberContainer">{EstadisticasMedico.pacientes}</div>
                                <div className="textModule">Pacientes</div>
                                <div style={{ backgroundColor: '#fff' }}><div className="seeMoreContainer">Ver más</div></div>
                            </div>
                            <div className="resumeContainer" onClick={() => {
                                navigate("/app/citas", { replace: false });
                            }}>
                                <div className="cardNumberContainer">{EstadisticasMedico.citas}</div>
                                <div className="textModule">Citas</div>
                                <div style={{ backgroundColor: '#fff' }}><div className="seeMoreContainer">Ver más</div></div>
                            </div>
                            <div className="resumeContainer" onClick={() => {
                                navigate("/app/Recetas", { replace: false });
                            }}>
                                <div className="cardNumberContainer">{EstadisticasMedico.recetas}</div>
                                <div className="textModule">Recetas</div>
                                <div style={{ backgroundColor: '#fff' }}><div className="seeMoreContainer">Ver más</div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ backgroundColor: '#ff940a', marginLeft: '8%', height: '1.5vh', width: '85%' }}></div>
                <br />
                {/* APARTADO DE "HERRAMIENTAS" */}
                <div style={{ padding: '0 8%' }}>
                    <h2 style={{ color: '#ff940a' }}>Herramientas</h2>
                    <Row className="toolsContainer first">
                        <div className="moduleCard" style={{ backgroundColor: '#55C7C8' }} onClick={() => { navigate("/app/ExpedientesNuevo", { replace: false }); }}>
                            <img src={IconoExpediente} style={{ width: '80px', height: '80px' }} />
                            <div style={{ marginLeft: '15px' }}>
                                <h3>Expedientes</h3>
                                <p>
                                    Accede de manera rápida y segura a historiales médicos completos y detallados de tus pacientes.
                                </p>
                            </div>
                        </div>
                        <div className="moduleCard" style={{ backgroundColor: '#3B7DB2' }} onClick={() => { navigate("/app/chats", { replace: false }); }}>
                            <img src={IconoChat} style={{ width: '80px', height: '80px' }} />
                            <div style={{ marginLeft: '15px' }}>
                                <h3>Chat</h3>
                                <p>
                                    Comunícate efcientemente con tus pacientes, enviando y recibiendo imágenes y documentos en cualquier momento.
                                </p>
                            </div>
                        </div>
                    </Row>
                    <Row className="toolsContainer second">
                        <div className="moduleCard" style={{ backgroundColor: '#3B7DB2' }} onClick={() => { navigate("/app/Recetas", { replace: false }); }}>
                            <img src={IconoReceta} style={{ width: '80px', height: '80px' }} />
                            <div style={{ marginLeft: '15px' }}>
                                <h3>Recetas</h3>
                                <p>
                                    Prescripciones médicas seguras y válidas, facilitando tratamientos efcientes para mejorar la atención de los pacientes.
                                </p>
                            </div>
                        </div>
                        <div className="moduleCard" style={{ backgroundColor: '#55C7C8' }} onClick={() => { navigate("/app/Ayuda", { replace: false }); }}>
                            <img src={IconoSoporte} style={{ width: '80px', height: '90px' }} />
                            <div style={{ marginLeft: '15px' }}>
                                <h3>Soporte técnico</h3>
                                <p>
                                    Para cualquier inconveniente, no dudes en contactarnos. Nuestro equipo de soporte está disponible para ayudarte.
                                </p>
                            </div>
                        </div>
                    </Row>
                </div>
                <br />
            </Content>
        </Stack>
    );
}

export default HomeView;